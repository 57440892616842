import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button } from "@material-ui/core";

export default function BackButton(props) {
    //画面遷移用
    const navigate = useNavigate();

    //---------------------------issue{No.84} start-----------------------------
    // props から遷移先のpathを分割代入
    // ※初期値は前の画面
    const { path = -1 } = props;
    //---------------------------issue{No.84} end-------------------------------

    // 戻るボタンをクリックしたら、情報一覧画面に戻る
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        //---------------------------issue{No.84} start-----------------------------
        navigate(path);
        //---------------------------issue{No.84} end-------------------------------
    };

    return (
        <Box sx={{ width: "max-content", mr: 1 }}>
            <Button variant='contained' onClick={(e) => handleOnClickBack(e)}>
                戻る
            </Button>
        </Box>
    );
}
