//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useState, useContext, useEffect } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useParams, useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import {
    Button,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import DataShow from "../../components/DataShow";
import DataTable from "../../components/DataTable";
import BackButton from "../../components/BackButton";
import CloseButton from "../../components/CloseButton";
import RequiredMark from "../../components/RequiredMark";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { UserContext } from "../../providers/UserProvider";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import processResponse from "../../common/processResponse";
import processErrorResponse from "../../common/processErrorResponse";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import "react-datepicker/dist/react-datepicker.css";
// MUIのTooltipのz-indexを調整
const StyledTooltip = withStyles({
    "@global": {
        ".MuiTooltip-popper": {
            zIndex: "1300",
        },
    },
})(Tooltip);
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// 戻るときの遷移先のパス
const returnPath = "/suspense_payment";
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// user の初期状態
const initSuspensePayments = { result: [], success: false };
// 見出しの配列のパターンを定義
const headers = [
    { id: "start_date", label: "開始", required: true },
    { id: "end_date", label: "終了", required: true },
    { id: "detail", label: "内容", required: true },
    { id: "delete", label: "-", required: false },
];
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function SuspensePaymentConfirmEdit() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面遷移用
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // ログイン中のユーザー情報を取得
    const { userInfo } = useContext(UserContext);
    const userName = userInfo.user.user_name; // ユーザー名
    const loggedInUserId = userInfo.user.user_id; // id
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 表示対象の仮払い情報のid
    const { id } = useParams();
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 仮払いの状態を管理する
    const [suspensePayments, setSuspensePayments] = useState(initSuspensePayments);

    const [isDeleted, setIsDeleted] = useState([]);

    const [loaded, setLoaded] = useState(false); // 読み込み完了のステータスを管理

    // 表示用のデータを格納するステート
    const [suspensePaymentData, setSuspensePaymentData] = useState([]);
    // 表示用のデータ(detail)を格納するステート
    const [suspensePaymentDetailData, setSuspensePaymentDetailData] = useState([]);

    const [suspensePaymentDetailLastData, setSuspensePaymentDetailLastData] = useState({});

    const [suspensePaymentAdditionalNumbers, setSuspensePaymentAdditionalNumbers] = useState(1);

    const [suspensePaymentDetailNumbers, setSuspensePaymentDetailNumbers] = useState(-1);

    const [displayErrorMessage, setDisplayErrorMessage] = useState(false); // バリデーションエラーメッセージ表示切替

    const [displayErrorArea, setDisplayErrorArea] = useState(false); // バリデーションエラーのスタイル表示切替

    const [spensePaymentRequestData, setSpensePaymentRequestData] = useState({});

    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 仮払い情報を取得しステート setSuspensePayments にセットする
    const getSuspensePaymentsData = async (id) => {
        const url = `suspense_payments/${id}`;
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        processResponse(res, setSuspensePayments, navigate, returnPath);
    };

    // 登録
    const sendSuspensePaymentRequestData = async () => {
        const id = suspensePayments.result["suspense_payment"]?.suspense_payment_id;
        let detail = [...Array(3)].map(() => []);
        if (Array.isArray(spensePaymentRequestData?.suspense_payment_detail)) {
            const detailData = [...spensePaymentRequestData.suspense_payment_detail];

            detail[0] = detailData
                .filter((element) => element.flg == "create")
                .map((item) => {
                    return {
                        start_date: item.start_date,
                        end_date: item.end_date,
                        detail: item.detail,
                    };
                });
            detail[1] = detailData
                .filter((element) => element.flg == "update")
                .map((item) => {
                    return {
                        id: item.id,
                        start_date: item.start_date,
                        end_date: item.end_date,
                        detail: item.detail,
                    };
                });
            detail[2] = detailData
                .filter((element) => element.flg == "delete")
                .map((item) => {
                    return {
                        id: item.id,
                    };
                });
        }
        const requestData = {
            suspense_payment: spensePaymentRequestData.suspense_payment ?? null,
            suspense_payment_detail: {
                create: detail[0],
                update: detail[1],
                delete: detail[2],
            },
        };
        const flg = 1;
        const url = `suspense_payments/${flg}/${id}`;
        const res = await api.put(url, requestData);
        // 成否の確認
        if (res.data.success) {
            // 登録成功時
            await swal(res.data.message, "", "success");

            // 年月選択前の状態に戻る
            navigate("/suspense_payment");
        } else {
            // 登録失敗時にエラーに応じた処理を行う
            processErrorResponse(res, () => {}, navigate);
        }
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const suspensePaymentsSubmit = async (e) => {
        // イベントの伝搬を中止
        e.preventDefault();
        // エラーの有無により処理を中断する
        if (spensePaymentRequestData.length === 0) {
            await swal({ title: "", text: "入力の内容を見直してください", icon: "warning" }).then(() => {});
            return;
        } else {
            await swal({ title: "確認", text: "登録します", icon: "info", buttons: ["中止", "登録"] }).then((value) => {
                if (value) {
                    // リクエストデータをapiで送信する処理へ進む
                    sendSuspensePaymentRequestData();
                }
            });
        }
    };
    // 削除・復元ボタン押下時の処理
    const handleOnClickDeleteAndRestore = (e, num, data, flg, id) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // trigger(); // バリデーション実行
        let isDeletedData = isDeleted;
        isDeletedData[num] = !isDeletedData[num];
        // 行の削除状態を切り替える
        setIsDeleted([...isDeletedData]);

        if (isDeleted[num]) {
            deleteData(data, num, flg, id);
        } else {
            createDataForRevival(data, num, id); // リクエストデータを準備する
        }
    };
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面初期表示時に仮払い情報を取得する
    useEffect(() => {
        getSuspensePaymentsData(id);
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        control,
        watch,
    } = useForm();

    // 仮払い情報取得後、表示用ステートに値をセット
    useEffect(() => {
        if (suspensePayments.success) {
            // showDataステートに格納する配列を作成
            const suspensePaymentsResult = suspensePayments.result;

            const suspensePayment = {
                id: suspensePaymentsResult["suspense_payment"]?.suspense_payment_id,
                data: [
                    {
                        label: "作成日",
                        id: `date`,
                        name: `suspense_payment.date`,
                        type: "date",
                        value: suspensePaymentsResult["suspense_payment"]?.suspense_payment_date,
                        rule: {
                            required: {
                                value: true,
                                message: "*作成日を入力してください。",
                            },
                        },
                    },
                    {
                        label: "受領日",
                        id: `receipt_date`,
                        name: `suspense_payment.receipt_date`,
                        type: "date",
                        value: suspensePaymentsResult["suspense_payment"]?.suspense_payment_receipt_date,
                        rule: {
                            required: {
                                value: true,
                                message: "*受領日を入力してください。",
                            },
                        },
                    },
                    {
                        label: "金額",
                        id: `amount`,
                        name: `suspense_payment.amount`,
                        type: "number",
                        value: suspensePaymentsResult["suspense_payment"]?.suspense_payment_amount,
                        rule: {
                            required: {
                                value: true,
                                message: "*金額を入力してください。",
                            },
                        },
                    },
                ],
            };

            let suspensePaymentDetail = suspensePaymentsResult["suspense_payment_detail"].map(
                (suspense_payment_detail, index) => {
                    return {
                        data: [
                            {
                                name: `suspense_payment_detail.${index}.id`,
                                type: "hidden",
                                value: suspense_payment_detail?.suspense_payment_detail_id,
                                hidden: true,
                            },
                            {
                                name: `suspense_payment_detail.${index}.flg`,
                                type: "hidden",
                                value: "update",
                                hidden: true,
                            },
                            {
                                name: `suspense_payment_detail.${index}.start_date`,
                                type: "date",
                                value: suspense_payment_detail?.suspense_payment_detail_start_date,
                                hidden: false,
                                rule: {
                                    required: {
                                        value: true,
                                        message: "*開始日を入力してください。",
                                    },
                                },
                            },
                            {
                                name: `suspense_payment_detail.${index}.end_date`,
                                type: "date",
                                value: suspense_payment_detail?.suspense_payment_detail_end_date,
                                hidden: false,
                                rule: {
                                    required: {
                                        value: true,
                                        message: "*終了日を入力してください。",
                                    },
                                },
                            },
                            {
                                name: `suspense_payment_detail.${index}.detail`,
                                type: "text",
                                value: suspense_payment_detail?.suspense_payment_detail_detail,
                                hidden: false,
                                rule: {
                                    required: {
                                        value: true,
                                        message: "*内容を入力してください。",
                                    },
                                },
                            },
                        ],
                    };
                }
            );

            // ステートに格納
            setSuspensePaymentData(suspensePayment);
            setSuspensePaymentDetailData(suspensePaymentDetail);
            setSuspensePaymentDetailNumbers(suspensePaymentDetail.length);
        }
    }, [suspensePayments]);
    useEffect(() => {
        if (suspensePayments.success) {
            if (Object.keys(suspensePaymentData).length > 0) {
                // 編集対象の場合の処理
                // 各フォームに値をセット
                suspensePaymentData.data.map((input) => {
                    setValue(input.name, input.value ?? "", {
                        shouldValidate: false,
                    });
                });
            }
            if (Object.keys(suspensePaymentDetailData).length > 0) {
                // 編集対象の場合の処理
                // 各フォームに値をセット
                suspensePaymentDetailData.map((row) => {
                    row.data.map((input) => {
                        setValue(input.name, input.value ?? "", {
                            shouldValidate: false,
                        });
                    });
                });
                let isDeletedData = isDeleted;
                suspensePaymentDetailData.map((_, num) => {
                    isDeletedData[num] = false;
                });
                setIsDeleted([...isDeletedData]);
                const suspensePaymentDetailLast = {
                    data: [
                        {
                            name: `flg`,
                            type: "hidden",
                            value: "create",
                            hidden: true,
                        },
                        {
                            name: `start_date`,
                            type: "date",
                            value: "",
                            hidden: false,
                            rule: {
                                required: {
                                    value: true,
                                    message: "*開始日を入力してください。",
                                },
                            },
                        },
                        {
                            name: `end_date`,
                            type: "date",
                            value: "",
                            hidden: false,
                            rule: {
                                required: {
                                    value: true,
                                    message: "*終了日を入力してください。",
                                },
                            },
                        },
                        {
                            name: `detail`,
                            type: "text",
                            value: "",
                            hidden: false,
                            rule: {
                                required: {
                                    value: true,
                                    message: "*内容を入力してください。",
                                },
                            },
                        },
                    ],
                };
                setSuspensePaymentDetailLastData(suspensePaymentDetailLast);
            }
            setLoaded(true);
        }
    }, [suspensePaymentDetailData, suspensePaymentData]);

    // 送信するデータを受け取った配列に更新する
    const updateRowRequest = async (name, value) => {
        let max = suspensePaymentDetailNumbers - 1 + suspensePaymentAdditionalNumbers;
        const split_name = name.split(".");
        let rows_name = "";
        let row_name = "";
        let row_number = "";
        if (split_name.length == 3) {
            rows_name = split_name[0];
            row_name = `${split_name[0]}.${split_name[1]}`;
            row_number = split_name[1];
        } else {
            rows_name = split_name[0];
            row_name = split_name[0];
            row_number = 0;
        }

        let changed_values = getValues(row_name);
        const changed_value = getValues(name);
        // 新規作成中の行にて,どれか1項目だけでも値が入力されている場合
        if (row_number == max && changed_value != "") {
            changed_values = { ...changed_values, flg: "create", number: row_number };
            setValue(`${row_name}.flg`, "create");

            // let isDeletedData = isDeleted;
            // isDeletedData[suspensePaymentAdditionalNumbers + 1] = false;
            setIsDeleted((prev) => [...prev, false]);
            // 新しい行を追加
            setSuspensePaymentAdditionalNumbers(suspensePaymentAdditionalNumbers + 1);
        }
        createDataForUpdate(rows_name, changed_values, row_number); // リクエストデータを準備する
    };

    // 復活用：リクエストデータを作成し、ステートで更新する
    const createDataForRevival = (value, num, id) => {
        // バリデーションエラーについて更新
        // if (Object.keys(validationErrorsRef.current).length > 0) {
        //     dispatchSuspensePaymentValidationErrors({ type: "add", number: number });
        // } else {
        //     dispatchSuspensePaymentValidationErrors({ type: "remove", number: number });
        // }

        let requestData = spensePaymentRequestData;

        if (requestData?.suspense_payment_detail != null) {
            let detailData = [];
            if (requestData?.suspense_payment_detail.some((item) => item.id == id)) {
                detailData = requestData?.suspense_payment_detail.map((item) => {
                    if (item.id == id) {
                        return value;
                    } else {
                        return item;
                    }
                });
            } else {
                detailData = [...requestData?.suspense_payment_detail, { ...value, number: num }];
            }

            // 送信するデータを作成
            requestData = { ...requestData, suspense_payment_detail: detailData };
        } else {
            // 送信するデータを作成
            requestData = { ...requestData, suspense_payment_detail: [{ ...value, number: num }] };
        }
        // リクエストデータのステートを更新
        setSpensePaymentRequestData(requestData);
    };
    // 新規・更新：リクエストデータを作成し、ステートで更新する
    const createDataForUpdate = (name, value, num) => {
        // バリデーションエラーについて更新
        // if (Object.keys(validationErrorsRef.current).length > 0) {
        //     dispatchSuspensePaymentValidationErrors({ type: "add", number: number });
        // } else {
        //     dispatchSuspensePaymentValidationErrors({ type: "remove", number: number });
        // }

        let requestData = spensePaymentRequestData;

        if (name == "suspense_payment") {
            // 送信するデータを作成
            requestData = { ...requestData, [name]: value };
        } else {
            if (requestData?.suspense_payment_detail != null) {
                let detailData = [];
                if (requestData?.suspense_payment_detail.some((item) => item.number == num)) {
                    detailData = requestData?.suspense_payment_detail.map((item) => {
                        if (item.number == num) {
                            return { ...value, number: num };
                        } else {
                            return item;
                        }
                    });
                } else {
                    detailData = [...requestData?.suspense_payment_detail, { ...value, number: num }];
                }

                // 送信するデータを作成
                requestData = { ...requestData, [name]: detailData };
            } else {
                // 送信するデータを作成
                requestData = { ...requestData, [name]: [{ ...value, number: num }] };
            }
        }
        // リクエストデータのステートを更新
        setSpensePaymentRequestData(requestData);
    };
    // リクエストデータを作成し、ステートで更新する
    const deleteData = (value, key, flg, id) => {
        // バリデーションエラーについて更新
        // if (Object.keys(validationErrorsRef.current).length > 0) {
        //     dispatchSuspensePaymentValidationErrors({ type: "add", number: number });
        // } else {
        //     dispatchSuspensePaymentValidationErrors({ type: "remove", number: number });
        // }
        let requestData = [];
        let data_flg = [];
        if (flg == 0) {
            data_flg = value.filter((element) => element.name.split(".")[2] == "flg");
        } else {
            data_flg = value.filter((element) => element.name == "flg");
        }
        switch (data_flg[0].value) {
            case "update":
                let data = spensePaymentRequestData;
                if (data?.suspense_payment_detail != undefined) {
                    if (data?.suspense_payment_detail.some((element) => element.id == id)) {
                        detailData = data?.suspense_payment_detail.map((item) => {
                            if (item.id == id) {
                                return { flg: "delete", id: id, number: key };
                            } else {
                                return item;
                            }
                        });
                        data.suspense_payment_detail = detailData;
                    } else {
                        data.suspense_payment_detail = [
                            ...data.suspense_payment_detail,
                            {
                                flg: "delete",
                                id: id,
                                number: key,
                            },
                        ];
                    }
                } else {
                    data = {
                        suspense_payment_detail: [
                            {
                                flg: "delete",
                                id: id,
                                number: key,
                            },
                        ],
                    };
                }
                requestData = data;
                break;
            case "create":
                const deletedData = data.suspense_payment_detail.filter((element) => element.number != key);
                data.suspense_payment_detail = deletedData;
                requestData = data;
                break;
            default:
        }

        // リクエストデータのステートを更新
        setSpensePaymentRequestData(requestData);
    };
    // 内容が変更されたかを監視
    useEffect(() => {
        if (suspensePaymentDetailNumbers != -1) {
            const subscription = watch(async (value, { name, type }) => {
                if (type === "change") {
                    // await trigger(); // バリデーションを実行
                    await updateRowRequest(name, value);
                }
            });
            return () => subscription.unsubscribe();
        }
    }, [watch, suspensePaymentDetailNumbers, suspensePaymentAdditionalNumbers, spensePaymentRequestData, isDeleted]);

    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-10 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1>仮払い情報更新</h1>
                        <div className='ms-auto'>
                            <CloseButton path={returnPath} />
                        </div>
                    </div>
                    {loaded && (
                        <div className='card-body'>
                            <form id='form'>
                                {
                                    <div className='d-flex justify-content-start align-items-center text-nowrap mt-4 mb-4'>
                                        {suspensePaymentData.data.map((row, index) => {
                                            return (
                                                <React.Fragment key={`suspensePaymentData-${index}`}>
                                                    <label className='fw-bold' htmlFor={row.id}>
                                                        {row.label}：
                                                    </label>
                                                    <StyledTooltip
                                                        className='me-3'
                                                        arrow={true}
                                                        title={
                                                            displayErrorMessage && formState.errors[row.name]
                                                                ? formState.errors[row.name]?.message
                                                                : ""
                                                        }>
                                                        <div
                                                            className={
                                                                displayErrorArea && formState.errors[row.name]
                                                                    ? "border border-danger border-2 rounded rounded-3"
                                                                    : ""
                                                            }>
                                                            <input
                                                                id={row.id}
                                                                type={row.type}
                                                                {...register(row.name, row.rule)}
                                                            />
                                                        </div>
                                                    </StyledTooltip>
                                                </React.Fragment>
                                            );
                                        })}
                                        <button
                                            className='btn btn-secondary'
                                            type='button'
                                            onClick={(e) => {
                                                suspensePaymentsSubmit(e);
                                            }}>
                                            更新
                                        </button>
                                    </div>
                                }
                                <TableContainer>
                                    <Table
                                        className='table table-sm table-bordered text-nowrap'
                                        aria-labelledby='travel_expenses'
                                        size='medium'
                                        aria-label='travel expenses table'>
                                        <TableHead>
                                            <TableRow className='table-active'>
                                                <TableCell align='center' padding='none' colSpan={headers.length}>
                                                    内訳
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className='table-light'>
                                                {headers.map((header, index) => (
                                                    <TableCell key={`headers-${index}`} align='center' padding='none'>
                                                        {header.label}
                                                        {header.required ? <RequiredMark /> : ""}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {suspensePaymentDetailData.map((row, key) => {
                                                return (
                                                    <TableRow
                                                        key={`row-${key}`}
                                                        className={`align-baseline ${
                                                            isDeleted[key] ? "bg-secondary" : ""
                                                        }`}>
                                                        {row.data
                                                            .filter((item) => !item.hidden)
                                                            .map((item, index) => {
                                                                return (
                                                                    <TableCell key={`input-${index}`}>
                                                                        {index == 0
                                                                            ? row.data
                                                                                  .filter((item) => item.hidden)
                                                                                  .map((item, num) => {
                                                                                      return (
                                                                                          <input
                                                                                              key={`input-hedden-${num}`}
                                                                                              type={item.type}
                                                                                              {...register(item.name)}
                                                                                          />
                                                                                      );
                                                                                  })
                                                                            : null}
                                                                        <input
                                                                            type={item.type}
                                                                            className='form-control mb-1'
                                                                            disabled={isDeleted[key]}
                                                                            {...register(item.name, item.rule)}
                                                                        />
                                                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                                                        <small className='text-danger'>
                                                                            {errors[item.name] &&
                                                                                errors[item.name]?.message}
                                                                        </small>
                                                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                                                        {/* <small className='text-danger'>
                                                                        {error_list[item.name] && error_list[item.name]}
                                                                    </small> */}
                                                                    </TableCell>
                                                                );
                                                            })}

                                                        <TableCell align='center' padding='none'>
                                                            {
                                                                <div className='px-1'>
                                                                    <button
                                                                        className='btn btn-sm btn-secondary px-3'
                                                                        type='button'
                                                                        onClick={(e) =>
                                                                            handleOnClickDeleteAndRestore(
                                                                                e,
                                                                                key,
                                                                                row.data,
                                                                                0,
                                                                                row.data.filter(
                                                                                    (item) =>
                                                                                        item.name ==
                                                                                        `suspense_payment_detail.${key}.id`
                                                                                )[0].value
                                                                            )
                                                                        }>
                                                                        {isDeleted[key] ? "復元" : "削除"}
                                                                    </button>
                                                                </div>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            {[...Array(suspensePaymentAdditionalNumbers)].map((_, key) => (
                                                <TableRow
                                                    key={`row-last-${key}`}
                                                    className={`align-baseline ${
                                                        isDeleted[suspensePaymentDetailData.length + key]
                                                            ? "bg-secondary"
                                                            : ""
                                                    }`}>
                                                    {suspensePaymentDetailLastData.data
                                                        .filter((item) => !item.hidden)
                                                        .map((row, index) => {
                                                            return (
                                                                <TableCell key={`input-last-${index}`}>
                                                                    {index == 0
                                                                        ? suspensePaymentDetailLastData.data
                                                                              .filter((item) => item.hidden)
                                                                              .map((item, num) => {
                                                                                  return (
                                                                                      <input
                                                                                          kay={`input-hidden-last-${num}`}
                                                                                          type={item.type}
                                                                                          {...register(
                                                                                              `suspense_payment_detail.${
                                                                                                  suspensePaymentDetailData.length +
                                                                                                  key
                                                                                              }.${item.name}`
                                                                                          )}
                                                                                      />
                                                                                  );
                                                                              })
                                                                        : null}
                                                                    <input
                                                                        type={row.type}
                                                                        className='form-control mb-1'
                                                                        {...register(
                                                                            `suspense_payment_detail.${
                                                                                suspensePaymentDetailData.length + key
                                                                            }.${row.name}`,
                                                                            row.rule
                                                                        )}
                                                                    />
                                                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                                                    {/* <small className='text-danger'>
                                                                    {errors[item.name]?.[
                                                                        suspensePaymentDetailData.length + key
                                                                    ] &&
                                                                        errors[item.name]?.[
                                                                            suspensePaymentDetailData.length + key
                                                                        ]?.message}
                                                                </small> */}
                                                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                                                    {/* <small className='text-danger'>
                                                                        {error_list[item.name] && error_list[item.name]}
                                                                    </small> */}
                                                                </TableCell>
                                                            );
                                                        })}

                                                    <TableCell align='center' padding='none'>
                                                        {
                                                            <div className='px-1'>
                                                                <button
                                                                    className='btn btn-sm btn-secondary px-3'
                                                                    type='button'
                                                                    onClick={(e) =>
                                                                        handleOnClickDeleteAndRestore(
                                                                            e,
                                                                            suspensePaymentDetailData.length + key,
                                                                            suspensePaymentDetailLastData.data,
                                                                            1,
                                                                            null
                                                                        )
                                                                    }>
                                                                    {isDeleted[suspensePaymentDetailData.length + key]
                                                                        ? "復元"
                                                                        : "削除"}
                                                                </button>
                                                            </div>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </form>
                        </div>
                    )}
                </div>

                <div className='card-footer'>
                    <BackButton path={returnPath} />
                </div>
            </div>
        </div>
    );
}
