import swal from "sweetalert";

import { HOME_PATH } from "./constants";

export default async function processErrorResponse(
    response = { data: {} },
    setResponseData = () => {},
    navigate = () => {},
    path = HOME_PATH
) {
    switch (response.status) {
        // 対象のデータが存在しない場合
        case 404:
            await swal({
                title: "失敗しました",
                text: response.data.message,
                icon: "warning",
                dangerMode: true
            });

            // 画面を閉じる
            await swal({
                title: "エラー",
                text: "問題が生じたためホーム画面に戻ります。",
                icon: "warning"
            }).then(() => {
                navigate(HOME_PATH);
            });

            break;

        // バリデーションなどによる失敗時
        case 422:
            setResponseData({
                ...response.data,
                error_list: response.data.result
            });

            swal({
                title: "失敗しました",
                text: response.data.message,
                icon: "warning"
            });

            break;

        default:
            // その他のエラーの場合
            await swal("エラー", "問題が発生し、処理が失敗しました。", "warning");

            break;
    }
}
