import React from "react";

function Footer() {
    return (
        <>
            <footer>
                <span>&copy; 2023 Dream Net Systems LLC.</span>
            </footer>
        </>
    );
}

export default Footer;
