import { createContext, useState } from "react";

export const monthlyDataReferenceContext = createContext({});
export const setMonthlyDataReferenceContext = createContext({});

export const MonthlyDataReferenceProvider = (props) => {
    const [proceedsList, setProceedsList] = useState([]); // 表示する売上項目のリストを管理
    const [expensesList, setExpensesList] = useState([]); // 表示する経費項目のリストを管理
    const [bankAccountsList, setBankAccountsList] = useState([]); // 表示する資金口座項目のリストを管理
    const [othersList, setOthersList] = useState([]); // 表示するその他項目のリストを管理
    return (
        <monthlyDataReferenceContext.Provider
            value={{
                proceedsList,
                expensesList,
                bankAccountsList,
                othersList
            }}
        >
            <setMonthlyDataReferenceContext.Provider
                value={{
                    setProceedsList,
                    setExpensesList,
                    setBankAccountsList,
                    setOthersList
                }}
            >
                {props.children}
            </setMonthlyDataReferenceContext.Provider>
        </monthlyDataReferenceContext.Provider>
    );
};