//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useRef, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import swal from "sweetalert";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import { parseISO } from "date-fns";
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { makeStyles, createStyles } from "@material-ui/core/styles";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import ItemNameColumn from "../../components/transaction/monthly_data/ItemNameColumn";
import MonthlyDataColumn, {
    calculateAmountYoY
} from "../../components/transaction/monthly_data/MonthlyDataColumn";
import OutputMonthlySpreadsheetButton from "../../components/transaction/monthly_data/OutputMonthlySpreadsheetButton";
import BackButton from "../../components/BackButton";
import { OutputPLExcel } from "../../components/OutputExcel";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { setMonthlyDataReferenceContext } from "../../providers/MonthlyDataReferenceProvider";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { HOME_PATH, FISCAL_YEAR_START_MONTH } from "../../common/constants";
import monthlyDataLeftHeadlines from "../../common/monthlyDataLeftHeadlines";
import monthAndYearFormat from "../../common/monthAndYearFormat";
import processResponse from "../../common/processResponse";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles((theme) =>
    createStyles({
        scrollYWrapper: {
            display: "flex",
            overflowY: "scroll",
            overflowStyle: "scrollbar",
            height: "80vh"
        },
        monthlyDataTable: {
            overflowStyle: "scrollbar",
            height: "max-content"
        },
        userTables: {
            marginBottom: "100px"
        },
        userTopTable: {
            marginBottom: "30px"
        },
        caption: {
            fontSize: "16px"
        },
        tableHead1: {
            backgroundColor: "#99ccff"
        },
        tableHead2: {
            backgroundColor: "#66ff99"
        },
        dialog: {
            maxHeight: "60vh",
            padding: "10px",
            boxSizing: "border-box",
            backgroundColor: "#fefefe",
            border: "1px solid #666",
            textAlign: "center"
        },
        imgWrapper: {
            width: "100%",
            height: "calc(100% - 80px)"
        },
        img: {
            objectFit: "contain",
            maxWidth: "100%",
            maxHeight: "100%"
        },
        me500: { marginRight: "500px" },
        imageDialog: { width: "500px" }
    })
);
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// 戻るときの遷移先のパス
const returnPath = HOME_PATH;

// DatePickerの日本語化に必要
registerLocale("ja", ja);
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// ステートの初期状態
const initMonthlyData = { result: [], success: false };

// ステートの初期状態
const initPastMonthlyData = { result: [], success: false };

// ステートの初期状態
const initBankAccounts = { result: [], success: false };

// ステートの初期状態
const initOthers = { result: [], success: false };

// ステートの初期値
const initTargetTravelExpensesTotalAmounts = { result: [], success: false };
const initPastTravelExpensesTotalAmounts = { result: [], success: false };
const initTargetNotices = { result: [], success: false };
const initPastNotices = { result: [], success: false };
const initTargetWithdrawal = { result: [], success: false };
const initPastWithdrawal = { result: [], success: false };
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function MonthlyDataReference() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //定義したスタイルを利用するための設定
    const classes = useStyles();
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { setProceedsList, setExpensesList, setBankAccountsList, setOthersList } =
        useContext(setMonthlyDataReferenceContext);
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const monthsPerYear = 12;
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 最も古いレコードのある年月を管理する
    // const [oldestFiscalYear, setOldestFiscalYear] = useState(null);

    // datePickerの選択済みの値を管理する
    const [startDate, setStartDate] = useState(new Date());

    // 選択した年度の値を管理する
    const [selectedYear, setSelectedYear] = useState("");

    // 選択した年度内の年月を管理する
    const [selectedTargetMonths, setSelectedTargetMonths] = useState([]);
    // 選択した年度の前年の年月を管理する
    const [selectedPastMonths, setSelectedPastMonths] = useState([]);

    // 選択した年度とその前年の年月を管理する
    const [selectedYoY, setSelectedYoY] = useState([]);
    // 選択した対象月に対しての作業を開始する
    const [start, setStart] = useState(false);

    // 画面読み込みの制御
    const [loaded, setLoaded] = useState(false);

    // 月次損益の選択した年度の値を管理する
    const [monthlyData, setMonthlyData] = useState(initMonthlyData);

    // 月次損益の選択した前年度の値を管理する
    const [pastMonthlyData, setPastMonthlyData] = useState(initPastMonthlyData);

    // 前年比表示切替
    const [showPastDataAndYoY, setShowPastDataAndYoY] = useState(false);

    // 資金口座の全データを管理する
    const [bankAccounts, setBankAccounts] = useState(initBankAccounts);

    const bankAccountsListRef = useRef([]); // resultのデータを変換して保持

    // その他の全データを管理する
    const [others, setOthers] = useState(initOthers);

    const othersListRef = useRef([]); // resultのデータを変換して保持

    // 展開用のデータを月ごとに管理
    const [monthlyDataForDeploy, setMonthlyDataForDeploy] = useState([]);
    const [pastDataForDeploy, setPastDataForDeploy] = useState([]);

    // csv, Excelに書き出すためのデータ行列を管理
    const [monthlySpreadSheetData, setMonthlySpreadSheetData] = useState([]);
    const [pastMonthlySpreadSheetData, setPastMonthlySpreadSheetData] = useState([]);
    const [YoYSpreadSheetData, setYoYSpreadSheetData] = useState({spreadSheet: [], monthlyChanges: []});

    // DBから取得した経費領収書合計の値を管理
    const [targetTravelExpensesTotalAmounts, setTargetTravelExpensesTotalAmounts] = useState(
        initTargetTravelExpensesTotalAmounts
    );
    const [pastTravelExpensesTotalAmounts, setPastTravelExpensesTotalAmounts] = useState(
        initPastTravelExpensesTotalAmounts
    );

    // 展開用の経費領収書の合計値のデータを管理する
    const [travelExpensesTotalAmountsForDeploy, setTravelExpensesTotalAmountsForDeploy] = useState([]);

    // DBから取得した特記事項の値を管理
    const [targetNotices, setTargetNotices] = useState(initTargetNotices);
    const [pastNotices, setPastNotices] = useState(initPastNotices);

    // 特記事項のデータを管理する
    const [noticesForDeploy, setNoticesForDeploy] = useState([]);

    // DBから取得した現金引き出しの値を管理
    const [targetWithdrawal, setTargetWithdrawal] = useState(initTargetWithdrawal);
    const [pastWithdrawal, setPastWithdrawal] = useState(initPastWithdrawal);

    // 現金引き出しの合計値のデータを管理する
    const [withdrawalForDeploy, setWithdrawalForDeploy] = useState([]);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 各種データを取得
    const getData = async (url, setState) => {
        const res = await api.get(url);
        processResponse(res, setState, navigate, returnPath);
    };
    // 最も古い領収書明細の年月の値を取得(未完成)
    // (経費領収書以外の最古の年月日を取得するメソッドが無いのできちんと求むるにはmonthly_data用の最古の年月日を取得する処理等の追加の必要あり)
    // const getTravelExpenseOldestAll = async (setOldestFiscalYear) => {
    //     const res = await api.get(`travel_expenses_oldest_all`);
    //     // 成功時に指定したユーザの最古のデータの年月を返す
    //     if (res.data.success) {
    //         setOldestFiscalYear(determineFiscalYear(parseISO(res.data.result.travel_expense_oldest_date)));
    //     }
    // };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 年度の選択に変更があったときの処理
    const handleOnChangeDate = (date) => {
        // 年度（datePicker）の選択している月をステートに格納
        setStartDate(date);
    };

    // 開始ボタン押下時
    const handleOnCliCkSelectSubmit = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // 年度のデータを読み込み開始
        setStart(true);
    };

    // 選択画面に戻るボタン押下時
    const handleOnClickResetSelected = async (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        await swal({
            title: "年度の選択に戻ります",
            text: "",
            icon: "info",
            buttons: true
        }).then((value) => {
            if (value) {
                backBeforeStart();
            }
        });
    };

    // 前年比表示切替
    const handleOnClickShowPastDataAndYoY = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // 表示切替のステートの真偽値を入れ替える
        setShowPastDataAndYoY(!showPastDataAndYoY);
    };
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => {
        // 各種カテゴリのそれぞれの項目名一覧を取得
        getData(`bank_accounts`, setBankAccounts); // 資金口座を取得
        getData(`others`, setOthers); // その他を取得
        // 最も古い経費領収書精算レコードのある年月を管理する
        // getTravelExpenseOldestAll(setOldestFiscalYear);
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 読み込み前に戻る
    const backBeforeStart = () => {
        setStart(false);
        setLoaded(false);
        setMonthlyData(initMonthlyData);
        setPastMonthlyData(initPastMonthlyData);
        setMonthlyDataForDeploy([]);
        setPastDataForDeploy([]);
        setSelectedTargetMonths([]);
        setSelectedPastMonths([]);
        setSelectedYoY([]);
        setProceedsList([]);
        setExpensesList([]);
        setBankAccountsList([]);
        setOthersList([]);
        setTargetTravelExpensesTotalAmounts(initTargetTravelExpensesTotalAmounts);
        setPastTravelExpensesTotalAmounts(initPastTravelExpensesTotalAmounts);
        setMonthlySpreadSheetData([]);
        setPastMonthlySpreadSheetData([]);
        setYoYSpreadSheetData({ spreadSheet: [], monthlyChanges: [] });
        setTravelExpensesTotalAmountsForDeploy([]);
        setTargetNotices(initTargetNotices);
        setPastNotices(initPastNotices);
        setNoticesForDeploy([]);
        setShowPastDataAndYoY(false);
    };

    // 年度が選択、またはステートの初期値が入った時に、選択済みの年度をステートに格納
    useEffect(() => {
        if (startDate) {
            // 選択された年度を文字列でステートに格納
            const year = startDate.getFullYear();
            setSelectedYear(year);
        }
    }, [startDate]);

    // 年度のデータ読み込み開始時の処理
    useEffect(() => {
        if (start && selectedYear !== "") {
            // 前年を定義
            const pastYear = Number(selectedYear) - 1;
            getData(`monthly_data_target_year/${selectedYear}`, setMonthlyData); // 選択した年度の月次損益データを取得
            getData(`monthly_data_target_year/${Number(selectedYear) - 1}`, setPastMonthlyData); // 前年度の月次損益データを取得

            getData(`travel_expenses_target_year_total_amount/${selectedYear}`, setTargetTravelExpensesTotalAmounts); // 表示対象の年度内の経費領収書の合計値全件を取得
            getData(`travel_expenses_target_year_total_amount/${pastYear}`, setPastTravelExpensesTotalAmounts); // 前年度の年度内の経費領収書の合計値全件を取得
            getData(`notices_target_year/${selectedYear}`, setTargetNotices); // 表示対象の年度内の特記事項全件を取得
            getData(`notices_target_year/${pastYear}`, setPastNotices); // 前年度のの年度内の特記事項全件を取得
            getData(`withdrawal_year/${selectedYear}`, setTargetWithdrawal); // 表示対象の年度内の現金引き出し全件を取得
            getData(`withdrawal_year/${pastYear}`, setPastWithdrawal); // 前年度のの年度内の現金引き出し全件を取得
        }
    }, [start]);

    // 年度間の年月の文字列の値を一年度分で作成し、配列にする
    const makeTargetMonths = (year) => {
        // indexは配列の呼び出される順として引き続き利用
        // indexは表示列の列番号の扱い
        // 各列に順に年月を表示するため、年月の値は年度始まり(FISCAL_YEAR_START_MONTH)からとなるように作成
        return [...Array(monthsPerYear)].map((month, index) => {
            if (index <= monthsPerYear - FISCAL_YEAR_START_MONTH) {
                return monthAndYearFormat(
                    parseISO(`${year}-${("0" + String(index + FISCAL_YEAR_START_MONTH)).slice(-2)}`)
                );
            } else {
                return monthAndYearFormat(
                    parseISO(
                        `${year + 1}-${("0" + String(index - (monthsPerYear - FISCAL_YEAR_START_MONTH))).slice(-2)}`
                    )
                );
            }
        });
    };

    // 各種データが揃ったときに、今回表示対象となる年月のリストを作成
    // monthlyDataがstartステート更新時にのみ作成されるため、
    // 基本的には、「開始」ボタン押下後に行われる処理
    useEffect(() => {
        if (
            monthlyData.success &&
            monthlyData.result.length > 0 &&
            pastMonthlyData.success &&
            bankAccounts.result.length > 0 &&
            others.result.length > 0
        ) {
            const year = Number(selectedYear);
            // 表示対象の年月のリストを作成
            const targetMonths = makeTargetMonths(year);
            setSelectedTargetMonths(targetMonths);
            // 前年度の年月のリストを作成
            const pastMonths = makeTargetMonths(year - 1);
            setSelectedPastMonths(pastMonths);
        }
    }, [monthlyData, pastMonthlyData, bankAccounts, others, selectedYear]);

    // 各カテゴリーごとにデータを振り分ける処理
    const distributeData = (data) => {
        // 各月ごとに全カテゴリーのidをキーにしたオブジェクトを包括する空の配列を用意
        const distributedData = [...Array(monthsPerYear)].map(() => {
            return { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] };
        });
        const excelData = [...Array(monthsPerYear)].map(() => {
            return { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] };
        });
        // データを振り分ける処理
        for (const item of data) {
            const month = Number(item.monthly_data_date.split("-")[1]);
            // 月の値をindexに変換して対応するindexの対象のカテゴリーのidの配列内に格納していく
            if (month >= FISCAL_YEAR_START_MONTH) {
                distributedData[month - FISCAL_YEAR_START_MONTH][item.monthly_data_category_id].push(item);
                excelData[month - FISCAL_YEAR_START_MONTH][item.monthly_data_category_id].push(
                    item.monthly_data_amount
                );
            } else {
                distributedData[month + (monthsPerYear - FISCAL_YEAR_START_MONTH)][item.monthly_data_category_id].push(
                    item
                );
                if (month - FISCAL_YEAR_START_MONTH >= 0) {
                    excelData[month - FISCAL_YEAR_START_MONTH][item.monthly_data_category_id].push(
                        item.monthly_data_amount
                    );
                }
            }
        }
        return distributedData;
    };

    // 対象の年月のリストが作成されてから、表示対象のデータを展開するための形に振り分ける
    useEffect(() => {
        if (selectedTargetMonths.length > 0) {
            setMonthlyDataForDeploy(distributeData(monthlyData.result ?? []));
        }
    }, [selectedTargetMonths]);

    // 前年度の対象の年月のリストが作成されてから、表示対象のデータを展開するための形に振り分ける
    useEffect(() => {
        if (selectedPastMonths.length > 0) {
            setPastDataForDeploy(distributeData(pastMonthlyData.result ?? []));
        }
    }, [selectedPastMonths]);

    // 選択した年度及びその前年度の年月のリストが作成されてから、表示対象のデータを展開するための形に振り分ける
    useEffect(() => {
        if (selectedPastMonths.length > 0 && selectedTargetMonths.length > 0) {
            setSelectedYoY(makeYoYMonthHeadline(selectedPastMonths, selectedTargetMonths));
        }
    }, [selectedPastMonths, selectedTargetMonths]);

    // 表示する項目名を表示対象の項目のみに絞り込む
    const makeFilteredList = (items, categoryId) => {
        // 表示対象、または前年度のいずれかに該当する項目の値が存在する項目のみを抽出
        return items.filter(
            (item) =>
                monthlyData.result.some(
                    (target) =>
                        target.monthly_data_category_id === categoryId && target.monthly_data_target_id === item.id
                ) ||
                (pastMonthlyData.result.length > 0 &&
                    pastMonthlyData.result.some(
                        (past) =>
                            past.monthly_data_category_id === categoryId && past.monthly_data_target_id === item.id
                    ))
        );
    };

    // データベースからデータの取得が済んだとき
    useEffect(async () => {
        if (monthlyData.success && pastMonthlyData.success) {
            if (monthlyData.result.length === 0 && pastMonthlyData.result.length === 0) {
                // データが存在しない場合、アラートを表示し
                await swal("データが未作成です", "", "info");

                // 開始以前に戻る
                backBeforeStart();

                return;
            } else {
                // 表示する項目名を表示対象の項目のみに絞り込み、展開するためのステートにセットしていく
                setProceedsList(makeFilteredList(bankAccountsListRef.current, 1));
                setExpensesList(makeFilteredList(bankAccountsListRef.current, 2));
                setBankAccountsList(makeFilteredList(bankAccountsListRef.current, 3));
                setOthersList(makeFilteredList(othersListRef.current, 7));
            }
        }
    }, [monthlyData, pastMonthlyData]);

    // 表示・行管理のために値を配列に作り直す
    useEffect(() => {
        if (bankAccounts.result.length > 0) {
            const result = bankAccounts.result;
            // 配列を作成
            const list = result.map((item) => {
                return {
                    id: item.bank_account_id,
                    name: item.bank_account_name
                };
            });
            // Refオブジェクトを更新
            bankAccountsListRef.current = list;
        }
    }, [bankAccounts]);

    // 表示・行管理のために値を配列に作り直す
    useEffect(() => {
        if (others.result.length > 0) {
            const result = others.result;
            // 配列を作成
            const list = result.map((item) => {
                return { id: item.other_id, name: item.other_name };
            });
            // Refオブジェクトを更新
            othersListRef.current = list;
        }
    }, [others]);

    // 各カテゴリーごとにデータを振り分ける処理
    const distributeForCellData = (targetData, pastData, dateKeyName, parameterKeyName) => {
        // 各月ごとに全カテゴリーのidをキーにしたオブジェクトを包括する空の配列を用意
        const distributedData = [...Array(monthsPerYear)].map(() => {
            return {
                target: null,
                past: null
            };
        });
        // 選択年度のデータを振り分ける処理
        distributionMonthly(targetData, distributedData, dateKeyName, parameterKeyName, "target");
        // 前年度のデータを振り分ける処理
        distributionMonthly(pastData, distributedData, dateKeyName, parameterKeyName, "past");

        return distributedData;
    };

    // 表示する経費領収書の合計値のステートを更新
    useEffect(() => {
        if (targetTravelExpensesTotalAmounts.success && pastTravelExpensesTotalAmounts.success) {
            setTravelExpensesTotalAmountsForDeploy(
                distributeForCellData(
                    targetTravelExpensesTotalAmounts.result,
                    pastTravelExpensesTotalAmounts.result,
                    "travel_expense_target_month_year",
                    "travel_expense_total_amount"
                )
            );
        }
    }, [targetTravelExpensesTotalAmounts, pastTravelExpensesTotalAmounts]);

    // 表示する特記事項のステートを更新
    useEffect(() => {
        if (targetNotices.success && pastNotices.success) {
            setNoticesForDeploy(
                distributeForCellData(targetNotices.result, pastNotices.result, "notice_date", "notice_detail")
            );
        }
    }, [targetNotices, pastNotices]);

    // 表示する現金引き出しのステートを更新
    useEffect(() => {
        if (targetWithdrawal.success && pastWithdrawal.success) {
            setWithdrawalForDeploy(
                distributeForCellData(
                    targetWithdrawal.result,
                    pastWithdrawal.result,
                    "withdrawal_target_month_year",
                    "withdrawal_total_amount"
                )
            );
        }
    }, [targetWithdrawal, pastWithdrawal]);

    // 読み込みがおおよそ完了したことをステートに反映させて、表を表示する
    useEffect(() => {
        if (
            monthlyData.success &&
            monthlyData.result.length > 0 &&
            selectedTargetMonths.length > 0 &&
            monthlyDataForDeploy.length > 0 &&
            travelExpensesTotalAmountsForDeploy.length > 0 &&
            noticesForDeploy.length > 0 &&
            withdrawalForDeploy.length > 0
        ) {
            setLoaded(true);
        }
    }, [
        monthlyData,
        selectedTargetMonths,
        monthlyDataForDeploy,
        travelExpensesTotalAmountsForDeploy,
        noticesForDeploy,
        withdrawalForDeploy
    ]);

    // 選択した年度と昨年度のデータからExcel出力用の行列(2次元配列)を作成して格納します
    useEffect(() => {
        if (
            pastMonthlySpreadSheetData.length > 0 &&
            monthlySpreadSheetData.length > 0 &&
            monthlyDataForDeploy.length > 0
        ) {
            calculateYoYSpreadSheet(
                pastMonthlySpreadSheetData,
                monthlySpreadSheetData,
                monthlyDataForDeploy
            );
        }
    }, [
        pastMonthlySpreadSheetData.length,
        monthlySpreadSheetData.length,
        monthlyDataForDeploy.length
    ]);

    // 表示対象年度のデータを振り分ける処理
    const distributionMonthly = (
        monthlyData,
        distributed,
        dateKeyName,
        parameterKeyName,
        selectedFiscalYear = "target"
    ) => {
        for (const monthData of monthlyData) {
            const month = Number(monthData[dateKeyName].split("-")[1]);
            // 月の値をindexに変換して対応するindexの対象のパラメーターに格納していく
            if (month >= FISCAL_YEAR_START_MONTH) {
                distributed[month - FISCAL_YEAR_START_MONTH][selectedFiscalYear] = monthData[parameterKeyName];
            } else {
                distributed[month + (monthsPerYear - FISCAL_YEAR_START_MONTH)][selectedFiscalYear] =
                    monthData[parameterKeyName];
            }
        }
    };

    // 前年度比のExcel fileを出力する際の[年月]行の表示用に
    // [前年度, 選択年度, 前年差額, 前年比] の順番の配列を作成します
    const makeYoYMonthHeadline = (
        pastMonthData = selectedPastMonths,
        monthData = selectedTargetMonths
    ) =>
        monthData.flatMap((month, index) => [
            pastMonthData[index],
            month,
            "前年差額",
            "前年比"
        ]);

    // 前年度比のExcel fileを出力する際のデータの表示用に
    // [前年度, 選択年度, 前年差額, 前年比] の順番の配列を作成します
    const calculateYoYSpreadSheet = (
        pastSpreadSheet,
        targetSpreadSheet,
        monthlyDataForDeploy = monthlyDataForDeploy
    ) => {
        // 月毎の前年差額を格納する配列
        const monthlyYoYChanges = [...Array(monthsPerYear)].map(() => []);
        const YoYSpreadSheet = targetSpreadSheet.map((targetRow, indexY) => {
            return targetRow.flatMap((targetColumn, indexMonth) =>
            {
                // 出力用のExcel file内に数式を残す訳にはいかぬので
                // [前年差額, 前年比]の数値を予め算出し配列として取得します
                const YoYArray = calculateAmountYoY(
                    monthlyDataForDeploy[indexMonth],
                    pastSpreadSheet[indexY][indexMonth],
                    targetColumn
                );
                // 前年差額を配列に格納します
                monthlyYoYChanges[indexMonth].push(YoYArray[0]);

                return [
                    pastSpreadSheet[indexY][indexMonth], // 前年度
                    targetColumn, // 選択年度
                    ...YoYArray // 前年差額, 前年比
                ];
            });
        });
        setYoYSpreadSheetData({
            spreadSheet: YoYSpreadSheet,
            monthlyChanges: monthlyYoYChanges
        });
    };
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    // 選択年度とその前年比のExcel fileをdownload
    const DownloadYoYPLButton = () => (
        <OutputPLExcel
            fileName={`月次損益${selectedYear - 1}~${selectedYear}年度`}
            monthlyDataLeftHeadlines={monthlyDataLeftHeadlines()}
            months={selectedYoY}
            dataList={YoYSpreadSheetData.spreadSheet}
            targetMonths={selectedTargetMonths}
            targetDataList={monthlySpreadSheetData}
            pastMonths={selectedPastMonths}
            pastDataList={pastMonthlySpreadSheetData}
        />
    );

    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h2>月次損益年間推移</h2>
                    </div>
                    <div className='card-body'>
                        {!start ? (
                            <div className='d-flex justify-content-start align-items-baseline text-nowrap my-4'>
                                <label className='fw-bold'>対象年度：</label>
                                <div className='w-auto me-3'>
                                    <DatePicker
                                        selected={startDate}
                                        // minDate={oldestFiscalYear}
                                        maxDate={new Date()}
                                        onChange={(date) => {
                                            handleOnChangeDate(date);
                                        }}
                                        showYearPicker
                                        yearItemNumber={monthsPerYear}
                                        locale={ja}
                                        dateFormat='yyyy年度'
                                    />
                                </div>
                                <button
                                    className='btn btn-secondary'
                                    type='button'
                                    disabled={!selectedYear}
                                    onClick={(e) => {
                                        handleOnCliCkSelectSubmit(e);
                                    }}>
                                    開始
                                </button>
                            </div>
                        ) : (
                            <>{!start && <p className=' m-4'>...読み込み中</p>}</>
                        )}
                        {start && (
                            <>
                                <div className='d-flex justify-content-start align-items-start text-nowrap my-4'>
                                    <label className='fw-bold me-3'>
                                        対象年度：
                                        <input type='text' readOnly value={`${selectedYear}年度`} />
                                    </label>
                                    {loaded && (
                                        <>
                                            <button
                                                className='btn btn-secondary me-5'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickResetSelected(e);
                                                }}>
                                                再選択
                                            </button>
                                            {pastMonthlyData.result.length > 0 ? (
                                                <button
                                                    className='btn btn-success'
                                                    type='button'
                                                    onClick={(e) => {
                                                        handleOnClickShowPastDataAndYoY(e);
                                                    }}>
                                                    前年比を
                                                    {showPastDataAndYoY ? "非表示" : "表示"}
                                                </button>
                                            ) : (
                                                <button className='btn btn-secondary' type='button' disabled>
                                                    前年度のデータなし
                                                </button>
                                            )}
                                        </>
                                    )}
                                </div>
                                {loaded && (
                                    <div className='d-flex align-items-center mb-4'>
                                        <span className='fw-bold'>ダウンロード：</span>
                                        <div className='d-flex'>
                                            <OutputMonthlySpreadsheetButton
                                                months={selectedTargetMonths}
                                                monthlyDeployData={monthlyDataForDeploy}
                                                withDraw={withdrawalForDeploy}
                                                totalTravelExpenses={travelExpensesTotalAmountsForDeploy}
                                                notices={noticesForDeploy}
                                                setMonthlyList={setMonthlySpreadSheetData}
                                                selectedFiscalYear='target'
                                                selectedYear={selectedYear}
                                            />
                                            {pastMonthlyData.result.length > 0 && pastDataForDeploy.length > 0 && (
                                                <div className='d-flex'>
                                                    <OutputMonthlySpreadsheetButton
                                                        months={selectedPastMonths}
                                                        monthlyDeployData={pastDataForDeploy}
                                                        withDraw={withdrawalForDeploy}
                                                        totalTravelExpenses={travelExpensesTotalAmountsForDeploy}
                                                        notices={noticesForDeploy}
                                                        setMonthlyList={setPastMonthlySpreadSheetData}
                                                        selectedFiscalYear='past'
                                                        selectedYear={selectedYear - 1}
                                                    />
                                                    <DownloadYoYPLButton />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                        {loaded ? (
                            <div className={classes.scrollYWrapper}>
                                <ItemNameColumn />
                                <div className={`d-flex overflow-x-scroll overflow-y-hidden ${classes.monthlyDataTable}`}>
                                    {[...Array(monthsPerYear)].map((item, index) => (
                                        <MonthlyDataColumn
                                            key={index}
                                            targetMonth={selectedTargetMonths[index]}
                                            pastMonth={selectedPastMonths[index]}
                                            targetData={monthlyDataForDeploy[index]}
                                            pastData={pastDataForDeploy[index]}
                                            travelExpensesData={travelExpensesTotalAmountsForDeploy[index]}
                                            noticesData={noticesForDeploy[index]}
                                            withdrawalData={withdrawalForDeploy[index]}
                                            monthlyChangesData={YoYSpreadSheetData.monthlyChanges[index]}
                                            showPastDataAndYoY={showPastDataAndYoY}
                                        />
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <>{start && <p className=' m-4'>...読み込み中</p>}</>
                        )}
                    </div>
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackButton path={returnPath} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
