//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useEffect, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { PDFDownloadLink, PDFViewer, Page, View, Image, Text, Font, Document, StyleSheet } from "@react-pdf/renderer";
// ttfファイル参照
import fontRegular from "../../../../fonts/Nasu-Regular.ttf";
import swal from "sweetalert";
import { parseISO } from "date-fns";
import { debounce } from "lodash";
//_________________________________________________ライブラリの読み込み_________________________________________________//
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { Button } from "@material-ui/core";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import arraySortByDate from "../../common/arraySortByDate";
import amountToLocaleString from "../../common/amountToLocaleString";
import dateFormat from "../../common/dateFormat";
import { getPictures } from "../../common/getPicture";
import monthAndYearFormat from "../../common/monthAndYearFormat";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
const INTERVAL_MIL_SEC = 1000; // 待ち時間(ms)
const PAGE_COLUMN = 2; // 1ページ当たり横に並ぶ要素数
const PAGE_ROW = 3; // 1ページ当たり縦に並ぶ要素数
const COL_WIDTH = 100 / PAGE_COLUMN;
const COL_HEIGHT = 90 / PAGE_ROW;
export const LOAD_ERROR_TITLE = "が取得できませんでした";
export const LOAD_ERROR_TEXT = "時間を置いて再試行するか,登録内容をご確認ください";
const loadText = <>...領収書写真取得中</>;
const loadErrorPicture = "写真" + LOAD_ERROR_TITLE;
// 引数transaction内の見出しに使用するkey一覧を定義します
const captionDic = {
    date: "年月日",
    amount: "円",
    name: "支払先名",
    account_list_name: "勘定科目"
};
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function DownloadReceiptPDF({
    monthAndYear = null, // 形式:'YYYY-MM'
    // 下記の様なkeyと値を持つ連想配列を格納している配列
    // {target_month_year: YYYY-MM, date: YYYY-MM-DD, user_id: Number, amount: Number, file_name: String}
    transactions = []
}) {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // ttfファイルのフォント定義
    // フォント「ナス レギュラー」
    Font.register({
        family: "Nasu-Regular",
        src: fontRegular
    });
    // 文字列の改行時の区切り文字を無くします
    Font.registerHyphenationCallback((word) => [word, ""]);
    const BORDER_COLOR = "#bfbfbf";
    const BORDER_STYLE = "solid";
    const styles = StyleSheet.create({
        body: {
            padding: 10
        },
        table: {
            display: "table",
            borderStyle: BORDER_STYLE,
            borderColor: BORDER_COLOR,
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableHeader: {
            height: "10%",
            borderStyle: BORDER_STYLE,
            borderColor: BORDER_COLOR,
            borderBottomColor: "#000",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableRow: {
            height: COL_HEIGHT + "%",
            flexDirection: "row"
        },
        tableCol: {
            width: COL_WIDTH + "%",
            height: "100%",
            marginTop: "auto",
            marginBottom: "auto",
            textAlign: "center",
            borderStyle: BORDER_STYLE,
            borderColor: BORDER_COLOR,
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCell: {
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
            margin: "5px"
        },
        pageNumber: {
            position: "absolute",
            width: "auto",
            marginLeft: "33vh",
            textAlign: "center",
            marginTop: "95vh",
            marginBottom: 0
        },
        title: { textAlign: "center", fontFamily: "Nasu-Regular" },
        caption: { fontSize: "10pt", fontFamily: "Nasu-Regular" },
        viewer: {
            width: "85%",
            height: "70vh"
        }
    });
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // receipts の状態を管理する
    const [receipts, setReceipts] = useState({
        receipt: [],
        date: null
    });
    const [loadingMessage, setLoadingMessage] = useState(loadText);
    const [isGetReceipts, setGetReceipts] = useState(false);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 渡された領収書情報の連想配列野中から写真のパスを割り出して
    // 取得した画像と見出し用の文字列を3次元配列として格納します
    const getReceiptsUri = async (transactions, isGetReceipts) => {
        if (transactions.length > 0 && !isGetReceipts) {
            setGetReceipts(true);
            setLoadingMessage(loadText);

            const transactionsArray = arraySortByDate(transactions, "date"); // 日付の昇順に並べ替え
            const filePaths = transactionsArray.map((transaction) => {
                // 引数monthAndYearに値がなければdateの
                // 先頭7文字(年月部分)のみ文字列として格納します
                const month =
                    monthAndYear && monthAndYear.length >= 7
                        ? monthAndYear
                        : transaction.target_month_year.slice(0, 7);

                return `${month}/${transaction.user_id}/${transaction.file_name}`;
            });

            // キー名に渡したfilePathsの通し番号が
            // 値に取得した画像のURIが格納された連想配列を取得します
            const gotPictures = await getPictures(filePaths);

            if (gotPictures) {
                // 取得出来た画像fileのURI及び対応する説明文を持つ連想配列を配列に其々格納します
                const receiptTiles = Object.keys(gotPictures).map(
                    (transactionIndex) => {
                        const receiptCaption = Object.keys(captionDic)
                            .filter(
                                (caption) =>
                                    transactionsArray[transactionIndex][caption]
                            )
                            .reduce(
                                (captionString, caption) =>
                                    captionString.concat(
                                        ", ",
                                        captionData(
                                            caption,
                                            transactionsArray[transactionIndex][
                                                caption
                                            ]
                                        )
                                    ),
                                ""
                            )
                            .slice(2); // 最後尾の", "を省く

                        return {
                            uri: gotPictures[transactionIndex],
                            caption: receiptCaption
                        };
                    }
                );

                if (receiptTiles.length > 0) {
                    const photoRow = [];
                    // 横に並ぶ写真枚数分に分割して2次元配列として格納します
                    while (receiptTiles.length > 0)
                        photoRow.push(receiptTiles.splice(0, PAGE_COLUMN));
                    // 縦に並ぶ写真枚数分に分割して3次元配列として格納する
                    while (photoRow.length > 0)
                        receiptTiles.push(photoRow.splice(0, PAGE_ROW));
                    setReceipts({
                        receipt: receiptTiles,
                        date: getFiscalYearOrMonthlyString(monthAndYear)
                    });
                    setGetReceipts(false);
                    return;
                }
            }
        }
        sendErrorMessage();
        setGetReceipts(false);
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => {
        if (transactions.length > 0) {
            getReceiptsUri(transactions, isGetReceipts);
        }
    }, [transactions]);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 写真の見出しの記述指定
    const captionData = (caption, transactionVal) => {
        switch (caption) {
            case "amount":
                return amountToLocaleString(transactionVal);
            case "date":
                return `${captionDic[caption]}:${
                    dateFormat(transactionVal, false, "Y-m-d").format_date
                }`;
            default:
                return `${captionDic[caption]}:${replaceASCII(transactionVal)}`;
        }
    };

    // 全角英数字記号を半角に変換します
    const replaceASCII = (t) =>
        t.replace(/[Ａ-Ｚａ-ｚ０-９！-～]/g, (x) => String.fromCharCode(x.charCodeAt(0) - 0xfee0));

    const sendErrorMessage = () => {
        setLoadingMessage(
            <p>
                {loadErrorPicture}。{LOAD_ERROR_TEXT}
                {GetReceiptsUriButton}
            </p>
        );
        swal({
            title: loadErrorPicture,
            text: LOAD_ERROR_TEXT,
            icon: "warning"
        });
    };

    // 引数の日付,又は西暦表記の年度を示す数値を画面表示する為の形式に変換して返します
    const getFiscalYearOrMonthlyString = (date) => {
        if (date) {
            if (date.length >= 7) {
                // [YYYY-MM-DD]の様な形式の日付型や文字列の場合[YYYY年M月]の形式に変換
                return dateFormat(
                    monthAndYearFormat(parseISO(date)),
                    false,
                    "Y-m"
                ).format_date;
            } else if (Number(date) > 0) {
                return `${date}年度`; // 西暦を示す数値のみ場合
            }
        }
        return "";
    };
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    const GetReceiptsUriButton = (
        <Button
            className="ms-3"
            variant="contained"
            onClick={debounce(
                () => getReceiptsUri(transactions, isGetReceipts),
                INTERVAL_MIL_SEC
            )}>
            写真再取得
        </Button>
    );

    // 領収書の写真を表示するPDFファイル
    const ReceiptView = receipts.receipt.length > 0 && (
        <Document>
            {receipts.receipt.map((receiptPage, pageNo) => (
                <Page
                    size="A4"
                    orientation="portrait"
                    pageNumber={pageNo + 1}
                    key={pageNo}
                    style={styles.body}
                    wrap>
                    <Text
                        style={styles.title}>{`経費領収書${receipts.date}`}</Text>
                    {receiptPage.map((receiptRow, rowIndex) => (
                        <View key={rowIndex} style={styles.tableRow} wrap={false}>
                            {receiptRow.map((receiptColumn, columnIndex) => (
                                <View key={`${rowIndex}_${columnIndex}`} style={styles.tableCol}>
                                    <Text style={styles.caption} className='position-absolute'>
                                        {receiptColumn.caption}
                                    </Text>
                                    <Image src={receiptColumn.uri} style={styles.tableCell} />
                                </View>
                            ))}
                        </View>
                    ))}
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages = receipts.receipt.length }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>
            ))}
        </Document>
    );

    const PDFOutput = (
        <>
            <p className="mt-3">
                {/* クリックするとPDFをダウンロードする */}
                <PDFDownloadLink
                    className="ps-4"
                    document={ReceiptView}
                    fileName={`経費領収書写真一覧${receipts.date}.pdf`}>
                    {({ loading }) =>
                        loading
                            ? "...Loading document"
                            : "クリックでPDFダウンロード"
                    }
                </PDFDownloadLink>
                {isGetReceipts ? loadingMessage : GetReceiptsUriButton}
            </p>
            {/* PDFをビューアで表示する */}
            <PDFViewer
                style={styles.viewer}
                fileName={`経費領収書写真一覧${receipts.date}.pdf`}>
                {ReceiptView}
            </PDFViewer>
        </>
    );
    return receipts.receipt.length === 0 ? loadingMessage : PDFOutput;
}
