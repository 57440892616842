import React, { createContext, useContext, useEffect, useRef, useReducer, useState } from "react";
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
import { NativeSelect, Paper, Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import swal from "sweetalert";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import TravelExpenseRequestTable from "../../components/transaction/travel_expense/TravelExpenseRequestTable";
import TravelExpenseDraftRequestTable from "../../components/transaction/travel_expense/TravelExpenseDraftRequestTable";
import BackButton from "../../components/BackButton";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { UserContext } from "../../providers/UserProvider";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { HOME_PATH, MIN_MAX_DATE } from "../../common/constants";
import processErrorResponse from "../../common/processErrorResponse";
import processResponse from "../../common/processResponse";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import "react-datepicker/dist/react-datepicker.css";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({ me500: { marginRight: "520px" }, imageDialog: { width: "500px" } })
);
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// 戻るときの遷移先のパス
const returnPath = HOME_PATH;

// DatePickerの日本語化に必要
registerLocale("ja", ja);

// 先月を定義
function lastMonthFormat(date) {
    date.setMonth(date.getMonth() - 1);
    return date;
}
// 先月の日付を取得
const lastMonth = lastMonthFormat(new Date());
const thisMonth = new Date();
// 画像表示に関する処理に使用する各機能名
const stringTravelExpense = "travel-expense";
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// ステートの初期状態
const initTravelExpenseOldest = { result: [], success: false };

// ステートの初期状態
const initTravelExpenses = { result: [], success: false };

// ステートの初期状態
const initTravelExpensesValidationErrors = [];

// ステートの初期状態
const initUsers = { result: [], success: false };

// ステートの初期状態
const initShowReceiptTarget = { type: "", number: null };

// ステートの初期状態
const initPhotos = { [stringTravelExpense]: {} };

// ステートの初期状態
const initRowCounts = { [stringTravelExpense]: null };
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
// ==========================経費明細============================ //
export const TravelExpenseContext = createContext();
// 登録済みテーブル用
// リクエストデータのステート更新用関数
const reducerTravelExpenseRequestDataFunc = (targetData, action) => {
    const number = action.number;
    const data = action.data;
    switch (action.type) {
        case "set":
            return action.data;
        case "reset":
            return [];
        case "update":
            if (targetData.find((p) => p.number === number)) {
                const requestDataArray = targetData.map((item) => {
                    if (item.number === number) {
                        return data;
                    } else {
                        return item;
                    }
                });
                return requestDataArray;
            } else {
                return [...targetData, data];
            }
        case "remove":
            const array = targetData.map((item, index) => {
                if (item.number == number) {
                    return { ...item, is_deleted: true };
                } else {
                    return item;
                }
            });
            // const array = targetData.filter((p) => p.number !== number);
            return array;
        case "restore":
            const restoredArray = targetData.map((item, index) => {
                if (item.number == number) {
                    return { ...item, is_deleted: false };
                } else {
                    return item;
                }
            });
            return restoredArray;
        default:
            return targetData;
    }
};

// const reducerTravelExpenseRequestDataFunc = (travelExpenseRequestData, action) => {
//     const number = action.number;
//     const data = action.data;
//     switch (action.type) {
//         case "set":
//             return action.data;
//         case "reset":
//             return [];
//         case "update":
//             if (travelExpenseRequestData.find((p) => p.number === number)) {
//                 const requestDataArray = travelExpenseRequestData.map((item) => {
//                     if (item.number === number) {
//                         return data;
//                     } else {
//                         return item;
//                     }
//                 });
//                 return requestDataArray;
//             } else {
//                 return [...travelExpenseRequestData, data];
//             }
//         case "remove":
//             const array = travelExpenseRequestData.map((item, index) => {
//                 if (item.number == number) {
//                     return { ...item, is_deleted: true };
//                 } else {
//                     return item;
//                 }
//             });
//             // const array = travelExpenseRequestData.filter((p) => p.number !== number);
//             return array;
//         case "removeData":
//             const removedArray = travelExpenseRequestData.filter((item, index) => {
//                 item.number !== number;
//             });
//             // const array = travelExpenseRequestData.filter((p) => p.number !== number);
//             return removedArray;
//         case "restore":
//             const restoredArray = travelExpenseRequestData.map((item, index) => {
//                 if (item.number == number) {
//                     return { ...item, is_deleted: false };
//                 } else {
//                     return item;
//                 }
//             });
//             return restoredArray;
//         default:
//             return travelExpenseRequestData;
//     }
// };

// 追加の行のナンバーを管理するステートの更新用関数
const reducerTravelExpenseAdditionalNumbersFunc = (travelExpenseAdditionalNumbers, action) => {
    switch (action.type) {
        case "set":
            return action.value;
        case "reset":
            return [];
        case "add":
            const maxNumber = Math.max(...travelExpenseAdditionalNumbers);
            const number = action.number;
            if (number === maxNumber) {
                const nextNumber = maxNumber + 1;
                return [...travelExpenseAdditionalNumbers, nextNumber];
            }
            return travelExpenseAdditionalNumbers;
        default:
            return travelExpenseAdditionalNumbers;
    }
};

// バリーデーションエラーの有無を管理するステートの更新用関数
const reducerTravelExpenseValidationErrorsFunc = (travelExpenseValidationErrors, action) => {
    const number = action.number;
    switch (action.type) {
        case "set":
            return action.data;
        case "reset":
            return initTravelExpensesValidationErrors;
        case "add":
            if (travelExpenseValidationErrors.find((item) => item === number)) {
                return travelExpenseValidationErrors;
            } else {
                return [...travelExpenseValidationErrors, number];
            }
        case "remove":
            const array = travelExpenseValidationErrors.filter((item) => item !== number);
            return array;
        default:
            return travelExpenseValidationErrors;
    }
};

// 下書き用
// リクエストデータのステート更新用関数
// const reducerTravelExpenseDraftRequestDataFunc = (travelExpenseRequestData, action) => {
//     const number = action.number;
//     const data = action.data;
//     switch (action.type) {
//         case "set":
//             return action.data;
//         case "reset":
//             return [];
//         case "update":
//             if (travelExpenseRequestData.find((p) => p.number === number)) {
//                 const requestDataArray = travelExpenseRequestData.map((item) => {
//                     if (item.number === number) {
//                         return data;
//                     } else {
//                         return item;
//                     }
//                 });
//                 return requestDataArray;
//             } else {
//                 return [...travelExpenseRequestData, data];
//             }
//         case "remove":
//             const array = travelExpenseRequestData.filter((p) => p.number !== number);
//             return array;
//         default:
//             return travelExpenseRequestData;
//     }
// };

// 追加の行のナンバーを管理するステートの更新用関数
// const reducerTravelExpenseDraftAdditionalNumbersFunc = (travelExpenseAdditionalNumbers, action) => {
//     switch (action.type) {
//         case "set":
//             return action.value;
//         case "reset":
//             return [];
//         case "add":
//             const maxNumber = Math.max(...travelExpenseAdditionalNumbers);
//             const number = action.number;
//             if (number === maxNumber) {
//                 const nextNumber = maxNumber + 1;
//                 return [...travelExpenseAdditionalNumbers, nextNumber];
//             }
//             return travelExpenseAdditionalNumbers;
//         default:
//             return travelExpenseAdditionalNumbers;
//     }
// };

// バリーデーションエラーの有無を管理するステートの更新用関数
// const reducerTravelExpenseDraftValidationErrorsFunc = (travelExpenseValidationErrors, action) => {
//     const number = action.number;
//     switch (action.type) {
//         case "set":
//             return action.data;
//         case "reset":
//             return initTravelExpensesValidationErrors;
//         case "add":
//             if (travelExpenseValidationErrors.find((item) => item === number)) {
//                 return travelExpenseValidationErrors;
//             } else {
//                 return [...travelExpenseValidationErrors, number];
//             }
//         case "remove":
//             const array = travelExpenseValidationErrors.filter((item) => item !== number);
//             return array;
//         default:
//             return travelExpenseValidationErrors;
//     }
// };

export default function TravelExpenseRegister() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //定義したスタイルを利用するための設定
    const classes = useStyles();
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // ログイン中のユーザー情報を取得
    const { userInfo } = useContext(UserContext);
    const userName = userInfo.user.user_name; // ユーザー名
    const loggedInUserId = userInfo.user.user_id; // id
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { MIN_DATE } = MIN_MAX_DATE;
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // ===========================両機能共通============================= //
    // ユーザー一覧の値を管理する
    const [users, setUsers] = useState(initUsers);

    // 最も古いレコードのある年月を管理する
    const [oldestDate, setOldestDate] = useState(null);

    // datePickerの選択済みの値を管理する
    const [startDate, setStartDate] = useState(thisMonth);

    // 選択した年月の値を管理する
    const [selectedMonthAndYear, setSelectedMonthAndYear] = useState("");

    // 選択済みの条件が締め処理を完了しているかどうかを管理する
    const [isClosing, setIsClosing] = useState(false);

    // プルダウンメニュー用のリスト
    const [selectableUsers, setSelectableUsers] = useState({});

    // 月ごとのユーザー一覧のRefオブジェクト
    const usersInMonthsRef = useRef({});

    // 未確認のみ表示への切り替え用ステート
    const [onlyUnconfirmed, setOnlyUnconfirmed] = useState(false);

    // 選択した対象月に対しての作業を開始する
    const [start, setStart] = useState(false);

    // 編集中の行があるかを管理する
    const [isRowLocked, setIsRowLocked] = useState(false);

    // 確定（締め処理）が可能な状態かを管理する
    const [confirmSubmittable, setConfirmSubmittable] = useState(false);

    // 確定済みの時、取消ボタンを表示するかを管理する
    const [openCancelGuide, setOpenCancelGuide] = useState(false);
    // ===========================両機能共通============================= //

    // ===========================画像表示コンポーネントについて============================= //
    // 各機能の行の数を格納
    const [rowCounts, setRowCounts] = useState(initRowCounts);

    // 表示する画像の対象の機能名とナンバーを管理する
    const [showReceiptTarget, setShowReceiptTarget] = useState(initShowReceiptTarget);

    // 表示する画像を管理する
    const [photoData, setPhotoData] = useState({});

    // 領収書表示のダイアログ画面を表示切替する
    const [isReceiptOpen, setIsReceiptOpen] = useState(false);

    // 取得した領収書の画像を格納していく
    const [photos, setPhotos] = useState(initPhotos);

    // 実際にクラス名として反映させるためのステート
    const [position, setPosition] = useState("position-absolute");

    // id="content"のスクロール位置を取得
    const contentYOffset = document.getElementById("content").getBoundingClientRect().top;
    // ===========================画像表示コンポーネントについて============================= //

    // ==========================経費明細============================ //

    // 締め処理済みかどうかを管理
    const [isClosed, setIsClosed] = useState(false);

    // 最も古いレコードの月日の値を管理する
    const [travelExpenseOldest, setTravelExpenseOldest] = useState(initTravelExpenseOldest);

    // --------------------------------登録済用------------------------------------ //
    // travelExpenses の状態を管理する
    const [travelExpenses, setTravelExpenses] = useState(initTravelExpenses);

    // 送信するデータを管理するレデューサー
    const [travelExpenseRequestData, dispatchTravelExpenseRequestData] = useReducer(
        reducerTravelExpenseRequestDataFunc,
        []
    );
    // 小計をステートで管理
    const [travelExpenseTotalAmount, setTravelExpenseTotalAmount] = useState(0);

    // バリデーションエラーの有無を管理するレデューサー
    const [travelExpenseValidationErrors, dispatchTravelExpenseValidationErrors] = useReducer(
        reducerTravelExpenseValidationErrorsFunc,
        initTravelExpensesValidationErrors
    );

    // バリデーションを実行するためのトリガー
    const [travelExpenseValidationTrigger, setTravelExpenseValidationTrigger] = useState(false);

    //変更チェックボックスのチェック状態管理
    const [confirmedChange, setConfirmedChange] = useState([]);
    const [confirmedChangeDone, setConfirmedChangeDone] = useState(false);
    // --------------------------------登録済用------------------------------------ //

    // --------------------------------↓下書き用↓----------------------------------- //
    // travelExpenses の状態を管理する
    const [travelExpensesDraft, setTravelExpensesDraft] = useState(initTravelExpenses);

    // 下書き（新規登録用）
    const [additionalDraft, dispatchAdditionalDraft] = useReducer(reducerTravelExpenseRequestDataFunc, []);

    // 送信するデータを管理するレデューサー
    const [travelExpenseDraftRequestData, dispatchTravelExpenseDraftRequestData] = useReducer(
        reducerTravelExpenseRequestDataFunc,
        []
    );
    // 小計をステートで管理
    const [travelExpenseDraftTotalAmount, setTravelExpenseDraftTotalAmount] = useState(0);

    // バリデーションエラーの有無を管理するレデューサー
    const [travelExpenseDraftValidationErrors, dispatchTravelExpenseDraftValidationErrors] = useReducer(
        reducerTravelExpenseValidationErrorsFunc,
        initTravelExpensesValidationErrors
    );

    // バリデーションを実行するためのトリガー
    const [travelExpenseDraftValidationTrigger, setTravelExpenseDraftValidationTrigger] = useState(false);

    // 経費明細の追加の行の明細ナンバーを管理するレデューサー
    const [travelExpenseAdditionalNumbers, dispatchTravelExpenseAdditionalNumbers] = useReducer(
        reducerTravelExpenseAdditionalNumbersFunc,
        []
    );

    // 行の最後尾のナンバーを管理するステート
    const [maxAdditionalNumber, setMaxAdditionalNumber] = useState(1);
    // --------------------------------↑下書き用↑------------------------------------ //

    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 経費明細の最も古い年月の値を取得
    const getTravelExpenseOldest = async (userId) => {
        const url = `travel_expenses_oldest/${userId}`;
        const res = await api.get(url);
        // 成功時、ステートを更新
        if (res.data.success) {
            setTravelExpenseOldest(res.data);
        }
    };
    const getUsers = async () => {
        const url = `users`;
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        processResponse(res, setUsers, navigate, returnPath);
    };

    const getClose = async (date) => {
        const url = `close/${date}`;
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        if (res.data.success) {
            if (res.data.result === null) {
                setIsClosed(false);
                return;
            } else {
                setIsClosed(res.data.result.closing_process_is_closed == 1);
            }
        }
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 年月の選択に変更があったときの処理
    const handleOnChangeDate = (date) => {
        // 年月（datePicker）の選択している月をステートに格納
        setStartDate(date);
    };
    // ユーザー選択時の処理
    const handleOnChangeUser = (e) => {
        // イベントの伝搬中止
        e.preventDefault();

        const userId = Number(e.target.value);

        // 選択したユーザーのidと名前をステートに格納
        setSelectedUserId(userId);

        const userName = users.result.find((p) => p.user_id === userId).user_name;
        setSelectedUserName(userName);
    };

    // 開始ボタン押下時
    const selectSubmit = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        getClose(`${selectedMonthAndYear}-01`);
        setStart(true);
    };

    // 経費入力完了ボタン押下時(修正)
    const travelExpensesUpdateSubmit = async (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // フォーム全体のバリデーションを実行
        setTravelExpenseValidationTrigger(!travelExpenseValidationTrigger);

        // エラーの有無により処理を中断する
        if (travelExpenseValidationErrors.length > 0) {
            await swal({ title: "", text: "入力の内容を見直してください", icon: "warning" }).then(() => {});
            return;
        } else {
            await swal({ title: "確認", text: "修正します", icon: "info", buttons: ["中止", "修正"] }).then((value) => {
                if (value) {
                    // リクエストデータをapiで送信する処理へ進む
                    sendRequestData(travelExpenseRequestData, 0);
                }
            });
        }
    };

    // 下書き欄の下書き・登録ボタン押下時の送信用データ作成
    const createDraftData = () => {
        // 下書き（新規登録）のうち、削除フラグが立っているものを除外
        const additionalDraftData = additionalDraft.filter((item) => {
            return item?.is_deleted == undefined || item?.is_deleted == false;
        });

        const sendData = [...travelExpenseDraftRequestData, ...additionalDraftData];

        return sendData;
    };

    // 経費入力完了ボタン押下時(登録)
    const travelExpensesSubmit = async (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // フォーム全体のバリデーションを実行
        setTravelExpenseDraftValidationTrigger(!travelExpenseDraftValidationTrigger);

        // エラーの有無により処理を中断する
        if (travelExpenseDraftValidationErrors.length > 0) {
            await swal({ title: "", text: "入力の内容を見直してください", icon: "warning" }).then(() => {});
            return;
        } else {
            await swal({ title: "確認", text: "登録します", icon: "info", buttons: ["中止", "登録"] }).then((value) => {
                if (value) {
                    const sendData = createDraftData();
                    // リクエストデータをapiで送信する処理へ進む
                    sendRequestData(sendData, 0);
                }
            });
        }
    };
    // 経費入力完了ボタン押下時(下書き)
    const travelExpensesDraftSubmit = async (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        await swal({ title: "確認", text: "下書き保存します", icon: "info", buttons: ["中止", "保存"] }).then(
            (value) => {
                if (value) {
                    const sendData = createDraftData();
                    // リクエストデータをapiで送信する処理へ進む
                    sendRequestData(sendData, 1);
                }
            }
        );
    };
    // ==============ここから画像表示コンポーネント用の記述============== //
    // 領収書表示コンポーネントを閉じる
    const handleOnReceiptClose = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // レシート確認を閉じる
        setIsReceiptOpen(false);
        setPhotoData({});
        setShowReceiptTarget(initShowReceiptTarget);
    };

    // 画像表示コンポーネントのページ送り
    const handleOnClickPhotoBack = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        setShowReceiptTarget((showReceiptTarget) => {
            return { type: showReceiptTarget.type, number: showReceiptTarget.number - 1 };
        });
    };

    // 画像表示コンポーネントのページ戻し
    const handleOnClickPhotoAdvance = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        setShowReceiptTarget((showReceiptTarget) => {
            return { type: showReceiptTarget.type, number: showReceiptTarget.number + 1 };
        });
    };
    // ==============ここまで画像表示コンポーネント用の記述============== //

    // 選択画面に戻るボタン押下時
    const resetSelected = async (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        await swal({
            title: "担当者の選択に戻ります",
            text: "",
            icon: "info",
            buttons: true
        }).then((value) => {
            if (value) {
                setStart(false);
            }
        });
    };

    // 確認チェックボックス押下時、データ送信用ステート更新
    const handleOnChangeConfirm = (e) => {
        const value = e.target.value;
        const isChecked = e.target.checked;

        // チェック時
        // 対象のステート（配列）にvalue追加
        if (isChecked) {
            setConfirmedChange([...confirmedChange, value]);
        } else {
            // 非チェック時
            // 対象のステート（配列）からvalue削除
            // 仮払い
            // 対象を除いた配列を作成
            const arr = confirmedChange.filter((item) => {
                return item != value;
            });

            setConfirmedChange(arr);
        }
    };

    // 変更フラグを解除する
    const handleOnChangeConfirmSubmit = async (e) => {
        e.preventDefault();
        // 確認のための変数を定義
        let confirm = false;

        // 確認画面を表示
        await swal({
            title: "確認",
            text: "チェックした行のデータ変更を確認しました。",
            buttons: ["戻る", "確認"]
        }).then((value) => {
            if (value) {
                confirm = true;
            }
        });

        // 戻る場合は処理を中断する
        if (!confirm) {
            return;
        }

        const url = "travel_expense_reset_is_changed";
        const res = await api.post(url, confirmedChange);
        // 成否の確認
        if (res.data.success) {
            // 登録成功時
            swal(res.data.message, "", "success").then(() => {
                setConfirmedChangeDone(true);
            });
        } else {
            // 登録失敗時にエラーに応じた処理を行う
            processErrorResponse(res, setUnpaidData, navigate);
        }
    };
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => {
        // 値を取得する
        getTravelExpenseOldest(loggedInUserId);
    }, []);
    useEffect(() => {
        // 各種データの取得
        getUsers();

        // datepickerの最小値を設定
        setOldestDate(new Date(MIN_DATE));
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const backBeforeStart = () => {
        setStart(false);
        setIsRowLocked(false);

        // 以下、表示画像を初期化
        setPhotos(initPhotos);
        setIsReceiptOpen(false);
        setPhotoData({});
        setShowReceiptTarget(initShowReceiptTarget);

        dispatchAdditionalDraft({ type: "reset" });
    };

    // リクエストデータをapiでコントローラーに渡すなどの処理
    const sendRequestData = async (data, flg) => {
        // リクエストデータをまとめる
        const requestData = {
            target: { month: selectedMonthAndYear, user_id: loggedInUserId },
            data: data,
            is_draft: flg
        };

        // 登録
        const url = "travel_expenses";
        const res = await api.post(url, requestData);
        // 成否の確認
        if (res.data.success) {
            // 登録成功時
            swal(res.data.message, "", "success");

            // 年月選択前の状態に戻る
            backBeforeStart();
        } else {
            // 登録失敗時にエラーに応じた処理を行う
            processErrorResponse(res, () => {}, navigate);
        }
    };

    // 年月が選択、またはステートの初期値が入った時に、選択済みの年月をステートに格納
    useEffect(() => {
        if (startDate) {
            // 選択された年月を文字列でステートに格納
            const y = startDate.getFullYear();
            const m = ("0" + (startDate.getMonth() + 1)).slice(-2);
            setSelectedMonthAndYear(`${y}-${m}`);
        }
    }, [startDate]);

    // 最も古いレコードの年月取得後、datePickerのminDate用の値をステートに格納する
    useEffect(() => {
        if (travelExpenseOldest.success) {
            const oldestDate = travelExpenseOldest.result.travel_expense_oldest_date;
            if (oldestDate) {
                const date = new Date(`${oldestDate}-01`);
                setOldestDate(date);
            } else {
                setOldestDate(lastMonth);
            }
        }
    }, [travelExpenseOldest]);

    // 最後尾の行のナンバーを取得し、ステートに格納
    useEffect(() => {
        if (travelExpenseAdditionalNumbers.length > 0) {
            const max = Math.max(...travelExpenseAdditionalNumbers);
            setMaxAdditionalNumber(max);
        } else {
            setMaxAdditionalNumber(1);
        }
    }, [travelExpenseAdditionalNumbers]);

    // 経費明細の小計を計算しステートに保存
    const setTotalAmount = (data, setState = (f) => f) => {
        if (data.length > 0) {
            const amountArray = data.map((item) => {
                return Number(item.amount);
            });

            const totalAmount = amountArray.reduce((accumulator, currentValue) => {
                return accumulator + currentValue;
            });
            setState(totalAmount);
        } else {
            setState(0);
        }
    };
    // 経費明細の小計を計算しステートに保存(登録済)
    useEffect(() => {
        setTotalAmount(travelExpenseRequestData, setTravelExpenseTotalAmount);
    }, [travelExpenseRequestData]);

    // 経費明細の小計を計算しステートに保存(下書き)
    useEffect(() => {
        setTotalAmount(travelExpenseDraftRequestData, setTravelExpenseDraftTotalAmount);
    }, [travelExpenseDraftRequestData]);

    // 画像表示用のダイアログ画面のコンポーネント
    const ReceiptDialog = () =>
        isReceiptOpen && Object.keys(photoData).length > 0 ? (
            <div className='position-absolute top-0 end-0 p-4'>
                <div className={`${classes.imageDialog} ${classes.me500}`}>
                    <Paper className='p-3' elevation={3}>
                        <div className='d-flex justify-content-start mb-3 p-2'>
                            <Button
                                variant='contained'
                                onClick={(e) => {
                                    handleOnReceiptClose(e);
                                }}>
                                閉じる
                            </Button>
                            <p className='p-2 m-0'>
                                <span className='fw-bold me-3'>領収書</span>
                                {photoData.name}
                            </p>
                        </div>
                        <div className='position-relative text-center'>
                            {!isRowLocked && showReceiptTarget.number > 1 && (
                                <button
                                    className='btn btn-outline-dark d-block h-100 position-absolute top-0 start-0'
                                    onClick={(e) => handleOnClickPhotoBack(e)}>
                                    ◀
                                </button>
                            )}
                            <img className='mw-100' src={photoData.photo} />
                            {!isRowLocked && showReceiptTarget.number < rowCounts[showReceiptTarget.type] && (
                                <button
                                    className='btn btn-outline-dark d-block h-100 position-absolute top-0 end-0'
                                    onClick={(e) => handleOnClickPhotoAdvance(e)}>
                                    ▶
                                </button>
                            )}
                        </div>
                    </Paper>
                </div>
            </div>
        ) : null;
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='position-sticky top-0'>
                        <div className='position-relative'>
                            {/* 画像表示用のDialogコンポーネント */}
                            <ReceiptDialog />
                        </div>
                    </div>
                    <div className='card-header d-flex'>
                        <h2>経費申請</h2>
                    </div>
                    <div className='card-body'>
                        {!start && oldestDate !== null ? (
                            <div className='d-flex justify-content-start align-items-center text-nowrap mt-4'>
                                <label className='fw-bold me-3'>
                                    年月：
                                    <DatePicker
                                        selected={startDate}
                                        minDate={oldestDate}
                                        maxDate={new Date()}
                                        onChange={(date) => {
                                            handleOnChangeDate(date);
                                        }}
                                        locale={ja}
                                        dateFormat='yyyy年MM月'
                                        showMonthYearPicker
                                    />
                                </label>
                                <label className='fw-bold me-3'>
                                    担当：
                                    <input type='text' readOnly value={userName} />
                                </label>
                                <button
                                    className='btn btn-secondary'
                                    type='button'
                                    onClick={(e) => {
                                        selectSubmit(e);
                                    }}>
                                    開始
                                </button>
                            </div>
                        ) : (
                            <>{!start && <p className=' m-4'>...読み込み中</p>}</>
                        )}
                        {start && (
                            <div className='d-flex justify-content-start align-items-center text-nowrap my-4'>
                                <label className='fw-bold me-3'>
                                    年月：
                                    <input
                                        type='text'
                                        readOnly
                                        value={`${selectedMonthAndYear.split("-")[0]}年${
                                            selectedMonthAndYear.split("-")[1]
                                        }月`}
                                    />
                                </label>
                                <label className='fw-bold me-3'>
                                    担当：
                                    <input type='text' readOnly value={userName} />
                                </label>
                                <button
                                    className='btn btn-secondary me-3'
                                    type='button'
                                    onClick={(e) => {
                                        resetSelected(e);
                                    }}>
                                    再選択
                                </button>
                                {isClosed && <p className='h-auto m-0 fw-bold'> 締め処理済みです</p>}
                            </div>
                        )}
                        <div className='d-flex justify-content-center mt-5'>
                            <div>
                                <TravelExpenseContext.Provider
                                    value={{
                                        returnPath,
                                        start,
                                        selectedMonthAndYear,
                                        loggedInUserId,
                                        isClosed,
                                        setIsClosed
                                    }}>
                                    <TravelExpenseRequestTable
                                        travelExpenses={travelExpenses}
                                        setTravelExpenses={setTravelExpenses}
                                        travelExpenseRequestData={travelExpenseRequestData}
                                        dispatchTravelExpenseRequestData={dispatchTravelExpenseRequestData}
                                        travelExpensesUpdateSubmit={travelExpensesUpdateSubmit}
                                        travelExpenseTotalAmount={travelExpenseTotalAmount}
                                        travelExpenseValidationErrors={travelExpenseValidationErrors}
                                        dispatchTravelExpenseValidationErrors={dispatchTravelExpenseValidationErrors}
                                        travelExpenseValidationTrigger={travelExpenseValidationTrigger}
                                        handleOnChangeConfirm={handleOnChangeConfirm}
                                        setConfirmedChange={setConfirmedChange}
                                        confirmedChange={confirmedChange}
                                        confirmedChangeDone={confirmedChangeDone}
                                        setConfirmedChangeDone={setConfirmedChangeDone}
                                        handleOnChangeConfirmSubmit={handleOnChangeConfirmSubmit}
                                    />
                                    <TravelExpenseDraftRequestTable
                                        travelExpensesDraft={travelExpensesDraft}
                                        setTravelExpensesDraft={setTravelExpensesDraft}
                                        dispatchTravelExpenseDraftRequestData={dispatchTravelExpenseDraftRequestData}
                                        travelExpensesSubmit={travelExpensesSubmit}
                                        travelExpensesDraftSubmit={travelExpensesDraftSubmit}
                                        maxAdditionalNumber={maxAdditionalNumber}
                                        travelExpenseAdditionalNumbers={travelExpenseAdditionalNumbers}
                                        dispatchTravelExpenseAdditionalNumbers={dispatchTravelExpenseAdditionalNumbers}
                                        travelExpenseDraftTotalAmount={travelExpenseDraftTotalAmount}
                                        travelExpenseDraftValidationErrors={travelExpenseDraftValidationErrors}
                                        dispatchTravelExpenseDraftValidationErrors={
                                            dispatchTravelExpenseDraftValidationErrors
                                        }
                                        travelExpenseDraftValidationTrigger={travelExpenseDraftValidationTrigger}
                                        dispatchAdditionalDraft={dispatchAdditionalDraft}
                                    />
                                </TravelExpenseContext.Provider>
                            </div>
                        </div>
                    </div>
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackButton path={returnPath} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
