//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import OutputExcel from "../../OutputExcel";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { monthlyDataReferenceContext } from "../../../providers/MonthlyDataReferenceProvider";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import dateFormat from "../../../common/dateFormat";
import monthlyDataLeftHeadlines from "../../../common/monthlyDataLeftHeadlines";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import "react-datepicker/dist/react-datepicker.css";
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
// 選択した年月
export default function OutputSingleMonthSpreadsheetButton({
    month = "", // 選択した年月の1日目
    monthDeployData = {},
    withDraw = null, // 現金引き出し合計金額
    totalTravelExpenses = null, // 経費領収書合計金額
    notice = null, // 特記事項
    hasTravelExpenseSummary = false, // 経費集計情報のsheetを付属するか否か
    hasTravelExpensePerUser = false, // ユーザ毎の経費集計sheetを付属するか否か
    fileType = "Excel"
}) {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 要素数が可変なカテゴリについてキーがカテゴリIDに
    // 値がやり取りした金額を格納する行列になっている連想配列を取得します
    const variableListAndRow = variableListAndRowPerCategory(useContext(monthlyDataReferenceContext));
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 要素が単一なカテゴリのやり取り金額を格納する行を其々定義します
    const advancePaymentRow = []; // 予納その他経費:categoryID === 4
    const pettyCashRow = []; // 小口繰越現金:categoryID === 5
    const nextMonthCashRow = []; // 翌月繰越現預金:categoryID === 6
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // csv,Excelに書き出すためのデータ行列を管理
    const [spreadSheetData, setSpreadSheetData] = useState([]);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => makeSpreadSheetData(), []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const makeSpreadSheetData = () => {
        pushMonthlyDeployRowByCategory(
            monthDeployData,
            variableListAndRow,
            advancePaymentRow,
            pettyCashRow,
            nextMonthCashRow
        );

        // 経費合計(資金口座の経費合計金額 + 領収書合計)
        const totalExpense = calculateTotalAmountRow(
            // 経費合計金額は出力用のデータには直接使用しないので配列から取り出します
            variableListAndRow[2]["rows"].pop(),
            [totalTravelExpenses]
        );
        // 粗利益(売上合計金額 - 経費合計 - 予納その他経費)
        // 表示する取引先が可変のカテゴリの行列では最終行に合計金額が格納されています
        const grossProfitRow = calculateGrossProfitRow(
            variableListAndRow[1]["rows"][variableListAndRow[1]["rows"].length - 1], // 売上合計金額
            [totalExpense], // 経費合計
            advancePaymentRow
        );

        // 差引現金残(小口繰越現金 + 現金引き出し合計 - 領収書合計)
        const deduction = calculateDeduction(pettyCashRow[0], withDraw, totalTravelExpenses);

        // 差額(翌月繰越現預金 - 月残高合計)
        // 月残高合計とは資金行列の最終行に格納している合計金額
        const differenceRow = hasTravelExpensePerUser
            ? [
                  -calculateBalanceAmountRow(
                      nextMonthCashRow,
                      variableListAndRow[3]["rows"][variableListAndRow[3]["rows"].length - 1] // 月残高合計(資金の合計金額)
                  )
              ]
            : calculateBalanceAmountRow(
                  nextMonthCashRow,
                  variableListAndRow[3]["rows"][variableListAndRow[3]["rows"].length - 1] // 月残高合計(資金の合計金額)
              );

        // csv file用のデータを作成します
        const dataList = [
            ...variableListAndRow[1]["rows"],
            [], // [経費]見出し行
            ...variableListAndRow[2]["rows"],
            [totalTravelExpenses],
            totalExpense,
            advancePaymentRow,
            grossProfitRow,
            [], // [現金出納帳]見出し行
            pettyCashRow,
            [withDraw],
            [totalTravelExpenses],
            [deduction],
            [], // [資金]見出し行
            nextMonthCashRow,
            ...variableListAndRow[3][`rows`],
            differenceRow,
            [], // [その他]見出し行
            ...variableListAndRow[7]["rows"],
            [], // [特記事項]見出し行
            [notice] // 特記事項
        ];
        setSpreadSheetData(dataList);
    };
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <OutputExcel
            fileName={`${dateFormat(month, false, "Y-m").format_date}次損益`}
            fileType={fileType}
            api_url='export_pl'
            data={{
                ...monthlyDataLeftHeadlines(),
                months: [month],
                dataList: spreadSheetData,
                isPrintingTravelExpense: {
                    // どの経費集計情報を出力するか
                    amount: totalTravelExpenses,
                    hasSummary: hasTravelExpenseSummary,
                    hasUsers: hasTravelExpensePerUser
                }
            }}
        />
    );
}

// 表示する取引先数が可変なカテゴリ用の行列を連想配列として返します
export const variableListAndRowPerCategory = (props) => {
    // カテゴリ毎の表示する取引先一覧
    const { proceedsList, expensesList, bankAccountsList, othersList } = props;

    // カテゴリ毎に取引先とのやり取り金額とその合計金額を格納する行列(===2次元配列)を作成
    // 最終行に合計金額を格納する為に1行追加します
    const proceedsRows = [...Array(proceedsList.length + 1)].map(() => []); // 売上行:categoryID === 1
    const expensesRows = [...Array(expensesList.length + 1)].map(() => []); // 経費行:categoryID === 2
    const bankAccountsRows = [...Array(bankAccountsList.length + 1)].map(() => []); // 資金口座行:categoryID === 3

    // その他の行列は合計金額無し
    const othersRows = [...Array(othersList.length)].map(() => []); // その他行:categoryID === 7

    // 1:売上, 2:経費, 3:資金口座, 7:その他
    return {
        1: { list: proceedsList, rows: proceedsRows }, // 売上
        2: { list: expensesList, rows: expensesRows }, // 経費
        3: { list: bankAccountsList, rows: bankAccountsRows }, // 資金口座
        7: { list: othersList, rows: othersRows } // その他
    };
};

// 表示する取引金額を数値として返す関数
export const intAmount = (categoryData, listId) => {
    const existData = categoryData.find((target) => target?.monthly_data_target_id === listId);
    if (existData) {
        return existData.monthly_data_amount;
    } else {
        return null;
    }
};

// 展開用の月次データをcategoryIdに応じた配列に振り分けて格納します
export const pushMonthlyDeployRowByCategory = (
    monthDeploy,
    variableListAndRowAndList,
    advancePaymentRow,
    pettyCashRow,
    nextMonthCashRow
) => {
    // 資金口座が削除されていると画面表示と異なり削除済み口座に対応している金額がExcelの合計金額の行に反映されない不具合あり
    // 今後の対応方針に応じて画面表示やこの関数での計算内容を変更して下さい
    // カテゴリ毎の取引用行列の各行末尾に取引金額を追加する
    const pushAmountRows = (
        categoryData, // 各カテゴリの単位時間での取引内容
        transactionList = [], // カテゴリ内の表示する取引先情報一覧
        transactionRow = [] // 取引先情報を行として,単位時間毎の取引金額を列として格納する行列
    ) => {
        if (categoryData.length === 0) {
            // 取引していない時は取引金額行列の全行末にnullを挿入します
            transactionRow.map((row) => row.push(null));
        } else {
            let totalAmount = null;
            transactionList.map((transaction, index) => {
                // IDにて取引先を絞り込んで当該行に挿入します
                const transactionAmount = intAmount(categoryData, transaction.id);
                transactionRow[index].push(transactionAmount);
                if (transactionAmount) {
                    totalAmount += transactionAmount;
                }
            });
            // 取引用行列の行数が取引先数よりも1多い場合は最終行に合計金額を格納します
            if (transactionRow.length === transactionList.length + 1) {
                transactionRow[transactionRow.length - 1].push(totalAmount);
            }
        }
    };

    Object.keys(monthDeploy).map((categoryId) => {
        switch (categoryId) {
            case 1: // 売上
            case "1":
            case 2: // 経費
            case "2":
            case 3: // 資金
            case "3":
            case 7: // その他
            case "7":
                pushAmountRows(
                    monthDeploy[categoryId],
                    variableListAndRowAndList[Number(categoryId)]["list"],
                    variableListAndRowAndList[Number(categoryId)][`rows`]
                );
                break;
            case 4: // 予納その他経費
            case "4":
                advancePaymentRow.push(intAmount(monthDeploy[categoryId], 1));
                break;
            case 5: // 小口繰越現金
            case "5":
                pettyCashRow.push(intAmount(monthDeploy[categoryId], 1));
                break;
            case 6: // 翌月繰越現預金
            case "6":
                nextMonthCashRow.push(intAmount(monthDeploy[categoryId], 1));
                break;
            default:
                break;
        }
    });
};

// 金額の数値が格納された同じ長さの配列2つから其々の差額を配列で返します
export const calculateBalanceAmountRow = (incomeRow, expenditureRow) =>
    incomeRow.map((income, index) =>
        Number(income) || Number(expenditureRow[index])
            ? Number(income ?? 0) - Number(expenditureRow[index] ?? 0)
            : null
    );

// 金額の数値が格納された同じ長さの配列2つから其々の合計額を配列で返します
export const calculateTotalAmountRow = (incomeRow, expenditureRow) =>
    incomeRow.map((income, index) =>
        Number(income) || Number(expenditureRow[index])
            ? Number(income ?? 0) + Number(expenditureRow[index] ?? 0)
            : null
    );

// 粗利益(売上合計 - 経費合計 - 予納その他経費)
export const calculateGrossProfitRow = (proceedsTotalRow, expenseTotalRow, advancePaymentRow) =>
    proceedsTotalRow.map((totalProceed, index) =>
        Number(totalProceed) || Number(expenseTotalRow[index]) || Number(advancePaymentRow[index])
            ? Number(totalProceed ?? 0) - Number(expenseTotalRow[index] ?? 0) - Number(advancePaymentRow[index] ?? 0)
            : null
    );

// 差引現金残(小口繰越現金 + 現金引き出し合計 - 領収書合計)
export const calculateDeduction = (pettyCash, withDraw, expenseTotal) =>
    Number(pettyCash) || Number(withDraw) || Number(expenseTotal)
        ? Number(pettyCash ?? 0) + Number(withDraw ?? 0) - Number(expenseTotal ?? 0)
        : null;
