//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { Tooltip } from "@material-ui/core";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { MonthlyDataContext } from "../../../contents/monthly_data/MonthlyDataRegister";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
const useStyles = makeStyles((theme) =>
    createStyles({
        rightCell: {
            width: "70%"
        },
        leftCell: {
            width: "30%"
        }
    })
);
// MUIのTooltipのz-indexを調整
const StyledTooltip = withStyles({
    "@global": {
        ".MuiTooltip-popper": {
            zIndex: "1300"
        }
    }
})(Tooltip);
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
const CATEGORY_BANK_ACCOUNT_ID = 3;
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function TargetWithdrawals(props) {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //定義したスタイルを利用するための設定
    const classes = useStyles();
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const {
        errors,
        setErrors,
        isDisplayErrors,
        setIsDisplayErrors,
        handleOnMouseEnter,
        handleOnMouseOut,
        validationTrigger,
        setValidationTrigger,
        nullOrPush
    } = useContext(MonthlyDataContext);
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const {
        count = 0,
        id,
        date,
        inputName,
        listName,
        sum = 0,
        setSum = (f) => f,
        pettyCash = 0,
        totalAmount = 0,
        setSub = (f) => f,
        row,
        setRow = (f) => f,
        data,
        setData = (f) => f
    } = props;

    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 金額欄変更時
    const handleAmountOnChange = (e) => {
        if (data !== null) {
            // 変更用データの更新
            const result = setStateBeforeInit(e);
            // 親コンポーネントの当該ステートを変更
            setData(result);

            const name = e.target.name;
            const key = name.substring(name.lastIndexOf("-") + 1);
            createError(key);

            // 親コンポーネントの合計用ステート更新
            const sum = data.reduce((sum, element) => {
                const amount = parseInt(element[`${inputName}_amount`] ?? 0);
                return parseInt(sum) + (isNaN(amount) ? 0 : amount);
            }, 0);
            setSum(sum);

            // 行を表示(再描画)
            rendering();
        }
    }; // 金額欄変更時
    const handleDateOnChange = (e) => {
        if (data !== null) {
            // 変更用データの更新
            const result = setStateBeforeInit(e);
            // 親コンポーネントの当該ステートを変更
            setData(result);

            const name = e.target.name;
            const key = name.substring(name.lastIndexOf("-") + 1);
            createError(key);

            // 親コンポーネントの合計用ステート更新
            const sum = data.reduce((sum, element) => {
                const amount = parseInt(element[`${inputName}_amount`] ?? 0);
                return parseInt(sum) + (isNaN(amount) ? 0 : amount);
            }, 0);
            setSum(sum);

            // 行を表示(再描画)
            rendering();
        }
    };
    // rowの削除
    const handleOnClickDelete = (e) => {
        const name = e.target.name;
        const key = name.substring(name.lastIndexOf("-") + 1);
        const result = data.filter((_, index) => index != key);
        setData(result);
        deleteError(key);
    };

    const calcSub = () => {
        const pettyCash2 = parseInt(pettyCash ? pettyCash.monthly_data_amount ?? 0 : 0);
        const total = parseInt(totalAmount ?? 0);
        const sub = (isNaN(pettyCash2) ? 0 : pettyCash2) + parseInt(sum ?? 0) - (isNaN(total) ? 0 : total);
        setSub(sub);
    };
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // data(props)更新時
    useEffect(() => {
        if (data !== null) {
            // 親コンポーネントの当該合計値を更新
            const sum = data.reduce((sum, element) => {
                const amount = parseInt(element[`${inputName}_amount`] ?? 0);
                return parseInt(sum) + (isNaN(amount) ? 0 : amount);
            }, 0);
            setSum(sum);

            createError(-1);
            // 行を表示(再描画)
            rendering();
        }
    }, [data, validationTrigger]);
    // errors(context)更新時 ※errorsの再レンダリングに必要
    useEffect(() => {
        if (data !== null) {
            // 親コンポーネントの差分更新
            calcSub();
        }
    }, [sum, totalAmount]);
    // errors(context)更新時 ※errorsの再レンダリングに必要
    useEffect(() => {
        if (data !== null) {
            // 行を表示(再描画)
            rendering();
        }
    }, [errors, isDisplayErrors]);
    //stateのセット前の準備
    function setStateBeforeInit(e) {
        const value = e.target.value;
        const name = e.target.name;
        let result = data;
        const target_name = name.substring(0, name.lastIndexOf("-"));
        const key = name.substring(name.lastIndexOf("-") + 1);

        if (!result[key]) {
            let result_val = {
                [`${inputName}_target_month_year`]: date,
                [`${inputName}_date`]: date,
                [target_name]: value
            };
            if (target_name == `${inputName}_date`) {
                result_val = {
                    [`${inputName}_target_month_year`]: date,
                    [target_name]: value
                };
            }
            result.push(result_val);
        } else {
            result[key] = Object.assign(result[key], { [target_name]: value });
        }
        return result;
    }
    function rendering() {
        if (data.length > 0) {
            setRow([...Array(data.length + 1)].map((_, index) => addRow(index)));
        } else if (data.length == 0) {
            setRow(addRow(0));
        }
    }
    // 実際にエラーを追加削除する関数
    function createError(key) {
        let errorData = errors;
        errorData = nullOrPush(errorData, validateAmount(data, key), `${inputName}_amount`, key);
        setErrors(errorData);
    }
    // 実際にエラーを削除する関数
    function deleteError(key) {
        let errorData = errors;
        errorData = nullOrPush(errorData, null, `${inputName}_amount`, key);
        setErrors(errorData);
    }
    // エラーが存在すればメッセージ入りのObjectを返し、なければnullを返す
    function validate(data, name, conditions, message) {
        let key = -1;
        if (Array.isArray(data)) {
            // エラーが存在するか
            key = data.findIndex(conditions);
        } else {
            key = data[name] ? -1 : 0;
        }
        if (key == -1) {
            return null;
        } else {
            return { key: key, message: message };
        }
    }
    // 金額のエラーバリデーション
    function validateAmount(data, key) {
        // エラーの条件
        const notExistAmount = (element, index) =>
            index == key && element[`${inputName}_date`] && (element[`${inputName}_amount`] ? false : true);
        let value = validate(data, `${inputName}_amount`, notExistAmount, "必須項目です");
        if (value != null) {
            return value;
        } else {
            // エラーの条件
            const negative = (element, index) => index == key && element[`${inputName}_amount`] < 0;
            value = validate(data, `${inputName}_amount`, negative, "0以上を入力");
            if (value != null) {
                return value;
            } else {
                // エラーの条件
                const maxOver = (element, index) => index == key && element[`${inputName}_amount`] > 999999999;
                value = validate(data, `${inputName}_amount`, maxOver, "入力可能な額を超えています");
                return value;
            }
        }
    }
    // レンダリング用の関数
    function addRow(count) {
        return (
            <tr key={count}>
                <th className={classes.leftCell}>{listName + (count + 1)}</th>
                <td className={classes.rightCell}>
                    <StyledTooltip
                        arrow={true}
                        title={
                            validationTrigger &&
                            errors
                                .filter((err) => err[`${inputName}_amount`] && err[`${inputName}_amount`].key == count)
                                .map((item) => item[`${inputName}_amount`]?.message ?? "")
                        }
                        open={
                            validationTrigger &&
                            isDisplayErrors[`${inputName}_amount`] == count &&
                            errors.filter(
                                (err) => err[`${inputName}_amount`] && err[`${inputName}_amount`].key == count
                            ).length > 0
                        }
                        onMouseEnter={(e) => handleOnMouseEnter(e, `${inputName}_amount`, count)}
                        onMouseOut={(e) => handleOnMouseOut(e)}>
                        <input
                            className={
                                validationTrigger &&
                                errors.filter(
                                    (err) => err[`${inputName}_amount`] && err[`${inputName}_amount`].key == count
                                ).length > 0
                                    ? "border border-danger border-2 rounded rounded-3"
                                    : ""
                            }
                            name={`${inputName}_amount-${count}`}
                            type='number'
                            value={data[count] ? data[count][`${inputName}_amount`] ?? "" : ""}
                            min={0}
                            step={1}
                            onChange={handleAmountOnChange}
                        />
                    </StyledTooltip>
                    <StyledTooltip
                        arrow={true}
                        title={
                            validationTrigger &&
                            errors
                                .filter((err) => err[`${inputName}_date`] && err[`${inputName}_date`].key == count)
                                .map((item) => item[`${inputName}_date`]?.message ?? "")
                        }
                        open={
                            validationTrigger &&
                            isDisplayErrors[`${inputName}_date`] == count &&
                            errors.filter((err) => err[`${inputName}_date`] && err[`${inputName}_date`].key == count)
                                .length > 0
                        }
                        onMouseEnter={(e) => handleOnMouseEnter(e, `${inputName}_date`, count)}
                        onMouseOut={(e) => handleOnMouseOut(e)}>
                        <input
                            className={
                                validationTrigger &&
                                errors.filter(
                                    (err) => err[`${inputName}_date`] && err[`${inputName}_date`].key == count
                                ).length > 0
                                    ? "border border-danger border-2 rounded rounded-3"
                                    : ""
                            }
                            name={`${inputName}_date-${count}`}
                            type='date'
                            value={data[count] ? data[count][`${inputName}_date`] ?? date : date}
                            onChange={handleDateOnChange}
                        />
                    </StyledTooltip>
                    <button
                        className='btn btn-sm btn-secondary px-3 ms-3'
                        type='button'
                        name={`${inputName}_delete-${count}`}
                        onClick={(e) => handleOnClickDelete(e)}>
                        削除
                    </button>
                </td>
            </tr>
        );
    }
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return row;
}
