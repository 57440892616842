//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { createContext, useEffect, useReducer, useRef, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import swal from "sweetalert";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import { subMonths } from "date-fns";
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { NativeSelect, Paper, Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import TravelExpenseConfirmTable from "../../components/transaction/travel_expense/TravelExpenseConfirmTable";
import BackButton from "../../components/BackButton";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { HOME_PATH } from "../../common/constants";
import monthAndYearFormat from "../../common/monthAndYearFormat";
import dateFormat from "../../common/dateFormat";
import processResponse from "../../common/processResponse";
import processErrorResponse from "../../common/processErrorResponse";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import "react-datepicker/dist/react-datepicker.css";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({ me500: { marginRight: "500px" }, imageDialog: { width: "500px" }, displayTop: { zIndex: "999" } })
);
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// 戻るときの遷移先のパス
const returnPath = HOME_PATH;

// DatePickerの日本語化に必要
registerLocale("ja", ja);

// 先月の日付を取得
const lastMonth = subMonths(new Date(), 1);

// 画像表示に関する処理に使用する各機能名
const stringTravelExpense = "travel-expense";
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// ステートの初期状態
const initUsers = { result: [], success: false };

// ステートの初期状態
const initShowReceiptTarget = { type: "", number: null };

// ステートの初期状態
const initExistDate = { result: [], success: false };

// ステートの初期状態
const initPhotos = { [stringTravelExpense]: {} };

// ステートの初期状態
const initRowCounts = { [stringTravelExpense]: null };
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
// ==========================経費精算明細============================ //
// コンテクストを作成
export const TravelExpenseContext = createContext();

// リクエストデータのステート更新用関数
const reducerTravelExpenseUpdateDataFunc = (travelExpenseUpdateData, action) => {
    const id = action.id;
    const data = action.data;
    switch (action.type) {
        case "set":
            return data;
        case "update":
            const requestDataArray = travelExpenseUpdateData.map((item) => {
                if (item.id === id) {
                    const returnItem = { ...item, ...data };
                    return returnItem;
                } else {
                    return item;
                }
            });
            return requestDataArray;
        case "reset":
            return [];
        default:
            return travelExpenseUpdateData;
    }
};
// ==========================経費精算明細============================ //

export default function TravelExpenseConfirm() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //定義したスタイルを利用するための設定
    const classes = useStyles();
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    // ===========================両機能共通============================= //
    // ユーザー一覧の値を管理する
    const [users, setUsers] = useState(initUsers);

    // 最も古いレコードのある年月を管理する
    const [oldestDate, setOldestDate] = useState(null);

    const [existDate, setExistDate] = useState(initExistDate);

    // datePickerの選択済みの値を管理する
    const [targetMonthAndYear, setTargetMonthAndYear] = useState(lastMonth);

    // 選択した年月の値を管理する
    const [selectedMonthAndYear, setSelectedMonthAndYear] = useState("");

    // 選択したユーザーのidを管理する
    const [selectedUserId, setSelectedUserId] = useState("");

    // 選択したユーザーの名前を管理する
    const [selectedUserName, setSelectedUserName] = useState("");

    // 選択した年月が締め処理済みかどうかを管理する
    const [isClosing, setIsClosing] = useState(false);

    // プルダウンメニュー用のリスト
    const [selectableUsers, setSelectableUsers] = useState([]);

    // 月ごとのユーザー一覧のRefオブジェクト
    const usersInMonthsRef = useRef({});

    // 未確認のみ表示への切り替え用ステート
    const [onlyUnconfirmed, setOnlyUnconfirmed] = useState(false);

    // 選択した対象月に対しての作業を開始する
    const [start, setStart] = useState(false);

    // 編集中の行があるかを管理する
    const [isRowLocked, setIsRowLocked] = useState(false);

    // 確認状態を変更する経費精算明細を格納する
    const [confirmSubmittable, setConfirmSubmittable] = useState([]);
    // ===========================両機能共通============================= //

    // ===========================画像表示コンポーネントについて============================= //
    // 各機能の行の数を格納
    const [rowCounts, setRowCounts] = useState(initRowCounts);

    // 表示する画像の対象の機能名とナンバーを管理する
    const [showReceiptTarget, setShowReceiptTarget] = useState(initShowReceiptTarget);

    // 表示する画像を管理する
    const [photoData, setPhotoData] = useState({});

    // 領収書表示のダイアログ画面を表示切替する
    const [isReceiptOpen, setIsReceiptOpen] = useState(false);

    // 取得した領収書の画像を格納していく
    const [photos, setPhotos] = useState(initPhotos);

    // 実際にクラス名として反映させるためのステート
    const [position, setPosition] = useState("position-absolute");

    // id="content"のスクロール位置を取得
    const contentYOffset = document.getElementById("content").getBoundingClientRect().top;
    // ===========================画像表示コンポーネントについて============================= //

    // ==========================経費精算明細============================ //
    // 送信するデータを管理するレデューサー
    const [travelExpenseUpdateData, dispatchTravelExpenseUpdateData] = useReducer(
        reducerTravelExpenseUpdateDataFunc,
        []
    );
    // 編集前のデータを持つステート
    const [travelExpensesBeforeChange, setTravelExpensesBeforeChange] = useState([]);

    // 小計をステートで管理
    const [travelExpenseTotalAmount, setTravelExpenseTotalAmount] = useState(0);
    // ==========================経費精算明細============================ //
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const getUsers = async () => {
        const url = `users`;
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        processResponse(res, setUsers, navigate, returnPath);
    };

    // 引数に受け取った年月が締め処理済みかどうかをDBから取得
    const getClose = async (monthAndYear) => {
        const url = `close/${monthAndYear}`;
        const res = await api.get(url);
        if (res.data.success && res.data.result !== null) {
            setIsClosing(Boolean(res.data.result.closing_process_is_closed));
        } else if (isClosing) {
            setIsClosing(false);
        }
    };

    const getExistDate = async () => {
        const url = `travel_expenses_exist_target_month_year`;
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        processResponse(res, setExistDate, navigate, returnPath);
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 年月の選択に変更があったときの処理
    const handleOnChangeMonthAndYear = (monthAndYear) => {
        // 年月(datePicker)の選択している月をステートに格納
        setTargetMonthAndYear(monthAndYear);

        // 選択済みのユーザーをリセット
        setSelectedUserId("");
    };

    // ユーザー選択時の処理
    const handleOnChangeUser = (e) => {
        // イベントの伝搬中止
        e.preventDefault();

        const userId = Number(e.target.value);

        // 選択したユーザーのidと名前をステートに格納
        setSelectedUserId(userId);

        const userName = users.result.find((user) => user.user_id === userId).user_name;
        setSelectedUserName(userName);
    };

    // 開始ボタン押下時
    const selectSubmit = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        setStart(true);
    };

    // ==============ここから画像表示コンポーネント用の記述============== //
    // 領収書表示コンポーネントを閉じる
    const handleOnReceiptClose = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // レシート確認を閉じる
        setIsReceiptOpen(false);
        setPhotoData({});
        setShowReceiptTarget(initShowReceiptTarget);
    };

    // 画像表示コンポーネントのページ送り
    const handleOnClickPhotoBack = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        setShowReceiptTarget((showReceiptTarget) => {
            return { type: showReceiptTarget.type, number: showReceiptTarget.number - 1 };
        });
    };

    // 画像表示コンポーネントのページ戻し
    const handleOnClickPhotoAdvance = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        setShowReceiptTarget((showReceiptTarget) => {
            return { type: showReceiptTarget.type, number: showReceiptTarget.number + 1 };
        });
    };
    // ==============ここまで画像表示コンポーネント用の記述============== //

    // 選択画面に戻るボタン押下時
    const resetSelected = async (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        await swal({
            title: "担当者の選択に戻ります",
            text: "",
            icon: "info",
            buttons: true
        }).then((value) => {
            if (value) {
                setStart(false);
                setIsRowLocked(false);
                setConfirmSubmittable([]);
                dispatchTravelExpenseUpdateData({ type: "reset" });
                // 表示画像を初期化
                setPhotos(initPhotos);
                setIsReceiptOpen(false);
                setPhotoData({});
                setShowReceiptTarget(initShowReceiptTarget);
            }
        });
    };

    // 確認完了ボタン押下時
    const confirmSubmit = async (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        if (confirmSubmittable.length > 0) {
            await swal({
                title: "確認",
                text: "データを更新しますか?",
                icon: "info",
                buttons: ["中止", "更新"]
            }).then((value) => {
                if (value) {
                    // apiの処理へ進む
                    changeConfirmed();
                }
            });
        }
    };
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => {
        // 各種データの取得
        getUsers();
        getExistDate();

        // イベントリスナーを作成
        window.addEventListener("scroll", changePositionCSS);
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // スクロール時にスクロール量に応じてスタイルを切り替える関数
    const changePositionCSS = () => {
        const scrollYOffset = window.scrollY;
        if (scrollYOffset > contentYOffset) {
            setPosition("position-fixed");
        } else if (scrollYOffset < contentYOffset) {
            setPosition("position-absolute");
        }
    };

    // submit可能かを判断する
    useEffect(() => {
        // 確認状態が変更されている経費精算データが有る時
        if (
            travelExpenseUpdateData.length > 0 &&
            travelExpensesBeforeChange.length > 0 &&
            travelExpenseUpdateData !== travelExpensesBeforeChange &&
            travelExpenseUpdateData.some(
                (updateData, index) => updateData.is_confirmed !== travelExpensesBeforeChange[index].is_confirmed
            )
        ) {
            // is_confirmedに変更が存在する行を抽出
            const travelExpensesConfirmable = travelExpenseUpdateData.filter(
                (updateData, index) => updateData.is_confirmed !== travelExpensesBeforeChange[index].is_confirmed
            );
            if (travelExpensesConfirmable.length > 0) {
                const tableName = "travel_expense_";
                const confirmColumns = ["id", "is_confirmed"]; // 確認状態の変更処理に必要な列のcolumn名
                // 確認状態が変更されている行から必要な列のみ抽出
                const confirmNecessary = travelExpensesConfirmable.map((travelExpense) => {
                    const changeConfirm = {};
                    confirmColumns.map((column) => {
                        changeConfirm[`${tableName}${column}`] = travelExpense[column];
                    });
                    if (Object.keys(changeConfirm).length === confirmColumns.length) {
                        return changeConfirm;
                    }
                });
                setConfirmSubmittable(confirmNecessary);
            }
        } else if (confirmSubmittable.length > 0) {
            // confirmSubmittableに既に要素がある場合に空にする
            setConfirmSubmittable([]);
        }
    }, [travelExpenseUpdateData, travelExpensesBeforeChange]);

    // 確認状態の変更処理
    const changeConfirmed = async () => {
        const res = await api.put("travel_expenses_change_is_confirmed", confirmSubmittable);
        // 成否の確認
        if (res.data.success) {
            // 確定成功時
            swal(res.data.message, "", "success");
            // 変更完了時点での行データを新たな変更前の基準として扱う
            setTravelExpensesBeforeChange(travelExpenseUpdateData);
        } else {
            // 確定失敗時にエラーに応じた処理を行う
            processErrorResponse(res, () => {}, navigate);
        }
    };

    // 年月が選択、またはステートの初期値が入った時に、選択済みの年月をステートに格納
    useEffect(() => {
        if (targetMonthAndYear) {
            // 選択された年月を文字列でステートに格納
            const monthAndYearString = monthAndYearFormat(targetMonthAndYear);
            setSelectedMonthAndYear(monthAndYearString.slice(0, 7));
            // 対象の月の締め処理の状態を取得
            getClose(monthAndYearString);
        }
    }, [targetMonthAndYear]);

    // 年月変更時、選択できるユーザー一覧を差し替える
    useEffect(() => {
        if (selectedMonthAndYear !== "" && existDate.result.length > 0) {
            const formattedDate = `${selectedMonthAndYear}-01`;
            const data = existDate.result.filter((item) => {
                return item.travel_expense == formattedDate;
            });
            const formattedData = data.length > 0 ? data[0].user : [];
            setSelectableUsers(formattedData);
        }
    }, [selectedMonthAndYear, existDate]);

    // 経費精算明細の小計を計算しステートに保存
    useEffect(() => {
        if (travelExpenseUpdateData.length > 0) {
            const amountArray = travelExpenseUpdateData.map((item) => {
                return Number(item.amount);
            });

            const totalAmount = amountArray.reduce((accumulator, currentValue) => {
                return accumulator + currentValue;
            });
            setTravelExpenseTotalAmount(totalAmount);
        } else {
            setTravelExpenseTotalAmount(0);
        }
    }, [travelExpenseUpdateData]);

    // 画像表示コンポーネントの状態切替
    useEffect(() => {
        if (showReceiptTarget.number === null) {
            // レシート確認を閉じる
            setIsReceiptOpen(false);
            setPhotoData({});
        }
    }, [showReceiptTarget]);

    // oldestStateの更新
    useEffect(() => {
        if (existDate.success) {
            let date = existDate.result[0]?.travel_expense;
            const objDate = date == null ? new Date() : new Date(date);
            setOldestDate(objDate);
        }
    }, [existDate]);

    // 画像表示用のダイアログ画面のコンポーネント
    const ReceiptDialog = () =>
        isReceiptOpen && Object.keys(photoData).length > 0 ? (
            <div
                className={`position-absolute top-0 pe-4 end-0
                }`}>
                <div className={`${classes.imageDialog}`}>
                    <Paper className='p-3' elevation={3}>
                        <div className='d-flex justify-content-start mb-3 p-2'>
                            <Button
                                variant='contained'
                                onClick={(e) => {
                                    handleOnReceiptClose(e);
                                }}>
                                閉じる
                            </Button>
                            <p className='p-2 m-0'>
                                <span className='fw-bold me-3'>領収書</span>
                                {photoData.name}
                            </p>
                        </div>
                        <div className='position-relative text-center'>
                            {!isRowLocked && showReceiptTarget.number > 1 && (
                                <button
                                    className='btn btn-outline-dark d-block h-100 position-absolute top-0 start-0'
                                    onClick={(e) => handleOnClickPhotoBack(e)}>
                                    ◀
                                </button>
                            )}
                            <img className='mw-100' src={photoData.photo} />
                            {!isRowLocked && showReceiptTarget.number < rowCounts[showReceiptTarget.type] && (
                                <button
                                    className='btn btn-outline-dark d-block h-100 position-absolute top-0 end-0'
                                    onClick={(e) => handleOnClickPhotoAdvance(e)}>
                                    ▶
                                </button>
                            )}
                        </div>
                    </Paper>
                </div>
            </div>
        ) : null;
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//

    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className={`${classes.displayTop} position-sticky top-0`}>
                        <div className='position-relative'>
                            {/* 画像表示用のDialogコンポーネント */}
                            <ReceiptDialog />
                        </div>
                    </div>
                    <div className='card-header d-flex'>
                        <h2>経費確認</h2>
                    </div>
                    <div id='scrollable-wrapper' className='card-body overflow-scroll'>
                        {!start && oldestDate !== null ? (
                            <div className='d-flex justify-content-start align-items-baseline text-nowrap my-4'>
                                <label className='fw-bold me-3'>
                                    対象月：
                                    <DatePicker
                                        selected={targetMonthAndYear}
                                        minDate={oldestDate}
                                        maxDate={new Date()}
                                        onChange={(date) => {
                                            handleOnChangeMonthAndYear(date);
                                        }}
                                        locale={ja}
                                        dateFormat='yyyy年MM月'
                                        showMonthYearPicker
                                    />
                                </label>
                                <div className='position-relative me-3'>
                                    <label className='fw-bold'>
                                        担当：
                                        {selectableUsers.length > 0 ? (
                                            <NativeSelect
                                                onChange={(e) => {
                                                    handleOnChangeUser(e);
                                                }}
                                                value={selectedUserId}>
                                                <option value='' disabled>
                                                    選択してください
                                                </option>
                                                {selectableUsers.map((item, index) => (
                                                    <option
                                                        key={index}
                                                        value={item.user_id}
                                                        // disabled={onlyUnconfirmed && item.is_confirmed === 1}
                                                    >
                                                        {item.user_name}
                                                    </option>
                                                ))}
                                            </NativeSelect>
                                        ) : (
                                            <span>選択可能なユーザーが居ません</span>
                                        )}
                                    </label>
                                    {Object.keys(selectableUsers).length > 0 && (
                                        <div className='position-absolute bottom-100 end-0'>
                                            <label className='fw-bold mb-1'>
                                                <input
                                                    type='checkbox'
                                                    checked={onlyUnconfirmed}
                                                    disabled={start}
                                                    onChange={(e) => setOnlyUnconfirmed(e.target.checked)}
                                                />
                                                未確認のみ対象
                                            </label>
                                        </div>
                                    )}
                                </div>
                                {Object.keys(selectableUsers).length > 0 && (
                                    <button
                                        className='btn btn-secondary'
                                        type='button'
                                        disabled={!selectedMonthAndYear || !selectedUserId}
                                        onClick={(e) => {
                                            selectSubmit(e);
                                        }}>
                                        開始
                                    </button>
                                )}
                            </div>
                        ) : (
                            <>{!start && <p className=' m-4'>...読み込み中</p>}</>
                        )}
                        {start && (
                            <div className='d-flex justify-content-start align-items-center text-nowrap my-4'>
                                <label className='fw-bold me-3'>
                                    対象月:
                                    <input
                                        type='text'
                                        readOnly
                                        value={
                                            dateFormat(monthAndYearFormat(targetMonthAndYear), false, "Y-m").format_date
                                        }
                                    />
                                </label>
                                <label className='fw-bold me-3'>
                                    担当：
                                    <input type='text' readOnly value={selectedUserName} />
                                </label>
                                <button
                                    className='btn btn-secondary'
                                    type='button'
                                    onClick={(e) => {
                                        resetSelected(e);
                                    }}>
                                    再選択
                                </button>
                                {isClosing && (
                                    <p className='h-auto m-0 ps-3 fw-bold'>締め処理済みです。変更はできません</p>
                                )}
                            </div>
                        )}
                        <div className='d-flex'>
                            <div className=''>
                                <TravelExpenseContext.Provider
                                    value={{
                                        returnPath,
                                        start,
                                        isClosing,
                                        isRowLocked,
                                        setIsRowLocked,
                                        selectedMonthAndYear,
                                        selectedUserId,
                                        travelExpenseUpdateData,
                                        dispatchTravelExpenseUpdateData,
                                        travelExpenseTotalAmount,
                                        setTravelExpensesBeforeChange,
                                        showReceiptTarget,
                                        setShowReceiptTarget,
                                        initShowReceiptTarget,
                                        setPhotoData,
                                        setIsReceiptOpen,
                                        photos,
                                        setPhotos,
                                        initPhotos,
                                        rowCounts,
                                        setRowCounts,
                                        stringTravelExpense,
                                        onlyUnconfirmed
                                    }}>
                                    <TravelExpenseConfirmTable />
                                </TravelExpenseContext.Provider>
                                {start && travelExpenseUpdateData.length > 0 && (
                                    <div className='mt-5'>
                                        <button
                                            className={`py-2 px-5 btn btn-lg${
                                                confirmSubmittable
                                                    ? " btn-success"
                                                    : " btn-outline-success  opacity-100"
                                            }`}
                                            type='button'
                                            disabled={confirmSubmittable.length === 0 || isRowLocked}
                                            onClick={(e) => confirmSubmit(e)}>
                                            完了
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackButton path={returnPath} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
