//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useEffect, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import CalculateRowForSuspensePayment from "./CalculateRowForSuspensePayment";
import CalculateRowForTravelExpense from "./CalculateRowForTravelExpense";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import amountToLocaleStringForMonthlyData from "../../../common/amountToLocaleStringForMonthlyData";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        userName: {
            fontSize: "18px",
            fontWeight: "bold"
        },
        userTables: {
            marginBottom: "100px"
        },
        userTopTable: {
            marginBottom: "30px"
        },
        caption: {
            fontSize: "16px"
        },
        tableHead1: {
            backgroundColor: "#99ccff"
        },
        tableHead2: {
            backgroundColor: "#66ff99"
        },
        tableHead3: {
            backgroundColor: "#ffbb88"
        },
        dialog: {
            maxHeight: "400px",
            backgroundColor: "#fefefe",
            border: "1px solid #666",
            textAlign: "center"
        },
        img: {
            objectFit: "contain",
            maxWidth: "100%"
        },
        plus: {
            fontWeight: "bold"
        },
        minus: {
            fontWeight: "bold",
            color: "#dc3545"
        }
    })
);
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// テーブルの見出しを定義
const suspensePaymentHeaders = ["No.", "作成日", "受領日", "金額"];
const travelExpenseHeaders = ["No.", "年月日", "金額", "支払先", "仕訳", "領収書"];
const differenceHeaders = ["仮払い合計", "経費合計", "差額"];
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function CalculateTable(props) {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //定義したスタイルを利用するための設定
    const classes = useStyles();
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const {
        setStart,
        loaded,
        setLoaded,
        suspensePaymentDataArr,
        travelExpenseDataArr,
        totalAmountDataArr,
        handleOnClickSnowImg
    } = props;
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 展開時に使用するステートを用意
    const [dataForDeployForSuspensePayment, setDataForDeployForSuspensePayment] = useState([]);
    const [dataForDeployForTravelExpense, setDataForDeployForTravelExpense] = useState([]);

    //データ存在フラグ
    const [isExistSuspensePayment, setIsExistSuspensePayment] = useState(false);
    const [isExistTravelExpense, setIsExistTravelExpense] = useState(false);

    // 各合計金額
    const [travelExpenseTotalAmount, setTravelExpenseTotalAmount] = useState(0);
    const [suspensePaymentTotalAmount, setSuspensePaymentTotalAmount] = useState(0);

    // 差額の値が正であるか
    const [isPositive, setIsPositive] = useState(true);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 初期値をRefオブジェクトに保管・フォーム展開用データの作成
    // 仮払い
    useEffect(() => {
        if (suspensePaymentDataArr.success && suspensePaymentDataArr.result.length > 0) {
            // 取得したデータを整理
            const suspensePaymentsData = [];
            for (const suspensePayment of suspensePaymentDataArr.result) {
                const data = {
                    id: suspensePayment.suspense_payment_id,
                    date: suspensePayment.suspense_payment_date,
                    receipt_date: suspensePayment.suspense_payment_receipt_date,
                    user_id: suspensePayment.suspense_payment_user_id,
                    amount: suspensePayment.suspense_payment_amount,
                    is_paid: suspensePayment.suspense_payment_is_paid === 1 ? true : false
                };
                suspensePaymentsData.push(data);
            }
            // 表示用ステートに格納
            setDataForDeployForSuspensePayment(suspensePaymentsData);

            // 読み込み完了
            setLoaded(true);
            setIsExistSuspensePayment(true);
        }
    }, [suspensePaymentDataArr]);

    // 経費
    useEffect(() => {
        if (travelExpenseDataArr.success && travelExpenseDataArr.result.length > 0) {
            // 取得したデータを整理
            const travelExpensesData = [];
            for (const travelExpense of travelExpenseDataArr.result) {
                const data = {
                    id: travelExpense.travel_expense_id,
                    date: travelExpense.travel_expense_date,
                    target_month_year: travelExpense.travel_expense_target_month_year,
                    user_id: travelExpense.travel_expense_user_id,
                    amount: travelExpense.travel_expense_amount,
                    name: travelExpense.travel_expense_name,
                    account_list_name: travelExpense.travel_expense_account_list_name,
                    is_paid: travelExpense.travel_expense_is_paid === 1 ? true : false,
                    file_name: travelExpense.travel_expense_file_name
                };
                travelExpensesData.push(data);
            }
            // 表示用ステートに格納
            setDataForDeployForTravelExpense(travelExpensesData);

            // 読み込み完了
            setLoaded(true);
            setIsExistTravelExpense(true);
        }
    }, [travelExpenseDataArr]);

    // 差額が正か負かチェック
    useEffect(() => {
        if (totalAmountDataArr.success) {
            // 各合計値
            const suspensePaymentAmount = Number(totalAmountDataArr.result.suspense_payment_total_amount);
            const travelExpenseAmount = Number(totalAmountDataArr.result.travel_expense_total_amount);
            setSuspensePaymentTotalAmount(suspensePaymentAmount);
            setTravelExpenseTotalAmount(travelExpenseAmount);

            // 差額
            const difference = suspensePaymentAmount - travelExpenseAmount;

            // 負の場合
            if (difference < 0) {
                setIsPositive(false);
            } else {
                setIsPositive(true);
            }
        }
    }, [totalAmountDataArr]);
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <>
            {/* 仮払い */}
            <div className={classes.userTables}>
                <h3>差額</h3>
                <Paper className='mb-4' square>
                    <TableContainer>
                        <Table>
                            <TableHead className={classes.tableHead3}>
                                <TableRow>
                                    {differenceHeaders.map((differenceHeader, index) => (
                                        <TableCell
                                            key={`differenceHeader_${index}`}
                                            className='MuiTableCell-alignCenter'>
                                            {differenceHeader}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className='MuiTableCell-alignCenter'>
                                        {suspensePaymentTotalAmount.toLocaleString()}
                                    </TableCell>
                                    <TableCell className='MuiTableCell-alignCenter'>
                                        {travelExpenseTotalAmount.toLocaleString()}
                                    </TableCell>
                                    <TableCell className='MuiTableCell-alignCenter'>
                                        <span className={`fs-4 fw-bold${isPositive ? "" : " text-danger"}`}>
                                            {amountToLocaleStringForMonthlyData(
                                                suspensePaymentTotalAmount - travelExpenseTotalAmount
                                            )}
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
            <div className={classes.userTables}>
                <h3>仮払い</h3>
                {isExistSuspensePayment ? (
                    <Paper className='mb-4' square>
                        {loaded && (
                            <TableContainer>
                                <Table>
                                    <TableHead className={classes.tableHead1}>
                                        <TableRow>
                                            {suspensePaymentHeaders.map((suspensePaymentHeader, index) => (
                                                <TableCell key={`suspensePaymentHeader_${index}`}>
                                                    {suspensePaymentHeader}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dataForDeployForSuspensePayment.map((suspensePaymentData, index) => {
                                            return (
                                                <CalculateRowForSuspensePayment
                                                    index={index + 1}
                                                    key={`suspensePaymentData_${index}`}
                                                    suspensePaymentData={suspensePaymentData}
                                                    handleOnClickSnowImg={handleOnClickSnowImg}
                                                />
                                            );
                                        })}
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>合計</TableCell>
                                            <TableCell>{suspensePaymentTotalAmount.toLocaleString()}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Paper>
                ) : (
                    <div className='text-center'>
                        <p>未精算の仮払いは存在しません。</p>
                    </div>
                )}
            </div>
            {/* 経費 */}
            <div>
                <h3>経費</h3>
                {isExistTravelExpense ? (
                    <Paper className='mb-4' square>
                        {loaded && (
                            <TableContainer>
                                <Table>
                                    <TableHead className={classes.tableHead2}>
                                        <TableRow>
                                            {travelExpenseHeaders.map((travelExpenseHeader, index) => (
                                                <TableCell key={`travelExpenseHeader_${index}`}>
                                                    {travelExpenseHeader}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dataForDeployForTravelExpense.map((travelExpenseData, index) => {
                                            return (
                                                <CalculateRowForTravelExpense
                                                    index={index + 1}
                                                    key={`travelExpenseData_${index}`}
                                                    travelExpenseData={travelExpenseData}
                                                    handleOnClickSnowImg={handleOnClickSnowImg}
                                                />
                                            );
                                        })}
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>合計</TableCell>
                                            <TableCell>{travelExpenseTotalAmount.toLocaleString()}</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Paper>
                ) : (
                    <div className='text-center'>
                        <p>未精算の経費は存在しません。</p>
                    </div>
                )}
            </div>
        </>
    );
}
