//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { FormControlLabel, RadioGroup, Radio } from "@material-ui/core";
import { Button } from "@material-ui/core";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import RequiredMark from "../../components/RequiredMark";
import BackButton from "../../components/BackButton";
import CloseButton from "../../components/CloseButton";
import DataShow from "../../components/DataShow";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import processResponse from "../../common/processResponse";
import processErrorResponse from "../../common/processErrorResponse";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
const returnPath = "/payee"; // 戻るときの遷移先のパス
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// accountLists の初期状態
const initAccountLists = { result: [], success: false };
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function PayeeRegister() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面遷移用
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // payeeの状態を管理する
    const [payee, setPayee] = useState({});

    // accountLists の状態を管理する
    const [accountLists, setAccountLists] = useState(initAccountLists);

    // プルダウンメニュー用の権限の配列を格納する
    const [accountListMenu, setAccountListsMenu] = useState([]);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 勘定科目情報を取得しステート accountLists にセットする
    const getAccountListsData = async () => {
        const url = "account_lists";
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        processResponse(res, setAccountLists, navigate, returnPath);
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//
    // 画面初期表示時に権限情報を取得する
    useEffect(() => {
        getAccountListsData();
    }, []);
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // accountLists からプルダウンメニュー用の配列を作成する
    useEffect(() => {
        if (accountLists.success) {
            // 取得した勘定科目情報から必要な値を配列にする
            const accountListsArray = accountLists.result.map((accountList) => {
                return {
                    label: accountList.account_list_name,
                    value: accountList.account_list_id
                };
            });
            // ステートに格納する
            setAccountListsMenu(accountListsArray);
        }
    }, [accountLists]);

    // Dialogに表示するレンダリング済みの要素を生成する関数
    const makeElementForDialogShow = (showData) => {
        // 空要素を生成
        const wrapper = document.createElement("div");
        // 空要素内に一時レンダリング
        ReactDOM.render(<DataShow data={showData} />, wrapper);
        // レンダリングされたコンポーネントを抽出
        const newElement = wrapper.firstChild;
        // 抽出した要素を返す
        return newElement;
    };

    // 確認ボタンクリック時、かつバリデーション通過時の処理
    const payeeSubmit = async (data) => {
        // 勘定科目の値から表示する勘定科目名を宣言
        const accountList = accountListMenu.find((item) => item.value === Number(data.account_list_id));
        const accountListName = accountList.label;

        // 確認画面用のデータ作成
        const showData = [
            { label: "支払先名", value: data.name },
            { label: "勘定科目", value: accountListName }
        ];

        // ダイアログ画面に表示するデータ一覧の要素を生成
        const element = makeElementForDialogShow(showData);

        // 確認のための変数を定義
        let confirm = false;

        // 確認画面を表示
        await swal({
            title: "確認",
            text: "以下の内容で登録します。",
            content: element,
            buttons: ["戻る", "登録"]
        }).then((value) => {
            if (value) {
                confirm = true;
            }
        });

        // 戻る場合は処理を中断する
        if (!confirm) {
            return;
        }

        // 登録データ作成
        const payeeData = {
            name: data.name,
            account_list_id: data.account_list_id
        };

        // 登録
        const url = "payees";
        const res = await api.post(url, payeeData);
        // 成否の確認
        if (res.data.success) {
            // 登録成功時
            swal(res.data.message, res.data.result.name, "success").then(() => {
                navigate(returnPath);
            });
        } else {
            // 登録失敗時にエラーに応じた処理を行う
            processErrorResponse(res, setPayee, navigate);
        }
    };

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control
    } = useForm();

    // プルダウンメニュー用のステート作成後に、フォームの初期値の入力を行う副作用フック
    useEffect(() => {
        if (accountListMenu.length === 1) {
            // 勘定科目の種類が一件のみの場合にその値をセットする
            setValue("account_list_id", accountListMenu[0].value, { shouldValidate: true });
        }
    }, [accountListMenu]);
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1>支払先情報登録</h1>
                        <div className='ms-auto'>
                            <CloseButton path={returnPath} />
                        </div>
                    </div>
                    <div className='card-body'>
                        <p className='fs-6 mb-4'>
                            <RequiredMark />
                            <span className='text-secondary'>は必須項目です。</span>
                        </p>
                        <form id='form'>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='name'>
                                    支払先名
                                    <RequiredMark />
                                </label>
                                <input
                                    id='name'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("name", {
                                        required: {
                                            value: true,
                                            message: "*支払先名を入力してください。"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "*255文字以内で入力してください。"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.name && errors.name?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{payee.error_list?.name && payee.error_list.name}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='account_list_id'>
                                    勘定科目
                                    <RequiredMark />
                                </label>
                                <br />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.account_list_id && errors.account_list_id?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {payee.error_list?.account_list_id && payee.error_list.account_list_id}
                                </small>
                                <Controller
                                    id='account_list_id'
                                    name='account_list_id'
                                    defaultValue=''
                                    control={control}
                                    rules={{ required: "*選択してください。" }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            {Object.keys(accountListMenu).length > 0 && (
                                                <RadioGroup {...field} className='d-flex flex-row mb-4'>
                                                    {accountListMenu.map((item, index) => (
                                                        <FormControlLabel
                                                            key={index}
                                                            className='w-50 mx-0'
                                                            value={item.value}
                                                            control={
                                                                <Radio
                                                                    checked={field.value == item.value ? true : false}
                                                                />
                                                            }
                                                            label={item.label}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            )}
                                        </>
                                    )}
                                />
                            </div>
                            <div className='form-group mb-3 d-flex'>
                                <BackButton path={returnPath} />
                                <Button
                                    type='button'
                                    variant='contained'
                                    color='primary'
                                    onClick={handleSubmit(payeeSubmit)}>
                                    確認
                                </Button>
                            </div>
                            {/* input(type='text')が一つしかない場合の誤作動防止のため。 */}
                            <input type='text' name='dummy' className='d-none' />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
