//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import swal from "sweetalert";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import { parseISO, isBefore, isSameDay } from "date-fns";
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import CreatePDFOutline from "../../components/transaction/CreatePDFOutline";
import { LOAD_ERROR_TITLE, LOAD_ERROR_TEXT } from "../../components/transaction/DownloadReceiptPDF";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { UserContext } from "../../providers/UserProvider";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import arraySortByDate from "../../common/arraySortByDate";
import { checkAuthorities } from "../../common/checkAuthorities";
import determineFiscalYear, {
    fiscalStartDate
} from "../../common/determineFiscalYear";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import "react-datepicker/dist/react-datepicker.css";
import { debounce } from "lodash";
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
registerLocale("ja", ja); // DatePickerの日本語化に必要

// 現在の年度を取得
const nowFiscalYear = determineFiscalYear(new Date());

const INTERVAL_MIL_SEC = 1000; // 待ち時間(ms)
const loadErrorReceipt = "領収書" + LOAD_ERROR_TITLE;
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function CreatePDF() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { userInfo } = useContext(UserContext);
    const userAuthorityNumber = userInfo.user.authority_number; // 権限
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 権限確認用関数を分割代入
    const { checkAccountant } = checkAuthorities;
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 経費明細の指定年度分のレコードの値を管理する
    const [travelExpensesYear, setTravelExpensesYear] = useState({
        travelExpense: [],
        fiscalYear: null
    });
    // 最も古いレコードのある年度を管理する
    const [oldestFiscalYear, setOldestFiscalYear] = useState(null);
    const [latestFiscalYear, setLatestFiscalYear] = useState(nowFiscalYear);
    // datePickerの選択済みの値を管理する
    const [selectedFiscalYear, setFiscalYear] = useState(nowFiscalYear);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 最も古い経費明細の年月の値を取得
    const getTravelExpenseOldestAll = async () => {
        const res = await api.get(`travel_expenses_oldest_all`);
        // 成功時に指定したユーザの最古のデータの年月を返す
        if (res.data.success && res.data.result.travel_expense_oldest_date) {
            setOldestFiscalYear(
                determineFiscalYear(
                    parseISO(res.data.result.travel_expense_oldest_date)
                )
            );
        }
        return false;
    };

    // 経費明細の指定した年度の全レコードを取得
    const getTravelExpensesYear = async (year) => {
        const res = await api.get(`travel_expenses_target_year_pdf/${year}`);
        // resの内容に応じて処理を行う
        if (res.data.success) {
            const tableValue = "travel_expense_"; // カラムを持つテーブル名
            // 取得したいカラム一覧
            const transactionKey = ["target_month_year", "date", "user_id", "file_name", "amount", "name"];
            const dataArray = res.data.result.map((result) => {
                const data = { account_list_name: result.account_list_name }; // 勘定科目を取得
                transactionKey
                    .filter((key) => result[`${tableValue}${key}`])
                    .map(
                        (key) =>
                            (data[`${key}`] = result[`${tableValue}${key}`])
                    );
                return data;
            });
            if (dataArray.length > 0) {
                setTravelExpensesYear({
                    travelExpense: arraySortByDate(dataArray, "date"),
                    fiscalYear: year
                });
            } else {
                swal({
                    title: loadErrorReceipt,
                    text: LOAD_ERROR_TEXT,
                    icon: "warning"
                });
            }
        }
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 年度を選択した時に最古の年度~最新の年度間の年度ならば更新します
    const handleOnChangeYear = (date = new Date()) => {
        // 年月(datePicker)で選択している年度の開始日
        const fiscalStartDay = fiscalStartDate(date);
        if (
            // 最古の年度以上
            (!oldestFiscalYear ||
                isBefore(oldestFiscalYear, fiscalStartDay) ||
                isSameDay(oldestFiscalYear, fiscalStartDay)) &&
            // 最後の年度以下
            (!latestFiscalYear ||
                isBefore(fiscalStartDay, latestFiscalYear) ||
                isSameDay(fiscalStartDay, latestFiscalYear))
        ) {
            setFiscalYear(fiscalStartDay);
        }
    };
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => {
        // 経理担当者以上
        if (checkAccountant(userAuthorityNumber)) {
            getTravelExpenseOldestAll();
            setLatestFiscalYear(nowFiscalYear);
            setFiscalYear(nowFiscalYear);
        }
    }, [nowFiscalYear]);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//
    //=====================================================JSXここから=====================================================//
    const YearPicker = (
        <label className="fw-bold me-3">
            対象年度:
            <DatePicker
                selected={selectedFiscalYear}
                minDate={oldestFiscalYear}
                maxDate={latestFiscalYear}
                onChange={(date) => handleOnChangeYear(date)}
                locale={ja}
                dateFormat="yyyy年度"
                showYearPicker
            />
        </label>
    );
    const GetTravelExpensesYearArea = (
        <>
            {YearPicker}
            <button
                className="ms-3 btn btn-secondary"
                type="button"
                disabled={!selectedFiscalYear}
                onClick={debounce(
                    () =>
                        getTravelExpensesYear(selectedFiscalYear.getFullYear()),
                    INTERVAL_MIL_SEC
                )}>
                PDF作成開始
            </button>
        </>
    );

    return (
        <CreatePDFOutline
            upperArea={GetTravelExpensesYearArea}
            monthAndYear={travelExpensesYear.fiscalYear}
            transactions={travelExpensesYear.travelExpense}
            pageType={"(年度)"}
        />
    );
}
