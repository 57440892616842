//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate, useParams } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import swal from "sweetalert";
import { useForm } from "react-hook-form";
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { Button } from "@material-ui/core";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import RequiredMark from "../../components/RequiredMark";
import BackButton from "../../components/BackButton";
import CloseButton from "../../components/CloseButton";
import DataShow from "../../components/DataShow";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import processResponse from "../../common/processResponse";
import processErrorResponse from "../../common/processErrorResponse";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
const returnPath = "/bank_account"; // 戻るときの遷移先のパス
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// bankAccount の初期状態
const initBankAccount = { result: [], success: false };
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function BankAccountEdit() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面遷移用
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { id } = useParams();
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 編集対象の資金口座の情報を管理するステート
    const [bankAccount, setBankAccount] = useState(initBankAccount);

    // 編集前の初期値を格納するRefオブジェクト
    const bankAccountRef = useRef({});

    // submitのボタンの状態を管理する
    const [submittable, setSubmittable] = useState(false);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 編集対象の資金口座情報を取得しステート bankAccount にセットする
    const getBankAccountData = async (id) => {
        const url = `bank_accounts/${id}`;
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        processResponse(res, setBankAccount, navigate, returnPath);
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面初期表示時に編集対象の資金口座と権限の情報を取得する
    useEffect(() => {
        getBankAccountData(id);
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    // Dialogに表示するレンダリング済みの要素を生成する関数
    const makeElementForDialogShow = (showData) => {
        // 空要素を生成
        const wrapper = document.createElement("div");
        // 空要素内に一時レンダリング
        ReactDOM.render(<DataShow data={showData} />, wrapper);
        // レンダリングされたコンポーネントを抽出
        const newElement = wrapper.firstChild;
        // 抽出した要素を返す
        return newElement;
    };

    // 確認ボタンクリック時、かつバリデーション通過時の処理
    const bankAccountSubmit = async (data) => {
        // 確認画面用のデータ作成
        const showData = [
            { label: "資金口座名", value: data.name },
            { label: "備考", value: data.remarks }
        ];

        // ダイアログ画面に表示するデータ一覧の要素を生成
        const element = makeElementForDialogShow(showData);

        // 確認のための変数を定義
        let confirm = false;

        // 確認画面を表示
        await swal({
            title: "確認",
            text: "以下の内容で更新します。",
            content: element,
            buttons: ["戻る", "更新"]
        }).then((value) => {
            if (value) {
                confirm = true;
            }
        });

        // 戻る場合は処理を中断する
        if (!confirm) {
            return;
        }

        // 更新データ作成
        const bankAccountData = {
            name: data.name,
            remarks: data.remarks
        };

        // 更新
        const url = `bank_accounts/${id}`;
        const res = await api.put(url, bankAccountData);
        // 成否の確認
        if (res.data.success) {
            // 更新成功時
            swal(res.data.message, res.data.result.name, "success").then(() => {
                navigate(returnPath);
            });
        } else {
            // 更新失敗時にエラーに応じた処理を行う
            processErrorResponse(res, setBankAccount, navigate);
        }
    };

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        watch
    } = useForm();

    // 以下、あらかじめinputに値を入力するための副作用フック
    useEffect(() => {
        // getValuesについて
        // 必須項目かつ、setValueでのみ値の入る項目の値がないことを条件にして、一度だけ初期値が入力されるようにしている
        if (bankAccount.success && !getValues("name")) {
            const bankAccountResult = bankAccount.result;
            // 編集前のデータ作成
            const beforeBankAccountData = {
                name: bankAccountResult.bank_account_name,
                remarks: bankAccountResult.bank_account_remarks
            };
            setValue("name", beforeBankAccountData.name, { shouldValidate: false });
            setValue("remarks", beforeBankAccountData.remarks, { shouldValidate: false });

            // Refオブジェクトに格納
            bankAccountRef.current = beforeBankAccountData;
        }
    }, [bankAccount]);

    // 内容が変更されたかを監視し、変更された場合にステートを変更する
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === "change") {
                const beforeValues = bankAccountRef.current; // 編集前の資金口座情報
                const newValues = getValues(); // 現在のフォーム全体のinputの値
                // 編集前の初期値から変更されているフォームの値が1つでもあればsubmitを可能にする
                setSubmittable(Boolean(Object.keys(beforeValues).find((key) => beforeValues[key] !== newValues[key])));
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1>資金口座情報編集</h1>
                        <div className='ms-auto'>
                            <CloseButton path={returnPath} />
                        </div>
                    </div>
                    <div className='card-body'>
                        <p className='fs-6 mb-4'>
                            <RequiredMark />
                            <span className='text-secondary'>は必須項目です。</span>
                        </p>
                        <form id='form'>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='name'>
                                    資金口座名
                                    <RequiredMark />
                                </label>
                                <input
                                    id='name'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("name", {
                                        required: {
                                            value: true,
                                            message: "*資金口座名を入力してください。"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "*255文字以内で入力してください。"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.name && errors.name?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {bankAccount.error_list?.name && bankAccount.error_list.name}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='remarks'>
                                    備考
                                </label>
                                <input
                                    id='remarks'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("remarks", {
                                        maxLength: {
                                            value: 255,
                                            message: "*255文字以内で入力してください。"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.remarks && errors.remarks?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {bankAccount.error_list?.remarks && bankAccount.error_list.remarks}
                                </small>
                            </div>
                            <div className='form-group mb-3 d-flex'>
                                <BackButton path={returnPath} />
                                <Button
                                    type='button'
                                    variant='contained'
                                    color='primary'
                                    disabled={!submittable}
                                    onClick={handleSubmit(bankAccountSubmit)}>
                                    確認
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
