//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { Table, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { monthlyDataReferenceContext } from "../../../providers/MonthlyDataReferenceProvider";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// MUIのTableRowのstyleを調整
const StyledTableRow = withStyles({
    "@global": { ".MuiTableRow-root": { height: "40px" } }
})(TableRow);

// スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        inlineTable: {
            display: "inline-table"
        }
    })
);
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
export const PL_HEAD_CAPTIONS = ["年月", "売上", "経費", "現金出納帳", "資金", "その他", "特記事項"]; // 損益管理大見出し(P/L === Profit and Loss Statement)
export const EXPENSE_CALC_CAPTIONS = ['領収書合計', '経費合計', '予納その他経費', '粗利益']; // 経費小見出し
export const CASHBOOK_CAPTIONS =['小口繰越現金', '現金引き出し合計', '領収書合計', '差引現金残']; // 現金出納帳小見出し
export const AMOUNT_CAPTIONS = ["翌月繰越現預金", "月残高合計", "差額"]; // 資金小見出し
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function ItemNameColumn() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //定義したスタイルを利用するための設定
    const classes = useStyles();
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { proceedsList, expensesList, bankAccountsList, othersList } =
        useContext(monthlyDataReferenceContext);
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    const CaptionTableRow = ({ children }) => (
        <StyledTableRow>
            <TableCell align="center">{children}</TableCell>
        </StyledTableRow>
    );

    return (
        <div
            className={`${classes.inlineTable} border-start border-start-1 border-secondary mb-4`}>
            <Table className="table table-bordered text-nowrap overflow-x-visible">
                <TableHead className="caption-top">
                    <StyledTableRow>
                        <TableCell align="center">年月</TableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {/* 売上 START */}
                    <StyledTableRow className="table-danger">
                        <TableCell
                            align="center"
                            className="fw-bolder">
                            売上
                        </TableCell>
                    </StyledTableRow>
                    {proceedsList.map((item) =>
                        <CaptionTableRow key={item.id}>
                            {item.name}
                        </CaptionTableRow>
                    )}
                    <StyledTableRow>
                        <TableCell align="center">売上合計</TableCell>
                    </StyledTableRow>
                    {/* 売上 END */}
                    {/* 経費 START */}
                    <StyledTableRow className="table-info">
                        <TableCell
                            align="center"
                            className="fw-bolder">
                            経費
                        </TableCell>
                    </StyledTableRow>
                    {expensesList.map((item) => (
                        <CaptionTableRow key={item.id}>
                            {item.name}
                        </CaptionTableRow>
                    ))}
                    {EXPENSE_CALC_CAPTIONS.map((caption, index) => (
                        <CaptionTableRow key={index}>{caption}</CaptionTableRow>
                    ))}
                    {/* 経費 END */}
                    {/* 現金出納帳 START */}
                    <StyledTableRow className="table-success">
                        <TableCell
                            align="center"
                            className="fw-bolder">
                            現金出納帳
                        </TableCell>
                    </StyledTableRow>
                    {CASHBOOK_CAPTIONS.map((caption, index) => (
                        <CaptionTableRow key={index}>{caption}</CaptionTableRow>
                    ))}
                    {/* 資金 START */}
                    <StyledTableRow className="table-success">
                        <TableCell
                            align="center"
                            className="fw-bolder">
                            資金
                        </TableCell>
                    </StyledTableRow>
                    <CaptionTableRow>{AMOUNT_CAPTIONS[0]}</CaptionTableRow>
                    {bankAccountsList.map((item) =>
                        <CaptionTableRow key={item.id}>
                            {item.name}
                        </CaptionTableRow>
                    )}
                    {AMOUNT_CAPTIONS.slice(1).map((caption, index) => (
                        <CaptionTableRow key={index}>{caption}</CaptionTableRow>
                    ))}
                    {/* 資金 END */}
                    {/* その他 END */}
                    <StyledTableRow className="table-secondary">
                        <TableCell
                            align="center"
                            className="fw-bolder">
                            その他
                        </TableCell>
                    </StyledTableRow>
                    {othersList.map((item) =>
                        <CaptionTableRow key={item.id}>
                            {item.name}
                        </CaptionTableRow>
                    )}
                    <StyledTableRow className="table-primary">
                        <TableCell
                            align="center"
                            className="fw-bolder">
                            特記事項
                        </TableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        </div>
    );
}
