import axios from "axios";

export const api = axios.create({
    baseURL: "/api/"
});

api.interceptors.request.use((config) => {
    // config.headers.authorization = `Bearer ${ここにアクセストークンを入れる}`
    config.headers.common["X-Requested-With"] = "XMLHttpRequest";
    config.headers.post["Content-Type"] = "application/json";
    config.headers.post["Accept"] = "application/json";
    config.withCredentials = true;
    config.timeout = 60 * 1000;
    config.maxContentLength = 1000000000;

    //リクエストのレスポンスステートの検証:resolve
    config.validateStatus = function (status) {
        return (status >= 200 && status < 300) || status === 401 || status === 404 || status === 422 || status === 500;
    };

    return config;
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        switch (error.response.status) {
            case 400:
            case 401:
                console.log("認証エラーです");
                break;
            case 404:
                console.log("URLがありません");
                break;
            case 422:
                console.log("バリデーションエラーです");
                break;
            case 500:
                console.log("サーバーエラーです");
                break;
            default:
                console.error(error.response.status, error.message);
        }
        return Promise.reject(error);
    }
);
