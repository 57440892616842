//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useMemo, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { NativeSelect, Button, Box } from "@material-ui/core";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import BackButton from "../../components/BackButton";
import CloseButton from "../../components/CloseButton";
import SuspensePaymentConfirmDataTable from "../../components/transaction/suspense_payment/SuspensePaymentConfirmDataTable";
import OutputExcel from "../../components/OutputExcel";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { UserContext } from "../../providers/UserProvider";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { checkAuthorities } from "../../common/checkAuthorities";
import { HOME_PATH } from "../../common/constants";
import processResponse from "../../common/processResponse";
import processErrorResponse from "../../common/processErrorResponse";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// ホーム画面に戻る時の遷移先のパス
const returnPath = HOME_PATH;
// 見出しの配列のパターンを定義
const headers = [/*"確認",*/ "精算", "作成日", "金額", "照会", "編集", "削除", "ダウンロード"];
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// suspense_payments の初期状態
const initSuspensePayments = { result: [], success: false };
const initUsers = { result: [], success: false };
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function SuspensePaymentConfirm() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面遷移用
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // ログイン中のユーザー情報を取得
    const { userInfo } = useContext(UserContext);
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 権限確認用関数を分割代入
    const { checkAccountant } = checkAuthorities;
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // ユーザ一覧の値を管理する
    const [users, setUsers] = useState(initUsers);
    /// 選択したユーザーのidを管理する
    const [selectedUserId, setSelectedUserId] = useState("");

    // 選択したユーザーの名前を管理する
    const [selectedUserName, setSelectedUserName] = useState("");

    // 選択したユーザーの名前を管理する
    // suspensePayments の状態を管理する
    const [suspensePayments, setSuspensePayments] = useState(initSuspensePayments);

    // suspensePayments を表示行に変換したものを保持する
    const [rows, setRows] = useState([]);

    const [start, setStart] = useState(false);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 仮払い情報を取得しステート suspensePayments に格納します
    const getSuspensePaymentsData = async (user_id) => {
        const res = await api.get(`suspense_payments_user_data/${user_id}`);
        // resの内容に応じて処理を行う
        processResponse(res, setSuspensePayments, navigate, returnPath);
    };
    // 1年以内に登録されている仮払い情報を持つユーザ一覧を取得してステートusersに格納します
    const getSuspensePaymentUsers = async () => {
        const res = await api.get(`users_suspense_payment`);
        // resの内容に応じて処理を行う
        processResponse(res, setUsers, navigate, returnPath);
    };

    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 一覧の編集ボタンをクリックしたら、仮払い情報編集画面へ
    const handleOnClickEdit = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 編集画面へ進む
        navigate(`/suspense_payment/confirm/edit/${id}`);
    };

    // 削除
    const handleOnClickDelete = async (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 確認のための変数を定義
        let confirm = false;

        // 確認画面を表示
        await swal({
            icon: "info",
            title: "対象の仮払いを削除しますか？",
            text: "",
            buttons: ["キャンセル", "削除"],
        }).then((value) => {
            if (value) {
                confirm = true;
            }
        });

        // キャンセルの場合は処理を中断する
        if (!confirm) {
            return;
        }

        //削除
        const url = `suspense_payments/${id}`;
        const res = await api.delete(url);

        // 成否の確認
        if (res.data.success) {
            // 削除成功時
            // モーダルウィンドウで成功メッセージを表示
            swal(res.data.message, "", "success").then(() => {
                // 削除したユーザを除外した全ユーザを配列化
                const newSuspensePayments = suspensePayments.result.filter(
                    (suspensePayment) => suspensePayment.suspense_payment_id !== id
                );
                // ステートを更新
                setSuspensePayments({
                    result: newSuspensePayments,
                    success: true,
                });
            });
        } else {
            // 削除失敗時にエラーに応じた処理を行う
            processErrorResponse(res, () => {}, navigate);
        }
    };

    // 照会
    const handleOnClickShow = async (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 照会画面へ進む
        navigate(`/suspense_payment/confirm/show/${id}`);
    };

    // ユーザー選択時の処理
    const handleOnChangeUser = (e) => {
        // イベントの伝搬中止
        e.preventDefault();

        const userId = Number(e.target.value);

        // 選択したユーザーのidと名前をステートに格納
        setSelectedUserId(userId);

        const userName = users.result.find((p) => p.user_id === userId).user_name;
        setSelectedUserName(userName);
    };

    // 開始ボタン押下時
    const selectSubmit = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        setStart(true);
    };

    // 仮払金申請書兼受領書Excel fileをdownloadする
    const downloadSuspensePaymentExcel = (id, created_date, user_name = userInfo.user.user_name) => (
        <OutputExcel
            fileName={`仮払金受領書_${user_name}_${created_date}`}
            fileType='Excel'
            api_url='export_suspense_payment'
            buttonTextIsFileName={false}
            data={{ id: id }}
        />
    );
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useMemo(() => {
        const userAuthorityNumber = userInfo.user.authority_number;
        // 経理担当者以上
        if (checkAccountant(userAuthorityNumber)) {
            getSuspensePaymentUsers();
            // getTravelExpenseOldestAll();
        }
        // 画面初期表示時に仮払い情報を取得する
        // getSuspensePaymentsData(id);
    }, []);

    // 選択画面に戻るボタン押下時
    const resetSelected = async (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        await swal({
            title: "担当者の選択に戻ります",
            text: "",
            icon: "info",
            buttons: true,
        }).then((value) => {
            if (value) {
                setStart(false);
                setSelectedUserId("");
                setSelectedUserName("");
                setSuspensePayments(initSuspensePayments);
                setRows([]);
                getSuspensePaymentUsers();
            }
        });
    };
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // searchedSuspensePaymentsに応じて、画面表示用の配列を生成
    useEffect(() => {
        if (suspensePayments.success) {
            const newRows = suspensePayments.result.map((suspensePayments) => {
                const row = {
                    id: suspensePayments.suspense_payment_id,
                    isActive: true,
                    isChanged: false,
                    data: {
                        date: suspensePayments.suspense_payment_date,
                        amount: suspensePayments.suspense_payment_amount,
                        showButton: (
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={(e) => handleOnClickShow(e, suspensePayments.suspense_payment_id)}>
                                照会
                            </Button>
                        ),
                        editBtn:
                            suspensePayments.is_closed || suspensePayments.suspense_payment_is_paid ? (
                                "-"
                            ) : (
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={(e) => handleOnClickEdit(e, suspensePayments.suspense_payment_id)}>
                                    編集
                                </Button>
                            ),
                        deleteBtn:
                            suspensePayments.is_closed || suspensePayments.suspense_payment_is_paid ? (
                                "-"
                            ) : (
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={(e) => handleOnClickDelete(e, suspensePayments.suspense_payment_id)}>
                                    削除
                                </Button>
                            ),
                        downloadBtn: downloadSuspensePaymentExcel(
                            suspensePayments.suspense_payment_id,
                            suspensePayments.suspense_payment_date,
                            userInfo.user.user_name
                        ),
                    },
                };

                return row;
            });

            setRows(newRows);
        }
    }, [suspensePayments]);

    useEffect(() => {
        if (start) {
            getSuspensePaymentsData(selectedUserId);
        }
    }, [start]);
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//

    const GetTravelExpensesYearArea = (
        <div className='d-flex justify-content-start align-items-baseline text-nowrap my-4'>
            <div className='position-relative me-3'>
                <label className='fw-bold'>
                    担当:
                    {Object.keys(users.result).length > 0 ? (
                        <NativeSelect
                            onChange={(e) => {
                                handleOnChangeUser(e);
                            }}
                            value={selectedUserId}>
                            <option value='' disabled>
                                選択してください
                            </option>
                            {users.result.map((user, index) => (
                                <option key={index} value={user.user_id}>
                                    {user.user_name}
                                </option>
                            ))}
                        </NativeSelect>
                    ) : (
                        <span>選択可能なユーザが居ません</span>
                    )}
                </label>
            </div>
            <button
                className='btn btn-secondary'
                type='button'
                disabled={!selectedUserId}
                onClick={(e) => {
                    selectSubmit(e);
                }}>
                開始
            </button>
        </div>
    );
    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1>仮払い情報確認</h1>
                        <div className='ms-auto'>
                            <CloseButton path={returnPath} />
                        </div>
                    </div>
                    <div className='card-body'>
                        {!start ? (
                            <div className='d-flex justify-content-start align-items-baseline text-nowrap my-4'>
                                <div className='position-relative me-3'>
                                    <label className='fw-bold'>
                                        担当：
                                        {Object.keys(users.result).length > 0 ? (
                                            <NativeSelect
                                                onChange={(e) => {
                                                    handleOnChangeUser(e);
                                                }}
                                                value={selectedUserId}>
                                                <option value='' disabled>
                                                    選択してください
                                                </option>
                                                {users.result.map((user, index) => (
                                                    <option key={index} value={user.user_id}>
                                                        {user.user_name}
                                                    </option>
                                                ))}
                                            </NativeSelect>
                                        ) : (
                                            <span>選択可能なユーザーが居ません</span>
                                        )}
                                    </label>
                                </div>
                                <button
                                    className='btn btn-secondary'
                                    type='button'
                                    disabled={!selectedUserId}
                                    onClick={(e) => {
                                        selectSubmit(e);
                                    }}>
                                    開始
                                </button>
                            </div>
                        ) : (
                            <>{!start && <p className=' m-4'>...読み込み中</p>}</>
                        )}
                        {start && (
                            <>
                                <div className='d-flex justify-content-start align-items-center text-nowrap my-4'>
                                    <label className='fw-bold me-3'>
                                        担当：
                                        <input type='text' readOnly value={selectedUserName} />
                                    </label>
                                    <button
                                        className='btn btn-secondary me-3'
                                        type='button'
                                        onClick={(e) => {
                                            resetSelected(e);
                                        }}>
                                        再選択
                                    </button>
                                </div>
                                <SuspensePaymentConfirmDataTable headers={headers} rows={rows} />
                            </>
                        )}
                    </div>
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackButton path={returnPath} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
