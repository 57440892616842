//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles"; //_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { MonthlyDataContext } from "../../../contents/monthly_data/MonthlyDataRegister";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// MUIのTooltipのz-indexを調整
const StyledTooltip = withStyles({
    "@global": {
        ".MuiTooltip-popper": {
            zIndex: "1300",
        },
    },
})(Tooltip);
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function MonthlyDataAmount(props) {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const {
        errors,
        setErrors,
        isDisplayErrors,
        setIsDisplayErrors,
        handleOnMouseEnter,
        handleOnMouseOut,
        validationTrigger,
        setValidationTrigger,
        nullOrPush,
    } = useContext(MonthlyDataContext);
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { id, date, state, setState = (f) => f, sum = 0, totalAmount = 0, setSub = (f) => f } = props;

    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 売上先金額
    const handleOnChange = (e) => {
        const result = setStateBeforeInit(e);
        setState(result);

        if (state?.monthly_data_amount != undefined) {
            createError(0);
        }
        if (id == 5) {
            // 小口繰越現金
            const amount = parseInt(result ? result?.monthly_data_amount ?? 0 : 0);
            const total = parseInt(totalAmount ?? 0);
            const sub = (isNaN(amount) ? 0 : amount) + parseInt(sum ?? 0) - (isNaN(total) ? 0 : total);
            setSub(sub);
        } else if (id == 6) {
            // 翌月繰越現預金
            const amount = parseInt(result ? result?.monthly_data_amount ?? 0 : 0);
            const sub = (isNaN(amount) ? 0 : amount) - parseInt(sum ?? 0);
            setSub(sub);
        }
    };
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    //stateのセット前の準備
    function setStateBeforeInit(e) {
        const value = e.target.value;
        const name = e.target.name;
        let key = -1;
        let result = state;
        const target_key = name.substring(name.lastIndexOf("-") + 1);
        const target_name = name.substring(0, name.lastIndexOf("-"));
        const result_val = {
            monthly_data_date: date,
            monthly_data_category_id: id,
            monthly_data_target_id: 1,
            [target_name]: value,
        };
        return result_val;
    }
    // 実際にエラーを追加削除する関数
    function createError(key) {
        let errorData = errors;
        errorData = nullOrPush(errorData, validateAmount(state), `amount-${id}`, key);
        setErrors(errorData);
    } // data(props)更新時
    useEffect(() => {
        if (state?.monthly_data_amount != undefined) {
            createError(0);
        }
    }, [state, validationTrigger]);
    // errors(context)更新時 ※errorsの再レンダリングに必要
    useEffect(() => {
        if (state !== null) {
        }
    }, [errors]);
    // エラーが存在すればメッセージ入りのObjectを返し、なければnullを返す
    function validate(conditions, message) {
        let key = -1;
        key = conditions ? 0 : -1;
        if (key == -1) {
            return null;
        } else {
            return { key: key, message: message };
        }
    }
    // 金額のエラーバリデーション
    function validateAmount(data) {
        // エラーの条件
        if (data?.monthly_data_amount == "") {
            return null;
        }
        // エラーの条件
        const negative = Number(data?.monthly_data_amount) < 0;
        let value = validate(negative, "0以上を入力");
        // 小口繰越現金以外は入力金額の下限が0
        if (id != 5 && value != null) {
            return value;
        } else if (id == 5) {
            // idが5の(小口繰越現金)入力欄における入力金額の下限を-9億9999万9999円に指定します
            const minUnder = Number(data?.monthly_data_amount) < -999999999;
            return validate(minUnder, "入力可能な金額を下回っています");
        } else {
            // エラーの条件
            const maxOver = Number(data?.monthly_data_amount) > 999999999;
            value = validate(maxOver, "入力可能な金額を超えています");
            return value;
        }
    }
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <StyledTooltip
            arrow={true}
            title={
                validationTrigger &&
                errors.filter((err) => err[`amount-${id}`]).map((item) => item[`amount-${id}`]?.message ?? "")
            }
            open={
                validationTrigger &&
                isDisplayErrors[`amount-${id}`] == 0 &&
                errors.filter((err) => err[`amount-${id}`]).length > 0
            }
            onMouseEnter={(e) => handleOnMouseEnter(e, `amount-${id}`, 0)}
            onMouseOut={(e) => handleOnMouseOut(e)}>
            <input
                className={
                    validationTrigger && errors.filter((err) => err[`amount-${id}`]).length > 0
                        ? "border border-danger border-2 rounded rounded-3"
                        : ""
                }
                name={`monthly_data_amount-0${id}`}
                type='number'
                value={state ? state.monthly_data_amount ?? "" : ""}
                max={999999999}
                min={id === 5 ? -999999999 : 0}
                step={1}
                onChange={handleOnChange}
            />
        </StyledTooltip>
    );
}
