import { AUTHORITY_NUMBERS } from "./constants";

const { NUM_SYSTEM_ADMIN, NUM_ADMIN, NUM_ACCOUNTANT, NUM_EMPLOYEE, NUM_TAX_ACCOUNT } = AUTHORITY_NUMBERS;

const checkAuthority = (number, target) => {
    return number <= target;
};

const checkSystemAdmin = (authorityNumber) => {
    return checkAuthority(authorityNumber, NUM_SYSTEM_ADMIN);
};

const checkAdmin = (authorityNumber) => {
    return checkAuthority(authorityNumber, NUM_ADMIN);
};

const checkAccountant = (authorityNumber) => {
    return checkAuthority(authorityNumber, NUM_ACCOUNTANT);
};

const checkEmployee = (authorityNumber) => {
    return checkAuthority(authorityNumber, NUM_EMPLOYEE);
};

const checkTaxAccount = (authorityNumber) => {
    return checkAuthority(authorityNumber, NUM_TAX_ACCOUNT);
};

export const checkAuthorities = {
    checkSystemAdmin: checkSystemAdmin,
    checkAdmin: checkAdmin,
    checkAccountant: checkAccountant,
    checkEmployee: checkEmployee,
    checkTaxAccount: checkTaxAccount
};
