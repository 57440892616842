//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import TravelExpenseConfirmRow from "./TravelExpenseConfirmRow";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { TravelExpenseContext } from "../../../contents/travel_expense/TravelExpenseConfirm";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import processResponse from "../../../common/processResponse";
import amountToLocaleString from "../../../common/amountToLocaleString";
import getPicture from "../../../common/getPicture";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        heightMaxContent: { height: "max-content" },
        me80: { marginRight: "80px" },
    })
);
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// ステートの初期状態
const initTravelExpenses = { result: [], success: false };

// ステートの初期状態
const initPayees = { result: [], success: false };

// ステートの初期状態
const initAccountLists = { result: [], success: false };

// テーブルの見出しを定義
const headersEditable = ["月日", "金額", "支払先", "仕訳"];
const headers = ["確認", "No.", ...headersEditable, "精算", "領収書", ""];

// ない場合の画像のパス
const noImagePath = "/img/no_image.jpg";
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function TravelExpenseConfirmTable() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //定義したスタイルを利用するための設定
    const classes = useStyles();
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面遷移用
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const {
        returnPath,
        start,
        setStart,
        selectedMonthAndYear,
        selectedUserId,
        dispatchTravelExpenseUpdateData,
        travelExpenseTotalAmount,
        setTravelExpensesBeforeChange,
        showReceiptTarget,
        setPhotoData,
        setIsReceiptOpen,
        photos,
        setPhotos,
        rowCounts,
        setRowCounts,
        stringTravelExpense,
        onlyUnconfirmed,
    } = useContext(TravelExpenseContext);
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // travelExpenses の状態を管理する
    const [travelExpenses, setTravelExpenses] = useState(initTravelExpenses);

    // 領収書写真を開いた際の画面表示を管理する
    const [receiptMargin, setReceiptMargin] = useState("");

    // 展開時に使用するステートを用意
    const [dataForDeploy, setDataForDeploy] = useState([]);

    // payees の状態を管理する
    const [payees, setPayees] = useState(initPayees);

    // プルダウンメニュー用の支払先の配列を格納する
    const [payeesMenu, setPayeesMenu] = useState([]);

    // accountLists の状態を管理する
    const [accountLists, setAccountLists] = useState(initAccountLists);

    // プルダウンメニュー用の勘定科目の配列を格納する
    const [accountListsMenu, setAccountListsMenu] = useState([]);

    // 対象月から期間を割り出す
    const [monthlyPeriod, setMonthlyPeriod] = useState({ min: "", max: "" });
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 経費精算明細を取得
    const getTravelExpenses = async (userId, month) => {
        const res = await api.get(`travel_expenses_show/${userId}/${month}`);
        // 成功時、ステートを更新
        if (res.data.success) {
            setTravelExpenses(res.data);
        }
    };

    // 支払先一覧を取得
    const getPayees = async () => {
        const url = "payees";
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        processResponse(res, setPayees, navigate, returnPath);
    };

    // 勘定科目一覧を取得
    const getAccountLists = async () => {
        const url = "account_lists";
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        processResponse(res, setAccountLists, navigate, returnPath);
    };

    // 領収書の画像を取得
    const getReceipt = async (filePath, number, name) => {
        const receipt = await getPicture(filePath);
        if (receipt) {
            const resultObject = { photo: receipt, name: name };
            setPhotoData(resultObject);
            setPhotos({ ...photos, [stringTravelExpense]: { [number]: resultObject } });
        } else {
            const noImageObject = { photo: noImagePath, name: name };
            setPhotoData(noImageObject);
            setPhotos({ ...photos, [stringTravelExpense]: { [number]: noImageObject } });
        }
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面初期表示時に勘定科目情報を取得する
    useEffect(() => {
        // DBから各種データを取得
        getPayees();
        getAccountLists();
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => {
        if (start) {
            // データベースから値を取得する
            getTravelExpenses(selectedUserId, selectedMonthAndYear);
        } else {
            // 初期値に戻す
            setTravelExpenses(initTravelExpenses);
        }
    }, [start]);

    useEffect(() => {
        if (selectedMonthAndYear) {
            // 対象月から期間を割り出してステートに格納
            const minDate = `${selectedMonthAndYear}-01`;
            function makePeriodDate(date) {
                date.setMonth(date.getMonth() + 1);
                date.setDate(0);
                const y = date.getFullYear();
                const m = ("0" + (date.getMonth() + 1)).slice(-2);
                const d = ("0" + date.getDate()).slice(-2);
                return `${y}-${m}-${d}`;
            }
            const maxDate = makePeriodDate(new Date(minDate));
            setMonthlyPeriod({ ...monthlyPeriod, min: minDate, max: maxDate });
        }
    }, [selectedMonthAndYear]);

    // 経費精算明細の初期値をRefオブジェクト及び変更前データとして保管・フォーム展開用データの作成
    useEffect(() => {
        if (travelExpenses.success) {
            if (travelExpenses.result.length === 0) {
                // 経費精算明細データが存在しない場合はアラートを表示後,読み込み停止します
                swal("データが未作成です", "", "info");

                // 読み込み停止
                setStart(false);
            } else {
                let travelExpensesResults = travelExpenses.result;
                // 未確認状態のデータのみ表示する場合の絞り込み
                if (onlyUnconfirmed) {
                    travelExpensesResults = travelExpensesResults.filter(
                        (travelExpense) => travelExpense.travel_expense_is_confirmed === 0
                    );
                }
                // 取得したデータを整理
                const travelExpensesData = travelExpensesResults.map((travelExpense, number) => {
                    return {
                        id: travelExpense.travel_expense_id,
                        is_confirmed: travelExpense.travel_expense_is_confirmed === 1 ? true : false,
                        is_paid: travelExpense.travel_expense_is_paid === 1,
                        number: number + 1,
                        date: travelExpense.travel_expense_date,
                        user_id: travelExpense.user_id,
                        amount: travelExpense.travel_expense_amount,
                        name: travelExpense.travel_expense_name,
                        account_list_id: travelExpense.travel_expense_account_list_id,
                        file_name: travelExpense.travel_expense_file_name,
                    };
                });
                // 行の数を格納
                setRowCounts({
                    ...rowCounts,
                    [stringTravelExpense]: travelExpensesData.length,
                });
                // 表示用ステートに格納
                setDataForDeploy(travelExpensesData);

                const arrayForRequestData = travelExpensesData.map((travelExpense) => ({
                    id: travelExpense.id,
                    number: travelExpense.number,
                    is_confirmed: travelExpense.is_confirmed,
                    amount: travelExpense.amount,
                }));
                setTravelExpensesBeforeChange(arrayForRequestData); // 変更前のデータとして保持
                // 送信する際に扱うデータをまとめる
                dispatchTravelExpenseUpdateData({
                    type: "set",
                    data: arrayForRequestData,
                });
            }
        }
    }, [travelExpenses, onlyUnconfirmed]);

    // payees からプルダウンメニュー用の配列を作成する
    useEffect(() => {
        if (payees.success) {
            // 取得した勘定科目情報から必要な値を配列にする
            const payeesArray = payees.result.map((payee) => ({
                label: payee.payee_name,
                value: {
                    payeeName: payee.payee_name,
                    accountListId: payee.payee_account_list_id,
                },
            }));
            // ステートに格納する
            setPayeesMenu(payeesArray);
        }
    }, [payees]);

    // accountLists からプルダウンメニュー用の配列を作成する
    useEffect(() => {
        if (accountLists.success) {
            // 取得した勘定科目情報から必要な値を配列にする
            const accountListsArray = accountLists.result.map((accountList) => ({
                label: accountList.account_list_name,
                value: accountList.account_list_id,
            }));
            // ステートに格納する
            setAccountListsMenu(accountListsArray);
        }
    }, [accountLists]);

    useEffect(() => {
        const showReceiptMargin = showReceiptTarget.number !== null ? classes.me80 : "";
        setReceiptMargin(showReceiptMargin);
        if (showReceiptTarget.type === stringTravelExpense) {
            const type = showReceiptTarget.type;
            const number = showReceiptTarget.number;
            const showReceipt = photos[type][number];
            if (showReceipt) {
                setPhotoData(showReceipt);
            } else {
                const targetData = dataForDeploy.find((data) => data.number === number);
                const date = targetData.date.replace(/-/g, "/"); // 表示用の日付けを作成
                const name = `No.${number} ${date}`; // 表示用の名前

                // 年月をPath用に変換
                const path = `/img/receipt/${selectedMonthAndYear}/${selectedUserId}/${targetData.file_name}`;
                // 画像のPathを元に、画像を取得する
                getReceipt(path, number, name);
            }
            setIsReceiptOpen(true);
        }
    }, [showReceiptTarget]);

    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <Paper className={classes.heightMaxContent + " " + receiptMargin}>
            {start && (
                <TableContainer className='pb-1 overflow-visible'>
                    <Table
                        className='table table-sm text-nowrap'
                        aria-labelledby='travel_expenses'
                        size='medium'
                        aria-label='travel expenses table'>
                        <TableHead>
                            <TableRow className='table-active'>
                                <TableCell align='center' padding='none' colSpan={headers.length}>
                                    経費精算明細
                                </TableCell>
                            </TableRow>
                            <TableRow className='table-light'>
                                {headers.map((header, index) => (
                                    <TableCell key={index} align='center' padding='none'>
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {travelExpenses.success &&
                                dataForDeploy.length > 0 &&
                                dataForDeploy.map((travelExpenseData, index) => (
                                    <TravelExpenseConfirmRow
                                        key={index}
                                        number={travelExpenseData.number}
                                        travelExpenseData={travelExpenseData}
                                        payeesMenu={payeesMenu}
                                        accountListsMenu={accountListsMenu}
                                        inputHeaders={headersEditable}
                                    />
                                ))}
                            <TableRow>
                                <TableCell colSpan='2'></TableCell>
                                <TableCell></TableCell>
                                <TableCell align='center'>小計</TableCell>
                                <TableCell align='right'>{amountToLocaleString(travelExpenseTotalAmount)}</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Paper>
    );
}
