// メニューの定義
// issue468 authorityを追加（権限ごとに要素を非表示にするため）
// authorityの値は非表示にしたい権限の数値
const menuConfig = [
    { auth: false, authority: 99, category: "ログイン", path: "/login", value: "ログイン" },
    { auth: true, authority: 99, category: "ホーム", path: "/home", value: "ホーム" },
    { auth: true, authority: 30, category: "基礎情報", path: "/user", value: "ユーザー" },
    { auth: true, authority: 30, category: "基礎情報", path: "/payee", value: "支払先" },
    { auth: true, authority: 30, category: "基礎情報", path: "/bank_account", value: "資金口座" },
    { auth: true, authority: 30, category: "基礎情報", path: "/other", value: "その他項目" },
    { auth: true, authority: 40, category: "経費", path: "/travel_expense/register", value: "経費申請" },
    { auth: true, authority: 30, category: "経費", path: "/travel_expense/confirm", value: "経費確認" },
    { auth: true, authority: 40, category: "仮払い", path: "/suspense_payment", value: "仮払い一覧" },
    { auth: true, authority: 30, category: "仮払い", path: "/suspense_payment/confirm", value: "仮払い確認" },
    { auth: true, authority: 30, category: "月次損益", path: "/monthly_data/register", value: "月次損益登録" },
    { auth: true, authority: 30, category: "月次損益", path: "/monthly_data/reference", value: "月次損益年間推移" },
    {
        auth: true,
        authority: 50,
        category: "月次損益（税理士用）",
        path: "/monthly_data/reference_for_tax_accountant",
        value: "月次損益確認（税理士用）",
    },
    {
        auth: true,
        authority: 50,
        category: "月次損益（税理士用）",
        path: "/monthly_data/show",
        value: "月次損益一覧（税理士用）",
    },
    { auth: true, authority: 30, category: "月次損益", path: "/monthly_data/show", value: "月次損益一覧" },
    { auth: true, authority: 30, category: "締め処理", path: "/closing_process/closing_process", value: "締め処理" },
    { auth: true, authority: 40, category: "未精算一覧", path: "/calculate_for_self", value: "未精算一覧" },
    { auth: true, authority: 30, category: "精算", path: "/calculate", value: "精算" },
    { auth: true, authority: 30, category: "精算", path: "/calculate_list", value: "未精算一覧" },
    { auth: true, authority: 30, category: "PDF作成", path: "/pdf/create_pdf_monthly", value: "月間" },
    { auth: true, authority: 30, category: "PDF作成", path: "/pdf/create_pdf_year", value: "年度" },
    {
        auth: true,
        authority: 50,
        category: "PDF作成（税理士用）",
        path: "/pdf/create_pdf_monthly",
        value: "PDF作成（税理士用）",
    },
    { auth: true, authority: 99, category: "アカウント", path: "/logout", value: "ログアウト" },
];

export default menuConfig;
