import { api } from "../api/api";

// file pathを基にDBから画像を取得してbase64形式で返す
export default async function getPicture(filePath) {
    // コントローラーに渡す値を作成
    const res = await api.post("file", { path: filePath });
    // 値が存在する場合state(=imgのURI)を更新
    const gotData = res.data.result;
    if (gotData) {
        return base64URIFormat(gotData);
    }
    return false;
}

// file pathが複数個格納された配列を基にDBから画像を取得してbase64形式に変換し
// 取得出来た画像の通し番号をキーにbase64形式の画像を値にして連想配列として返します
export const getPictures = async (filePaths) => {
    // コントローラーに渡す値を作成
    const res = await api.post('file_for_pdf', filePaths);
    // 値が存在する場合state(=imgのURI)を更新
    const gotData = res.data.result;
    if (gotData) {
        // filePathsの通し番号とURIを格納する連想配列
        // 格納例: {通し番号: URI, 2: 'data:image/jpeg;base64,/...' }
        const gotPictures = {};
        gotData.map((picture) => {
            const adjustedData = JSON.parse(picture); // 受け取ったデータをjsonに
            const index = adjustedData.index; // filePathsの通し番号

            gotPictures[index] = base64URIFormat(picture);
        });
        if (Object.keys(gotPictures).length > 0) {
            return gotPictures;
        }
    }
    return false;
};

// 引数に受け取ったデータをbase64形式のURIに変換して返します
const base64URIFormat = (data) => {
    const adjustedData = JSON.parse(data); // 引数に受け取ったデータをjsonに変換します
    const mimeType = adjustedData.mimeType;
    const fileText = adjustedData.fileText;
    // base64形式URIを返します
    return 'data:' + mimeType + ';base64,' + fileText;
};