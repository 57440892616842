//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { Button, Box } from "@material-ui/core";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import DataTable from "../../components/DataTable";
import BackButton from "../../components/BackButton";
import CloseButton from "../../components/CloseButton";
import FreeWordSearch from "../../components/FreeWordSearch";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { UserContext } from "../../providers/UserProvider";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { HOME_PATH } from "../../common/constants";
import processResponse from "../../common/processResponse";
import { checkAuthorities } from "../../common/checkAuthorities";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// ホーム画面に戻る時の遷移先のパス
const returnPath = HOME_PATH;
// 見出しの配列のパターンを定義
const initHeaders = ["項目名", "削除"];
// const initHeadersOfButtons = ["編集"];

// 検索対象のカラムの配列定義
const columns = ["other_name"];
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// others の初期状態
const initOthers = { result: [], success: false };
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function Other() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面遷移用
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // ログイン中のユーザー情報を取得
    const { userInfo } = useContext(UserContext);
    const userAuthorityNumber = userInfo.user.authority_number; // 権限
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 権限確認用関数を分割代入
    const { checkAccountant } = checkAuthorities;
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // others の状態を管理する
    const [others, setOthers] = useState(initOthers);

    // others を表示行に変換したものを保持する
    const [rows, setRows] = useState([]);

    // 検索されたその他項目のデータを格納するステート
    const [searchedOthers, setSearchedOthers] = useState([]);

    // 見出しの配列を保持するステート
    const [headers, setHeaders] = useState(initHeaders);

    // ボタン表示フラグ
    const [displayButtons, setDisplayButtons] = useState(false);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 情報を取得しステート others にセットする
    const getOthersData = async () => {
        const url = "others";
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        processResponse(res, setOthers, navigate, returnPath);
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 新規登録ボタンクリック時
    const handleOnClickRegister = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // 新規登録画面へ進む
        navigate(`/other/register`);
    };

    // 復活ボタンクリック時
    const handleOnClickRestore = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // 削除済み一覧へ進む
        navigate(`/other_deleted`);
    };

    // 一覧の削除ボタンクリック時に、
    // ユーザーを論理削除する
    const handleOnClickDelete = async (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 削除対象のユーザーの情報を取得
        const other = others.result.find((other) => other.other_id === id);
        // 表示用の名前を宣言
        const otherName = other.other_name;

        // 確認のための変数を定義
        let confirm = false;

        // 確認画面を表示
        await swal({
            icon: "info",
            title: "その他項目を削除しますか？",
            text: otherName,
            buttons: ["キャンセル", "実行"]
        }).then((value) => {
            if (value) {
                confirm = true;
            }
        });

        // キャンセルの場合は処理を中断する
        if (!confirm) {
            return;
        }

        //論理削除
        const url = `others/${id}`;
        const res = await api.delete(url);
        //---------------------------issue{No.81} start-----------------------------
        // 成否の確認
        if (res.data.success) {
            // 削除成功時
            // モーダルウィンドウで成功メッセージを表示
            swal(res.data.message, "", "success").then(() => {
                // 削除したユーザを除外した全ユーザを配列化
                const newOthers = others.result.filter((other) => other.other_id !== id);
                // ステートを更新
                setOthers({ result: newOthers, success: true });
            });
        } else {
            // 削除失敗時にエラーに応じた処理を行う
            processErrorResponse(res, () => {}, navigate);
        }
        //---------------------------issue{No.81} end-------------------------------
    };

    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => {
        // 画面初期表示時にその他項目情報を取得する
        getOthersData();

        // 権限ごとにステート（ボタン表示フラグ及びログインユーザー情報）を調整(初回のみ実行)
        // 管理者以上
        if (checkAccountant(userAuthorityNumber)) {
            setDisplayButtons(true);
            setHeaders([...initHeaders]);
        }
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // searchedOthersに応じて、画面表示用の配列を生成
    useEffect(() => {
        if (searchedOthers && others.success) {
            const newRows = searchedOthers.map((other) => {
                const row = {
                    id: other.other_id,
                    isActive: true,
                    data: {
                        name: other.other_name,
                        deleteBtn: (
                            <Button variant='contained' onClick={(e) => handleOnClickDelete(e, other.other_id)}>
                                削除
                            </Button>
                        )
                    }
                };

                // ボタンを表示しない場合はボタン要素のプロパティを削除
                if (!displayButtons) {
                    delete row.data.deleteBtn;
                }

                return row;
            });

            setRows(newRows);
        }
    }, [searchedOthers]);
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1>その他項目情報一覧</h1>
                        <div className='ms-auto'>
                            <CloseButton path={returnPath} />
                        </div>
                    </div>
                    <div className='card-body'>
                        {displayButtons && (
                            <Box className='card-body' sx={{ display: "flex", width: "max-content", ml: "auto" }}>
                                <Button variant='contained' color='primary' onClick={(e) => handleOnClickRegister(e)}>
                                    新規登録
                                </Button>
                                <Button className='ms-3' variant='contained' onClick={(e) => handleOnClickRestore(e)}>
                                    削除済み一覧
                                </Button>
                            </Box>
                        )}
                        <FreeWordSearch
                            searchColumns={columns}
                            data={others.result}
                            setSearchedData={setSearchedOthers}
                        />
                        <DataTable headers={headers} rows={rows} />
                    </div>
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackButton path={returnPath} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
