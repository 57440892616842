import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Menu, MenuItem } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import menuConfig from "../layout/menuConfig";
import { UserContext } from "../providers/UserProvider";

//スタイルの定義：開くメニューの表示位置をずらす
const useStyles = makeStyles((theme) =>
    createStyles({
        MuiPopoverPaper: {
            top: "30px !important",
            left: "10px !important"
        }
    })
);

export default function MenuDown(props) {
    const { userInfo } = useContext(UserContext);
    const [loginUserAuthority, setLoginUserAuthority] = useState(null); // ログインユーザーの権限
    const [newMenuItems, setNewMenuItems] = useState({}); // アコーディオンメニュー要素

    // userInfo.userの値変更時（≒取得時）
    useEffect(() => {
        // ログイン成功時
        if (userInfo.isLogin) {
            const loginUserAuthorityOrigin = Number(userInfo.user.authority_number); // ログインユーザーの権限（数値型）
            setLoginUserAuthority(loginUserAuthorityOrigin); // 上記をステートに代入
        }
    }, [userInfo.user]);

    // ログインユーザーの権限ステート変更時（≒取得時）
    useEffect(() => {
        // グローバルナビ用要素のオブジェクト作成
        const menuItems = menuConfig.filter((item) => {
            let targetAuthority = item.authority; // 各要素のauthority
            let isDisplay = false; // 表示フラグ
            // ログインユーザーの権限がtargetAuthorityの値が小さい（権限が強い）場合、表示フラグをtrueに
            if (loginUserAuthority < targetAuthority) {
                isDisplay = true;
            }
            if (item.category === category && item.auth === userInfo.isLogin && isDisplay) {
                return item;
            }
        });
        setNewMenuItems(menuItems);
    }, [loginUserAuthority]);

    // 非ログイン時、ログインユーザー権限及びアコーディオンメニュー要素管理用ステートを初期化
    useEffect(() => {
        if (!userInfo.isLogin) {
            setNewMenuItems({});
            setLoginUserAuthority(null);
        }
    }, [userInfo.isLogin]);

    //定義したスタイルを利用するための設定
    const classes = useStyles();
    //画面遷移するため
    const navigate = useNavigate();
    //プロパティ受け取り
    const category = props.category;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };
    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };
    const handleSelect = (e, path) => {
        console.log(path);
        e.stopPropagation();
        setAnchorEl(null);
        navigate(path);
    };

    // const menuItems = menuConfig.filter((item) => {
    //     if (item.category === category && item.auth === isLogin) {
    //         return item;
    //     }
    // });

    if (newMenuItems.length > 0) {
        return (
            <>
                <Button
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? "true" : undefined}
                    onClick={(e) => handleClick(e)}>
                    <span className='p-1'>{category}</span>
                </Button>
                <Menu
                    className={classes.MuiPopoverPaper}
                    // id='basic-menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button"
                    }}>
                    {newMenuItems.map((item, index) => {
                        return (
                            <MenuItem key={index} onClick={(e) => handleSelect(e, item.path)}>
                                {item.value}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </>
        );
    } else {
        return null;
    }
}
