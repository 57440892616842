import React, { useState } from "react";
import { Button } from "@material-ui/core";
import swal from "sweetalert";
import { api } from "../api/api";

// fileTypeによって拡張子を使い分けます
const fileExtensions = { Excel: ".xlsx", csv: ".csv" };

// fileをdownloadする際の受け取り形式をblobに指定
const responseOption = { responseType: "blob" };

// ダウンロードボタンの表記
const defaultDownloadButtonText = "ダウンロード";
const buttonText = (outputState = "", fileType = "Excel", fileName = defaultDownloadButtonText) => {
    switch (outputState) {
        case "waiting":
            return <>{`…${fileType}ファイルを作成しています`}</>;
        case "doing":
            return (
                <>
                    <span className='spinner-grow grow spinner-grow-sm' />
                    {`…${fileType}ファイルをダウンロードしています`}
                </>
            );
        case "":
        default:
            return <>{`${fileName}`}</>;
    }
};

// format用のExcel fileに受け取った情報を記入して出力する
export default function OutputExcel({
    fileName,
    fileType = "Excel",
    api_url = "export_pl",
    buttonTextIsFileName = true,
    data = {},
}) {
    const downloadButtonText = buttonTextIsFileName && fileName ? fileName : defaultDownloadButtonText;
    // Excel fileの作成からdownload完了迄の進行状況を保持する
    const [outputState, setOutputState] = useState("");

    // DBに登録されているトラン系のdataを出力用のformat Excel file中に記入してdownloadする
    const spreadSheetDownload = async (e) => {
        e.stopPropagation();
        setOutputState("waiting");

        // api通信のpost時に渡す連想配列
        const outputData = { ...data, fileName: fileName, fileType: fileType };

        // Excel fileを生成してdownload
        await api
            .post(api_url, outputData, responseOption)
            .then(
                async (res) => {
                    const link = document.createElement("a");
                    setOutputState("doing");
                    link.href = window.URL.createObjectURL(res.data);
                    link.setAttribute("download", `${fileName}${fileExtensions[fileType]}`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    setOutputState("");
                    swal(`${fileType}ファイルのダウンロード完了`, `${fileName}${fileExtensions[fileType]}`, "success");
                },
                swal({
                    title: `${fileType}ファイル生成中…`,
                    text: "処理終了まで画面はそのままにしてください。",
                    buttons: false,
                    closeOnEsc: false,
                })
            )
            .catch(() => {
                setOutputState("");
                swal(`${fileType}ファイルの作成に失敗`, "", "error");
            })
            .finally(() => setOutputState(""));
    };

    return (
        <div className='px-3'>
            <Button
                variant='contained'
                color='primary'
                onClick={(e) => {
                    if (outputState === "") {
                        setOutputState("waiting");
                        spreadSheetDownload(e);
                    }
                }}>
                {buttonText(outputState, fileType, downloadButtonText)}
            </Button>
        </div>
    );
}

// format用のExcel fileに受け取った月次損益の前年比情報を記入して出力する
export const OutputPLExcel = ({
    fileName,
    monthlyDataLeftHeadlines,
    months,
    dataList,
    targetMonths,
    targetDataList,
    pastMonths,
    pastDataList,
}) => {
    // Excel fileの作成からdownload完了迄の進行状況を保持する
    const [outputState, setOutputState] = useState("");

    // DBに登録されているトラン系のdataを出力用のformat Excel file中に記入してdownloadする
    const excelDownload = async (e) => {
        e.stopPropagation();

        setOutputState("waiting");
        // api通信のpost時に渡す連想配列
        const outputYoYData = {
            headline: monthlyDataLeftHeadlines.headline,
            headCaptionsBackgroundColor: monthlyDataLeftHeadlines.headCaptionsBackgroundColor,
            YoY: { months: months, dataList: dataList }, // 前年比データ
            targetYear: { months: targetMonths, dataList: targetDataList }, // 選択年度
            pastYear: { months: pastMonths, dataList: pastDataList }, // 選択前年度
        };

        // Excel fileを生成してdownload
        await api
            .post("export_yoy_excel", outputYoYData, responseOption)
            .then(
                async (res) => {
                    const link = document.createElement("a");
                    setOutputState("doing");
                    link.href = window.URL.createObjectURL(res.data);
                    link.setAttribute("download", `${fileName}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    setOutputState("");
                    swal(`Excelファイルのダウンロード完了`, `${fileName}.xlsx`, "success");
                },
                swal({
                    title: `Excelファイル生成中…`,
                    text: "処理終了まで画面はそのままにしてください。",
                    buttons: false,
                    closeOnEsc: false,
                })
            )
            .catch(() => {
                setOutputState("");
                swal(`Excelファイルの作成に失敗`, "", "error");
            })
            .finally(() => setOutputState(""));
    };

    return (
        <div className='px-3'>
            <Button
                variant='contained'
                color='primary'
                onClick={(e) => {
                    if (outputState === "") {
                        setOutputState("waiting");
                        excelDownload(e);
                    }
                }}>
                {buttonText(outputState, "Excel", fileName)}
            </Button>
        </div>
    );
};
