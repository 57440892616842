//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { createContext, useState, useEffect } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import SelectMonthlyDataReferenceForm from "../../components/transaction/monthly_data/SelectMonthlyDataReferenceForm";
import MonthlyDataEdit from "../../components/transaction/monthly_data/MonthlyDataEdit";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { subMonths } from "date-fns";
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import BackButton from "../../components/BackButton";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { HOME_PATH } from "../../common/constants";
import dateFormat from "../../common/dateFormat";
import monthAndYearFormat from "../../common/monthAndYearFormat";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//
// コンテクストを作成
export const MonthlyDataContext = createContext();
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// ステートの初期状態
const initDefaultState = { result: [], success: false };
const today = new Date();
const todayY = today.getFullYear();
const todayM = today.getMonth();
const lastMonth = subMonths(new Date(todayY, todayM, 1, 0, 0, 0), 1); // lastMonth yyyy-mm-dd
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function MonthlyDataRegister(props) {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    const [datePickerValue, setDatePickerValue] = useState(today); // datePickerの値(選択された対象年月 obj)

    const [formattedSelectedTargetDate, setFormattedSelectedTargetDate] = useState(monthAndYearFormat(today)); // 対象年月(API用 Y-m-01)

    const [targetData, setTargetData] = useState(initDefaultState); // 対象年月のデータ

    const [selectedReferenceDate, setSelectedReferenceDate] = useState(monthAndYearFormat(lastMonth)); // 参照年月(obj)
    const [displayReferenceDate, setDisplayReferenceDate] = useState(""); // 参照年月（表示用）
    const [referenceData, setReferenceData] = useState(initDefaultState); // 参照年月のデータ
    const [referenceSwitch, setReferenceSwitch] = useState(0); // 参照年月の切り替え用(配列のキー)

    const [proceeds, setProceeds] = useState([]); // 売上先のデータ(プルダウン用)
    const [proceedsData, setProceedsData] = useState(null); // 売上のデータ(登録・表示用)
    const [proceedsRow, setProceedsRow] = useState([]); // 売上の行データ(表示用)
    const [proceedsSum, setProceedsSum] = useState(0); // 売上合計

    const [expenses, setExpenses] = useState([]); // 経費先のデータ(プルダウン用)
    const [expensesData, setExpensesData] = useState(null); // 経費のデータ(登録用)
    const [expensesRow, setExpensesRow] = useState([]); // 経費の行データ(表示用)
    const [expensesSum, setExpensesSum] = useState(0); // 経費合計

    const [withdrawalsData, setWithdrawalsData] = useState([]); // 現金引き出しのデータ(登録用)
    const [withdrawalsRow, setWithdrawalsRow] = useState([]); // 現金引き出しの行データ(表示用)
    const [withdrawalsSum, setWithdrawalsSum] = useState(0); // 現金引き出し合計
    const [withdrawalsSub, setWithdrawalsSub] = useState(0); // 差引現金残
    const [referenceWithdrawalsData, setReferenceWithdrawalsData] = useState([]); // 現金引き出しのデータ(登録用)

    const [bankAccounts, setBankAccounts] = useState([]); // 資金先のデータ(プルダウン用)
    const [bankAccountsData, setBankAccountsData] = useState(null); // 資金のデータ(登録用)
    const [bankAccountsRow, setBankAccountsRow] = useState([]); // 資金の行データ(表示用)
    const [bankAccountsSum, setBankAccountSum] = useState(0); // 資金合計
    const [bankAccountSub, setBankAccountSub] = useState(0); // 差額

    const [bankAccountLists, setBankAccountLists] = useState(null);

    const [advancePayment, setAdvancePayment] = useState({}); // 予納その他経費
    const [pettyCash, setPettyCash] = useState({}); // 小口繰越現金
    const [nextMonthCash, setNextMonthCash] = useState({}); // 翌月繰越現預金
    const [travelExpenseTotalAmount, setTravelExpenseTotalAmount] = useState([]); // 経費(合計)
    const [referenceTravelExpenseTotalAmount, setReferenceTravelExpenseTotalAmount] = useState([]); // 経費(合計)
    const [grossMargin, setGrossMargin] = useState(0); // 粗利益

    const [others, setOthers] = useState([]); // その他のデータ(プルダウン用)
    const [othersData, setOthersData] = useState(null); // その他のデータ(登録用)
    const [othersRow, setOthersRow] = useState([]); // その他の行データ(表示用)
    const [othersSum, setOthersSum] = useState(0);

    const [targetNotice, setTargetNotice] = useState([]); // 特記事項(対象年月)
    const [referenceNotice, setReferenceNotice] = useState([]); // 特記事項(参照年月)

    const [errors, setErrors] = useState([]); // エラー
    const [validationTrigger, setValidationTrigger] = useState(false); // バリデーションの表示のタイミング
    const [isDisplayErrors, setIsDisplayErrors] = useState({}); // エラーメッセージの表示・非表示(Tooltip)

    const [visibility, setVisibility] = useState(false); // コンポーネントの可視フラグ(開始時表示・登録時非表示)
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const getData = async (url, setState) => {
        const res = await api.get(url);
        // 成功時、ステートを更新
        if (res.data.success) {
            setState(res.data.result);
        }
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面初期表示時に情報を取得する
    const handleButtonClick = () => {
        setFormattedSelectedTargetDate(monthAndYearFormat(datePickerValue));
        setSelectedReferenceDate(monthAndYearFormat(formatToswitchingMonth(datePickerValue, referenceSwitch)));
        setValidationTrigger(false);
    };
    // エラーのツールチップの表示
    const handleOnMouseEnter = (e, target_name, count) => {
        setIsDisplayErrors({ [target_name]: count });
    };
    // エラーのツールチップの非表示
    const handleOnMouseOut = (e) => {
        setIsDisplayErrors({});
    };
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => {
        getData(`bank_accounts`, setProceeds); // 売上先を取得
        getData(`bank_accounts`, setExpenses); // 経費先を取得
        getData(`bank_accounts`, setBankAccounts); // 口座を取得
        getData(`others`, setOthers); // その他を取得
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 参照月の切り替え
    function formatToswitchingMonth(date, flg) {
        const months = [1, 12]; // 一カ月前、一年前(１２カ月前)
        const targetMonth = months[flg]; // 0, 1で切り替え
        const dateObj = new Date(date);
        dateObj.setMonth(dateObj.getMonth() - targetMonth);
        return dateObj;
    }
    useEffect(() => {
        if (formattedSelectedTargetDate != null) {
            getData(`bank_accounts_month/${formattedSelectedTargetDate}`, setBankAccountLists);
            getData(`travel_expenses_total_amount/${formattedSelectedTargetDate}`, setTravelExpenseTotalAmount); // 経費合計を取得
            getData(
                `travel_expenses_past_total_amount/${formattedSelectedTargetDate}`,
                setReferenceTravelExpenseTotalAmount
            ); // 経費合計を取得
            getData(`monthly_data/${formattedSelectedTargetDate}`, setTargetData); //対象月のデータ取得
            getData(`monthly_data_past/${formattedSelectedTargetDate}`, setReferenceData); //参照月のデータ取得
            getData(`withdrawal_month/${formattedSelectedTargetDate}`, setWithdrawalsData); //対象月の現金引き出しデータ取得
            getData(`notices/${formattedSelectedTargetDate}`, setTargetNotice); // 特記事項を取得
            getData(`notices_past/${formattedSelectedTargetDate}`, setReferenceNotice); // 特記事項を取得
            getData(`withdrawal_month_past/${formattedSelectedTargetDate}`, setReferenceWithdrawalsData); // 特記事項を取得
            setErrors([]); // エラーの初期化
        }
    }, [formattedSelectedTargetDate]);

    // 参照月の表示用の日付の切り替え
    useEffect(() => {
        setSelectedReferenceDate(monthAndYearFormat(formatToswitchingMonth(datePickerValue, referenceSwitch)));
    }, [referenceSwitch]);

    // 参照月の表示用の日付の切り替え
    useEffect(() => {
        setDisplayReferenceDate(dateFormat(selectedReferenceDate, false, "Y-m").format_date);
    }, [selectedReferenceDate]);

    // 翌月繰越現預金
    useEffect(() => {
        const nextMonthCashAmount = parseInt(nextMonthCash?.monthly_data_amount ?? 0, 10);
        let val = nextMonthCashAmount - (bankAccountsSum ?? 0);
        let result = isNaN(val) ? 0 : val;
        setBankAccountSub(result);
    }, [nextMonthCash, bankAccountsSum]);

    // 粗利益変更
    useEffect(() => {
        const proceedsSumValue = parseInt(proceedsSum ?? 0, 10);
        const expensesSumValue = parseInt(expensesSum ?? 0, 10);
        const advancePaymentValue = parseInt(advancePayment ? advancePayment.monthly_data_amount : 0, 10);
        const travelExpenseValue = parseInt(
            travelExpenseTotalAmount[0] ? travelExpenseTotalAmount[0].travel_expense_total_amount ?? 0 : 0,
            10
        );
        const value = proceedsSumValue - expensesSumValue - advancePaymentValue - travelExpenseValue;
        const result = isNaN(value) ? 0 : value;
        setGrossMargin(result);
    }, [proceedsSum, expensesSum, advancePayment, travelExpenseTotalAmount]);

    // 開始ボタン押下時、対象月のデータを初期値とする
    useEffect(() => {
        if (targetData.length > 0) {
            setProceedsData(targetData[0]); // 売上データ
            setExpensesData(targetData[1]); // 経費データ
            setBankAccountsData(targetData[2]); // 口座
            setAdvancePayment(targetData[3]); // 予納その他経費
            setPettyCash(targetData[4]); // 小口繰越現金
            setNextMonthCash(targetData[5]); // 翌月繰越現預金
            setOthersData(targetData[6]); // その他
        }
    }, [targetData]);

    // エラー配列の追加削除
    function nullOrPush(array, value, name, count) {
        let key = -1;
        if (value != null) {
            key = array.findIndex((element) => element[name]);
            if (key == -1) {
                // 同じnameが存在しない場合は配列要素追加
                array.push({ [name]: value });
            } else {
                key = array.findIndex((element) => element[name]?.key == value?.key);
                if (key == -1) {
                    // 同じkeyが存在しない場合は配列要素追加
                    array.push({ [name]: value });
                } else {
                    // 同じkeyが存在する場合は配列要素を置き換える
                    array[key] = { [name]: value };
                }
            }
        } else {
            if (array.length > 0) {
                // error要素がない場合でkeyが存在する場合はその配列要素を削除
                array = array.filter((element) => (element[name] && element[name]?.key == count ? false : true));
            }
        }
        return array;
    }
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h2>月次損益登録</h2>
                    </div>
                    <div className='card-body'>
                        <MonthlyDataContext.Provider
                            value={{
                                errors,
                                setErrors,
                                isDisplayErrors,
                                setIsDisplayErrors,
                                handleOnMouseEnter,
                                handleOnMouseOut,
                                validationTrigger,
                                setValidationTrigger,
                                nullOrPush
                            }}>
                            <SelectMonthlyDataReferenceForm
                                datePickerValue={datePickerValue}
                                setDatePickerValue={setDatePickerValue}
                                handleButtonClick={handleButtonClick}
                                visibility={visibility}
                                setVisibility={setVisibility}
                                Component={
                                    <MonthlyDataEdit
                                        selectedTargetDate={formattedSelectedTargetDate}
                                        displayTargetDate={
                                            dateFormat(formattedSelectedTargetDate, false, "Y-m").format_date
                                        }
                                        selectedReferenceDate={selectedReferenceDate}
                                        displayReferenceDate={displayReferenceDate}
                                        setDisplayReferenceDate={setDisplayReferenceDate}
                                        referenceSwitch={referenceSwitch}
                                        setReferenceSwitch={setReferenceSwitch}
                                        proceedsData={proceedsData}
                                        setProceedsData={setProceedsData}
                                        proceeds={proceeds}
                                        setProceeds={setProceeds}
                                        proceedsRow={proceedsRow}
                                        setProceedsRow={setProceedsRow}
                                        proceedsSum={proceedsSum}
                                        setProceedsSum={setProceedsSum}
                                        expensesData={expensesData}
                                        setExpensesData={setExpensesData}
                                        expenses={expenses}
                                        setExpenses={setExpenses}
                                        expensesRow={expensesRow}
                                        setExpensesRow={setExpensesRow}
                                        expensesSum={expensesSum}
                                        setExpensesSum={setExpensesSum}
                                        withdrawalsData={withdrawalsData}
                                        setWithdrawalsData={setWithdrawalsData}
                                        withdrawalsRow={withdrawalsRow}
                                        setWithdrawalsRow={setWithdrawalsRow}
                                        withdrawalsSum={withdrawalsSum}
                                        setWithdrawalsSum={setWithdrawalsSum}
                                        withdrawalsSub={withdrawalsSub}
                                        setWithdrawalsSub={setWithdrawalsSub}
                                        referenceWithdrawalsData={referenceWithdrawalsData}
                                        setReferenceWithdrawalsData={setReferenceWithdrawalsData}
                                        bankAccountsData={bankAccountsData}
                                        setBankAccountsData={setBankAccountsData}
                                        bankAccounts={bankAccounts}
                                        setBankAccounts={setBankAccounts}
                                        bankAccountsRow={bankAccountsRow}
                                        setBankAccountsRow={setBankAccountsRow}
                                        bankAccountsSum={bankAccountsSum}
                                        setBankAccountSum={setBankAccountSum}
                                        bankAccountSub={bankAccountSub}
                                        setBankAccountSub={setBankAccountSub}
                                        advancePayment={advancePayment}
                                        setAdvancePayment={setAdvancePayment}
                                        pettyCash={pettyCash}
                                        setPettyCash={setPettyCash}
                                        nextMonthCash={nextMonthCash}
                                        setNextMonthCash={setNextMonthCash}
                                        travelExpenseTotalAmount={travelExpenseTotalAmount}
                                        setTravelExpenseTotalAmount={setTravelExpenseTotalAmount}
                                        referenceTravelExpenseTotalAmount={referenceTravelExpenseTotalAmount}
                                        setReferenceTravelExpenseTotalAmount={setReferenceTravelExpenseTotalAmount}
                                        othersData={othersData}
                                        setOthersData={setOthersData}
                                        others={others}
                                        setOthers={setOthers}
                                        othersRow={othersRow}
                                        setOthersRow={setOthersRow}
                                        othersSum={othersSum}
                                        setOthersSum={setOthersSum}
                                        targetNotice={targetNotice}
                                        setTargetNotice={setTargetNotice}
                                        referenceNotice={referenceNotice}
                                        setReferenceNotice={setReferenceNotice}
                                        grossMargin={grossMargin}
                                        setGrossMargin={setGrossMargin}
                                        referenceData={referenceData}
                                        setReferenceData={setReferenceData}
                                        targetData={targetData}
                                        setTargetData={setTargetData}
                                        setVisibility={setVisibility}
                                        initDefaultState={initDefaultState}
                                    />
                                }
                            />
                        </MonthlyDataContext.Provider>
                    </div>
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackButton path={HOME_PATH} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
