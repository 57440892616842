export const HOME_PATH = "/home";

export const MIN_MAX_DATE = {
    MIN_DATE: "1900-01-01",
    MAX_DATE: "2100-12-31"
};

export const AUTHORITY_NUMBERS = {
    NUM_SYSTEM_ADMIN: 0,
    NUM_ADMIN: 10,
    NUM_ACCOUNTANT: 20,
    NUM_EMPLOYEE: 30,
    NUM_TAX_ACCOUNT: 40
};

// 会計年度の開始月
export const FISCAL_YEAR_START_MONTH = 2;
