//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useMemo, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { Table, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { calculateDeduction, intAmount } from "./OutputSingleMonthSpreadsheetButton";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { monthlyDataReferenceContext } from "../../../providers/MonthlyDataReferenceProvider";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import amountToLocaleStringForMonthlyData from "../../../common/amountToLocaleStringForMonthlyData";
import dateFormat from "../../../common/dateFormat";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// MUIのTableRowのstyleを調整
const StyledTableRow = withStyles({
    "@global": { ".MuiTableRow-root": { height: "40px" } }
})(TableRow);

// スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        noticeTextArea: {
            resize: "none",
            width: "100px",
            height: "120px"
        },
        inlineTable: {
            display: "inline"
        }
    })
);
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
const COLUMN_SPAN_ONLY_TARGET = 1; // 選択年度のみ表示時のTableCell内の要素数
const COLUMN_SPAN_SHOW_PAST = 4; // 前年比表示時のTableCell内の要素数:[選択前年度, 選択年度, 前年差額, 前年比]
const EMPTY_NOTICE_COLUMN_SPAN_SHOW_PAST = COLUMN_SPAN_SHOW_PAST - 2; // 前年比表示時の特記事項列の余白となるTableCellの幅
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function MonthlyDataColumn(props) {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //定義したスタイルを利用するための設定
    const classes = useStyles();
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { proceedsList, expensesList, bankAccountsList, othersList } = useContext(monthlyDataReferenceContext);
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const {
        targetMonth,
        pastMonth,
        targetData,
        pastData,
        travelExpensesData,
        noticesData,
        withdrawalData,
        monthlyChangesData = [], // 月毎の前年差額
        showPastDataAndYoY
    } = props;
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const monthlyChanges = monthlyChangesData.slice(); // 前年差額を取り出すので複製します
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 売上合計
    const [totalSales, setTotalSales] = useState({ done: false });
    // 経費合計
    const [totalExpense, setTotalExpense] = useState({ done: false });

    // 月残高合計
    const [totalBalance, setTotalBalance] = useState({ done: false });
    // 差額
    const [difference, setDifference] = useState({ done: false });
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 各種計算を行う
    useEffect(() => {
        // 売上の合計
        setTotalSales(makeTotalAmountObj(1));

        // 領収書の合計
        setTotalExpense(makeTotalAmountObj(2));

        // 月残高の合計
        setTotalBalance(makeTotalAmountObj(3));
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // categoryIdの対応 => 1: 売上先ID, 2: 経費先ID, 3: 資金口座ID, 4: 予納その他経費, 5:小口繰越現金, 6: 翌月繰越現預金, 7: その他
    // 各合計値を計算し、ステートに格納する形にする
    const makeTotalAmountObj = (categoryId) => {
        return {
            target: calculateAmount(targetData, categoryId),
            past: calculateAmount(pastData, categoryId),
            done: true
        };
    };

    // 資金口座が削除されているとExcelと異なり削除済み口座に対応している金額が画面表示上は反映される不具合あり
    // 今後の対応方針に応じてExcelやこの関数での計算内容を変更して下さい
    // 各種データの合計値を計算
    const calculateAmount = (data, categoryId) => {
        if (data[categoryId].length > 0) {
            // 金額のみの配列を作成
            const amounts = data[categoryId].map((item) => {
                return item.monthly_data_amount;
            });
            // 合計値を計算
            const total = amounts.reduce((accumulator, currentValue) => {
                return accumulator + currentValue;
            });
            if (total !== 0) {
                return total;
            }
        }
        return null;
    };

    // 経費合計(資金口座の経費合計金額 + 領収書合計)の計算結果をmemo化
    const totalExpensesAmount = useMemo(() => {
        let totalExpensesAmountTarget = null;
        let totalExpensesAmountPast = null;
        if (totalExpense.done && Object.keys(travelExpensesData).length > 0) {
            totalExpensesAmountTarget = calculateTotalAmount(totalExpense?.target, travelExpensesData?.target);
            totalExpensesAmountPast = calculateTotalAmount(totalExpense?.past, travelExpensesData?.past);
        }
        return {
            target: totalExpensesAmountTarget,
            past: totalExpensesAmountPast
        };
    }, [totalExpense.done, travelExpensesData]);

    // 粗利益(売上合計金額 - 経費合計 - 予納その他経費)を計算しmemo化します
    const grossProfit = useMemo(() => {
        let grossProfitTarget = null;
        let grossProfitPast = null;
        if (totalSales.done) {
            grossProfitTarget = calculateGrossProfit(totalSales, totalExpensesAmount, targetData[4][0], "target");
            grossProfitPast = calculateGrossProfit(totalSales, totalExpensesAmount, pastData[4][0], "past");
        }
        return {
            target: grossProfitTarget,
            past: grossProfitPast
        };
    }, [totalSales.done, totalExpensesAmount]);

    // 差額の計算を行い、ステートに格納
    useEffect(() => {
        if (totalBalance.done) {
            setDifference({
                target: -calculateDifference(targetData[6][0], totalBalance.target),
                past: -calculateDifference(pastData[6][0], totalBalance.past),
                done: true
            });
        }
    }, [totalBalance]);

    // 差引現金残(小口繰越現金 + 現金引き出し合計 - 領収書合計)を計算しmemo化します
    const deductionAmount = useMemo(() => {
        let deductionAmountTarget = null;
        let deductionAmountPast = null;
        if (Object.keys(travelExpensesData).length > 0 && Object.keys(withdrawalData).length > 0) {
            deductionAmountTarget = calculateDeduction(
                targetData[5][0]?.monthly_data_amount,
                withdrawalData?.target,
                travelExpensesData?.target
            );
            deductionAmountPast = calculateDeduction(
                pastData[5][0]?.monthly_data_amount,
                withdrawalData?.past,
                travelExpensesData?.past
            );
        }
        return {
            target: deductionAmountTarget,
            past: deductionAmountPast
        };
    }, [travelExpensesData, withdrawalData]);

    // 表示する前年比を調整する関数
    const displayYoY = (targetAmount, pastAmount) => {
        const YoYRate = calculateAmountYoY(targetData, pastAmount, targetAmount)[1];
        if (YoYRate === 0 || (YoYRate && Math.round(Math.abs(YoYRate) * 100) === 0)) {
            return <>0%</>;
        } else if (YoYRate) {
            return checkYoYSign(Math.round(YoYRate * 100));
        } else {
            return null;
        }
    };

    // 可変長の会計金額表示行における前年比の表示を調整する関数
    const displayFlexibleYoY = (categoryId, listId) => {
        if (pastData[categoryId].some((data) => data.monthly_data_target_id === listId)) {
            return displayYoY(
                targetData[categoryId].find((data) => data.monthly_data_target_id === listId)?.monthly_data_amount ??
                    null,
                pastData[categoryId].find((data) => data.monthly_data_target_id === listId)?.monthly_data_amount
            );
        } else {
            return null;
        }
    };

    // 第1引数に渡された数値が負の数ならば第2引数に文字色を付ける関数
    const checkAccountantSign = (num, numString) =>
        Math.sign(num) === -1 ? <span className='text-danger'>{numString}</span> : numString;

    // 正負の符号をチェックし、必要に応じて色付けする関数
    const checkAmountSign = (value) => {
        const amountValue = amountToLocaleStringForMonthlyData(value);
        const absStr = Math.sign(value) === -1 ? `(${amountValue.replace("-", "")})` : amountValue;
        return checkAccountantSign(value, absStr);
    };

    // 前年比の正負の符号をチェックし、必要に応じて色付けする関数
    const checkYoYSign = (rate) => {
        const perStr = <>{amountToLocaleStringForMonthlyData(rate)}%</>;
        return checkAccountantSign(rate, perStr);
    };
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    // 可変長の会計金額データを基にした表示行を返す関数
    const flexibleAmountTableRows = (
        monthlyDataReferenceList,
        categoryId,
        past = pastData,
        target = targetData,
        monthlyChangesData = monthlyChanges,
        showPastDataAndYoY = showPastDataAndYoY
    ) => {
        const monthlyChangesList = monthlyChangesData.splice(0, monthlyDataReferenceList.length);
        return monthlyDataReferenceList.map((item) => (
            <StyledTableRow key={item.id}>
                {/* 前年度の金額 */}
                {showPastDataAndYoY && <TableCell align='right'>{displayAmount(past, categoryId, item.id)}</TableCell>}
                {/* 今年度の金額 */}
                <TableCell align='right'>{displayAmount(target, categoryId, item.id)}</TableCell>
                {showPastDataAndYoY && (
                    <>
                        {/* 前年差額 */}
                        <TableCell align='right'>{checkAmountSign(monthlyChangesList.shift())}</TableCell>
                        {/* 前年比 */}
                        <TableCell align='right'>{displayFlexibleYoY(categoryId, item.id)}</TableCell>
                    </>
                )}
            </StyledTableRow>
        ));
    };

    // 単独の会計金額データを基にした表示行を返す関数
    const individualAmountTableRow = ({ past, target }, monthlyChanges, showPastDataAndYoY = showPastDataAndYoY) => (
        <StyledTableRow>
            {/* 前年度の金額 */}
            {showPastDataAndYoY && <TableCell align='right'>{checkAmountSign(past ? Number(past) : null)}</TableCell>}
            {/* 今年度の金額 */}
            <TableCell align='right'>{checkAmountSign(target ? Number(target) : null)}</TableCell>
            {showPastDataAndYoY && (
                <>
                    {/* 前年差額 */}
                    <TableCell align='right'>
                        {checkAmountSign(isFinite(monthlyChanges) ? monthlyChanges : null)}
                    </TableCell>
                    {/* 前年比 */}
                    <TableCell align='right'>{displayYoY(target, past)}</TableCell>
                </>
            )}
        </StyledTableRow>
    );

    // 色付きの区切り行の幅を前年比の表示状態に合わせて変更します
    const sectionRowsCell = (showPastDataAndYoY = showPastDataAndYoY) => {
        const columnCount = showPastDataAndYoY ? COLUMN_SPAN_SHOW_PAST : COLUMN_SPAN_ONLY_TARGET;
        return <TableCell colSpan={columnCount} />;
    };

    return (
        <div className={`${classes.inlineTable} border-start border-start-1 border-secondary mb-4`}>
            <Table className='table table-bordered text-nowrap m-0'>
                <TableHead className='caption-top'>
                    <TableRow>
                        {/* 前年度の年月 */}
                        {showPastDataAndYoY && (
                            <TableCell className='fw-bold' align='center'>
                                {dateFormat(pastMonth, false, "Y-m").format_date}
                            </TableCell>
                        )}
                        {/* 今年度の年月 */}
                        <TableCell className='fw-bold' align='center'>
                            {dateFormat(targetMonth, false, "Y-m").format_date}
                        </TableCell>
                        {showPastDataAndYoY && (
                            <>
                                <TableCell align='center'>前年差額</TableCell>
                                <TableCell align='center'>前年比</TableCell>
                            </>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* 売上 START */}
                    <StyledTableRow className='table-danger'>
                        {
                            /* 色付きの区切りの行 */
                            sectionRowsCell(showPastDataAndYoY)
                        }
                    </StyledTableRow>
                    {/* 売上先一覧を展開 */}
                    {flexibleAmountTableRows(proceedsList, 1, pastData, targetData, monthlyChanges, showPastDataAndYoY)}
                    {/* 売上合計 */}
                    {individualAmountTableRow(totalSales, monthlyChanges.shift(), showPastDataAndYoY)}
                    {/* 売上 END */}
                    {/* 経費 START */}
                    <StyledTableRow className='table-info'>
                        {
                            /* 色付きの区切りの行 */
                            sectionRowsCell(showPastDataAndYoY)
                        }
                    </StyledTableRow>
                    {/* 経費先一覧を展開 */}
                    {flexibleAmountTableRows(expensesList, 2, pastData, targetData, monthlyChanges, showPastDataAndYoY)}
                    {/* 領収書合計金額 */}
                    {individualAmountTableRow(travelExpensesData, monthlyChanges.shift(), showPastDataAndYoY)}
                    {/* 経費合計 */}
                    {individualAmountTableRow(totalExpensesAmount, monthlyChanges.shift(), showPastDataAndYoY)}
                    {/* 予納その他経費 */}
                    {individualAmountTableRow(
                        {
                            past: pastData[4][0]?.monthly_data_amount,
                            target: targetData[4][0]?.monthly_data_amount
                        },
                        monthlyChanges.shift(),
                        showPastDataAndYoY
                    )}
                    {/* 粗利益 */}
                    {individualAmountTableRow(grossProfit, monthlyChanges.shift(), showPastDataAndYoY)}
                    {/* 経費 END */}
                    {/* 現金出納帳 START */}
                    <StyledTableRow className='table-success'>
                        {/* 色付きの区切り行 */}
                        {sectionRowsCell(showPastDataAndYoY)}
                    </StyledTableRow>
                    {/* 小口繰越現金 */}
                    {individualAmountTableRow(
                        {
                            past: pastData[5][0]?.monthly_data_amount,
                            target: targetData[5][0]?.monthly_data_amount
                        },
                        monthlyChanges.shift(),
                        showPastDataAndYoY
                    )}
                    {/* 現金引き出し */}
                    {individualAmountTableRow(withdrawalData, monthlyChanges.shift(), showPastDataAndYoY)}
                    {/* 領収書合計金額 */}
                    {individualAmountTableRow(travelExpensesData, monthlyChanges.shift(), showPastDataAndYoY)}
                    {/* 差引現金残 */}
                    {individualAmountTableRow(deductionAmount, monthlyChanges.shift(), showPastDataAndYoY)}
                    {/* 資金 START */}
                    <StyledTableRow className='table-success'>
                        {/* 色付きの区切り行 */}
                        {sectionRowsCell(showPastDataAndYoY)}
                    </StyledTableRow>
                    {/* 翌月繰越現預金 */}
                    {individualAmountTableRow(
                        {
                            past: pastData[6][0]?.monthly_data_amount,
                            target: targetData[6][0]?.monthly_data_amount
                        },
                        monthlyChanges.shift(),
                        showPastDataAndYoY
                    )}
                    {/* 資金口座一覧を展開 */}
                    {flexibleAmountTableRows(
                        bankAccountsList,
                        3,
                        pastData,
                        targetData,
                        monthlyChanges,
                        showPastDataAndYoY
                    )}
                    {/* 月残高合計 */}
                    {individualAmountTableRow(totalBalance, monthlyChanges.shift(), showPastDataAndYoY)}
                    {/* 差額 */}
                    {individualAmountTableRow(difference, monthlyChanges.shift(), showPastDataAndYoY)}
                    {/* 資金 END */}
                    {/* その他 END */}
                    <StyledTableRow className='table-secondary'>
                        {/* 色付きの区切り行 */}
                        {sectionRowsCell(showPastDataAndYoY)}
                    </StyledTableRow>
                    {/* その他一覧を展開 */}
                    {flexibleAmountTableRows(othersList, 7, pastData, targetData, monthlyChanges, showPastDataAndYoY)}
                    <StyledTableRow className='table-primary'>
                        {/* 色付きの区切り行 */}
                        {sectionRowsCell(showPastDataAndYoY)}
                    </StyledTableRow>
                    <StyledTableRow>
                        {/* 前年度の特記事項 */}
                        {showPastDataAndYoY && (
                            <TableCell align='right'>
                                <textarea
                                    className={classes.noticeTextArea}
                                    readOnly
                                    value={noticesData.past ?? ""}></textarea>
                            </TableCell>
                        )}
                        {/* 今年度の特記事項 */}
                        <TableCell align='right'>
                            <textarea
                                className={classes.noticeTextArea}
                                readOnly
                                value={noticesData.target ?? ""}></textarea>
                        </TableCell>
                        {/* 余白 */}
                        {showPastDataAndYoY && <TableCell colSpan={EMPTY_NOTICE_COLUMN_SPAN_SHOW_PAST} />}
                    </StyledTableRow>
                </TableBody>
            </Table>
        </div>
    );
}

// 差額(翌月繰越現預金 - 月残高合計)の計算を行う関数
export const calculateDifference = (nextMonthCashData, totalBalance) => {
    if (Number(nextMonthCashData?.monthly_data_amount) || Number(totalBalance)) {
        return Number(nextMonthCashData?.monthly_data_amount ?? 0) - Number(totalBalance ?? 0);
    } else {
        return null;
    }
};

// 合計金額の計算を行う関数
export const calculateTotalAmount = (nextMonthCashData, totalBalance) => {
    if (Number(nextMonthCashData) || Number(totalBalance)) {
        return Number(nextMonthCashData ?? 0) + Number(totalBalance ?? 0);
    } else {
        return null;
    }
};

// 粗利益(売上合計金額 - 経費合計金額 - 予納その他経費)の計算を行う関数
export const calculateGrossProfit = (totalProceeds, totalExpense, advancePaymentData, targetOrPast) => {
    if (
        Number(totalProceeds[targetOrPast]) ||
        Number(totalExpense[targetOrPast]) ||
        Number(advancePaymentData?.monthly_data_amount)
    ) {
        return (
            Number(totalProceeds[targetOrPast] ?? 0) - // 売上合計金額
            Number(totalExpense[targetOrPast] ?? 0) - // 経費合計金額
            Number(advancePaymentData?.monthly_data_amount ?? 0)
        ); // 予納その他経費
    } else {
        return null;
    }
};

// 選択年度とその前年度の項目同士の金額を其々数値として受け取り
// 金額同士の差額と比率を配列で返します;
export const calculateAmountYoY = (
    targetMonthData, // 第3引数のtargetAmountと同じ年月の月次損益データ
    pastAmount,
    targetAmount
) => {
    // 前年との差額・比率を計算できるか否かの真偽値
    const calculableYoY =
        Number(pastAmount) && (Number(targetAmount) || Object.values(targetMonthData).flat().length > 0);

    // 前年差額 = 選択年度 - 前年度
    const YoYChanges = calculableYoY ? Number(targetAmount ?? 0) - Number(pastAmount ?? 0) : null;
    // monthlyYoYChanges[indexMonth].push(YoYChanges);

    // 前年比 = 選択年度 / 前年度
    const YoYRate = calculableYoY ? Number(targetAmount ?? 0) / Number(pastAmount) : null;
    return [
        YoYChanges, // 前年差額 = 選択年度 - 前年度
        YoYRate // 前年比 = 選択年度 / 前年度
    ];
};

// 表示する金額部分を調整する関数
export const displayAmount = (data, categoryId, listId) =>
    amountToLocaleStringForMonthlyData(intAmount(data[categoryId], listId)) ?? "";
