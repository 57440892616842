//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { isBefore, setMonth, subYears, startOfMonth } from "date-fns";
//_________________________________________________ライブラリの読み込み_________________________________________________////^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { FISCAL_YEAR_START_MONTH } from "./constants"; // 年度開始月
//_________________________________________________共通関数・定数の読み込み_________________________________________________//
// 引数で渡された年の年度開始日を算出して返します
export const fiscalStartDate = (date = new Date()) =>
    startOfMonth(setMonth(date, FISCAL_YEAR_START_MONTH - 1));

// 引数で渡された日付に対応する年度の開始年月日を返します
export default function determineFiscalYear(date = new Date()) {
    // 年度開始年月日は年度開始月の初日とします
    const fiscalDate = fiscalStartDate(date);
    // 引数で受け取った日付が新年度開始年月日よりも前ならば前年の年度開始年月日を返します
    return isBefore(date, fiscalDate)
        ? subYears(fiscalDate, 1)
        : fiscalDate;
}
