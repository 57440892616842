//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useRef, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import swal from "sweetalert";
import { startOfMonth, subMonths } from "date-fns";
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { makeStyles, createStyles } from "@material-ui/core/styles";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import ItemNameColumn from "../../components/transaction/monthly_data/ItemNameColumn";
import OutputSingleMonthSpreadsheetButton from "../../components/transaction/monthly_data/OutputSingleMonthSpreadsheetButton";
import MonthlyDataColumnForTaxAccountant from "../../components/transaction/monthly_data/MonthlyDataColumnForTaxAccountant";
import SelectMonthAndYearMenu from "../../components/transaction/monthly_data/SelectMonthAndYearMenu";
import BackButton from "../../components/BackButton";
import SelectMonthlyDataReferenceForm from "../../components/transaction/monthly_data/SelectMonthlyDataReferenceForm";
import MonthlyDataShow from "../../components/transaction/monthly_data/MonthlyDataShow";
import TravelExpenseShowForUser from "../../components/transaction/monthly_data/TravelExpenseShowForUser";
import TravelExpenseShowForCost from "../../components/transaction/monthly_data/TravelExpenseShowForCost";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import {
    monthlyDataReferenceContext,
    setMonthlyDataReferenceContext
} from "../../providers/MonthlyDataReferenceProvider";
import { UserContext } from "../../providers/UserProvider";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { HOME_PATH } from "../../common/constants";
import dateFormat from "../../common/dateFormat";
import monthAndYearFormat from "../../common/monthAndYearFormat";
import processResponse from "../../common/processResponse";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
const useStyles = makeStyles((theme) =>
    createStyles({
        dialog: {
            position: "fixed",
            right: "1vw",
            top: "20vh",
            width: "30vw",
            height: "60vh",
            padding: "10px",
            boxSizing: "border-box",
            backgroundColor: "#fefefe",
            border: "1px solid #666",
            textAlign: "center"
        },
        imgWrapper: {
            width: "100%",
            height: "calc(100% - 80px)"
        },
        img: {
            objectFit: "contain",
            maxWidth: "100%",
            maxHeight: "100%"
        }
    })
);
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// 戻るときの遷移先のパス
const returnPath = HOME_PATH;

// 先月の日付を取得
const thisMonth = new Date();
// 先月の日付を取得
const lastMonth = startOfMonth(subMonths(new Date(), 1));

const TaxAccountantAuthorityNumber = 40;
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// ステートの初期状態
const initMonthlyData = { result: [], success: false };

// ステートの初期状態
const initBankAccounts = { result: [], success: false };

// ステートの初期状態
const initOthers = { result: [], success: false };

// ステートの初期値
const initTargetTravelExpensesTotalAmounts = { result: [], success: false };
const initTargetNotices = { result: [], success: false };
const initTargetWithdrawal = { result: [], success: false };
const iniImgData = { result: null, success: false };

const initTotalData = { result: { user_data: [], total_data: [] } };
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function MonthlyDataTotalShow() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //定義したスタイルを利用するための設定
    const classes = useStyles();
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { setProceedsList, setExpensesList, setBankAccountsList, setOthersList } =
        useContext(setMonthlyDataReferenceContext);
    const { proceedsList, expensesList, bankAccountsList, othersList } = useContext(monthlyDataReferenceContext);

    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { userInfo } = useContext(UserContext);
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    // datePickerの選択済みの値を管理する
    const [startDate, setStartDate] = useState(thisMonth);

    // 開始ボタンが押された時の変化を伝える。
    const [start, setStart] = useState(false);

    const [formattedSelectedTargetDate, setFormattedSelectedTargetDate] = useState(monthAndYearFormat(thisMonth)); // 対象年月(API用 Y-m-01)

    // 選択した対象月の1日の日付を文字列として管理する
    const [selectedMonthAndYear, setSelectedMonthAndYear] = useState("");

    // 画面読み込みの制御
    const [loaded, setLoaded] = useState(false);

    // 月次損益の選択した年度の値を管理する
    const [monthlyData, setMonthlyData] = useState(initMonthlyData);

    const [proceedsSum, setProceedsSum] = useState(0); // 売上合計
    const [expensesSum, setExpensesSum] = useState(0); // 経費合計
    const [withdrawalsSub, setWithdrawalsSub] = useState(0); // 差引現金残
    const [bankAccountsSum, setBankAccountSum] = useState(0); // 資金合計
    const [bankAccountSub, setBankAccountSub] = useState(0); // 差額
    const [grossMargin, setGrossMargin] = useState(0); // 粗利益
    const [travelExpenseTotalAmount, setTravelExpenseTotalAmount] = useState([]); // 経費(合計)

    // 集計用データ
    const [totalData, setTotalData] = useState(initTotalData);

    // 資金口座の全データを管理する
    const [bankAccounts, setBankAccounts] = useState(initBankAccounts);

    const bankAccountsListRef = useRef([]); // resultのデータを変換して保持

    // その他の全データを管理する
    const [others, setOthers] = useState(initOthers);

    const othersListRef = useRef([]); // resultのデータを変換して保持

    // 展開用のデータを月ごとに管理
    const [monthlyDataForDeploy, setMonthlyDataForDeploy] = useState({});

    // DBから取得した旅費精算合計の値を管理
    const [targetTravelExpensesTotalAmounts, setTargetTravelExpensesTotalAmounts] = useState(
        initTargetTravelExpensesTotalAmounts
    );

    // 展開用の旅費精算の合計値のデータを管理する
    const [travelExpensesTotalAmountsForDeploy, setTravelExpensesTotalAmountsForDeploy] = useState({});

    // DBから取得した特記事項の値を管理
    const [targetNotices, setTargetNotices] = useState(initTargetNotices);

    // 特記事項の合計値のデータを管理する
    const [noticesForDeploy, setNoticesForDeploy] = useState({});

    // DBから取得した現金引き出しの値を管理
    const [targetWithdrawal, setTargetWithdrawal] = useState(initTargetWithdrawal);

    // 現金引き出しの合計値のデータを管理する
    const [withdrawalForDeploy, setWithdrawalForDeploy] = useState({});

    //データがない時True
    const [isNoData, setIsNoData] = useState(false);

    // 税理士でない時True
    const [isNotTaxAccountant, setIsNotTaxAccountant] = useState(false);

    const [visibility, setVisibility] = useState(false); // コンポーネントの可視フラグ(開始時表示・登録時非表示)

    // 画像用
    // 画像管理用ステート
    const [imgData, setImgData] = useState(iniImgData);
    const [imgSrc, setImgSrc] = useState("");
    const [showImg, setShowImg] = useState(false);

    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 各種データを取得
    const getData = async (url, setState) => {
        const res = await api.get(url);
        processResponse(res, setState, navigate, returnPath);
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    // 開始ボタン押下時
    const handleOnClickSelectSubmit = (e) => {
        backBeforeStart();
        // 年月のデータを読み込み開始
        setStart(!start);
        setSelectedMonthAndYear(monthAndYearFormat(startDate));
        setBankAccounts;
    };

    // 領収書ボタン押下時、画像を表示
    const handleOnClickSnowImg = (date, user_id, fileName) => {
        const splittedDate = date.split("-");
        const yearMonth = `${splittedDate[0]}-${splittedDate[1]}`;
        const path = `file/receipt/${yearMonth}/${user_id}/${fileName}`;
        getData(path, setImgData);
    };

    // 画像非表示
    const handleOnClickCloseImage = () => {
        setImgSrc("");
        setImgData(iniImgData);
        setShowImg(false);
    };

    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => {
        // 各種カテゴリのそれぞれの項目名一覧を取得
        getData(`bank_accounts`, setBankAccounts); // 口座を取得
        getData(`others`, setOthers); // その他を取得
        setIsNotTaxAccountant(userInfo.user.authority_number < TaxAccountantAuthorityNumber);
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 読み込み前に戻る
    const backBeforeStart = () => {
        setLoaded(false);
        setMonthlyData(initMonthlyData);
        setMonthlyDataForDeploy({});
        setTargetTravelExpensesTotalAmounts(initTargetTravelExpensesTotalAmounts);
        setTravelExpensesTotalAmountsForDeploy({});
        setTargetNotices(initTargetNotices);
        setNoticesForDeploy({});
        setWithdrawalForDeploy({});
    };

    // 年度のデータ読み込み開始時の処理
    useEffect(() => {
        if (selectedMonthAndYear !== "") {
            getData(`travel_expenses_total_data/${selectedMonthAndYear}`, setTotalData);
            getData(`travel_expenses_total_amount/${selectedMonthAndYear}`, setTravelExpenseTotalAmount); // 経費合計を取得
            getData(`monthly_data/${selectedMonthAndYear}`, setMonthlyData); // 選択した年度の月次損益データを取得

            getData(
                `travel_expenses_target_month_total_amount/${selectedMonthAndYear.slice(0, 7)}`,
                setTargetTravelExpensesTotalAmounts
            ); // 表示対象の年度内の旅費精算の合計値全件を取得

            getData(`notices/${selectedMonthAndYear}`, setTargetNotices); // 表示対象の年度内の特記事項全件を取得
            getData(`withdrawal_month/${selectedMonthAndYear}`, setTargetWithdrawal); // 表示対象の年度内の現金引き出し全件を取得
        }
    }, [selectedMonthAndYear, start]);

    // 各カテゴリーごとにデータを振り分ける処理
    const distributeData = (data) => {
        // 各月ごとに全カテゴリーのidをキーにしたオブジェクトを包括する空の配列を用意
        const distributedData = {
            1: data[0], // 売上データ
            2: data[1], // 経費データ
            3: data[2], // 口座
            4: data[3] ? [data[3]] : [], // 予納その他経費
            5: data[4] ? [data[4]] : [], // 小口繰越現金
            6: data[5] ? [data[5]] : [], // 翌月繰越現預金
            7: data[6] // その他
        };
        setIsNoData(
            distributedData[1].length === 0 &&
                distributedData[2].length === 0 &&
                distributedData[3].length === 0 &&
                distributedData[4].length === 0 &&
                distributedData[5].length === 0 &&
                distributedData[6].length === 0 &&
                distributedData[7].length === 0
        );
        return distributedData;
    };

    // 表示する項目名を表示対象の項目のみに絞り込む
    const makeFilteredList = (tableDataList, monthlyPrices) => {
        const data = monthlyPrices.filter((monthlyPrice) => monthlyPrice !== null);
        // 表示対象、または前年度のいずれかに該当する項目の値が存在する項目のみを抽出
        return tableDataList.filter(
            (tableData) => data.findIndex((price) => price.monthly_data_target_id === tableData.id) != -1
        );
    };

    // データベースからデータの取得が済んだとき
    useEffect(() => {
        if (monthlyData.success) {
            if (monthlyData.result.length > 0) {
                // 対象の年月のリストが作成されてから、表示対象のデータを展開するための形に振り分ける
                setMonthlyDataForDeploy(distributeData(monthlyData.result ?? []));
            }
        }
    }, [monthlyData]);

    // データベースからデータの取得が済んだとき
    useEffect(() => {
        if (monthlyData.success && Object.keys(monthlyDataForDeploy).length > 0) {
            if (!isNoData) {
                // 表示する項目名を表示対象の項目のみに絞り込み、展開するためのステートにセットしていく
                setProceedsList(makeFilteredList(bankAccountsListRef.current, monthlyDataForDeploy[1]));
                setExpensesList(makeFilteredList(bankAccountsListRef.current, monthlyDataForDeploy[2]));
                setBankAccountsList(makeFilteredList(bankAccountsListRef.current, monthlyDataForDeploy[3]));
                setOthersList(makeFilteredList(othersListRef.current, monthlyDataForDeploy[7]));
            }
        }
    }, [monthlyDataForDeploy]);

    // 表示・行管理のために値を配列に作り直す
    useEffect(() => {
        if (bankAccounts.result.length > 0) {
            const result = bankAccounts.result;
            // 配列を作成
            const list = result.map((item) => {
                return {
                    id: item.bank_account_id,
                    name: item.bank_account_name
                };
            });
            // Refオブジェクトを更新
            bankAccountsListRef.current = list;
        }
    }, [bankAccounts]);

    // 表示・行管理のために値を配列に作り直す
    useEffect(() => {
        if (others.result.length > 0) {
            const result = others.result;
            // 配列を作成
            const list = result.map((item) => {
                return { id: item.other_id, name: item.other_name };
            });
            // Refオブジェクトを更新
            othersListRef.current = list;
        }
    }, [others]);

    // 各カテゴリーごとにデータを振り分ける処理
    const distributeForCellData = (targetData, parameterKeyName) => {
        // 各月ごとに全カテゴリーのidをキーにしたオブジェクトを包括する空の配列を用意
        const distributedData = {
            target: null
        };
        if (targetData.length > 0) {
            // 表示対象のデータを振り分ける処理
            for (const item of targetData) {
                distributedData.target = item[parameterKeyName];
            }
        }
        return distributedData;
    };

    // 表示する旅費精算の合計値のステートを更新
    useEffect(() => {
        if (targetTravelExpensesTotalAmounts.success) {
            setTravelExpensesTotalAmountsForDeploy(
                distributeForCellData(targetTravelExpensesTotalAmounts.result, "travel_expense_total_amount")
            );
        }
    }, [targetTravelExpensesTotalAmounts]);

    // 表示する特記事項のステートを更新
    useEffect(() => {
        if (targetNotices.success) {
            setNoticesForDeploy(
                distributeForCellData(targetNotices.result ? [targetNotices.result] : [], "notice_detail")
            );
        }
    }, [targetNotices]);

    // 表示する現金引き出しのステートを更新
    useEffect(() => {
        if (targetWithdrawal.success) {
            let withdrawalTotal = { target: 0 };
            if (Array.isArray(targetWithdrawal.result)) {
                withdrawalTotal.target = targetWithdrawal.result.reduce((sum, element) => {
                    const amount = parseInt(element?.withdrawal_amount ?? 0);
                    return parseInt(sum) + (isNaN(amount) ? 0 : amount);
                }, 0);
            }
            setWithdrawalForDeploy(withdrawalTotal);
        }
    }, [targetWithdrawal]);

    // 読み込みがおおよそ完了したことをステートに反映させて、表を表示する
    useEffect(async () => {
        if (
            Object.keys(monthlyDataForDeploy).length > 0 &&
            Object.keys(travelExpensesTotalAmountsForDeploy).length > 0 &&
            Object.keys(noticesForDeploy).length > 0 &&
            Object.keys(withdrawalForDeploy).length > 0 &&
            selectedMonthAndYear.length >= 7
        ) {
            if (
                isNoData &&
                travelExpensesTotalAmountsForDeploy.target == null &&
                noticesForDeploy.target == null &&
                withdrawalForDeploy.target == 0
            ) {
                // データが存在しない場合、アラートを表示し
                await swal("データが未作成です", "", "info");
                // 開始以前に戻る
                backBeforeStart();
                return;
            } else {
                setLoaded(true);
            }
        }
    }, [
        isNoData,
        monthlyDataForDeploy,
        travelExpensesTotalAmountsForDeploy,
        noticesForDeploy,
        withdrawalForDeploy,
        selectedMonthAndYear
    ]);

    // imgData更新時、画像表示用パスを作成
    useEffect(() => {
        if (imgData.result !== null) {
            const adjustedData = JSON.parse(imgData.result); // 受け取ったデータをjsonに
            const mimeType = adjustedData.mimeType;
            const fileText = adjustedData.fileText;
            const receiptUri = "data:" + mimeType + ";base64," + fileText;
            setShowImg(true);
            setImgSrc(receiptUri);
        }
    }, [imgData]);

    const Component = (
        <>
            <div className='row'>
                <div className='col mb-5'>
                    <OutputSingleMonthSpreadsheetButton
                        month={selectedMonthAndYear}
                        monthDeployData={monthlyDataForDeploy}
                        withDraw={withdrawalForDeploy.target}
                        totalTravelExpenses={travelExpensesTotalAmountsForDeploy.target}
                        notice={noticesForDeploy.target}
                        hasTravelExpenseSummary={true}
                        hasTravelExpensePerUser={isNotTaxAccountant}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-7'>
                    {totalData?.result?.user_data.length > 0 ? (
                        <>
                            {isNotTaxAccountant && <TravelExpenseShowForUser userData={totalData?.result?.user_data} />}
                            <TravelExpenseShowForCost
                                totalData={totalData?.result?.total_data}
                                onClick={handleOnClickSnowImg}
                            />
                        </>
                    ) : (
                        "データが存在しません"
                    )}
                </div>
                <div className='col-1'></div>
                <div className='col-4'>
                    <MonthlyDataShow
                        displayTargetDate={dateFormat(selectedMonthAndYear, false, "Y-m").format_date}
                        proceedsData={monthlyData.result[0]}
                        proceeds={bankAccounts.result}
                        proceedsSum={proceedsSum}
                        setProceedsSum={setProceedsSum}
                        expensesData={monthlyData.result[1]}
                        expenses={bankAccounts.result}
                        expensesSum={expensesSum}
                        setExpensesSum={setExpensesSum}
                        withdrawalsData={targetWithdrawal.result}
                        withdrawalsSub={withdrawalsSub}
                        setWithdrawalsSub={setWithdrawalsSub}
                        bankAccountsData={monthlyData.result[2]}
                        bankAccounts={bankAccounts.result}
                        setBankAccounts={setBankAccounts}
                        bankAccountsSum={bankAccountsSum}
                        setBankAccountSum={setBankAccountSum}
                        bankAccountSub={bankAccountSub}
                        setBankAccountSub={setBankAccountSub}
                        advancePayment={monthlyData.result[3]}
                        pettyCash={monthlyData.result[4]}
                        nextMonthCash={monthlyData.result[5]}
                        travelExpenseTotalAmount={travelExpensesTotalAmountsForDeploy.target}
                        othersData={monthlyData.result[6]}
                        others={others.result}
                        targetNotice={noticesForDeploy.target}
                        grossMargin={grossMargin}
                        setGrossMargin={setGrossMargin}
                        TaxAccountantAuthorityNumber={TaxAccountantAuthorityNumber}
                    />
                    {showImg && (
                        <div className={`${classes.dialog}`}>
                            <div className={classes.imgWrapper}>
                                <img className={classes.img} src={imgSrc} alt='領収書・受領書' />
                            </div>
                            <div className='mt-5'>
                                <button onClick={(e) => handleOnClickCloseImage(e)}>閉じる</button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h2>月次損益一覧</h2>
                    </div>
                    <div className='card-body'>
                        <SelectMonthlyDataReferenceForm
                            datePickerValue={startDate}
                            setDatePickerValue={setStartDate}
                            handleButtonClick={handleOnClickSelectSubmit}
                            visibility={visibility}
                            setVisibility={setVisibility}
                            Component={loaded ? Component : null}
                        />
                    </div>
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackButton path={HOME_PATH} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
