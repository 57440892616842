import React, { useContext, useEffect } from "react";
import { Routes as BrowserRoutes, Route, useLocation } from "react-router-dom";
import { UserContext } from "../providers/UserProvider";
import swal from "sweetalert";

import Login from "../contents/Login";
import Logout from "../contents/Logout";
import Home from "../contents/Home";
import NotFound from "../contents/NotFound";

import User from "../contents/user/User";
import UserEdit from "../contents/user/UserEdit";
import UserRegister from "../contents/user/UserRegister";
import UserShow from "../contents/user/UserShow";
import UserDeleted from "../contents/user/UserDeleted";
import UserDeletedShow from "../contents/user/UserDeletedShow";
import BankAccount from "../contents/bank_account/BankAccount";
import BankAccountEdit from "../contents/bank_account/BankAccountEdit";
import BankAccountShow from "../contents/bank_account/BankAccountShow";
import BankAccountDeleted from "../contents/bank_account/BankAccountDeleted";
import BankAccountDeletedShow from "../contents/bank_account/BankAccountDeletedShow";
import BankAccountRegister from "../contents/bank_account/BankAccountRegister";
import Proceed from "../contents/proceed/Proceed";
import ProceedEdit from "../contents/proceed/ProceedEdit";
import ProceedRegister from "../contents/proceed/ProceedRegister";
import ProceedShow from "../contents/proceed/ProceedShow";
import ProceedDeleted from "../contents/proceed/ProceedDeleted";
import ProceedDeletedShow from "../contents/proceed/ProceedDeletedShow";
import Expense from "../contents/expense/Expense";
import ExpenseEdit from "../contents/expense/ExpenseEdit";
import ExpenseRegister from "../contents/expense/ExpenseRegister";
import ExpenseShow from "../contents/expense/ExpenseShow";
import ExpenseDeleted from "../contents/expense/ExpenseDeleted";
import ExpenseDeletedShow from "../contents/expense/ExpenseDeletedShow";
import Payee from "../contents/payee/Payee";
import PayeeEdit from "../contents/payee/PayeeEdit";
import PayeeRegister from "../contents/payee/PayeeRegister";
import PayeeShow from "../contents/payee/PayeeShow";
import PayeeDeleted from "../contents/payee/PayeeDeleted";
import PayeeDeletedShow from "../contents/payee/PayeeDeletedShow";
import TravelExpenseRegister from "../contents/travel_expense/TravelExpenseRegister";
import TravelExpenseConfirm from "../contents/travel_expense/TravelExpenseConfirm";
import SuspensePayment from "../contents/suspense_payment/SuspensePayment";
import SuspensePaymentShow from "../contents/suspense_payment/SuspensePaymentShow";
import SuspensePaymentConfirmShow from "../contents/suspense_payment/SuspensePaymentConfirmShow";
import SuspensePaymentEdit from "../contents/suspense_payment/SuspensePaymentEdit";
import SuspensePaymentConfirmEdit from "../contents/suspense_payment/SuspensePaymentConfirmEdit";
import SuspensePaymentRegister from "../contents/suspense_payment/SuspensePaymentRegister";
import SuspensePaymentConfirm from "../contents/suspense_payment/SuspensePaymentConfirm";
import MonthlyDataRegister from "../contents/monthly_data/MonthlyDataRegister";
import MonthlyDataTotalShow from "../contents/monthly_data/MonthlyDataTotalShow";
import MonthlyDataReference from "../contents/monthly_data/MonthlyDataReference";
import { MonthlyDataReferenceProvider } from "../providers/MonthlyDataReferenceProvider";
import Other from "../contents/other/Other";
import OtherEdit from "../contents/other/OtherEdit";
import OtherRegister from "../contents/other/OtherRegister";
import OtherShow from "../contents/other/OtherShow";
import OtherDeleted from "../contents/other/OtherDeleted";
import OtherDeletedShow from "../contents/other/OtherDeletedShow";
import ReissuePassword from "../contents/ReissuePassword";
import ClosingProcess from "../contents/closing_process/ClosingProcess";
import CreatePDF from "../contents/pdf/CreatePDF";
import CreatePDFMonthly from "../contents/pdf/CreatePDFMonthly";
import CreatePDFForTaxAccount from "../contents/pdf/CreatePDFForTaxAccount";
import MonthlyDataReferenceForTaxAccountant from "../contents/monthly_data/MonthlyDataReferenceForTaxAccountant";
import Calculate from "../contents/calculate/Calculate";
import CalculateList from "../contents/calculate/CalculateList";
import CalculateForSelf from "../contents/calculate/CalculateForSelf";

function RouterConfig() {
    const { userInfo } = useContext(UserContext);
    const isLogin = userInfo.isLogin;

    const isLoggedIn = (component) => {
        if (isLogin) {
            return component;
        }
        return <NotFound />;
    };

    // URLの変化を検出する
    const location = useLocation();

    // ページ遷移時にSweetAlertが表示されているとき、SweetAlertのモーダルウィンドウを閉じる
    useEffect(() => {
        if (swal.getState().isOpen) {
            swal.close();
        }
    }, [location]);

    return (
        <BrowserRoutes>
            <Route path='/' element={<Login />} />
            <Route path='/login' element={<Login />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/reissue_password' element={<ReissuePassword />} />
            <Route path='/home' element={isLoggedIn(<Home />)} />
            <Route path='/user' element={isLoggedIn(<User />)} />
            <Route path='/user/edit/:id' element={isLoggedIn(<UserEdit />)} />
            <Route path='/user/show/:id' element={isLoggedIn(<UserShow />)} />
            <Route path='/user_deleted' element={isLoggedIn(<UserDeleted />)} />
            <Route path='/user_deleted/show/:id' element={isLoggedIn(<UserDeletedShow />)} />
            <Route path='/register' element={isLoggedIn(<UserRegister />)} />
            <Route path='/bank_account' element={isLoggedIn(<BankAccount />)} />
            <Route path='/bank_account/edit/:id' element={isLoggedIn(<BankAccountEdit />)} />
            <Route path='/bank_account/show/:id' element={isLoggedIn(<BankAccountShow />)} />
            <Route path='/bank_account_deleted' element={isLoggedIn(<BankAccountDeleted />)} />
            <Route path='/bank_account_deleted/show/:id' element={isLoggedIn(<BankAccountDeletedShow />)} />
            <Route path='/bank_account/register' element={isLoggedIn(<BankAccountRegister />)} />
            <Route path='/proceed' element={isLoggedIn(<Proceed />)} />
            <Route path='/proceed/edit/:id' element={isLoggedIn(<ProceedEdit />)} />
            <Route path='/proceed/show/:id' element={isLoggedIn(<ProceedShow />)} />
            <Route path='/proceed_deleted' element={isLoggedIn(<ProceedDeleted />)} />
            <Route path='/proceed_deleted/show/:id' element={isLoggedIn(<ProceedDeletedShow />)} />
            <Route path='/proceed/register' element={isLoggedIn(<ProceedRegister />)} />
            <Route path='/expense' element={isLoggedIn(<Expense />)} />
            <Route path='/expense/edit/:id' element={isLoggedIn(<ExpenseEdit />)} />
            <Route path='/expense/show/:id' element={isLoggedIn(<ExpenseShow />)} />
            <Route path='/expense_deleted' element={isLoggedIn(<ExpenseDeleted />)} />
            <Route path='/expense_deleted/show/:id' element={isLoggedIn(<ExpenseDeletedShow />)} />
            <Route path='/expense/register' element={isLoggedIn(<ExpenseRegister />)} />
            <Route path='/payee' element={isLoggedIn(<Payee />)} />
            <Route path='/payee/edit/:id' element={isLoggedIn(<PayeeEdit />)} />
            <Route path='/payee/show/:id' element={isLoggedIn(<PayeeShow />)} />
            <Route path='/payee_deleted' element={isLoggedIn(<PayeeDeleted />)} />
            <Route path='/payee_deleted/show/:id' element={isLoggedIn(<PayeeDeletedShow />)} />
            <Route path='/payee/register' element={isLoggedIn(<PayeeRegister />)} />
            <Route path='/travel_expense/register' element={isLoggedIn(<TravelExpenseRegister />)} />
            <Route path='/travel_expense/confirm' element={isLoggedIn(<TravelExpenseConfirm />)} />
            <Route path='/suspense_payment' element={isLoggedIn(<SuspensePayment />)} />
            <Route path='/suspense_payment/show/:id' element={isLoggedIn(<SuspensePaymentShow />)} />
            <Route path='/suspense_payment/confirm/show/:id' element={isLoggedIn(<SuspensePaymentConfirmShow />)} />
            <Route path='/suspense_payment/edit/:id' element={isLoggedIn(<SuspensePaymentEdit />)} />
            <Route path='/suspense_payment/confirm/edit/:id' element={isLoggedIn(<SuspensePaymentConfirmEdit />)} />
            <Route path='/suspense_payment/register' element={isLoggedIn(<SuspensePaymentRegister />)} />
            <Route path='/suspense_payment/confirm' element={isLoggedIn(<SuspensePaymentConfirm />)} />
            <Route path='/monthly_data/register' element={isLoggedIn(<MonthlyDataRegister />)} />
            <Route
                path='/monthly_data/show'
                element={isLoggedIn(
                    <MonthlyDataReferenceProvider>
                        <MonthlyDataTotalShow />
                    </MonthlyDataReferenceProvider>
                )}
            />
            <Route
                path='/monthly_data/reference'
                element={isLoggedIn(
                    <MonthlyDataReferenceProvider>
                        <MonthlyDataReference />
                    </MonthlyDataReferenceProvider>
                )}
            />
            <Route
                path='/monthly_data/reference_for_tax_accountant'
                element={isLoggedIn(
                    <MonthlyDataReferenceProvider>
                        <MonthlyDataReferenceForTaxAccountant />
                    </MonthlyDataReferenceProvider>
                )}
            />
            <Route path='/other' element={isLoggedIn(<Other />)} />
            <Route path='/other/edit/:id' element={isLoggedIn(<OtherEdit />)} />
            <Route path='/other/show/:id' element={isLoggedIn(<OtherShow />)} />
            <Route path='/other_deleted' element={isLoggedIn(<OtherDeleted />)} />
            <Route path='/other_deleted/show/:id' element={isLoggedIn(<OtherDeletedShow />)} />
            <Route path='/other/register' element={isLoggedIn(<OtherRegister />)} />
            <Route path='/closing_process/closing_process' element={isLoggedIn(<ClosingProcess />)} />
            <Route path='/pdf/create_pdf_year' element={isLoggedIn(<CreatePDF />)} />
            <Route path='/pdf/create_pdf_monthly' element={isLoggedIn(<CreatePDFMonthly />)} />
            <Route path='/pdf/create_pdf_tax_account' element={isLoggedIn(<CreatePDFForTaxAccount />)} />
            <Route path='/calculate' element={isLoggedIn(<Calculate />)} />
            <Route path='/calculate_list' element={isLoggedIn(<CalculateList />)} />
            <Route path='/calculate_for_self' element={isLoggedIn(<CalculateForSelf />)} />
            <Route path='*' element={<NotFound />} />
        </BrowserRoutes>
    );
}

export default RouterConfig;
