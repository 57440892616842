//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useState, useEffect, useContext } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import swal from "sweetalert";
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

import { Button, Card } from "@material-ui/core";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import DataTable from "../../components/DataTable";
import BackButton from "../../components/BackButton";
import CloseButton from "../../components/CloseButton";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import processResponse from "../../common/processResponse";
import processErrorResponse from "../../common/processErrorResponse";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// 戻るときの遷移先のパス
const returnPath = "/user";

//見出しの配列定義
const headers = ["ユーザー名", "権限", "照会", "復活"];
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// users の初期状態
const initUsers = { result: [], success: false };
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function UserDeleted() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面遷移用
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // Users の状態を管理する
    const [users, setUsers] = useState(initUsers);

    // Users を表示行に変換したものを保持する
    const [rows, setRows] = useState([]);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 情報を取得しステート users にセットする
    const getUsersData = async () => {
        const url = `users_deleted`;
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        processResponse(res, setUsers, navigate, returnPath);
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 一覧の復活ボタンクリック時、論理削除済みユーザー情報を復活
    const handleOnClickRestore = async (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 復活対象のユーザーの情報を取得
        const user = users.result.find((user) => user.user_id === id);
        // 表示用の名前を宣言
        const userName = user.user_name;

        // 確認のための変数を定義
        let confirm = false;

        // 確認画面を表示
        await swal({
            icon: "info",
            title: "ユーザーを復活しますか？",
            text: userName,
            buttons: ["キャンセル", "実行"],
        }).then((value) => {
            if (value) {
                confirm = true;
            }
        });

        // キャンセルの場合は処理を中断する
        if (!confirm) {
            return;
        }

        //復活
        const url = `users/${id}/restore`;
        const res = await api.put(url);
        //---------------------------issue{No.81} start-----------------------------
        // 成否の確認
        if (res.data.success) {
            // 復活成功時
            // モーダルウィンドウで成功メッセージを表示
            swal(res.data.message, "", "success").then(() => {
                // 復活したユーザーを除外した全ユーザーを配列化
                const newUsers = users.result.filter((user) => user.user_id !== id);
                // ステートを更新
                setUsers({ result: newUsers, success: true });
            });
        } else {
            // 復活失敗時にエラーに応じた処理を行う
            processErrorResponse(res, () => {}, navigate);
        }
        //---------------------------issue{No.81} end-------------------------------
    };

    // 一覧の行クリック時、【削除済み】ユーザー情報表示画面へ
    const handleOnClickShow = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // クリックした行のユーザーのidと一致するユーザーのidをusersステートから変数に代入
        const user = users.result.find((user) => user.user_id === id);
        // userに値が存在する場合
        if (user) {
            // 削除済みのユーザー情報画面へ進む
            navigate(`/user_deleted/show/${id}`);
        }
    };
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面初期表示時にユーザー情報を取得する
    useEffect(() => {
        getUsersData();
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // Usersに応じて、画面表示用の配列を生成
    useEffect(() => {
        if (!users.success) {
            return;
        }

        const newRows = users.result.map((user) => {
            return {
                id: user.user_id,
                isActive: false,
                data: {
                    name: user.user_name,
                    authority: user.authority_name,
                    //---------------------------issue{No.102} start-----------------------------
                    showBtn: (
                        <Button variant='contained' color='primary' onClick={(e) => handleOnClickShow(e, user.user_id)}>
                            照会
                        </Button>
                    ),
                    //---------------------------issue{No.102} end-------------------------------
                    restoreBtn: (
                        <Button variant='contained' onClick={(e) => handleOnClickRestore(e, user.user_id)}>
                            復活
                        </Button>
                    ),
                },
            };
        });
        setRows(newRows);
    }, [users]);
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1>【削除済み】ユーザー情報一覧</h1>
                        <div className='ms-auto'>
                            <CloseButton path={returnPath} />
                        </div>
                    </div>
                    <Card>
                        {/* テーブル部分の定義 */}
                        <DataTable headers={headers} rows={rows} />
                    </Card>
                    <div className='card-footer'>
                        <BackButton path={returnPath} />
                    </div>
                </div>
            </div>
        </div>
    );
}
