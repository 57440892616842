//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import swal from "sweetalert";
import { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import { subMonths } from "date-fns";
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { Button } from "@material-ui/core";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import CreatePDFOutline from "../../components/transaction/CreatePDFOutline";
import { LOAD_ERROR_TITLE, LOAD_ERROR_TEXT } from "../../components/transaction/DownloadReceiptPDF";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { UserContext } from "../../providers/UserProvider";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import arraySortByDate from "../../common/arraySortByDate";
import monthAndYearFormat from "../../common/monthAndYearFormat";
import dateFormat from "../../common/dateFormat";
import { checkAuthorities } from "../../common/checkAuthorities";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import "react-datepicker/dist/react-datepicker.css";
import { debounce } from "lodash";
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
registerLocale("ja", ja); // DatePickerの日本語化に必要

// 先月の日付を取得
const lastMonthString = monthAndYearFormat(subMonths(new Date(), 1));
const INTERVAL_MIL_SEC = 1000; // 待ち時間(ms)
const loadErrorReceipt = "領収書" + LOAD_ERROR_TITLE;
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function CreatePDFForTaxAccount() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { userInfo } = useContext(UserContext);
    const userAuthorityNumber = userInfo.user.authority_number; // 権限
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 権限確認用関数を分割代入
    const { checkTaxAccount } = checkAuthorities;
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 経費明細の先月分の全レコードの値を管理する
    const [travelExpensesTax, setTravelExpensesTax] = useState([]);
    const [upperView, setUpperView] = useState(<></>);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 経費明細の先月分の全レコードを取得
    const getTravelExpensesTax = async (month) => {
        const url = `travel_expenses_target_year_pdf_tax_account/${month}`;
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        if (res.data.success) {
            const tableValue = "travel_expense_"; // カラムを持つテーブル名
            // 取得したいカラム一覧
            const transactionKey = ["target_month_year", "date", "user_id", "file_name", "amount", "name"];
            const dataArray = res.data.result.map((result) => {
                const data = { account_list_name: result.account_list_name }; // 勘定科目を取得
                transactionKey
                    .filter((key) => result[`${tableValue}${key}`])
                    .map((key) => (data[`${key}`] = result[`${tableValue}${key}`]));
                return data;
            });
            if (dataArray.length > 0) {
                setTravelExpensesTax(await arraySortByDate(dataArray, "date"));
            } else {
                setUpperView(
                    <>
                        {LastMonthView}
                        <p>
                            {loadErrorReceipt}。{LOAD_ERROR_TEXT}
                            {GetTravelExpensesButton}
                        </p>
                    </>
                );
                swal({
                    title: loadErrorReceipt,
                    text: LOAD_ERROR_TEXT,
                    icon: "warning"
                });
            }
        }
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________イベントハンドラーの定義_________________________________________________//
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => {
        // 税理士
        if (checkTaxAccount(userAuthorityNumber)) {
            getTravelExpensesTax(lastMonthString);
            setUpperView(LastMonthView);
        }
    }, [lastMonthString]);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//
    //=====================================================JSXここから=====================================================//
    const LastMonthView = (
        <label className='fw-bold me-3'>
            対象月:
            <input type='text' readOnly value={dateFormat(lastMonthString, false, "Y-m").format_date} />
        </label>
    );
    const GetTravelExpensesButton = (
        <Button variant='contained' onClick={debounce(() => getTravelExpensesTax(lastMonthString), INTERVAL_MIL_SEC)}>
            領収書情報再取得
        </Button>
    );

    return (
        <CreatePDFOutline
            upperArea={upperView}
            monthAndYear={lastMonthString.slice(0, 7)}
            transactions={travelExpensesTax}
            pageType={"(税理士用)"}
        />
    );
}
