//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useForm, Controller } from "react-hook-form";
import { parseISO } from "date-fns";
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { TableRow, TableCell, Tooltip } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import PullDownMenu from "../PullDownMenu";
import DataShow from "../../DataShow";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { TravelExpenseContext } from "../../../contents/travel_expense/TravelExpenseConfirm";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import processErrorResponse from "../../../common/processErrorResponse";
import amountToLocaleString from "../../../common/amountToLocaleString";
import displaySlashingDate, { dateFormatSlashing } from "../../../common/displaySlashingDate";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// ステートの初期状態
const initEditedResponse = { result: {}, success: false };

// 入力欄のname一覧を保持
const inputList = ["date", "amount", "name", "account_list_id"];
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function TravelExpenseConfirmRow(props) {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面遷移用
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const {
        isClosing,
        isRowLocked,
        setIsRowLocked,
        dispatchTravelExpenseUpdateData,
        showReceiptTarget,
        setShowReceiptTarget,
        initShowReceiptTarget,
        stringTravelExpense,
    } = useContext(TravelExpenseContext);
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { number, travelExpenseData = {}, payeesMenu = [], accountListsMenu = [], inputHeaders = [] } = props;
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const currentDataRef = useRef({}); // 現在の値を管理

    const [editedResponse, setEditedResponse] = useState(initEditedResponse);

    const [isEditing, setIsEditing] = useState(false); // 編集状態切替のステート
    const [submittable, setSubmittable] = useState(false); // 編集が行われて、submit可能になった場合にtrue

    const [formattedEditData, setFormattedEditData] = useState([]); // 表示する編集データを管理する

    const validationErrorsRef = useRef({}); // 最新のformState.errorsの値を保持する
    const [loaded, setLoaded] = useState(false); // 読み込み完了のステータスを管理
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 編集ボタン押下時の処理
    const handleOnClickEdit = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        switchEdit(true); // 対象の行を編集状態にする
    };

    // 編集状態で取消ボタン押下時の処理
    const handleOnClickClear = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        switchEdit(false); // 編集状態を解除する

        // 領収書を閉じる
        setShowReceiptTarget(initShowReceiptTarget);

        // 合計値に使用する金額のデータを更新する
        amountUpdate();
    };

    // 合計値に使用する金額のデータを更新する
    const amountUpdate = () => {
        // 合計用の値をセット
        const currentData = currentDataRef.current;
        const id = currentData.id;
        const amountValue = currentData.amount;
        dispatchTravelExpenseUpdateData({
            type: "update",
            id: id,
            data: { amount: amountValue },
        });
    };

    // 編集状態で保存ボタン押下時の処理
    const handleOnClickSubmit = async (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        if (Object.keys(validationErrorsRef.current).length > 0) {
            swal({ title: "", text: "入力の内容を見直してください", icon: "warning" });
            return;
        }

        const currentData = currentDataRef.current;
        const newData = getValues();

        const showData = shownEditableDataArray(inputList, inputHeaders, newData);
        // ダイアログ画面に表示するデータ一覧の要素を生成
        const element = makeElementForDialogShow(showData);

        // 確認用の変数を定義
        let confirm = false;

        // 確認画面を表示
        await swal({
            title: "確認",
            text: "以下の内容で更新します。",
            content: element,
            buttons: ["戻る", "更新"],
        }).then((value) => {
            if (value) {
                confirm = true;
            }
        });

        // 戻る場合は処理を中断する
        if (!confirm) {
            return;
        }
        // 送信するデータを作成
        const requestData = {};
        inputList.map((input) => (requestData[input] = newData[input]));
        const id = currentData.id;

        // 更新
        const url = `travel_expenses/${id}`;
        const res = await api.put(url, requestData);
        // 成否の確認
        if (res.data.success) {
            // 登録成功時
            await swal("更新しました", "", "success").then(() => {
                const result = res.data.result;
                const isConfirmed = getValues("is_confirmed");
                const data = {
                    id: result.travel_expense_id,
                    is_confirmed: isConfirmed,
                    number: number,
                    date: result.travel_expense_date,
                    user_id: result.user_id,
                    amount: result.travel_expense_amount,
                    name: result.travel_expense_name,
                    account_list_id: result.travel_expense_account_list_id,
                    file_name: result.travel_expense_file_name,
                };

                setEditedResponse({ result: data, success: true });
                setSubmittable(false);
            });

            handleOnClickClear(e); // 編集状態を解除して領収書写真を閉じる
        } else {
            // 登録失敗時にエラーに応じた処理を行う
            processErrorResponse(res, setEditedResponse, navigate);
        }
    };

    // 領収書表示コンポーネントを表示する
    const handleOnClickReceiptOpen = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        setShowReceiptTarget(
            showReceiptTarget.type === stringTravelExpense && showReceiptTarget.number === number
                ? initShowReceiptTarget
                : { type: stringTravelExpense, number: number }
        );
    };
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => {
        // 読み込み完了とする
        setLoaded(true);
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 編集状態に切り替えたときの処理
    useEffect(() => {
        if (loaded) {
            const currentData = currentDataRef.current;
            if (isEditing && Object.keys(currentData).length > 0) {
                clearErrors();
                inputList.map((input) =>
                    setValue(input, currentData[input], {
                        shouldValidate: false,
                    })
                );
            }
        }
    }, [isEditing]);

    // 編集内容保存時にDialogに表示するレンダリング済みの要素を生成する関数
    const makeElementForDialogShow = (showData) => {
        // 空要素を生成
        const wrapper = document.createElement("div");
        // 空要素内に一時レンダリング
        ReactDOM.render(<DataShow data={showData} />, wrapper);
        // レンダリングされたコンポーネントを抽出
        const newElement = wrapper.firstChild;
        // 抽出した要素を返す
        return newElement;
    };

    // react-hook-formの使用する機能を宣言
    const { register, formState, clearErrors, setValue, getValues, control, watch, trigger } = useForm();

    // 受け取ったデータから、初回レンダリング時の値をRefオブジェクトに保管する
    useEffect(() => {
        if (Object.keys(travelExpenseData).length > 0) {
            currentDataRef.current = travelExpenseData;
        }
    }, [travelExpenseData]);

    // 編集後の値をRefオブジェクトに保管する
    useEffect(() => {
        if (Object.keys(editedResponse.result).length > 0) {
            currentDataRef.current = { ...editedResponse.result };
            // 合計値用の値を更新する
            amountUpdate();
        }
    }, [editedResponse]);

    useEffect(() => {
        const currentData = currentDataRef.current;
        if (Object.keys(currentData).length > 0) {
            // 確認の状態を更新
            setValue("is_confirmed", currentData.is_confirmed, {
                shouldValidate: true,
            });
            setFormattedEditData(shownEditableDataArray(inputList, inputHeaders, currentData));
        }
    }, [currentDataRef.current]);

    // 内容が変更されたかを監視
    useEffect(() => {
        const subscription = watch(async (value, { name, type }) => {
            if (type === "change") {
                // 現在の値を定数に格納
                const currentData = currentDataRef.current;
                const id = currentData.id;

                switch (name) {
                    case "is_confirmed": // 確認変更時
                        const isConfirmed = value.is_confirmed;
                        dispatchTravelExpenseUpdateData({
                            type: "update",
                            id: id,
                            data: { is_confirmed: isConfirmed },
                        });
                        setValue("is_confirmed", isConfirmed, {
                            shouldValidate: true,
                        });
                        break;
                    case "date":
                        dispatchTravelExpenseUpdateData({
                            type: "update",
                            id: id,
                            data: { date: value.date },
                        });
                        setValue("date", value.date, {
                            shouldValidate: true,
                        });
                        break;
                    case "amount": // 金額変更時
                        if (value.amount.length > 0) {
                            const amountValue = Number(value.amount);
                            dispatchTravelExpenseUpdateData({
                                type: "update",
                                id: id,
                                data: { amount: amountValue },
                            });
                        }
                        break;
                    case "payee_format": // プルダウンメニューから支払先を選択した時
                        const payeeFormatValues = value["payee_format"];
                        const payeeName = payeeFormatValues.payeeName;
                        const accountListId = payeeFormatValues.accountListId;
                        // インプットに値をセット
                        setValue("name", payeeName, { shouldValidate: true });
                        setValue("account_list_id", accountListId, {
                            shouldValidate: true,
                        });
                        setValue("payee_format", "", { shouldValidate: true });
                        break;
                }

                await trigger(); // バリデーションを実行

                // 各入力の変更がある場合に、submit可能にする
                const values = getValues(); // 現在のフォーム全体のinputの値
                delete values.is_confirmed; // これらの変更は対象にしない
                delete values.payee_format;
                // 編集前の初期値から変更されているフォームの値が1つでもあればsubmitを可能にする
                setSubmittable(Boolean(Object.keys(values).find((key) => currentData[key] != values[key]))); // 数値が対象になる場合があるので厳密比較をしない
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    // react-hook-formのformStateの更新時に、バリデーションエラーをRefオブジェクトに保持する
    useEffect(() => {
        if (loaded) {
            validationErrorsRef.current = formState.errors;
        }
    }, [formState.errors]);

    // 編集状態を切り替える処理
    const switchEdit = (boolean = false) => {
        // 行の編集状態を切り替える
        setIsEditing(boolean);
        // 他の操作の停止状態を切り替える
        setIsRowLocked(boolean);
    };

    // データを画面に表示する為に列名に対応する書式に変換します
    const convertDataShownFormat = (column = "", data = null) => {
        switch (column) {
            case "date": // 日付
                // dataがDate型以外ならDate型に変換する
                if (isValidDate(data)) {
                    // 表示用の日付を作成
                    return displaySlashingDate(isValidDate(data));
                }
                break;
            case "amount": // 金額
                return amountToLocaleString(data);
                break;
            case "account_list_id": // 勘定科目
                // 勘定科目名を変換してステートに持たせる
                if (accountListsMenu.length > 0) {
                    const formattedAccountName = accountListsMenu.find((account) => account.value === data).label;
                    return formattedAccountName;
                }
                break;
            default:
                return data;
                break;
        }
    };
    // 編集可能な列を画面表示する為のデータの配列を返します
    const shownEditableDataArray = (
        editColumns = inputList,
        editLabels = inputHeaders,
        rowData = currentDataRef.current
    ) =>
        editColumns.map((column, index) => ({
            label: editLabels[index],
            value: convertDataShownFormat(column, rowData[column]),
        }));

    // 日付型の有効な値か否かを判定します
    const isValidDate = (date = Date) => {
        if (toString.call(date).slice(8, -1) !== "Date") {
            // 日付型以外なら日付型に変換する
            date = parseISO(date);
        }
        // 有効な日付型ならば返り値に渡します
        return !Number.isNaN(date.getTime()) ? date : false;
    };
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    const isStableElement = (isRowLocked && !isEditing) || travelExpenseData.is_paid || getValues("is_confirmed");

    const StableTableCell = ({ isRowLocked, children }) => (
        <TableCell className={`border${isRowLocked && " text-muted"}`} align='center' padding='none'>
            {children}
        </TableCell>
    );

    // 編集可能なデータの非編集時の表示列
    const StableEditDataCells = formattedEditData.map((data, index) => (
        <StableTableCell isRowLocked={isRowLocked} children={data.value} key={`${inputList[index]}_${number}`} />
    ));

    // 編集時の要素の外枠
    const EditingTableCell = ({ name = "", children }) => (
        <TableCell className='border' align='center' padding='none'>
            {/* react-hook-formのバリデーションによるエラーメッセージ */}
            <Tooltip arrow={true} title={formState.errors[name] ? formState.errors[name]?.message : ""}>
                <div className={formState.errors[name] ? "border border-danger border-2 rounded rounded-3" : ""}>
                    {children}
                </div>
            </Tooltip>
        </TableCell>
    );

    // 編集時のpulldownList要素
    const EditingPulldownCell = (name = "", menu = [{ label: "-", value: null }], defaultValue = "") => (
        <EditingTableCell name={name}>
            <Controller
                name={name}
                defaultValue={defaultValue}
                control={control}
                rules={{ required: "必須項目です" }}
                render={({ field, fieldState }) => <PullDownMenu field={field} menuItems={menu} />}
            />
        </EditingTableCell>
    );

    return (
        <TableRow
            className={`align-baseline w-auto${
                (isEditing ||
                    (showReceiptTarget.type === stringTravelExpense && showReceiptTarget.number === number)) &&
                " bg-info"
            }`}>
            <TableCell className='border p-1' align='center' padding='none'>
                <input
                    type='checkbox'
                    disabled={isEditing || isRowLocked || travelExpenseData.is_paid}
                    {...register("is_confirmed")}
                />
            </TableCell>
            <TableCell className={`border${isRowLocked && " text-muted"} p-1`} align='center' padding='none'>
                {number}
            </TableCell>
            {!isEditing || travelExpenseData.is_paid ? (
                StableEditDataCells
            ) : (
                <>
                    <EditingTableCell name='date'>
                        <input
                            className='form-control form-control-sm'
                            type='date'
                            // onChange={handleOnChangeDate}
                            {...register("date", {
                                required: {
                                    value: true,
                                    message: "必須項目です",
                                },
                            })}
                        />
                    </EditingTableCell>
                    <TableCell className='border p-1' align='center' padding='none'>
                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                        <Tooltip arrow={true} title={formState.errors.amount ? formState.errors.amount?.message : ""}>
                            <div
                                className={
                                    formState.errors.amount ? "border border-danger border-2 rounded rounded-3" : ""
                                }>
                                <input
                                    className='form-control form-control-sm w-auto ms-auto'
                                    type='number'
                                    min='1'
                                    max='9999999'
                                    inputMode='numeric'
                                    pattern='[0-9]*'
                                    {...register("amount", {
                                        required: {
                                            value: true,
                                            message: "必須項目です",
                                        },
                                        min: {
                                            value: 1,
                                            message: "1以上を入力",
                                        },
                                        max: {
                                            value: 9999999,
                                            message: "入力可能な額を超えています",
                                        },
                                    })}
                                />
                            </div>
                        </Tooltip>
                    </TableCell>
                    <TableCell className='border p-1' align='center' padding='none'>
                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                        <Tooltip arrow={true} title={formState.errors.name ? formState.errors.name?.message : ""}>
                            <div
                                className={
                                    formState.errors.name ? "border border-danger border-2 rounded rounded-3" : ""
                                }>
                                <div className='d-flex'>
                                    <input
                                        className='form-control form-control-sm w-auto'
                                        type='text'
                                        {...register("name", {
                                            required: {
                                                value: true,
                                                message: "必須項目です",
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: "文字数の上限を超えています",
                                            },
                                        })}
                                    />
                                    <Controller
                                        name='payee_format'
                                        defaultValue={""}
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <PullDownMenu field={field} menuItems={payeesMenu} />
                                        )}
                                    />
                                </div>
                            </div>
                        </Tooltip>
                    </TableCell>
                    {EditingPulldownCell("account_list_id", accountListsMenu)}
                </>
            )}
            <TableCell className={`border${isRowLocked && " text-muted"} p-1`} align='center' padding='none'>
                {travelExpenseData.is_paid ? "済" : "未"}
            </TableCell>
            <TableCell className='border p-1' align='center' padding='none'>
                <button
                    className={`btn btn-sm w-100 px-3 opacity-100${
                        isRowLocked && !isEditing ? " text-muted btn-light" : " btn-primary"
                    }`}
                    type='button'
                    disabled={isRowLocked && !isEditing}
                    onClick={(e) => handleOnClickReceiptOpen(e)}>
                    領収書
                </button>
            </TableCell>
            {!isClosing && (
                <TableCell className='border' align='center' padding='none'>
                    <div className='px-1'>
                        {isEditing ? (
                            <>
                                <button
                                    className={`btn btn-sm px-3 me-2 opacity-100${
                                        !submittable ? " text-success btn-light" : " btn-success"
                                    }`}
                                    type='button'
                                    disabled={!submittable}
                                    onClick={(e) => handleOnClickSubmit(e)}>
                                    更新
                                </button>
                                <button
                                    className='btn btn-sm btn-secondary px-3'
                                    type='button'
                                    onClick={(e) => handleOnClickClear(e)}>
                                    取消
                                </button>
                            </>
                        ) : isStableElement ? (
                            <>-</>
                        ) : (
                            <button
                                className='btn btn-sm px-3 opacity-100  btn-secondary'
                                type='button'
                                disabled={isStableElement}
                                onClick={(e) => handleOnClickEdit(e)}>
                                編集
                            </button>
                        )}
                    </div>
                </TableCell>
            )}
        </TableRow>
    );
}
