import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) =>
    createStyles({
        labelCell: {
            backgroundColor: "#e3f7fe",
            width: "200px"
        },
        valueCell: {}
    })
);

export default function DataShow(props) {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //親コンポーネントから表示するデータを受け取る
    const { data = [] } = props;

    //---------------------------issue{No.357} start-----------------------------
    // 改行文字を反映させる処理を行ない、反映させるためのステート
    const [showData, setShowData] = useState([]);

    // propsが取得されてから、ステートを更新する
    // useEffect(() => {
    //     if (data.length >= 1) {
    //         setShowData(data);
    //     }
    // }, [data]);

    // 改行文字を改行タグ(<br />)に変換する処理
    useEffect(() => {
        if (data.length >= 1) {
            const replaceNewline = () => {
                // ステートを複製
                let newObj = JSON.parse(JSON.stringify(data));

                for (let index in data) {
                    let isString = false; // 文字列判定
                    let val = data[index].value; // 表示する値
                    if (typeof val === "string") {
                        isString = true;
                    }
                    if (isString && val != null) {
                        if (val.match("\n")) {
                            // 改行文字が含まれている場合に、その改行文字を残したまま区切って配列化したものを
                            // 繰り返し処理で要素に変換していく
                            const replaceValue = val.replace(/(\n){3,}/g, "\n\n"); // "\n"が3回以上続いた場合、"\n\n"(改行2回分)に変換
                            const replaceValue2 = replaceValue.split(/(\n)/); // "\n"で区切って配列化（"\n"は残したまま）
                            const replaceValue3 = replaceValue2.map((item, index) => {
                                return <React.Fragment key={index}>{item.match(/\n/) ? <br /> : item}</React.Fragment>;
                            }); // "\n"を<br />に変換

                            // 出来てきたものをさらに要素に包む
                            const newValue = (value) => {
                                return <React.Fragment>{value}</React.Fragment>;
                            };

                            // 対象のオブジェクトのvalueを書き換える
                            newObj[index].value = newValue(replaceValue3);
                        }
                    }
                }

                // ステートを更新
                setShowData(newObj);
            };

            // 上記の関数を実行
            replaceNewline();
        }
    }, [data]);
    //---------------------------issue{No.357} end-------------------------------

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
                <TableBody>
                    {Object.keys(showData).length >= 1 &&
                        showData.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell className={classes.labelCell}>{item.label}</TableCell>
                                <TableCell className={classes.valueCell}>{item.value}</TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
