//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import swal from "sweetalert";
import { parseISO, subMonths } from "date-fns";
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import CreatePDFOutline from "../../components/transaction/CreatePDFOutline";
import {LOAD_ERROR_TITLE, LOAD_ERROR_TEXT} from "../../components/transaction/DownloadReceiptPDF";
import SelectMonthAndYearMenu from "../../components/transaction/monthly_data/SelectMonthAndYearMenu";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { UserContext } from "../../providers/UserProvider";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import arraySortByDate from "../../common/arraySortByDate";
import monthAndYearFormat from "../../common/monthAndYearFormat";
import { checkAuthorities } from "../../common/checkAuthorities";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import "react-datepicker/dist/react-datepicker.css";
import { debounce } from "lodash";
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
const nowMonthAndYear = new Date(); // 今月の日付を取得
const INTERVAL_MIL_SEC = 1000; // 待ち時間(ms)
const loadErrorReceipt = "領収書" + LOAD_ERROR_TITLE;
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function CreatePDFMonthly() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { userInfo } = useContext(UserContext);
    const userAuthorityNumber = userInfo.user.authority_number; // 権限
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 権限確認用関数を分割代入
    const { checkAccountant, checkTaxAccount } = checkAuthorities;
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 経費明細の先月分の全レコードの値を管理する
    const [travelExpenses, setTravelExpenses] = useState({
        travelExpense: [],
        monthAndYear: nowMonthAndYear
    });

    // 最も古いレコードのある年月を管理する
    const [oldestMonthAndYear, setOldestMonthAndYear] = useState(null);
    // datePickerの選択済みの値を管理する
    const [selectedMonthAndYear, setMonthAndYear] = useState(nowMonthAndYear);
    const [pageType, setPageType] = useState("");
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 最も古い経費明細の年月の値を取得
    const getTravelExpenseOldestAll = async () => {
        const res = await api.get(`travel_expenses_oldest_all`);
        // 成功時に指定したユーザの最古のデータの年月を返す
        if (res.data.success && res.data.result.travel_expense_oldest_date) {
            setOldestMonthAndYear(
                parseISO(res.data.result.travel_expense_oldest_date)
            );
        }
        return false;
    };

    // 経費明細の指定した月の全レコードを取得
    const getTravelExpenses = async (month) => {
        const url = `travel_expenses_target_year_pdf_tax_account/${month}`;
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        if (res.data.success) {
            const tableValue = "travel_expense_"; // カラムを持つテーブル名
            // 取得したいカラム一覧
            const transactionKey = [
                "target_month_year",
                "date",
                "user_id",
                "file_name",
                "amount",
                "name"
            ];
            const dataArray = res.data.result.map((result) => {
                const data = { account_list_name: result.account_list_name }; // 勘定科目を取得
                transactionKey
                    .filter((key) => result[`${tableValue}${key}`])
                    .map(
                        (key) =>
                            (data[`${key}`] = result[`${tableValue}${key}`])
                    );
                return data;
            });
            if (dataArray.length > 0) {
                setTravelExpenses({
                    travelExpense: arraySortByDate(dataArray, "date"),
                    monthAndYear: month.slice(0, 7)
                });
            } else {
                swal({
                    title: loadErrorReceipt,
                    text: LOAD_ERROR_TEXT,
                    icon: "warning"
                });
            }
        }
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________イベントハンドラーの定義_________________________________________________//
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => {
        // 経理担当者以上
        if (checkAccountant(userAuthorityNumber)) {
            getTravelExpenseOldestAll();
            setPageType("(月間)");
        }
        // 税理士
        else if (checkTaxAccount(userAuthorityNumber)) {
            // 先月の日付を年月の最小値に指定します
            setOldestMonthAndYear(subMonths(nowMonthAndYear, 1));
        }
    }, [nowMonthAndYear]);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//
    //=====================================================JSXここから=====================================================//
    // 領収書写真を取得してPDF作成
    const GetTravelExpensesMonthAndYear = (
        <span className="d-flex">
            <SelectMonthAndYearMenu
                startDate={selectedMonthAndYear}
                setStartDate={setMonthAndYear}
                minMonthAndYear={oldestMonthAndYear}
            />
            <button
                className="ms-3 btn btn-secondary"
                type="button"
                disabled={!selectedMonthAndYear}
                onClick={debounce(
                    () => getTravelExpenses(monthAndYearFormat(selectedMonthAndYear)),
                    INTERVAL_MIL_SEC
                )}>
                PDF作成開始
            </button>
        </span>
    );
    return (
        <CreatePDFOutline
            upperArea={GetTravelExpensesMonthAndYear}
            monthAndYear={travelExpenses.monthAndYear}
            transactions={travelExpenses.travelExpense}
            pageType={pageType}
        />
    );
}
