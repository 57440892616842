//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useMemo, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { Table, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { calculateTotalAmount, calculateDifference, calculateGrossProfit, displayAmount } from "./MonthlyDataColumn";
import { calculateDeduction } from "./OutputSingleMonthSpreadsheetButton";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { monthlyDataReferenceContext } from "../../../providers/MonthlyDataReferenceProvider";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import amountToLocaleStringForMonthlyData from "../../../common/amountToLocaleStringForMonthlyData";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// MUIのTableRowのstyleを調整
const StyledTableRow = withStyles({
    "@global": { ".MuiTableRow-root": { height: "40px" } }
})(TableRow);

// スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        noticeTextArea: {
            resize: "none",
            width: "100px",
            height: "120px"
        }
    })
);
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function MonthlyDataColumnForTaxAccountant(props) {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 定義したスタイルを利用するための設定
    const classes = useStyles();
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { proceedsList, expensesList, bankAccountsList, othersList } = useContext(monthlyDataReferenceContext);
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { targetMonth, targetData, travelExpensesData, noticesData, withdrawalData } = props;
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 売上合計
    const [totalSales, setTotalSales] = useState({ done: false });
    // 領収書合計
    const [totalExpense, setTotalExpense] = useState({ done: false });
    // 月残高合計
    const [totalBalance, setTotalBalance] = useState({ done: false });
    // 差額
    const [difference, setDifference] = useState({ done: false });
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 各種計算を行う
    useEffect(() => {
        // 売上の合計
        setTotalSales(makeTotalAmountObj(1));

        // 領収書の合計
        setTotalExpense(makeTotalAmountObj(2));

        // 月残高の合計
        setTotalBalance(makeTotalAmountObj(3));
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // categoryIdの対応 => 1: 売上先ID, 2: 経費先ID, 3: 資金口座ID, 4: 予納その他経費, 5:小口繰越現金, 6: 翌月繰越現預金, 7: その他
    // 各合計値を計算し、ステートに格納する形にする
    const makeTotalAmountObj = (categoryId) => {
        return {
            target: calculateAmount(targetData, categoryId),
            done: true
        };
    };

    // 各種データの合計値を計算
    const calculateAmount = (data, categoryId) => {
        if (data[categoryId].length > 0) {
            // 金額のみの配列を作成
            const amounts = data[categoryId].map((item) => {
                return item.monthly_data_amount;
            });
            // 合計値を計算
            const total = amounts.reduce((accumulator, currentValue) => {
                return accumulator + currentValue;
            });
            return total;
        } else {
            return null;
        }
    };

    // 経費合計(資金口座の経費合計金額 + 領収書合計)の計算結果をmemo化
    const totalExpensesAmount = useMemo(() => {
        let totalExpensesAmountTarget = null;
        if (totalExpense.done && Object.keys(travelExpensesData).length > 0) {
            // 選択年度の経費合計
            totalExpensesAmountTarget = calculateTotalAmount(totalExpense.target, travelExpensesData.target);
        }
        return { target: totalExpensesAmountTarget };
    }, [totalExpense.done, travelExpensesData]);

    // 粗利益(売上合計金額 - 経費合計金額 - 予納その他経費)を計算しmemo化します
    const grossProfit = useMemo(() => {
        if (totalSales.done) {
            return calculateGrossProfit(totalSales, totalExpensesAmount, targetData[4][0], "target");
        }
        return null;
    }, [totalSales.done, totalExpensesAmount]);

    // 差額の計算を行い、ステートに格納
    useEffect(() => {
        if (totalBalance.done) {
            setDifference({
                target: calculateDifference(targetData[6][0], totalBalance.target),
                done: true
            });
        }
    }, [totalBalance]);

    // 差引現金残(小口繰越現金 + 現金引き出し合計 - 領収書合計)を計算しmemo化します
    const deductionAmount = useMemo(() => {
        if (Object.keys(travelExpensesData).length > 0 && Object.keys(withdrawalData).length > 0) {
            return calculateDeduction(
                targetData[5][0]?.monthly_data_amount,
                withdrawalData.target,
                travelExpensesData.target
            );
        }
        return null;
    }, [travelExpensesData, withdrawalData]);

    // 一行のみのデータの表示についての表示用の文字列への変換を行う関数
    const displaySingleAmount = (data) => {
        if (data) {
            return amountToLocaleStringForMonthlyData(data.monthly_data_amount);
        } else {
            return "";
        }
    };

    // 正負の符号をチェックし、必要に応じて色付けする関数
    const checkAmountSign = (value) => {
        const str = amountToLocaleStringForMonthlyData(value).replace("-", "");
        if (Math.sign(value) === -1) {
            return <span className='text-danger'>{`(${str})`}</span>;
        } else {
            return str;
        }
    };
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    const AmountTableRow = ({ children }) => (
        <StyledTableRow>
            <TableCell align='right'>{checkAmountSign(children ? Number(children) : null)}</TableCell>
        </StyledTableRow>
    );

    return (
        <div className='border-start border-start-1 border-secondary'>
            <Table className='table table-bordered text-nowrap m-0'>
                <TableHead className='caption-top'>
                    <TableRow>
                        {/* 対象年月 */}
                        <TableCell className='fw-bold' align='center'>
                            {targetMonth}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* 売上 START */}
                    <StyledTableRow className='table-danger'>
                        <TableCell align='right'></TableCell>
                    </StyledTableRow>
                    {/* 売上先一覧を展開 */}
                    {proceedsList.map((item, index) => {
                        return (
                            <StyledTableRow key={index}>
                                {/* 今年度の売上先一覧 */}
                                <TableCell align='right'>{displayAmount(targetData, 1, item.id)}</TableCell>
                            </StyledTableRow>
                        );
                    })}
                    <StyledTableRow>
                        {/* 今年度の売上合計 */}
                        <TableCell align='right'>
                            {amountToLocaleStringForMonthlyData(totalSales.target)
                                ? amountToLocaleStringForMonthlyData(totalSales.target)
                                : 0}
                        </TableCell>
                        {/* 前年比 */}
                    </StyledTableRow>
                    {/* 売上 END */}
                    {/* 経費 START */}
                    <StyledTableRow className='table-info'>
                        {/* 色付きの区切りの行 */}
                        <TableCell align='right'></TableCell>
                    </StyledTableRow>
                    {/* 経費先一覧を展開 */}
                    {expensesList.map((item) => (
                        <StyledTableRow key={item.id}>
                            {/* 今年度の経費先一覧 */}
                            <TableCell align='right'>{displayAmount(targetData, 2, item.id)}</TableCell>
                        </StyledTableRow>
                    ))}
                    {/* 選択年月の領収書合計 */}
                    <AmountTableRow>{travelExpensesData.target}</AmountTableRow>
                    {/* 選択年月の経費合計金額 */}
                    <AmountTableRow>{totalExpensesAmount.target}</AmountTableRow>
                    <StyledTableRow>
                        {/* 今年度の予納その他経費 */}
                        <TableCell align='right'>
                            {displaySingleAmount(targetData[4][0]) ? displaySingleAmount(targetData[4][0]) : 0}
                        </TableCell>
                    </StyledTableRow>
                    {/* 今年度の粗利益 */}
                    <AmountTableRow>{grossProfit}</AmountTableRow>
                    {/* 経費 END */}
                    {/* 現金出納帳 START */}
                    <StyledTableRow className='table-success'>
                        {/* 色付きの区切りの行 */}
                        <TableCell align='right'></TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        {/* 今年度の小口繰越現金 */}
                        <TableCell align='right'>
                            {displaySingleAmount(targetData[5][0]) ? displaySingleAmount(targetData[5][0]) : 0}
                        </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        {/* 今年度の現金引き出し */}
                        <TableCell align='right'>
                            {amountToLocaleStringForMonthlyData(
                                withdrawalData.target ? Number(withdrawalData.target) : null
                            )
                                ? amountToLocaleStringForMonthlyData(
                                      withdrawalData.target ? Number(withdrawalData.target) : null
                                  )
                                : 0}
                        </TableCell>
                    </StyledTableRow>
                    {/* 選択年月の領収書合計 */}
                    <AmountTableRow>{travelExpensesData.target}</AmountTableRow>
                    {/* 選択年月の差引現金残 */}
                    <AmountTableRow>{deductionAmount}</AmountTableRow>
                    {/* 資金 START */}
                    <StyledTableRow className='table-success'>
                        {/* 色付きの区切り行 */}
                        <TableCell align='right'></TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        {/* 今年度の翌月繰越現預金 */}
                        <TableCell align='right'>
                            {displaySingleAmount(targetData[6][0]) ? displaySingleAmount(targetData[6][0]) : 0}
                        </TableCell>
                    </StyledTableRow>
                    {/* 資金口座一覧を展開 */}
                    {bankAccountsList.map((item, index) => {
                        return (
                            <StyledTableRow key={index}>
                                {/* 今年度の資金口座一覧 */}
                                <TableCell align='right'>{displayAmount(targetData, 3, item.id)}</TableCell>
                            </StyledTableRow>
                        );
                    })}
                    <StyledTableRow>
                        {/* 今年度の月残高合計 */}
                        <TableCell align='right'>
                            {amountToLocaleStringForMonthlyData(totalBalance.target)
                                ? amountToLocaleStringForMonthlyData(totalBalance.target)
                                : 0}
                        </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        {/* 今年度の差額 */}
                        <TableCell align='right'>
                            {checkAmountSign(difference.target) ? checkAmountSign(difference.target) : 0}
                        </TableCell>
                    </StyledTableRow>
                    {/* 資金 END */}
                    {/* その他 END */}
                    <StyledTableRow className='table-secondary'>
                        {/* 色付きの区切り行 */}
                        <TableCell align='right'></TableCell>
                    </StyledTableRow>
                    {/* その他一覧を展開 */}
                    {othersList.map((item, index) => {
                        return (
                            <StyledTableRow key={index}>
                                {/* 今年度のその他一覧 */}
                                <TableCell align='right'>{displayAmount(targetData, 7, item.id)}</TableCell>
                            </StyledTableRow>
                        );
                    })}
                    <StyledTableRow className='table-primary'>
                        {/* 色付きの区切り行 */}
                        <TableCell align='right'></TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        {/* 今年度の特記事項 */}
                        <TableCell align='right'>
                            <textarea
                                className={classes.noticeTextArea}
                                readOnly
                                value={noticesData?.target ?? ""}></textarea>
                        </TableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        </div>
    );
}
