import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { UserContext } from "../providers/UserProvider";

import MenuButton from "../components/MenuButton";
import MenuDown from "../components/MenuDown";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            padding: "9px",
            fontSize: "1.6rem",
        },
        loggedInTitle: {
            padding: "9px",
            fontSize: "1.6rem",
            cursor: "pointer",
        },
        menu: {
            padding: "9px",
        },
        navTop: {
            display: "flex",
            justifyContent: "space-between",
            // marginBottom: ".5rem"
            // borderBottom: "1px solid #666"
        },
    })
);

function Header() {
    const [isTaxAccount, setIsTaxAccount] = useState(false); // PDF作成ページ表示（税理士用）フラグ
    const [isOverAccountant, setIsOverAccountant] = useState(false); // PDF作成ページ表示フラグ
    const { userInfo } = useContext(UserContext);
    const isLogin = userInfo.isLogin;

    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移するため
    const navigate = useNavigate();

    const handleClick = (e, path) => {
        // イベント伝播阻止
        e.stopPropagation();
        navigate(path);
    };

    // 一般社員は非表示
    useEffect(() => {
        const isLogin = userInfo.isLogin; // ログイン状態を取得
        if (isLogin) {
            const taxAccountAuthorityId = 5;
            const employeeAuthorityId = 4;
            const loginUserAuthorityId = userInfo.user.user_authority_id;
            // ログインユーザーの権限が税理士時にPDF表示フラグを変更
            if (loginUserAuthorityId == taxAccountAuthorityId) {
                setIsTaxAccount(true);
            } else {
                setIsTaxAccount(false);
            }

            // ログインユーザーの権限が一般社員以下時にPDF表示フラグを変更
            if (loginUserAuthorityId < employeeAuthorityId) {
                setIsOverAccountant(true);
            } else {
                setIsOverAccountant(false);
            }
        }
    }, [userInfo]);

    return (
        <>
            {/* -------------------- issue507start -------------------- */}
            {isLogin ? (
                <div className={classes.loggedInTitle} onClick={(e) => handleClick(e, "/home")}>
                    経費管理システム
                </div>
            ) : (
                <div className={classes.title}>経費管理システム</div>
            )}
            <div className={classes.menu}>
                <div className={classes.navTop}>
                    <MenuButton category='ログイン' />
                    <MenuDown category='仮払い' />
                    <MenuDown category='経費' />
                    <MenuDown category='月次損益' />
                    {!isOverAccountant && !isTaxAccount && <MenuButton category='未精算一覧' />}
                    {isOverAccountant && (
                        <>
                            <MenuButton category='締め処理' />
                            <MenuDown category='精算' />
                            <MenuDown category='PDF作成' />
                        </>
                    )}
                    {isTaxAccount && (
                        <>
                            <MenuDown category='月次損益（税理士用）' />
                            <MenuButton category='PDF作成（税理士用）' />
                        </>
                    )}
                    <MenuDown category='基礎情報' />
                    <MenuDown category='アカウント' />
                </div>
            </div>
        </>
    );
}

export default Header;
