//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import OutputExcel from "../../OutputExcel";
import {
    calculateBalanceAmountRow,
    calculateTotalAmountRow,
    calculateGrossProfitRow,
    variableListAndRowPerCategory,
    pushMonthlyDeployRowByCategory
} from "./OutputSingleMonthSpreadsheetButton";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { monthlyDataReferenceContext } from "../../../providers/MonthlyDataReferenceProvider";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import monthlyDataLeftHeadlines from "../../../common/monthlyDataLeftHeadlines";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import "react-datepicker/dist/react-datepicker.css";
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
// 選択した年月
export default function OutputMonthlySpreadsheetButton({
    months = [], // 選択した年度の年月一覧
    monthlyDeployData = {},
    withDraw = [], // 現金引き出し合計金額
    totalTravelExpenses = [], // 領収書合計
    notices = [], // 特記事項
    setMonthlyList, // 呼び出し元で月次損益データを格納する処理
    selectedFiscalYear = "target", // 選択年度かその前年度か
    selectedYear = new Date().getFullYear(), // 選択年度
    fileType = "Excel"
}) {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 要素数が可変なカテゴリについてキーがカテゴリIDに,値がやり取り金額を格納する為の行列にしている連想配列を取得します
    const variableListAndRow = variableListAndRowPerCategory(useContext(monthlyDataReferenceContext));
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const advancePaymentRow = []; // 予納その他経費:categoryID === 4
    const pettyCashRow = []; // 小口繰越現金:categoryID === 5
    const nextMonthCashRow = []; // 翌月繰越現預金:categoryID === 6
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // csv, Excelに書き出すためのデータ行列を管理
    const [spreadSheetData, setSpreadSheetData] = useState([]);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => makeSpreadSheetData(), []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const makeSpreadSheetData = () => {
        monthlyDeployData.map((monthDeploy) =>
            pushMonthlyDeployRowByCategory(
                monthDeploy,
                variableListAndRow,
                advancePaymentRow,
                pettyCashRow,
                nextMonthCashRow
            )
        );
        // 領収書合計金額
        const travelExpenseRow = totalTravelExpenses.map((travelExpense) => travelExpense[selectedFiscalYear] ?? null);

        // 経費合計(資金口座の経費合計金額 + 領収書合計)
        const totalExpenseRow = calculateTotalAmountRow(
            // 経費合計金額は出力用のデータには直接使用しないので配列から取り出します
            variableListAndRow[2]["rows"].pop(),
            travelExpenseRow
        );

        // 粗利益(売上合計金額 - 経費合計金額 - 予納その他経費)
        // 表示する取引先が可変のカテゴリの行列では最終行に合計金額が格納されています
        const grossProfitRow = calculateGrossProfitRow(
            variableListAndRow[1]["rows"][variableListAndRow[1]["rows"].length - 1], // 売上合計金額
            totalExpenseRow,
            advancePaymentRow
        );

        // 現金引き出し合計金額
        const withDrawRow = withDraw.map((withdrawal) => withdrawal[selectedFiscalYear]);

        // 差引現金残(小口繰越現金 + 現金引き出し合計 - 領収書合計)
        const deductionRow = calculateDeductionRow(pettyCashRow, withDrawRow, travelExpenseRow);

        // 差額(翌月繰越現預金 - 月残高合計)
        // 月残高合計とは資金行列の最終行に格納している合計金額
        const differenceRow = calculateBalanceAmountRow(
            variableListAndRow[3]["rows"][variableListAndRow[3]["rows"].length - 1], // 月残高合計(資金の合計金額)
            nextMonthCashRow
        );
        // 特記事項
        const noticeRow = notices.map((notice) => notice[selectedFiscalYear]);

        // csv, Excel file用のデータを作成します
        const dataList = [
            ...variableListAndRow[1]["rows"],
            [], // [経費]見出し行
            ...variableListAndRow[2]["rows"],
            travelExpenseRow,
            totalExpenseRow,
            advancePaymentRow,
            grossProfitRow,
            [], // [現金出納帳]見出し行
            pettyCashRow,
            withDrawRow,
            travelExpenseRow,
            deductionRow,
            [], // [資金]見出し行
            nextMonthCashRow,
            ...variableListAndRow[3]["rows"],
            differenceRow,
            [], // [その他]見出し行
            ...variableListAndRow[7]["rows"],
            [], // [特記事項]見出し行
            noticeRow // 特記事項
        ];
        // 作成したcsv, Excel用の月次データを呼び出し元でも格納する
        setMonthlyList(dataList); // 呼び出し元
        setSpreadSheetData(dataList);
    };
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <OutputExcel
            fileName={`月次損益${selectedYear}年度`}
            fileType={fileType}
            data={{
                ...monthlyDataLeftHeadlines(),
                months: months,
                dataList: spreadSheetData
            }}
        />
    );
}

// 差引現金残(小口繰越現金 + 現金引き出し合計 - 領収書合計)
export const calculateDeductionRow = (pettyCashRow, withDrawRow, expenseTotalRow) =>
    pettyCashRow.map((pettyCash, index) =>
        Number(pettyCash) || Number(withDrawRow[index]) || Number(expenseTotalRow[index])
            ? Number(pettyCash ?? 0) + Number(withDrawRow[index] ?? 0) - Number(expenseTotalRow[index] ?? 0)
            : null
    );
