//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useRef, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useForm, Controller } from "react-hook-form";
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import {
    TableRow,
    TableCell,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    DialogTitle
} from "@material-ui/core";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import PullDownMenu from "../PullDownMenu";
import { TravelExpenseContext } from "../../../contents/travel_expense/TravelExpenseRegister";
import TakeReceipt from "../TakeReceipt";
import ShowReceipt from "../ShowReceipt";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import amountToLocaleString from "../../../common/amountToLocaleString";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// MUIのTooltipのz-indexを調整
const StyledTooltip = withStyles({
    "@global": {
        ".MuiTooltip-popper": {
            zIndex: "1300"
        }
    }
})(Tooltip);

const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1)
        },
        changedRow: {
            backgroundColor: "#fdd",
            "&:hover": {
                background: "#fee"
            }
        }
    })
);
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// 入力欄のname一覧を保持
const inputList = ["suspense_payment_id", "date", "amount", "name", "account_list_id", "file_name", "file"];
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function TravelExpenseDraftInputRow(props) {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //定義したスタイルを利用するための設定
    const classes = useStyles();
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { loggedInUserId, selectedMonthAndYear, isClosed } = useContext(TravelExpenseContext);
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const {
        index,
        number,
        travelExpenseData = {},
        dispatchTravelExpenseDraftRequestData = (f) => f,
        maxAdditionalNumber,
        dispatchTravelExpenseAdditionalNumbers = (f) => f,
        dispatchTravelExpenseDraftValidationErrors = (f) => f,
        travelExpenseDraftValidationTrigger = (f) => f,
        payeesMenu = [],
        accountListsMenu = [],
        monthlyPeriod = { min: "", max: "" },
        targetId = null,
        setIsChanged
    } = props;
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const [file, setFile] = useState(null); // レシートの画像ファイルを管理
    const [filePath, setFilePath] = useState(""); // 登録済みの画像のファイルパスを管理
    const [existPhoto, setExistPhoto] = useState(null); // 保存済みの画像ファイルを管理
    const [isReceiptOpen, setIsReceiptOpen] = useState(false); // レシート撮影のダイアログ画面を表示切替する

    // 締め処理済みの場合に使用
    const [formattedDate, setFormattedDate] = useState(""); // 表示する日付けを管理する
    const [accountList, setAccountList] = useState(""); // 表示する勘定科目を管理する

    const [displayErrorMessage, setDisplayErrorMessage] = useState(false); // バリデーションエラーメッセージ表示切替
    const [displayErrorArea, setDisplayErrorArea] = useState(false); // バリデーションエラーのスタイル表示切替
    const validationErrorsRef = useRef({}); // 最新のformState.errorsの値を保持する
    const [equalMaxNumber, setEqualMaxNumber] = useState(false); // 最後尾の行であるかどうかを管理
    const [loaded, setLoaded] = useState(false); // 読み込み完了のステータスを管理
    const [updateFlg, setUpdateFlg] = useState(false); // 作成済みで編集対象であることのフラグ
    const [isDeleted, setIsDeleted] = useState(false); // 削除対象に指定されたかどうか
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 削除・復元ボタン押下時の処理
    const handleOnClickDeleteAndRestore = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        trigger(); // バリデーション実行

        // 行の削除状態を切り替える
        setIsDeleted(!isDeleted);
    };

    // レシート撮影コンポーネントを表示する
    const handleOnClickReceiptOpen = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        setIsReceiptOpen(true);
    };

    // レシート撮影コンポーネントを閉じる
    const handleOnReceiptClose = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        setIsReceiptOpen(false);
    };
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => {
        // 読み込み完了とする
        setLoaded(true);

        // エラーオブジェクトを作成しておく
        trigger(); // バリデーションの実行
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => {
        // 行が最後尾であるかどうかを判断し、ステートに状態を格納する
        setEqualMaxNumber(number === maxAdditionalNumber);
    }, [maxAdditionalNumber]);

    // リクエストデータを作成し、ステートのdispatchで更新する
    const createData = (data) => {
        // バリデーションエラーについて更新
        if (Object.keys(validationErrorsRef.current).length > 0) {
            dispatchTravelExpenseDraftValidationErrors({
                type: "add",
                number: number
            });
        } else {
            dispatchTravelExpenseDraftValidationErrors({
                type: "remove",
                number: number
            });
        }

        // 送信するデータを作成
        const requestData = {
            number: number,
            id: targetId,
            date: data.date,
            user_id: loggedInUserId,
            amount: Number(data.amount),
            name: data.name,
            account_list_id: data.account_list_id,
            suspense_payment_id: data.suspense_payment_id,
            is_paid: data.is_paid ?? 0,
            is_confirmed: data.is_confirmed ?? 0,
            is_changed: 0,
            file_name: data.file_name,
            file: data.file
        };

        // リクエストデータのステートを更新
        dispatchTravelExpenseDraftRequestData({
            type: "update",
            data: requestData,
            number: number
        });
    };

    // 削除または復元時に、リクエストデータを更新する
    useEffect(() => {
        if (loaded) {
            if (isDeleted) {
                setDisplayErrorMessage(false); // バリデーションエラーメッセージを非表示にする
                setDisplayErrorArea(false); // バリデーションエラーの赤枠を非表示
                dispatchTravelExpenseDraftRequestData({
                    type: "remove",
                    number: number
                }); // リクエストデータから対象のレコードを削除
                dispatchTravelExpenseDraftValidationErrors({
                    type: "remove",
                    number: number
                }); // エラーの配列からこの行のエラーを削除
            } else {
                const data = getValues(); // フォームの値を取得
                createData(data); // リクエストデータを準備する関数を実行
            }
        }
    }, [isDeleted]);

    // 送信するデータを受け取った配列に更新する
    const updateRowRequest = async () => {
        const values = await getValues(); // 現在のフォーム全体のinputの値
        createData(values); // リクエストデータを準備する

        // 新規作成中の行にて,どれか1項目だけでも値が入力されている場合
        if (!updateFlg && Boolean(inputList.find((input) => values[input]))) {
            // 新しい行を追加
            dispatchTravelExpenseAdditionalNumbers({
                type: "add",
                number: number
            });
        }
    };

    // react-hook-formの使用する機能を宣言
    const { register, formState, setValue, getValues, control, watch, trigger } = useForm();

    // データがある場合にインプットに値を入力する
    useEffect(() => {
        if (Object.keys(travelExpenseData).length > 0) {
            // 締め処理済みの場合に表示用の文字列に変換
            if (travelExpenseData.is_paid == 1 || travelExpenseData.is_confirmed == 1 || isClosed) {
                // 表示用の日付けを作成
                const date = travelExpenseData.date.replace(/-/g, "/");
                setFormattedDate(date);

                // 勘定科目名を変換してステートに持たせる
                const changedAccountList = accountListsMenu.find(
                    (item) => item.value === travelExpenseData.account_list_id
                ).label;
                setAccountList(changedAccountList);
            } else {
                // 編集対象の場合の処理
                // 各フォームに値をセット
                inputList.map((input) =>
                    setValue(input, travelExpenseData[input] ?? "", {
                        shouldValidate: false
                    })
                );

                // 編集対象なので、フラグを立てる
                setUpdateFlg(true);
            }
            // 画像のpathを作成
            const path = `/img/receipt/${selectedMonthAndYear}/${loggedInUserId}/${travelExpenseData.file_name}`;
            // 画像のPathをステートに保存する
            setFilePath(path);
        }
    }, [travelExpenseData, isClosed]);

    // 内容が変更されたかを監視
    useEffect(() => {
        const subscription = watch(async (value, { name, type }) => {
            if (type === "change") {
                // プルダウンメニューから支払先を選択した時の処理
                if (name === "payee_format") {
                    const payeeFormatValues = value["payee_format"];
                    const payeeName = payeeFormatValues.payeeName;
                    const accountListId = payeeFormatValues.accountListId;
                    // インプットに値をセット
                    setValue("name", payeeName, { shouldValidate: true });
                    setValue("account_list_id", accountListId, {
                        shouldValidate: true
                    });
                    setValue("payee_format", "", { shouldValidate: true });
                }
                await trigger(); // バリデーションの実行
                await updateRowRequest(); // リクエストデータを準備する
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    // 撮影された画像に変更があった場合、フォームのインプットの中身を更新
    useEffect(async () => {
        if (loaded && !isClosed) {
            if (file) {
                // 画像データをインプットに埋め込み
                setValue("file", file, { shouldValidate: false });

                // 以下、ファイル名がまだ作成されていない場合にファイル名を作成し、インプットに埋め込み
                if (!getValues("file_name")) {
                    // 現在時刻を取得
                    const now = new Date();
                    const y = now.getFullYear();
                    const m = zeroPadding(now.getMonth() + 1);
                    const d = zeroPadding(now.getDate());
                    const h = zeroPadding(now.getHours());
                    const min = zeroPadding(now.getMinutes());
                    const sec = zeroPadding(now.getSeconds());
                    // ファイル名作成
                    const fileName = `${loggedInUserId}_${y}${m}${d}${h}${min}${sec}.jpg`;
                    // 作成したファイル名をインプットに埋め込み
                    setValue("file_name", fileName, { shouldValidate: false });
                }
            } else if (file === null && filePath !== "") {
                // 保存済みの画像があり、撮影した画像が破棄されたとき
                setValue("file", "", { shouldValidate: false });
                setValue("file_name", travelExpenseData.file_name, { shouldValidate: false });
            } else if (file === null && filePath === "") {
                // 新規の画像を破棄した場合
                setValue("file", "", { shouldValidate: false });
                setValue("file_name", "", { shouldValidate: false });
            }

            await trigger(); // バリデーションの実行
            await updateRowRequest(); // リクエストデータを準備する
        }
    }, [file]);

    // 二桁の0埋め用関数
    const zeroPadding = (value) => {
        return ("0" + value).slice(-2);
    };

    // react-hook-formのformStateの更新時に、バリデーションエラーをRefオブジェクトに保持する
    useEffect(() => {
        if (loaded) {
            validationErrorsRef.current = formState.errors;
            if (Object.keys(formState.errors).length > 0) {
                dispatchTravelExpenseDraftValidationErrors({
                    type: "add",
                    number: number
                });
            }
        }
    }, [formState.errors]);

    // 親コンポーネントでのサブミット時にバリデーション、エラーの更新を実行する
    useEffect(() => {
        // 読み込み完了、削除対象、最後尾の行以外の場合
        if (loaded && !isDeleted && !equalMaxNumber) {
            // バリデーションの実行
            trigger();
            // バリデーションの結果を表示する
            setDisplayErrorMessage(true);
            setDisplayErrorArea(true);
        }
    }, [travelExpenseDraftValidationTrigger]);

    useEffect(() => {
        if (formState.isDirty) {
            setIsChanged(true);
        }
    }, [formState.isDirty]);

    // レシート撮影用のダイアログ画面のコンポーネント
    const ReceiptDialog = () =>
        isReceiptOpen ? (
            <Dialog open={isReceiptOpen} maxWidth='lg' className='h-100'>
                <DialogContent className='card-body position-relative'>
                    <Button
                        variant='contained'
                        onClick={(e) => handleOnReceiptClose(e)}
                        className='position-absolute top-0 end-0 btn-close btn-outline-primary fs-3 lh-1 m-4 p-4 w-auto'
                    />
                    {isClosed || travelExpenseData.is_confirmed == 1 || isDeleted ? (
                        <ShowReceipt
                            filePath={filePath}
                            file={file}
                            setFile={setFile}
                            setIsReceiptOpen={setIsReceiptOpen}
                        />
                    ) : (
                        <TakeReceipt
                            filePath={filePath}
                            file={file}
                            setFile={setFile}
                            existPhoto={existPhoto}
                            setExistPhoto={setExistPhoto}
                            setIsReceiptOpen={setIsReceiptOpen}
                        />
                    )}
                </DialogContent>
            </Dialog>
        ) : null;
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <TableRow className={`align-baseline ${isDeleted ? "bg-secondary" : ""}`}>
            <TableCell align='center' padding='none'>
                {index}
            </TableCell>
            {travelExpenseData.is_paid == 1 || travelExpenseData.is_confirmed == 1 || isClosed ? (
                <>
                    {/* 締め処理済み、確認済み等の場合 */}
                    <TableCell align='center' padding='none'>
                        {formattedDate}
                    </TableCell>
                    <TableCell align='right' padding='none'>
                        {amountToLocaleString(travelExpenseData.amount)}
                    </TableCell>
                    <TableCell align='left' padding='none'>
                        {travelExpenseData.name}
                    </TableCell>
                    <TableCell align='left' padding='none'>
                        {accountList}
                    </TableCell>
                    <TableCell>
                        <button
                            className='btn btn-sm btn-primary px-3'
                            type='button'
                            onClick={(e) => handleOnClickReceiptOpen(e)}>
                            領収書
                        </button>
                        <ReceiptDialog />
                    </TableCell>
                    {/* 締め処理済み、確認済み等の場合 END */}
                </>
            ) : (
                <>
                    <TableCell align='center' padding='none'>
                        <StyledTooltip
                            arrow={true}
                            title={displayErrorMessage && formState.errors.date ? formState.errors.date?.message : ""}>
                            <div
                                className={
                                    displayErrorArea && formState.errors.date
                                        ? "border border-danger border-2 rounded rounded-3"
                                        : ""
                                }>
                                <input
                                    className='form-control form-control-sm'
                                    type='date'
                                    min={monthlyPeriod.min}
                                    max={monthlyPeriod.max}
                                    defaultValue={travelExpenseData.date}
                                    readOnly={travelExpenseData.is_confirmed === 1}
                                    disabled={isDeleted}
                                    {...register("date", {
                                        required: "必須項目です",
                                        min: {
                                            value: monthlyPeriod.min,
                                            message: "無効な日付です"
                                        },
                                        max: {
                                            value: monthlyPeriod.max,
                                            message: "無効な日付です"
                                        }
                                    })}
                                />
                            </div>
                        </StyledTooltip>
                    </TableCell>
                    <TableCell align='center' padding='none'>
                        <StyledTooltip
                            arrow={true}
                            title={
                                displayErrorMessage && formState.errors.amount ? formState.errors.amount?.message : ""
                            }>
                            <div
                                className={
                                    displayErrorArea && formState.errors.amount
                                        ? "border border-danger border-2 rounded rounded-3"
                                        : ""
                                }>
                                <input
                                    className='form-control form-control-sm'
                                    type='number'
                                    min='1'
                                    max='9999999'
                                    step={1}
                                    readOnly={travelExpenseData.is_confirmed === 1}
                                    disabled={isDeleted}
                                    {...register("amount", {
                                        required: {
                                            value: true,
                                            message: "必須項目です"
                                        },
                                        min: {
                                            value: 1,
                                            message: "1以上を入力"
                                        },
                                        max: {
                                            value: 9999999,
                                            message: "入力可能な額を超えています"
                                        }
                                    })}
                                />
                            </div>
                        </StyledTooltip>
                    </TableCell>
                    <TableCell align='center' padding='none'>
                        <StyledTooltip
                            arrow={true}
                            title={displayErrorMessage && formState.errors.name ? formState.errors.name?.message : ""}>
                            <div
                                className={
                                    displayErrorArea && formState.errors.name
                                        ? "border border-danger border-2 rounded rounded-3"
                                        : ""
                                }>
                                <div className='d-flex'>
                                    <input
                                        className='form-control form-control-sm'
                                        type='text'
                                        readOnly={travelExpenseData.is_confirmed === 1}
                                        disabled={isDeleted}
                                        {...register("name", {
                                            required: {
                                                value: true,
                                                message: "必須項目です"
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: "文字数の上限を超えています"
                                            }
                                        })}
                                    />
                                    <Controller
                                        name='payee_format'
                                        defaultValue={""}
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <PullDownMenu
                                                field={field}
                                                menuItems={payeesMenu}
                                                readOnly={isDeleted || travelExpenseData.is_confirmed === 1}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </StyledTooltip>
                    </TableCell>
                    <TableCell align='center' padding='none'>
                        <StyledTooltip
                            arrow={true}
                            title={
                                displayErrorMessage && formState.errors.account_list_id
                                    ? formState.errors.account_list_id?.message
                                    : ""
                            }>
                            <div
                                className={
                                    displayErrorArea && formState.errors.account_list_id
                                        ? "border border-danger border-2 rounded rounded-3"
                                        : ""
                                }>
                                <Controller
                                    name='account_list_id'
                                    defaultValue={""}
                                    control={control}
                                    rules={{ required: "必須項目です" }}
                                    render={({ field, fieldState }) => (
                                        <PullDownMenu
                                            field={field}
                                            menuItems={accountListsMenu}
                                            readOnly={isDeleted || travelExpenseData.is_confirmed === 1}
                                        />
                                    )}
                                />
                            </div>
                        </StyledTooltip>
                    </TableCell>
                    <TableCell align='center' padding='none'>
                        <StyledTooltip
                            arrow={true}
                            title={
                                displayErrorMessage && formState.errors.file_name
                                    ? formState.errors.file_name?.message
                                    : ""
                            }>
                            <div
                                className={
                                    displayErrorArea && formState.errors.file_name
                                        ? "border border-danger border-2 rounded rounded-3"
                                        : ""
                                }>
                                <input
                                    type='hidden'
                                    disabled={isDeleted}
                                    {...register("file_name", {
                                        required: {
                                            value: true,
                                            message: "必須項目です"
                                        }
                                    })}
                                />
                                <input type='hidden' disabled={isDeleted} {...register("file")} />
                                <button
                                    className='btn btn-sm btn-primary px-3'
                                    type='button'
                                    onClick={(e) => handleOnClickReceiptOpen(e)}>
                                    領収書
                                </button>
                            </div>
                        </StyledTooltip>
                        {/* 領収書撮影用のDialogコンポーネント */}
                        <ReceiptDialog />
                    </TableCell>
                    <TableCell align='center' padding='none'>
                        {(!equalMaxNumber || travelExpenseData.is_confirmed === 0) && (
                            <div className='px-1'>
                                <button
                                    className='btn btn-sm btn-secondary px-3'
                                    type='button'
                                    onClick={(e) => handleOnClickDeleteAndRestore(e)}>
                                    {isDeleted ? "復元" : "削除"}
                                </button>
                            </div>
                        )}
                    </TableCell>
                </>
            )}
        </TableRow>
    );
}
