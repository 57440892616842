import React, { useEffect, useState } from "react";
import Camera, { IMAGE_TYPES, FACING_MODES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";
import "react-image-crop/dist/ReactCrop.css";
import { Button } from "@material-ui/core";
import { api } from "../../api/api";
import swal from "sweetalert";
const noImage = "/img/no_image.jpg";
export default function TakeReceipt(props) {
    const { filePath, file, setFile, existPhoto, setExistPhoto, setIsReceiptOpen } = props;
    const [isCameraUsed, setCameraUsed] = useState(false); // cameraが使用可能か否か
    const [isCameraOpen, setCameraOpen] = useState(false);
    const [photo, setPhoto] = useState(noImage);
    const [crop, setCrop] = useState({});
    const [croppedUri, setCroppedUri] = useState(null);
    const cv = window.cv; // openCVの読み込み
    // 以前に撮影した画像を取得
    const getReceipt = async (path) => {
        // コントローラーに渡す値を作成
        const data = { path: path };
        const res = await api.post("file", data);
        // 値が存在する場合state(=imgのURI)を更新
        const gotData = res.data.result;
        if (gotData) {
            const adjustedData = JSON.parse(gotData); // 受け取ったデータをjsonに
            const mimeType = adjustedData.mimeType;
            const fileText = adjustedData.fileText;
            const receiptUri = "data:" + mimeType + ";base64," + fileText;
            setExistPhoto(receiptUri);
        } else {
            setExistPhoto(noImage);
        }
    };
    const CropDemo = (src) => (
        <span className='text-center my-4 d-inline-flex align-items-start vstack'>
            <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
                <img src={src} className='receiptPhoto' alt='撮影した写真' />
            </ReactCrop>
        </span>
    );

    const onCameraOpen = () => setCameraOpen(true);
    const onCameraClose = () => setCameraOpen(false);
    const onCrop = () => {
        if (photo && crop.width && crop.height) {
            const croppedImageUrl = getCroppedImg(photo, crop);
            // 現時点の切り抜き画像から変化がある場合に切り抜き画像を上書きする
            if (croppedUri !== croppedImageUrl) {
                setCroppedUri(croppedImageUrl);
            }
        }
    };

    const onChangePhoto = async () => {
        if (photo && photo !== noImage) {
            await getContoursPoints(photo);
        }
    };

    const onTakePhoto = (uri) => {
        onCameraClose(); // 写真撮影後にカメラを終了させる
        setCrop({});
        setPhoto(uri);
    };

    // 画像をcrop(=x,y座標の位置と縦横幅を示す数値配列)範囲に切り取って返す
    const getCroppedImg = (uri, crop) => {
        let image = new Image();
        image.src = uri;
        const canvas = document.createElement("canvas");
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "low";
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
        ctx.restore();
        return canvas.toDataURL("image/jpeg", 1.0);
    };

    // 受け取った画像の中で最大の四角形の輪郭線を取得する
    const getContoursPoints = (uri) => {
        let image = new Image();
        image.src = uri;
        const mat = cv.imread(image);
        if (mat.channels() !== 1) {
            cv.cvtColor(mat, mat, cv.COLOR_RGBA2GRAY, 0);
        }
        // 画像を白黒で2値化する
        cv.threshold(mat, mat, 20, 255, cv.THRESH_OTSU);

        let contours = new cv.MatVector(); // 輪郭の情報を格納する
        let hierarchy = new cv.Mat();
        // 輪郭を全部見つけ出す
        cv.findContours(mat, contours, hierarchy, cv.RETR_LIST, cv.CHAIN_APPROX_SIMPLE);
        hierarchy.delete();
        mat.delete();
        let warp = new cv.Mat();
        let maxCntArea = 0;
        // 抽出した輪郭に近似する直線を探す
        for (let i = 0; i < contours.size(); i++) {
            // ある程度のサイズ(15000)以上の輪郭のみ処理
            const area = cv.contourArea(contours.get(i), false);
            if (area > 15000) {
                let approx = new cv.Mat();
                cv.approxPolyDP(contours.get(i), approx, 0.01 * cv.arcLength(contours.get(i), true), true);
                // approxは行列で,幅1,高さ4のものが4頂点(四角形)に近似できた範囲になる
                if (approx.size().width === 1 && approx.size().height === 4 && maxCntArea < area) {
                    // 四角形に近似できる最大の領域で輪郭線の4頂点座標を格納する
                    warp = approx;
                    maxCntArea = area;
                }
            }
        }

        contours.delete();
        if (maxCntArea && warp) {
            setCropCircumscribedRectangle(image, warp);
        } else {
            swal("切り抜けませんでした");
        }
        warp.delete();
    };
    // 受け取った輪郭線多角形の頂点の座標に対して傾きなく外接する長方形を切り取り位置に指定する
    const setCropCircumscribedRectangle = async (image, pointers) => {
        let minWidth = image.width;
        let minHeight = image.height;
        let maxWidth = 0;
        let maxHeight = 0;
        for (let column = 0; column < pointers.cols; column++) {
            // 輪郭多角形の頂点数
            for (let row = 0; row < pointers.rows; row++) {
                const currentWidth = pointers.intPtr(column, row)[0];
                const currentHeight = pointers.intPtr(column, row)[1];
                if (maxWidth < currentWidth) {
                    maxWidth = currentWidth;
                }
                if (currentWidth < minWidth) {
                    minWidth = currentWidth;
                }
                if (maxHeight < currentHeight) {
                    maxHeight = currentHeight;
                }
                if (currentHeight < minHeight) {
                    minHeight = currentHeight;
                }
            }
        }
        // 算出した座標を切り取り位置に指定
        const rectangle = {
            x: minWidth,
            y: minHeight,
            width: maxWidth - minWidth,
            height: maxHeight - minHeight,
            unit: "px"
        };
        setCrop(await rectangle);
    };
    useEffect(() => {
        if (filePath && existPhoto === null) {
            getReceipt(filePath); // 保存済みの写真を取得
        }
        if ("mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices) {
            setCameraUsed(true);
        } else {
            swal("カメラを使用できません\n接続状態や設定等を見直して下さい");
        }
    }, []);

    useEffect(async () => await onChangePhoto(), [photo]);

    const TakeCamera = () => (
        <div className='mt-5'>
            <Camera
                onTakePhoto={onTakePhoto}
                onCameraError={onCameraClose}
                imageType={IMAGE_TYPES.JPG}
                isMaxResolution={false}
                isSilentMode={true}
                isFullscreen={false}
                isDisplayStartCameraError={true}
                idealFacingMode={FACING_MODES.ENVIRONMENT}
            />
        </div>
    );

    const CropButtons = () => (
        <>
            {photo && photo !== noImage && (
                <p className='d-flex flex-column m-5'>
                    <Button variant='contained' onClick={onChangePhoto} disabled={!photo}>
                        自動切り抜き
                    </Button>
                    <br />
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={onCrop}
                        disabled={!photo || Object.keys(crop).length === 0 || crop.width === 0 || crop.height === 0}>
                        決定
                    </Button>
                </p>
            )}
        </>
    );

    const RegisterReceipt = () => (
        <div className='d-flex align-self-start'>
            <div>
                <img src={croppedUri} alt='保存する写真' id='savePhoto' />
            </div>
            <div className='w-auto mt-auto'>
                <div className='m-3 ps-4 pe-2'>
                    <p className='px-5'>この写真を保存しても宜しいですか?</p>
                    <div className='d-flex justify-content-around w-100'>
                        <Button
                            variant='contained'
                            onClick={() => {
                                setCroppedUri(null);
                            }}>
                            戻る
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => {
                                setFile(croppedUri);
                                setIsReceiptOpen(false);
                            }}>
                            保存
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
    const CameraDialog = isCameraOpen ? TakeCamera : CropButtons;

    return croppedUri ? (
        RegisterReceipt()
    ) : (
        <div className='d-flex justify-content-start align-content-center'>
            {!isCameraOpen &&
                (!photo || photo === noImage ? (
                    file || existPhoto ? (
                        <div className='d-inline-flex'>
                            {existPhoto && (
                                <figure className='form-check-inline my-3'>
                                    <figcaption className='fs-5 mb-4'>
                                        {file ? "変更前の写真" : "保存されている写真"}
                                    </figcaption>
                                    <img src={existPhoto} alt='写真' />
                                </figure>
                            )}
                            {file && file !== existPhoto && (
                                <div className='d-flex flex-column justify-content-between form-check-inline my-3'>
                                    <figure>
                                        <figcaption className='fs-5 mb-4'>新たに撮影した写真</figcaption>
                                        <img src={file} alt='写真' />
                                    </figure>
                                    <div>
                                        <Button
                                            variant='contained'
                                            onClick={() => {
                                                setFile(null);
                                            }}>
                                            {existPhoto ? "変更前の写真に戻す" : "撮影した写真を破棄する"}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            {filePath === "" ? (
                                <img src={noImage} alt='写真無し' className='m-5' />
                            ) : (
                                <p className='m-5'>...読み込み中</p>
                            )}
                        </>
                    )
                ) : (
                    CropDemo(photo)
                ))}
            <div className='mt-auto'>
                <CameraDialog />
                {isCameraOpen ? (
                    <div className='me-auto p-5'>
                        <Button variant='contained' onClick={onCameraClose}>
                            撮影を中断
                        </Button>
                    </div>
                ) : (
                    <div className='text-center m-3'>
                        <Button variant='contained' color='primary' onClick={onCameraOpen} disabled={!isCameraUsed}>
                            撮影
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}
