//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

import ShowRow from "./ShowRow";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { MonthlyDataContext } from "../../../contents/monthly_data/MonthlyDataRegister";
import { UserContext } from "../../../providers/UserProvider";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import processErrorResponse from "../../../common/processErrorResponse";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
const useStyles = makeStyles((theme) =>
    createStyles({
        rightCell: {
            width: "50%"
        },
        leftCell: {
            width: "50%"
        },
        wrap: {
            wordBreak: "break-all"
        }
    })
);
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function MonthlyDataShow(props) {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //定義したスタイルを利用するための設定
    const classes = useStyles();
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { userInfo } = useContext(UserContext);
    const authority_number = userInfo.user.authority_number;
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const {
        displayTargetDate,
        proceedsData,
        proceeds,
        proceedsSum,
        setProceedsSum,
        expensesData,
        expenses,
        expensesSum,
        setExpensesSum,
        withdrawalsData,
        withdrawalsSub,
        setWithdrawalsSub,
        bankAccountsData,
        bankAccounts,
        bankAccountsSum,
        setBankAccountSum,
        bankAccountSub,
        setBankAccountSub,
        advancePayment,
        pettyCash,
        nextMonthCash,
        travelExpenseTotalAmount,
        othersData,
        others,
        targetNotice,
        grossMargin,
        setGrossMargin,
        TaxAccountantAuthorityNumber
    } = props;
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 各項目の合計値
    // 売上
    useEffect(() => {
        sumAmount(proceedsData, setProceedsSum);
    }, [proceedsData]);

    // 経費
    useEffect(() => {
        sumAmount(expensesData, setExpensesSum);
    }, [expensesData]);

    // 資金
    useEffect(() => {
        sumAmount(bankAccountsData, setBankAccountSum);
    }, [bankAccountsData]);

    // 差引現金残を計算
    useEffect(() => {
        // 現金引き出しの合計
        let sum = 0;
        if (Array.isArray(withdrawalsData)) {
            sum = withdrawalsData.reduce((sum, element) => {
                const amount = parseInt(element?.withdrawal_amount ?? 0);
                return parseInt(sum) + (isNaN(amount) ? 0 : amount);
            }, 0);
        }
        // 小口繰越現金 + 現金引き出しの合計
        const amount = parseInt(pettyCash?.monthly_data_amount ?? 0) + parseInt(sum ?? 0);

        // 上記の値から経費の合計を引く
        const sub = (isNaN(amount) ? 0 : amount) - (isNaN(travelExpenseTotalAmount) ? 0 : travelExpenseTotalAmount);
        setWithdrawalsSub(sub);
    }, [withdrawalsData, travelExpenseTotalAmount]);

    // 差額
    useEffect(() => {
        const amount = parseInt(nextMonthCash?.monthly_data_amount ?? 0);
        const sub = (isNaN(amount) ? 0 : amount) - (isNaN(bankAccountsSum) ? 0 : bankAccountsSum);
        setBankAccountSub(sub);
    }, [nextMonthCash, bankAccountsSum]);

    // 粗利益変更
    useEffect(() => {
        const proceedsSumValue = parseInt(proceedsSum ?? 0, 10);
        const expensesSumValue = parseInt(expensesSum ?? 0, 10);
        const advancePaymentValue = parseInt(advancePayment ? advancePayment.monthly_data_amount : 0, 10);
        const travelExpenseValue = parseInt(travelExpenseTotalAmount ? travelExpenseTotalAmount ?? 0 : 0, 10);
        const value = proceedsSumValue - expensesSumValue - advancePaymentValue - travelExpenseValue;
        const result = isNaN(value) ? 0 : value;
        setGrossMargin(result);
    }, [proceedsSum, expensesSum, advancePayment, travelExpenseTotalAmount]);

    // 合計値計算用関数
    const sumAmount = (data, setSum) => {
        // 親コンポーネントの合計用ステート更新
        let sum = 0;
        if (Array.isArray(data)) {
            sum = data.reduce((sum, element) => {
                const amount = parseInt(element?.monthly_data_amount ?? 0);
                return parseInt(sum) + (isNaN(amount) ? 0 : amount);
            }, 0);
        }
        setSum(sum);
    };
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <>
            {/* 対象月 START */}
            <table className='table table-bordered'>
                <caption className='caption-top'>対象月&nbsp;&nbsp;{displayTargetDate}</caption>
                <tbody>
                    {/* 売上 START */}
                    <tr className='table-danger'>
                        <th colSpan={2}>売上</th>
                    </tr>
                    <ShowRow dataName='bank_account' state={proceeds} data={proceedsData ?? null} />
                    <tr>
                        <th className={classes.leftCell}>売上合計</th>
                        <td className={classes.leftCell}>{proceedsSum.toLocaleString()}</td>
                    </tr>
                    {/* 売上 END */}
                    {/* 経費 START */}
                    <tr className='table-info'>
                        <th colSpan={2}>経費</th>
                    </tr>
                    <ShowRow dataName='bank_account' state={expenses} data={expensesData ?? null} />
                    <tr>
                        <th className={classes.leftCell}>領収書合計</th>
                        <td className={classes.leftCell}>{parseInt(travelExpenseTotalAmount ?? 0).toLocaleString()}</td>
                    </tr>
                    <tr>
                        <th className={classes.leftCell}>経費合計</th>
                        <td className={classes.leftCell}>
                            {(parseInt(travelExpenseTotalAmount ?? 0) + expensesSum).toLocaleString()}
                        </td>
                    </tr>
                    <tr>
                        <th className={classes.leftCell}>予納その他経費</th>
                        <td className={classes.leftCell}>
                            {(advancePayment?.monthly_data_amount ?? 0).toLocaleString()}
                        </td>
                    </tr>
                    <tr>
                        <th className={classes.leftCell}>粗利益</th>
                        <td className={classes.leftCell}>{grossMargin.toLocaleString()}</td>
                    </tr>
                    {/* 経費 END */}
                    {/* 現金出納帳 START */}
                    <tr className='table-success'>
                        <th colSpan={2}>現金出納帳</th>
                    </tr>
                    <tr>
                        <th className={classes.leftCell}>小口繰越現金</th>
                        <td className={classes.leftCell}>{(pettyCash?.monthly_data_amount ?? 0).toLocaleString()}</td>
                    </tr>
                    {withdrawalsData
                        ? withdrawalsData.map((item, index) => {
                              return (
                                  <tr key={index}>
                                      <th className={classes.leftCell}>現金引き出し{index + 1}</th>
                                      <td className={classes.rightCell}>
                                          {(item?.withdrawal_amount ?? 0).toLocaleString()}
                                      </td>
                                  </tr>
                              );
                          })
                        : null}
                    <tr>
                        <th className={classes.leftCell}>領収書合計</th>
                        <td className={classes.leftCell}>{parseInt(travelExpenseTotalAmount ?? 0).toLocaleString()}</td>
                    </tr>
                    <tr>
                        <th className={classes.leftCell}>差引現金残</th>
                        <td className={classes.leftCell}>{withdrawalsSub.toLocaleString() ?? 0}</td>
                    </tr>
                    {/* 現金出納帳 END */}
                    {/* 資金 START */}
                    <tr className='table-success'>
                        <th colSpan={2}>資金</th>
                    </tr>
                    <tr>
                        <th className={classes.leftCell}>翌月繰越現預金</th>
                        <td className={classes.leftCell}>
                            {(nextMonthCash?.monthly_data_amount ?? 0).toLocaleString()}
                        </td>
                    </tr>
                    <ShowRow dataName='bank_account' state={bankAccounts} data={bankAccountsData ?? null} />
                    <tr>
                        <th className={classes.leftCell}>月残高合計</th>
                        <td className={classes.leftCell}>{bankAccountsSum.toLocaleString()}</td>
                    </tr>
                    <tr>
                        <th className={classes.leftCell}>差額</th>
                        <td className={classes.leftCell}>
                            {authority_number === TaxAccountantAuthorityNumber
                                ? bankAccountSub.toLocaleString()
                                : (-bankAccountSub).toLocaleString()}
                        </td>
                    </tr>
                    {/* 資金 END */}
                    {/* その他 START */}
                    <tr className='table-secondary'>
                        <th colSpan={2}>その他</th>
                    </tr>
                    <ShowRow dataName='other' state={others} data={othersData ?? null} />
                    {/* その他 END */}
                    <tr className='table-primary'>
                        <th colSpan={2}>特記事項</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>{targetNotice ?? "データなし"}</td>
                    </tr>
                </tbody>
            </table>
            {/* 対象月 END */}
        </>
    );
}
