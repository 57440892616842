//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { Select, MenuItem } from "@material-ui/core";
import { Button } from "@material-ui/core";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import RequiredMark from "../../components/RequiredMark";
import BackButton from "../../components/BackButton";
import CloseButton from "../../components/CloseButton";
import DataShow from "../../components/DataShow";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import processResponse from "../../common/processResponse";
import processErrorResponse from "../../common/processErrorResponse";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// 戻るときの遷移先のパス
const returnPath = "/user";
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// authorities の初期状態
const initAuthorities = { result: [], success: false };
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function UserRegister() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面遷移用
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // userの状態を管理する
    const [user, setUser] = useState({});

    // authorities の状態を管理する
    const [authorities, setAuthorities] = useState(initAuthorities);

    // プルダウンメニュー用の権限の配列を格納する
    const [authoritiesMenu, setAuthoritiesMenu] = useState([]);

    const [excelFile, setExcelFile] = useState(null);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 権限情報を取得しステート authorities にセットする
    const getAuthoritiesData = async () => {
        const url = "authorities";
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        processResponse(res, setAuthorities, navigate, returnPath);
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面初期表示時に権限情報を取得する
    useEffect(() => {
        getAuthoritiesData();
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // authorities からプルダウンメニュー用の配列を作成する
    useEffect(() => {
        if (authorities.success) {
            // 取得した権限情報から必要な値を配列にする
            const authoritiesArray = authorities.result.map((authority) => {
                return {
                    label: authority.authority_name,
                    value: authority.authority_id,
                    number: authority.authority_number,
                };
            });
            // 権限の数値によって並び順を変更する
            const sortedAuthorities = authoritiesArray.sort((a, b) => a.number - b.number);
            // ステートに格納する
            setAuthoritiesMenu(sortedAuthorities);
        }
    }, [authorities]);

    // Dialogに表示するレンダリング済みの要素を生成する関数
    const makeElementForDialogShow = (showData) => {
        // 空要素を生成
        const wrapper = document.createElement("div");
        // 空要素内に一時レンダリング
        ReactDOM.render(<DataShow data={showData} />, wrapper);
        // レンダリングされたコンポーネントを抽出
        const newElement = wrapper.firstChild;
        // 抽出した要素を返す
        return newElement;
    };

    // 確認ボタンクリック時、かつバリデーション通過時の処理
    const userSubmit = async (data) => {
        // 権限の値から表示する権限名を宣言
        const authority = authoritiesMenu.find((item) => item.value === data.authority_id);
        const authorityName = authority.label;

        // 確認画面用のデータ作成
        const showData = [
            { label: "名前", value: data.name },
            { label: "メールアドレス", value: data.email },
            { label: "権限", value: authorityName },
        ];

        // ダイアログ画面に表示するデータ一覧の要素を生成
        const element = makeElementForDialogShow(showData);

        // 確認のための変数を定義
        let confirm = false;

        // 確認画面を表示
        await swal({
            title: "確認",
            text: "以下の内容で登録します。",
            content: element,
            buttons: ["戻る", "登録"],
        }).then((value) => {
            if (value) {
                confirm = true;
            }
        });

        // 戻る場合は処理を中断する
        if (!confirm) {
            return;
        }

        // 登録データ作成
        const userData = new FormData();
        userData.append("email", data.email);
        userData.append("name", data.name);
        userData.append("password", data.password);
        userData.append("authority_id", data.authority_id);
        userData.append("excel_file", data.excel_file[0]); // ファイルを追加

        // 登録
        const url = "users";
        const res = await api.post(url, userData);
        //---------------------------issue{No.81} start-----------------------------
        // 成否の確認
        if (res.data.success) {
            // 登録成功時
            swal(res.data.message, res.data.result.name, "success").then(() => {
                navigate(returnPath);
            });
        } else {
            // 登録失敗時にエラーに応じた処理を行う
            processErrorResponse(res, setUser, navigate);
        }
        //---------------------------issue{No.81} end-------------------------------
    };

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
    } = useForm();

    // プルダウンメニュー用のステート作成後に、フォームの初期値の入力を行う副作用フック
    useEffect(() => {
        if (authoritiesMenu.length === 1) {
            // 権限の種類が一件のみの場合にその値をセットする
            setValue("authority", authoritiesMenu[0].value, { shouldValidate: true });
        }
    }, [authoritiesMenu]);
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1>ユーザー情報登録</h1>
                        <div className='ms-auto'>
                            <CloseButton path={returnPath} />
                        </div>
                    </div>
                    <div className='card-body'>
                        <p className='fs-6 mb-4'>
                            <RequiredMark />
                            <span className='text-secondary'>は必須項目です。</span>
                        </p>
                        <form id='form' enctype='multipart/form-data'>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='name'>
                                    ユーザー名
                                    <RequiredMark />
                                </label>
                                <input
                                    id='name'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("name", {
                                        required: {
                                            value: true,
                                            message: "*ユーザー名を入力してください。",
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "*255文字以内で入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.name && errors.name?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{user.error_list?.name && user.error_list.name}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='email'>
                                    メールアドレス
                                    <RequiredMark />
                                </label>
                                <input
                                    id='email'
                                    type='email'
                                    className='form-control mb-1'
                                    {...register("email", {
                                        required: {
                                            value: true,
                                            message: "*メールアドレスを入力してください。",
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "*255文字以内で入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.email && errors.email?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{user.error_list?.email && user.error_list.email}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='password'>
                                    パスワード
                                    <RequiredMark />
                                </label>
                                <input
                                    id='password'
                                    type='password'
                                    className='form-control mb-1'
                                    {...register("password", {
                                        required: {
                                            value: true,
                                            message: "*パスワードを入力してください。",
                                        },
                                        minLength: {
                                            value: 4,
                                            message: "*4文字以上で入力してください。",
                                        },
                                        maxLength: {
                                            value: 12,
                                            message: "*12文字以内で入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.password && errors.password?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {user.error_list?.password && user.error_list.password}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='authority_id'>
                                    権限
                                    <RequiredMark />
                                </label>
                                <Controller
                                    name='authority_id'
                                    defaultValue={""}
                                    control={control}
                                    rules={{ required: "*権限を選択してください。" }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            {Object.keys(authoritiesMenu).length > 0 && (
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled>
                                                        選択してください
                                                    </MenuItem>
                                                    {authoritiesMenu.map((item, index) => (
                                                        <MenuItem key={index} value={item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        </>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.authority_id && errors.authority_id?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {user.error_list?.authority_id && user.error_list.authority_id}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <Controller
                                    control={control}
                                    name='excel_file'
                                    defaultValue=''
                                    render={({ field: { onChange } }) => (
                                        <>
                                            <label className='fw-bold mb-1' htmlFor={`excel_file`}>
                                                仮払金申請書兼受領書 <small>（.xlsxファイルのみ）</small>
                                            </label>
                                            <input
                                                id={`excel_file`}
                                                className='form-control mb-1'
                                                type='file'
                                                accept='.xlsx'
                                                onChange={(e) => onChange(e.target.files)}
                                            />
                                        </>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.excel_file && errors.excel_file?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {user.error_list?.excel_file && user.error_list.excel_file}
                                </small>
                            </div>
                            <div className='form-group mb-3 d-flex'>
                                <BackButton path={returnPath} />
                                <Button
                                    type='button'
                                    variant='contained'
                                    color='primary'
                                    onClick={handleSubmit(userSubmit)}>
                                    確認
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
