//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useRef, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import {
    TableRow,
    TableCell,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    DialogTitle,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// import TakeReceipt from "../TakeReceipt";
// import ShowReceipt from "../ShowReceipt";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { SuspensePaymentContext } from "../../../contents/suspense_payment/SuspensePaymentRegister";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import amountToLocaleString from "../../../common/amountToLocaleString";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// MUIのTooltipのz-indexを調整
const StyledTooltip = withStyles({
    "@global": {
        ".MuiTooltip-popper": {
            zIndex: "1300",
        },
    },
})(Tooltip);
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// 入力欄のname一覧を保持
const inputList = ["start_date", "end_date", "detail"];
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function SuspensePaymentInputRow(props) {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const {
        equalMaxNumber,
        setEqualMaxNumber,
        loaded,
        setIsDeleted,
        isDeleted = false,
        displayErrorMessage,
        setDisplayErrorMessage,
        unregister,
        register,
        formState,
        setValue,
        getValues,
        control,
        watch,
        trigger,
        loggedInUserId,
        maxSuspensePaymentAdditionalNumber,
        dispatchSuspensePaymentRequestData,
        dispatchSuspensePaymentAdditionalNumbers,
        dispatchSuspensePaymentValidationErrors,
        // suspensePaymentValidationTrigger,
        // isConfirmed,
    } = useContext(SuspensePaymentContext);
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { number, suspensePaymentData = {}, monthlyPeriod } = props;
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 仮払金申請書兼受領書において1page当たりの仮払い申請件数
    const SUSPENSE_PAYMENTS_PER_PAGE = 15;
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // const [editable, setEditable] = useState(true); // 編集可能かを管理

    // 締め処理済みの場合に使用
    // const [formattedDate, setFormattedDate] = useState(""); // 表示する日付を管理する

    const validationErrorsRef = useRef({}); // 最新のformState.errorsの値を保持する
    const [updateFlg, setUpdateFlg] = useState(false); // 作成済みで編集対象であることのフラグ
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 削除・復元ボタン押下時の処理
    const handleOnClickDeleteAndRestore = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        trigger(); // バリデーション実行

        // 行の削除状態を切り替える
        setIsDeleted(!isDeleted);
    };

    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    // 削除または復元時に、リクエストデータを更新する
    useEffect(() => {
        if (loaded) {
            if (isDeleted) {
                setDisplayErrorMessage(false); // バリデーションエラーメッセージを非表示にする
                setDisplayErrorMessage(false); // バリデーションエラーの赤枠を非表示
                // 新規のデータは削除後はリクエストデータに含めない
                dispatchSuspensePaymentRequestData({ type: "remove", number: number }); // リクエストデータから対象のレコードを削除
                dispatchSuspensePaymentValidationErrors({ type: "remove", number: number }); // エラーの配列からこの行のエラーを削除
            } else {
                const data = getValues(); // フォームの値を取得
                createData(data); // リクエストデータを準備する関数を実行
            }
        }
    }, [isDeleted]);

    // 送信するデータを受け取った配列に更新する
    const updateRowRequest = async () => {
        const values = getValues(); // 現在のフォーム全体のinputの値
        createData(values); // リクエストデータを準備する
        // 新規作成中の行のどれか1項目だけでも値が入力されており,行の上限数を下回っている場合
        if (
            !updateFlg &&
            SUSPENSE_PAYMENTS_PER_PAGE > number &&
            Boolean(inputList.find((input) => values.suspense_payment_detail[number][input]))
        ) {
            // 新しい行を追加
            dispatchSuspensePaymentAdditionalNumbers({
                type: "add",
                number: number,
            });
        }
    };

    // リクエストデータを作成し、ステートのdispatchで更新する
    const createData = (data) => {
        // バリデーションエラーについて更新
        if (Object.keys(validationErrorsRef.current).length > 0) {
            dispatchSuspensePaymentValidationErrors({ type: "add", number: number });
        } else {
            dispatchSuspensePaymentValidationErrors({ type: "remove", number: number });
        }

        // 送信するデータを作成
        const requestData = {
            suspense_payment: {
                id: null,
                date: data.date,
                receipt_date: data.receipt_date,
                user_id: loggedInUserId,
                amount: Number(data.amount),
                is_paid: false,
                is_changed: false,
            },
            suspense_payment_detail: data.suspense_payment_detail,
        };
        // リクエストデータのステートを更新
        dispatchSuspensePaymentRequestData({ type: "update", data: requestData, number: number });
    };

    // 内容が変更されたかを監視
    useEffect(() => {
        const subscription = watch(async (value, { name, type }) => {
            if (type === "change") {
                await trigger(); // バリデーションを実行
                await updateRowRequest();
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    // 二桁の0埋め用関数
    const zeroPadding = (value) => {
        return ("0" + value).slice(-2);
    };

    // react-hook-formのformStateの更新時に、バリデーションエラーをRefオブジェクトに保持する
    useEffect(() => {
        if (loaded) {
            validationErrorsRef.current = formState.errors;
        }
    }, [formState]);

    useEffect(() => {
        // 行が最後尾であるかどうかを判断し、ステートに状態を格納する
        setEqualMaxNumber(number === maxSuspensePaymentAdditionalNumber);
    }, [maxSuspensePaymentAdditionalNumber]);
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <TableRow className={`align-baseline${isDeleted ? " bg-secondary" : ""}`}>
            <TableCell align='center' padding='none'>
                <StyledTooltip
                    arrow={true}
                    title={
                        !equalMaxNumber && displayErrorMessage && formState.errors.start_date?.[number]
                            ? formState.errors.start_date?.[number]?.message
                            : ""
                    }>
                    <div
                        className={
                            !equalMaxNumber && displayErrorMessage && formState.errors.start_date?.[number]
                                ? "border border-danger border-2 rounded rounded-3"
                                : ""
                        }>
                        <input
                            className='form-control form-control-sm'
                            type='date'
                            // min={monthlyPeriod.min}
                            // max={monthlyPeriod.max}
                            disabled={isDeleted}
                            {...register(`suspense_payment_detail.${number}.start_date`, {
                                required: {
                                    value: true,
                                    message: "必須項目です",
                                },
                            })}
                        />
                    </div>
                </StyledTooltip>
            </TableCell>
            <TableCell align='center' padding='none'>
                <StyledTooltip
                    arrow={true}
                    title={
                        !equalMaxNumber && displayErrorMessage && formState.errors.end_date?.[number]
                            ? formState.errors.end_date?.[number]?.message
                            : ""
                    }>
                    <div
                        className={
                            !equalMaxNumber && displayErrorMessage && formState.errors.end_date?.[number]
                                ? "border border-danger border-2 rounded rounded-3"
                                : ""
                        }>
                        <input
                            className='form-control form-control-sm'
                            type='date'
                            // min={monthlyPeriod.min}
                            // max={monthlyPeriod.max}
                            disabled={isDeleted}
                            {...register(`suspense_payment_detail.${number}.end_date`, {
                                required: {
                                    value: true,
                                    message: "必須項目です",
                                },
                            })}
                        />
                    </div>
                </StyledTooltip>
            </TableCell>
            <TableCell align='center' padding='none'>
                <StyledTooltip
                    arrow={true}
                    title={
                        !equalMaxNumber && displayErrorMessage && formState.errors.detail?.[number]
                            ? formState.errors.detail?.[number]?.message
                            : ""
                    }>
                    <div
                        className={
                            !equalMaxNumber && displayErrorMessage && formState.errors.detail?.[number]
                                ? "border border-danger border-2 rounded rounded-3"
                                : ""
                        }>
                        <input
                            className='form-control form-control-sm'
                            type='text'
                            disabled={isDeleted}
                            {...register(`suspense_payment_detail.${number}.detail`, {
                                required: {
                                    value: true,
                                    message: "必須項目です",
                                },
                            })}
                        />
                    </div>
                </StyledTooltip>
            </TableCell>
            <TableCell align='center' padding='none'>
                {!equalMaxNumber && (
                    <div className='px-1'>
                        <button
                            className='btn btn-sm btn-secondary px-3'
                            type='button'
                            onClick={(e) => handleOnClickDeleteAndRestore(e)}>
                            {isDeleted ? "復元" : "削除"}
                        </button>
                    </div>
                )}
            </TableCell>
        </TableRow>
    );
}
