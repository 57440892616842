//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { Button, Box } from "@material-ui/core";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import SuspensePaymentDataTable from "../../components/transaction/suspense_payment/SuspensePaymentDataTable";
import BackButton from "../../components/BackButton";
import CloseButton from "../../components/CloseButton";
import OutputExcel from "../../components/OutputExcel";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { UserContext } from "../../providers/UserProvider";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { HOME_PATH } from "../../common/constants";
import processResponse from "../../common/processResponse";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// ホーム画面に戻る時の遷移先のパス
const returnPath = HOME_PATH;
// 見出しの配列のパターンを定義
const headers = ["変更確認", "精算", "作成日", "金額", "照会", "編集", "削除", "ダウンロード"];
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// suspense_payments の初期状態
const initSuspensePayments = { result: [], success: false };
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function SuspensePayment() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面遷移用
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // ログイン中のユーザー情報を取得
    const { userInfo } = useContext(UserContext);
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // suspensePayments の状態を管理する
    const [suspensePayments, setSuspensePayments] = useState(initSuspensePayments);

    // suspensePayments を表示行に変換したものを保持する
    const [rows, setRows] = useState([]);

    // 確認
    const [confirmedChange, setConfirmedChange] = useState([]);
    const [confirmedChangeDone, setConfirmedChangeDone] = useState(false);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 情報を取得しステート suspensePayments にセットする
    const getSuspensePaymentsData = async (id) => {
        const url = `suspense_payments_user_data/${id}`;
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        processResponse(res, setSuspensePayments, navigate, returnPath);
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 新規登録ボタンクリック時
    const handleOnClickRegister = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // 新規登録画面へ進む
        navigate(`/suspense_payment/register`);
    };
    // 仮払金申請書兼受領書Excel fileをdownloadする
    const downloadSuspensePaymentExcel = (id, created_date, user_name = userInfo.user.user_name) => (
        <OutputExcel
            fileName={`仮払金受領書_${user_name}_${created_date}`}
            fileType='Excel'
            api_url='export_suspense_payment'
            buttonTextIsFileName={false}
            data={{ id: id }}
        />
    );
    // 一覧の編集ボタンをクリックしたら、仮払い情報編集画面へ
    const handleOnClickEdit = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 編集画面へ進む
        navigate(`/suspense_payment/edit/${id}`);
    };

    // 削除
    const handleOnClickDelete = async (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 確認のための変数を定義
        let confirm = false;

        // 確認画面を表示
        await swal({
            icon: "info",
            title: "対象の仮払いを削除しますか？",
            text: "",
            buttons: ["キャンセル", "削除"]
        }).then((value) => {
            if (value) {
                confirm = true;
            }
        });

        // キャンセルの場合は処理を中断する
        if (!confirm) {
            return;
        }

        //削除
        const url = `suspense_payments/${id}`;
        const res = await api.delete(url);
        //---------------------------issue{No.81} start-----------------------------
        // 成否の確認
        if (res.data.success) {
            // 削除成功時
            // モーダルウィンドウで成功メッセージを表示
            swal(res.data.message, "", "success").then(() => {
                // 削除したユーザを除外した全ユーザを配列化
                const newSuspensePayments = suspensePayments.result.filter(
                    (suspensePayment) => suspensePayment.suspense_payment_id !== id
                );
                // ステートを更新
                setSuspensePayments({ result: newSuspensePayments, success: true });
            });
        } else {
            // 削除失敗時にエラーに応じた処理を行う
            processErrorResponse(res, () => {}, navigate);
        }
    };

    // 照会
    const handleOnClickShow = async (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 照会画面へ進む
        navigate(`/suspense_payment/show/${id}`);
    };

    // 確認チェックボックス押下時、データ送信用ステート更新
    const handleOnChangeConfirm = (e) => {
        const value = e.target.value;
        const isChecked = e.target.checked;

        // チェック時
        // 対象のステート（配列）にvalue追加
        if (isChecked) {
            setConfirmedChange([...confirmedChange, value]);
        } else {
            // 非チェック時
            // 対象のステート（配列）からvalue削除
            // 対象を除いた配列を作成
            const arr = confirmedChange.filter((item) => {
                return item != value;
            });

            setConfirmedChange(arr);
        }
    };

    // 変更フラグを解除する
    const handleOnChangeConfirmSubmit = async (e) => {
        e.preventDefault();
        // 確認のための変数を定義
        let confirm = false;

        // 確認画面を表示
        await swal({
            title: "確認",
            text: "チェックした行のデータ変更を確認しました。",
            buttons: ["戻る", "確認"],
        }).then((value) => {
            if (value) {
                confirm = true;
            }
        });

        // 戻る場合は処理を中断する
        if (!confirm) {
            return;
        }

        const url = "suspense_payment_reset_is_changed";
        const res = await api.post(url, confirmedChange);
        // 成否の確認
        if (res.data.success) {
            // 登録成功時
            swal(res.data.message, "", "success").then(() => {
                setConfirmedChangeDone(true);
                const id = userInfo.user.user_id;
                // 画面初期表示時に仮払い情報を取得する
                getSuspensePaymentsData(id);
            });
        } else {
            // 登録失敗時にエラーに応じた処理を行う
            processErrorResponse(res, setUnpaidData, navigate);
        }
    };
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => {
        const id = userInfo.user.user_id;
        // 画面初期表示時に仮払い情報を取得する
        getSuspensePaymentsData(id);
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // searchedSuspensePaymentsに応じて、画面表示用の配列を生成
    useEffect(() => {
        if (suspensePayments.success) {
            const newRows = suspensePayments.result.map((suspensePayments) => {
                const row = {
                    id: suspensePayments.suspense_payment_id,
                    isActive: true,
                    isChanged: suspensePayments.suspense_payment_is_changed == 1 ? true : false,
                    data: {
                        is_paid: suspensePayments.suspense_payment_is_paid ? "済" : "未",
                        date: suspensePayments.suspense_payment_date,
                        amount: suspensePayments.suspense_payment_amount,
                        showButton: (
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={(e) => handleOnClickShow(e, suspensePayments.suspense_payment_id)}>
                                照会
                            </Button>
                        ),
                        editBtn:
                            suspensePayments.is_closed || suspensePayments.suspense_payment_is_paid ? (
                                "-"
                            ) : (
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={(e) => handleOnClickEdit(e, suspensePayments.suspense_payment_id)}>
                                    編集
                                </Button>
                            ),
                        deleteBtn:
                            suspensePayments.is_closed || suspensePayments.suspense_payment_is_paid ? (
                                "-"
                            ) : (
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={(e) => handleOnClickDelete(e, suspensePayments.suspense_payment_id)}>
                                    削除
                                </Button>
                            ),
                        downloadBtn: downloadSuspensePaymentExcel(
                            suspensePayments.suspense_payment_id,
                            suspensePayments.suspense_payment_date,
                            userInfo.user.user_name
                        ),
                    },
                };

                return row;
            });

            setRows(newRows);
        }
    }, [suspensePayments]);
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1>仮払い情報一覧</h1>
                        <div className='ms-auto'>
                            <CloseButton path={returnPath} />
                        </div>
                    </div>
                    <div className='card-body'>
                        <Box className='card-body' sx={{ display: "flex", width: "max-content", ml: "auto" }}>
                            <Button variant='contained' color='primary' onClick={(e) => handleOnClickRegister(e)}>
                                新規登録
                            </Button>
                        </Box>
                        <SuspensePaymentDataTable
                            headers={headers}
                            rows={rows}
                            handleOnChangeConfirmSubmit={handleOnChangeConfirmSubmit}
                            handleOnChangeConfirm={handleOnChangeConfirm}
                            confirmedChange={confirmedChange}
                            setConfirmedChange={setConfirmedChange}
                            confirmedChangeDone={confirmedChangeDone}
                            setConfirmedChangeDone={setConfirmedChangeDone}
                        />
                    </div>
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackButton path={returnPath} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
