//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { createContext, useEffect, useRef, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ライブラリの読み込み_________________________________________________//
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import BackButton from "../../components/BackButton";
//_________________________________________________コンポーネント読み込み_________________________________________________//
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { HOME_PATH } from "../../common/constants";
import amountToLocaleStringForMonthlyData from "../../common/amountToLocaleStringForMonthlyData";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        userName: {
            fontSize: "18px",
            fontWeight: "bold"
        },
        userTables: {
            marginBottom: "100px"
        },
        userTable: {
            marginBottom: "50px"
        },
        userTopTable: {
            marginTop: "50px",
            marginBottom: "50px"
        },
        underLine: {
            marginBottom: "100px"
        },
        caption: {
            fontSize: "16px"
        },
        tableHead1: {
            backgroundColor: "#99ccff"
        },
        tableHead2: {
            backgroundColor: "#66ff99"
        },
        tableHead3: {
            backgroundColor: "#ffbb88"
        },
        dialog: {
            maxHeight: "60vh",
            padding: "10px",
            boxSizing: "border-box",
            backgroundColor: "#fefefe",
            border: "1px solid #666",
            textAlign: "center"
        },
        imgWrapper: {
            width: "100%",
            height: "calc(100% - 80px)"
        },
        img: {
            objectFit: "contain",
            maxWidth: "100%",
            maxHeight: "100%"
        },
        plus: {
            fontWeight: "bold",
            fontSize: "1.5rem"
        },
        minus: {
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: "#dc3545"
        }
    })
);
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// 戻るときの遷移先のパス
const returnPath = HOME_PATH;
const suspensePaymentHeaders = ["No.", "作成日", "受領日", "金額"];
const travelExpenseHeaders = ["No.", "年月日", "金額", "支払先", "仕訳", ""];
const differenceHeaders = ["仮払い合計", "経費合計", "差額"];
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// 未払いのユーザー毎の各種データ
const initUnpaidList = { result: [], success: false };
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function CalculateList() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //定義したスタイルを利用するための設定
    const classes = useStyles();
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const [unpaidList, setUnpaidList] = useState(initUnpaidList);
    const [imgData, setImgData] = useState(null);
    const [imgSrc, setImgSrc] = useState("");
    const [showImg, setShowImg] = useState(false);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const getData = async (url, setState) => {
        const res = await api.get(url);
        // 成功時、ステートを更新
        if (res.data.success) {
            setState(res.data.result);
        }
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => {
        getData("suspense_payments_for_unpaid_list", setUnpaidList);
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画像の表示切替
    const handleOnClickSnowImg = (targetMonthYear, id, fileName, isReceipt) => {
        if (targetMonthYear !== undefined) {
            const folderName = isReceipt ? "receipt" : "suspense_payment";
            const splittedTargetMonthYear = targetMonthYear.split("-");
            const targetMonth = `${splittedTargetMonthYear[0]}-${splittedTargetMonthYear[1]}`;
            const path = `file/${folderName}/${targetMonth}/${id}/${fileName}`;
            getData(path, setImgData);
        }
    };

    useEffect(() => {
        if (imgData !== null) {
            const adjustedData = JSON.parse(imgData); // 受け取ったデータをjsonに
            const mimeType = adjustedData.mimeType;
            const fileText = adjustedData.fileText;
            const receiptUri = "data:" + mimeType + ";base64," + fileText;
            setShowImg(true);
            setImgSrc(receiptUri);
        }
    }, [imgData]);

    // 画像用
    const handleOnClickCloseImage = () => {
        setImgSrc("");
        setImgData(null);
        setShowImg(false);
    };

    const checkPositiveNumber = (suspense, travel) => {
        const result = suspense - travel >= 0 ? true : false;
        return result;
    };
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//

    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h2>未精算一覧</h2>
                    </div>
                    <div className='card-body row'>
                        <div className='col-7'>
                            {unpaidList.length > 0 &&
                                unpaidList.map((parentItem, index) => {
                                    return (
                                        <>
                                            <div key={`parentItem_${index}`} className={classes.userTables}>
                                                <h3 className={classes.userName}>{parentItem.user.user_name}</h3>
                                                {/* 差額 */}
                                                <div className={classes.userTopTable}>
                                                    <h4 className={classes.caption}>差額</h4>
                                                    <Paper>
                                                        <TableContainer>
                                                            <Table>
                                                                <TableHead className={classes.tableHead3}>
                                                                    <TableRow>
                                                                        {differenceHeaders.map(
                                                                            (differenceHeader, index) => {
                                                                                return (
                                                                                    <TableCell
                                                                                        key={`differenceHeader_${index}`}>
                                                                                        {differenceHeader}
                                                                                    </TableCell>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell>
                                                                            {Number(
                                                                                parentItem.total_amount
                                                                                    .suspense_payment_total_amount
                                                                            ).toLocaleString()}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {Number(
                                                                                parentItem.total_amount
                                                                                    .travel_expense_total_amount
                                                                            ).toLocaleString()}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <span
                                                                                className={
                                                                                    checkPositiveNumber(
                                                                                        parentItem.total_amount
                                                                                            .suspense_payment_total_amount,
                                                                                        parentItem.total_amount
                                                                                            .travel_expense_total_amount
                                                                                    )
                                                                                        ? classes.plus
                                                                                        : classes.minus
                                                                                }>
                                                                                {amountToLocaleStringForMonthlyData(
                                                                                    parentItem.total_amount
                                                                                        .suspense_payment_total_amount -
                                                                                        parentItem.total_amount
                                                                                            .travel_expense_total_amount
                                                                                )}
                                                                            </span>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Paper>
                                                </div>
                                                {/* 差額 END */}
                                                {/* 仮払い */}
                                                {parentItem.suspense_payment.length > 0 ? (
                                                    <div className={classes.userTable}>
                                                        <h4 className={classes.caption}>仮払い</h4>
                                                        <Paper>
                                                            <TableContainer>
                                                                <Table>
                                                                    <TableHead className={classes.tableHead1}>
                                                                        <TableRow>
                                                                            {suspensePaymentHeaders.map(
                                                                                (suspensePaymentHeader, index) => {
                                                                                    return (
                                                                                        <TableCell
                                                                                            key={`suspensePaymentHeader_${index}`}>
                                                                                            {suspensePaymentHeader}
                                                                                        </TableCell>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {parentItem.suspense_payment.map(
                                                                            (suspensePaymentItem, index) => {
                                                                                return (
                                                                                    <TableRow
                                                                                        key={`suspensePaymentItem_${index}`}>
                                                                                        <TableCell>
                                                                                            {index + 1}
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {
                                                                                                suspensePaymentItem.suspense_payment_date
                                                                                            }
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {
                                                                                                suspensePaymentItem.suspense_payment_receipt_date
                                                                                            }
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {Number(
                                                                                                suspensePaymentItem.suspense_payment_amount
                                                                                            ).toLocaleString()}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            }
                                                                        )}
                                                                        <TableRow>
                                                                            <TableCell></TableCell>
                                                                            <TableCell></TableCell>
                                                                            <TableCell>合計</TableCell>
                                                                            <TableCell>
                                                                                {Number(
                                                                                    parentItem.total_amount
                                                                                        .suspense_payment_total_amount
                                                                                ).toLocaleString()}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Paper>
                                                    </div>
                                                ) : (
                                                    <div className='text-center'>
                                                        <p>未精算の仮払いは存在しません。</p>
                                                    </div>
                                                )}
                                                {/* 仮払い END */}
                                                {/* 経費 */}
                                                {parentItem.travel_expense.length > 0 ? (
                                                    <div className={classes.userTable}>
                                                        <h4 className={classes.caption}>経費</h4>
                                                        <Paper>
                                                            <TableContainer>
                                                                <Table>
                                                                    <TableHead className={classes.tableHead2}>
                                                                        <TableRow>
                                                                            {travelExpenseHeaders.map(
                                                                                (travelExpenseHeader, index) => {
                                                                                    return (
                                                                                        <TableCell
                                                                                            key={`travelExpenseHeader_${index}`}>
                                                                                            {travelExpenseHeader}
                                                                                        </TableCell>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {parentItem.travel_expense.map(
                                                                            (travelExpenseItem, index) => {
                                                                                return (
                                                                                    <TableRow
                                                                                        key={`travelExpenseItem_${index}`}>
                                                                                        <TableCell>
                                                                                            {index + 1}
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {
                                                                                                travelExpenseItem.travel_expense_date
                                                                                            }
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {Number(
                                                                                                travelExpenseItem.travel_expense_amount
                                                                                            ).toLocaleString()}
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {
                                                                                                travelExpenseItem.travel_expense_name
                                                                                            }
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {
                                                                                                travelExpenseItem.travel_expense_account_list_name
                                                                                            }
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            <button
                                                                                                className='btn btn-primary btn-sm px-3 opacity-100'
                                                                                                onClick={(e) => {
                                                                                                    handleOnClickSnowImg(
                                                                                                        travelExpenseItem.travel_expense_target_month_year,
                                                                                                        parentItem.user
                                                                                                            .user_id,
                                                                                                        travelExpenseItem.travel_expense_file_name,
                                                                                                        true
                                                                                                    );
                                                                                                }}>
                                                                                                領収書
                                                                                            </button>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            }
                                                                        )}
                                                                        <TableRow>
                                                                            <TableCell></TableCell>
                                                                            <TableCell>合計</TableCell>
                                                                            <TableCell>
                                                                                {Number(
                                                                                    parentItem.total_amount
                                                                                        .travel_expense_total_amount
                                                                                ).toLocaleString()}
                                                                            </TableCell>
                                                                            <TableCell></TableCell>
                                                                            <TableCell></TableCell>
                                                                            <TableCell></TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Paper>
                                                    </div>
                                                ) : (
                                                    <div className='text-center'>
                                                        <p>未精算の経費は存在しません。</p>
                                                    </div>
                                                )}
                                                {/* 経費 END */}
                                            </div>
                                            <hr className={classes.underLine} />
                                        </>
                                    );
                                })}
                        </div>
                        {showImg && (
                            <div className={`${classes.dialog} col-5 sticky-top`}>
                                <div className={classes.imgWrapper}>
                                    <img className={classes.img} src={imgSrc} alt='領収書・受領書' />
                                </div>
                                <div className='mt-5'>
                                    <button onClick={handleOnClickCloseImage}>閉じる</button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackButton path={returnPath} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
