import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
    createStyles({
        table: {
            minWidth: 650
        },
        tableHead: {},
        tableCell: {
            height: "43px",
            padding: 3
        },
        tableRowActive: {
            "&:hover": {
                background: "#e3f2fd",
                boxShadow: "0 0 1px 1px #c5cae9 inset"
            }
        },
        tableRowInActive: {
            backgroundColor: "#aaa",
            "&:hover": {
                background: "#bbb"
            }
        },
        tableRowIsChanged: {
            backgroundColor: "#fdd",
            "&:hover": {
                background: "#fee"
            }
        }
    })
);

export default function SuspensePaymentDataTable(props) {
    // 定義したスタイルを利用するための設定
    const classes = useStyles();

    // 親コンポーネントからpropsで受け取る
    const {
        headers = [],
        rows = [],
        handleOnChangeConfirmSubmit,
        handleOnChangeConfirm,
        confirmedChange,
        setConfirmedChange,
        confirmedChangeDone,
        setConfirmedChangeDone
    } = props;

    return (
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label='simple table'>
                    {/* ヘッダー部分 */}
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            {headers.map((item, index) => (
                                <TableCell className={classes.tableCell} align='center' key={index}>
                                    {item}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {/* ボディ部分 */}
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow
                                key={index}
                                className={`${row.isActive ? classes.tableRowActive : classes.tableRowInActive} ${
                                    row.isChanged ? classes.tableRowIsChanged : ""
                                }`}>
                                <TableCell align='center' padding='none'>
                                    {row.isChanged ? (
                                        <input
                                            type='checkbox'
                                            onClick={(e) => {
                                                handleOnChangeConfirm(e);
                                            }}
                                            value={row.id}
                                        />
                                    ) : (
                                        "-"
                                    )}
                                </TableCell>
                                {Object.keys(row.data).map(function (key, i) {
                                    return (
                                        <TableCell className={classes.tableCell} align='center' key={i}>
                                            {row.data[key]}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {confirmedChange.length > 0 && (
                <div className='text-center mt-4'>
                    <button
                        className='btn btn-lg btn-outline-secondary py-2 px-5'
                        type='button'
                        onClick={(e) => handleOnChangeConfirmSubmit(e)}>
                        変更確認
                    </button>
                </div>
            )}
        </>
    );
}
