//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useEffect, useMemo, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import swal from "sweetalert";
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { makeStyles, createStyles } from "@material-ui/core/styles";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import BackButton from "../../components/BackButton";
import SelectMonthAndYearMenu from "../../components/transaction/monthly_data/SelectMonthAndYearMenu";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { HOME_PATH } from "../../common/constants";
import dateFormat from "../../common/dateFormat";
import monthAndYearFormat from "../../common/monthAndYearFormat";
import processErrorResponse from "../../common/processErrorResponse";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        top: {
            zIndex: 1000
        }
    })
);
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// 戻るときの遷移先のパス
const returnPath = HOME_PATH;

const nowMonthAndYear = new Date(); // 今月の日付を取得
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
const iniClosingProcessData = { result: [], success: false };
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function ClosingProcess() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //定義したスタイルを利用するための設定
    const classes = useStyles();
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    // datePickerの選択済みの値を管理する
    const [startDate, setStartDate] = useState(nowMonthAndYear);

    // 選択した年月の値を文字列としてmemo化

    const selectedMonthAndYear = useMemo(() => monthAndYearFormat(startDate), [startDate]);

    // 選択した対象月に対しての作業を開始する
    const [start, setStart] = useState(false);

    // 締め処理済みかどうかを管理
    const [isConfirmed, setIsConfirmed] = useState(false);

    // 締め処理済みかどうかを管理
    const [closingProcessData, setClosingProcessData] = useState(iniClosingProcessData);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 仮払い明細の全レコードを取得
    const getClosingProcessData = async () => {
        const url = `close/${selectedMonthAndYear}`;
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        if (res.data.success) {
            setClosingProcessData(res.data);
        } else {
            setClosingProcessData({
                closing_process_id: null,
                closing_process_is_closed: false
            });
        }
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 開始ボタン押下時
    const selectSubmit = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        setStart(true);
    };

    // 締め・締め解除
    const handleOnClose = async (e) => {
        // イベントの伝搬を中止
        e.preventDefault();
        const swalMessage = [
            { title: "確認", text: "締め処理を行います", icon: "info", buttons: ["中止", "締める"] },
            { title: "確認", text: "締め処理を解除します", icon: "info", buttons: ["中止", "解除"] }
        ];
        const swalMessageNumber = isConfirmed ? 1 : 0;

        await swal(swalMessage[swalMessageNumber]).then((value) => {
            if (value) {
                // リクエストデータをapiで送信する処理へ進む
                sendClosingProcessRequestData();
            }
        });
    };

    // メール送信
    const handleOnSendMail = async (e) => {
        // イベントの伝搬を中止
        e.preventDefault();
        // 確認のための変数を定義
        let confirm = false;

        // 確認画面を表示
        await swal({
            title: "確認",
            text: "税理士にメールを送信します。",
            icon: "info",
            buttons: ["戻る", "送信"]
        }).then((value) => {
            if (value) {
                confirm = true;
            }
        });

        // 戻る場合は処理を中断する
        if (!confirm) {
            return;
        }

        // 送信
        const url = "close_mail";
        const res = await api.get(url);
        //---------------------------issue{No.81} start-----------------------------
        // 成否の確認
        if (res.data.success) {
            // 送信成功時
            swal("メールを送信しました。", "", "success").then(() => {
                navigate(returnPath);
            });
        } else {
            // 登録失敗時にエラーに応じた処理を行う
            swal("メールの送信に失敗しました。", "", "error").then(() => {
                navigate(returnPath);
            });
        }
    };
    //_________________________________________________イベントハンドラーの定義_________________________________________________//
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => {
        if (start && startDate) {
            // 表示画像を初期化
            getClosingProcessData();
        }
    }, [start]);

    // 締め処理情報取得時の処理
    useEffect(() => {
        if (closingProcessData.success) {
            setIsConfirmed(closingProcessData?.result?.closing_process_is_closed == 1 ? true : false);
        }
    }, [closingProcessData]);

    // リクエストデータをapiでコントローラーに渡すなどの処理
    const sendClosingProcessRequestData = async () => {
        // リクエストデータをまとめる
        const requestData = {
            id: closingProcessData?.result?.closing_process_id,
            date: selectedMonthAndYear,
            is_closed: !isConfirmed
        };

        // 登録
        const url = "close";
        const res = await api.post(url, requestData);
        // 成否の確認
        if (res.data.success) {
            // 登録成功時
            swal(res.data.message, "", "success");
            setIsConfirmed(!isConfirmed);
            setStart(!start);
        } else {
            // 登録失敗時にエラーに応じた処理を行う
            processErrorResponse(res, () => {}, navigate);
        }
    };
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//
    //=====================================================JSXここから=====================================================//
    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h2>締め処理</h2>
                    </div>
                    <div className='card-body'>
                        {!start ? (
                            <div className='d-flex justify-content-start align-items-center text-nowrap mt-4'>
                                <SelectMonthAndYearMenu
                                    startDate={startDate}
                                    setStartDate={setStartDate}
                                />
                                <button
                                    className={`${classes.top} btn btn-secondary`}
                                    type='button'
                                    onClick={(e) => {
                                        selectSubmit(e);
                                    }}>
                                    開始
                                </button>
                            </div>
                        ) : (
                            <>{!start && <p className='m-4'>...読み込み中</p>}</>
                        )}
                        {start && (
                            <div className='d-flex justify-content-start align-items-center text-nowrap my-4'>
                                <label className='fw-bold me-3'>
                                    対象月：
                                    <input
                                        type='text'
                                        readOnly
                                        value={dateFormat(selectedMonthAndYear, false, "Y-m").format_date}
                                    />
                                </label>
                                {isConfirmed ? (
                                    <p className='h-auto m-0 fw-bold'>締め処理済みです</p>
                                ) : (
                                    <p className='h-auto m-0 fw-bold'>締め処理されていません</p>
                                )}
                            </div>
                        )}
                        <div className='d-flex w-100'>
                            <div className='ms-5'>
                                {start && (
                                    <div className='d-flex p-2 text-nowrap'>
                                        <button
                                            className={`${classes.closeButton} btn btn-lg btn-outline-success py-2 px-5`}
                                            type='button'
                                            onClick={(e) => handleOnClose(e)}>
                                            {isConfirmed ? "締め解除" : "締め"}
                                        </button>
                                        {isConfirmed && (
                                            <button
                                                className='ms-5 btn btn-lg btn-outline-primary py-2 px-5'
                                                type='button'
                                                onClick={(e) => handleOnSendMail(e)}>
                                                メール送信
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackButton path={returnPath} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
