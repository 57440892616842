import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import swal from "sweetalert";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import { useForm } from "react-hook-form";
import { format, subMonths } from "date-fns";
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import SuspensePaymentRequestTable from "../../components/transaction/suspense_payment/SuspensePaymentRequestTable";
import BackButton from "../../components/BackButton";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { UserContext } from "../../providers/UserProvider";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import arraySortByDate from "../../common/arraySortByDate";
import { HOME_PATH, MIN_MAX_DATE } from "../../common/constants";
import processErrorResponse from "../../common/processErrorResponse";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import "react-datepicker/dist/react-datepicker.css";
// MUIのTooltipのz-indexを調整
const StyledTooltip = withStyles({
    "@global": {
        ".MuiTooltip-popper": {
            zIndex: "1300",
        },
    },
})(Tooltip);
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// 戻るときの遷移先のパス
const returnPath = HOME_PATH;

// DatePickerの日本語化に必要
registerLocale("ja", ja);

// // 先月を定義
// function lastMonthFormat(date) {
//     date.setMonth(date.getMonth() - 1);
//     return date;
// }
// // 先月の日付を取得
// const lastMonth = lastMonthFormat(new Date());
// 先月の日付を取得
const lastMonth = format(subMonths(new Date(), 1), "yyyy-MM-dd");
// 本日の日付を取得
const today = format(new Date(), "yyyy-MM-dd");
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// ステートの初期状態
const initSuspensePaymentOldest = { result: [], success: false };

// ステートの初期状態
const initSuspensePayments = { result: [], success: false };

// ステートの初期状態
const initSuspensePaymentsValidationErrors = [];
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//

// コンテクストを作成
export const SuspensePaymentContext = createContext();

// リクエストデータのステート更新用関数
const reducerSuspensePaymentRequestDataFunc = (suspensePaymentRequestData, action) => {
    const number = action?.number;
    const data = action?.data;
    switch (action?.type) {
        // case "set":
        // case "update":
        //     return data;
        // case "update":
        //     suspensePaymentRequestData.suspense_payment = data.suspense_payment;
        //     if (Array.isArray(suspensePaymentRequestData.suspense_payment_detail)) {
        //         if (suspensePaymentRequestData.suspense_payment_detail.find((item, index) => item.number === number)) {
        //             const requestDataArray = suspensePaymentRequestData.suspense_payment_detail.map((item) => {
        //                 if (item.number === number) {
        //                     return data.suspense_payment_detail;
        //                 } else {
        //                     return item;
        //                 }
        //             });
        //             return {
        //                 suspense_payment: suspensePaymentRequestData.suspense_payment,
        //                 suspense_payment_detail: requestDataArray
        //             };
        //         } else {
        //             return {
        //                 suspense_payment: suspensePaymentRequestData.suspense_payment,
        //                 suspense_payment_detail: [
        //                     ...suspensePaymentRequestData.suspense_payment_detail,
        //                     data.suspense_payment_detail
        //                 ]
        //             };
        //         }
        //     } else {
        //         if (
        //             Object.keys(suspensePaymentRequestData.suspense_payment_detail).find(
        //                 (key, index) => suspensePaymentRequestData.suspense_payment_detail.number === number
        //             )
        //         ) {
        //             if (suspensePaymentRequestData.suspense_payment_detail.number === number) {
        //                 return {
        //                     suspense_payment: suspensePaymentRequestData.suspense_payment,
        //                     suspense_payment_detail: data.suspense_payment_detail
        //                 };
        //             } else {
        //                 return {
        //                     suspense_payment: suspensePaymentRequestData.suspense_payment,
        //                     suspense_payment_detail: suspensePaymentRequestData.suspense_payment_detail
        //                 };
        //             }
        //         } else {
        //             return {
        //                 suspense_payment: suspensePaymentRequestData.suspense_payment,
        //                 suspense_payment_detail: data.suspense_payment_detail
        //             };
        //         }
        //     }
        case "remove":
            const array = /* suspensePaymentRequestData */ data?.suspense_payment_detail.filter(
                (payment, index) => index !== number
            );
            return array;
        case "reset":
            return [];
        case "set":
        case "update":
        default:
            return data; // suspensePaymentRequestData;
    }
};

// 追加の行のナンバーを管理するステートの更新用関数
const reducerSuspensePaymentAdditionalNumbersFunc = (suspensePaymentAdditionalNumbers, action) => {
    switch (action.type) {
        case "set":
            return action.value;
        case "add":
            const maxNumber = Math.max(...suspensePaymentAdditionalNumbers);
            const number = action.number;
            if (number === maxNumber) {
                const nextNumber = maxNumber + 1;
                return [...suspensePaymentAdditionalNumbers, nextNumber];
            }
            return suspensePaymentAdditionalNumbers;
        case "reset":
            return [];
        default:
            return suspensePaymentAdditionalNumbers;
    }
};

// バリーデーションエラーの有無を管理するステートの更新用関数
const reducerSuspensePaymentValidationErrorsFunc = (suspensePaymentValidationErrors, action) => {
    const number = action.number;
    switch (action.type) {
        case "set":
            return action.data;
        case "reset":
            return initSuspensePaymentsValidationErrors;
        case "add":
            if (suspensePaymentValidationErrors.find((item) => item === number)) {
                return suspensePaymentValidationErrors;
            } else {
                return [...suspensePaymentValidationErrors, number];
            }
        case "remove":
            const array = suspensePaymentValidationErrors.filter((item) => item !== number);
            return array;
        case "reset":
            return initSuspensePaymentsValidationErrors;
        default:
            return suspensePaymentValidationErrors;
    }
};

export default function SuspensePaymentRegister() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // ログイン中のユーザー情報を取得
    const { userInfo } = useContext(UserContext);
    // const userName = userInfo.user.user_name; // ユーザー名
    const loggedInUserId = userInfo.user.user_id; // id
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { MIN_DATE } = MIN_MAX_DATE;
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    const [displayErrorMessage, setDisplayErrorMessage] = useState(false); // バリデーションエラーメッセージ表示切替

    const [loaded, setLoaded] = useState(false); // 読み込み完了のステータスを管理

    const [isDeleted, setIsDeleted] = useState(false); // 削除対象に指定されたかどうか

    const [equalMaxNumber, setEqualMaxNumber] = useState(false); // 最後尾の行であるかどうかを管理

    // 対象月から期間を割り出す
    // const [monthlyPeriod, setMonthlyPeriod]=useState({min: lastMonth, max: today});
    const monthlyPeriod = { min: lastMonth, max: today };

    // 最も古いレコードのある年月を管理する
    // const [oldestDate, setOldestDate] = useState(null);

    // datePickerの選択済みの値を管理する
    // const [startDate, setStartDate] = useState(new Date());

    // 選択した年月の値を管理する
    // const [selectedMonthAndYear, setSelectedMonthAndYear] = useState("");

    // 選択した対象月に対しての作業を開始する
    const [start, setStart] = useState(false);

    // 締め処理済みかどうかを管理
    // const [isConfirmed, setIsConfirmed] = useState(false);

    // 最も古いレコードの月日の値を管理する
    // const [suspensePaymentOldest, setSuspensePaymentOldest] = useState(initSuspensePaymentOldest);

    // DBから取得した仮払いのデータを管理する
    // const [suspensePayments, setSuspensePayments] = useState(initSuspensePayments);

    // 送信するデータを管理するレデューサー
    const [suspensePaymentRequestData, dispatchSuspensePaymentRequestData] = useReducer(
        reducerSuspensePaymentRequestDataFunc,
        {
            type: "",
            number: 0,
            data: {
                suspense_payment: {
                    id: null,
                    date: null,
                    receipt_date: null,
                    user_id: loggedInUserId,
                    amount: 0,
                    is_paid: false,
                    is_changed: false,
                },
                suspense_payment_detail: [], // {start_date: null, end_date: null, detail: null }
            },
        }
    );

    // 追加の行のナンバーを管理するレデューサー
    const [suspensePaymentAdditionalNumbers, dispatchSuspensePaymentAdditionalNumbers] = useReducer(
        reducerSuspensePaymentAdditionalNumbersFunc,
        []
    );

    // 行の最後尾のナンバーを管理するステート
    const [maxSuspensePaymentAdditionalNumber, setMaxSuspensePaymentAdditionalNumber] = useState(1);

    // バリデーションエラーの有無を管理するレデューサー
    const [suspensePaymentValidationErrors, dispatchSuspensePaymentValidationErrors] = useReducer(
        reducerSuspensePaymentValidationErrorsFunc,
        initSuspensePaymentsValidationErrors
    );

    // バリデーションを実行するためのトリガー
    const [suspensePaymentValidationTrigger, setSuspensePaymentValidationTrigger] = useState(false);

    // 合計をステートで管理
    // const [suspensePaymentTotalAmount, setSuspensePaymentTotalAmount] = useState(0);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 仮払いの最も古い年月の値を取得
    // const getSuspensePaymentOldest = async (userId) => {
    //     const url = `suspense_payments_oldest/${userId}`;
    //     const res = await api.get(url);
    //     // 成功時、ステートを更新
    //     if (res.data.success) {
    //         setSuspensePaymentOldest(res.data);
    //     }
    // };

    // 締め処理済みかどうかをDBから取得
    // const getClose = async (date) => {
    //     const url = `close/${date}`;
    //     const res = await api.get(url);
    //     if (res.data.success) {
    //         if (res.data.result === null) {
    //             setIsConfirmed(false);
    //             return;
    //         } else {
    //             setIsConfirmed(res.data.result.closing_process_is_closed);
    //         }
    //     }
    // };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 年月の選択に変更があったときの処理
    // const handleOnChangeDate = (date) => {
    //     // 年月（datePicker）の選択している月をステートに格納
    //     setStartDate(date);
    // };

    // 仮払い明細の入力完了ボタン押下時
    const suspensePaymentsSubmit = async (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // フォーム全体のバリデーションを実行
        setSuspensePaymentValidationTrigger(!suspensePaymentValidationTrigger);

        // エラーの有無により処理を中断する
        if (suspensePaymentRequestData.length === 0) {
            await swal({ title: "", text: "入力の内容を見直してください", icon: "warning" }).then(() => {});
            return;
        } else {
            await swal({ title: "確認", text: "登録します", icon: "info", buttons: ["中止", "登録"] }).then((value) => {
                if (value) {
                    // リクエストデータをapiで送信する処理へ進む
                    sendSuspensePaymentRequestData();
                }
            });
        }
    };
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // useEffect(() => {
    //     // 最も古いレコードを取得
    //     getSuspensePaymentOldest(loggedInUserId);
    // }, []);

    useEffect(() => {
        // 読み込み完了とする
        setLoaded(true);

        // エラーオブジェクトを作成しておく;
        trigger(); // バリデーションの実行
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    // react-hook-formの使用する機能を宣言
    const { unregister, register, formState, setValue, getValues, control, watch, trigger } = useForm();
    // 読み込み前に戻る
    const backBeforeStart = () => {
        setStart(false);
        setSuspensePayments(initSuspensePayments);
        dispatchSuspensePaymentRequestData({ type: "reset" });
        dispatchSuspensePaymentAdditionalNumbers({ type: "reset" });
        setMaxSuspensePaymentAdditionalNumber(1);
        dispatchSuspensePaymentValidationErrors({ type: "reset" });
        setSuspensePaymentTotalAmount(0);
    };

    // 開始日と終了日のsort時に使う関数
    const compare = (a, b) => {
        const startDayA = new Date(a?.start_date);
        const startDayB = new Date(b?.start_date);

        if (startDayA === startDayB) {
            const endDayA = new Date(a?.end_date);
            const endDayB = new Date(b?.end_date);
            if (endDayA <= endDayB) {
                return a.number - b.number;
            }
        } else if (startDayA !== startDayB) {
            return startDayA - startDayB;
        }
        return a.number - b.number;
    };

    // 仮払い明細のリクエストデータをapiでコントローラーに渡すなどの処理
    const sendSuspensePaymentRequestData = async () => {
        let requestDetailData = [];
        if (Array.isArray(suspensePaymentRequestData.suspense_payment_detail)) {
            const sortedRequestData = suspensePaymentRequestData.suspense_payment_detail
                .filter((detail) => Object.values(detail).filter((value) => value).length)
                .sort((a, b) => compare(a, b));
            requestDetailData = sortedRequestData.map((item) => {
                return item;
            });
        } else {
            requestDetailData = suspensePaymentRequestData.suspense_payment_detail;
        }
        const requestData = {
            suspense_payment: suspensePaymentRequestData.suspense_payment,
            suspense_payment_detail: requestDetailData,
        };
        // 登録
        const url = "suspense_payments";
        const res = await api.post(url, requestData);
        // 成否の確認
        if (res.data.success) {
            // 登録成功時
            await swal(res.data.message, "", "success");

            // 年月選択前の状態に戻る
            navigate("/suspense_payment");
        } else {
            // 登録失敗時にエラーに応じた処理を行う
            processErrorResponse(res, () => {}, navigate);
        }
    };

    // 仮払い明細の最後尾の行のナンバーを取得し、ステートに格納
    useEffect(() => {
        if (suspensePaymentAdditionalNumbers.length > 0) {
            const max = Math.max(...suspensePaymentAdditionalNumbers);
            setMaxSuspensePaymentAdditionalNumber(max);
        }
    }, [suspensePaymentAdditionalNumbers]);

    // 親コンポーネントでのサブミット時にバリデーション、エラーの更新を実行する
    useEffect(() => {
        // 読み込み完了、削除対象、かつ最後尾の行ではない場合
        if (loaded && !isDeleted) {
            // バリデーションの実行
            trigger();
            // バリデーションの結果を表示する
            setDisplayErrorMessage(true);
        }
    }, [suspensePaymentValidationTrigger]);
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h2>仮払い登録</h2>
                    </div>
                    <div className='card-body'>
                        {
                            <div className='d-flex justify-content-start align-items-center text-nowrap mt-4'>
                                <label className='fw-bold' htmlFor='date'>
                                    作成日：
                                </label>
                                <StyledTooltip
                                    className='me-3'
                                    arrow={true}
                                    title={
                                        displayErrorMessage && formState.errors.date
                                            ? formState.errors.date?.message
                                            : ""
                                    }>
                                    <div
                                        className={
                                            displayErrorMessage && formState.errors.date
                                                ? "border border-danger border-2 rounded rounded-3"
                                                : ""
                                        }>
                                        <input
                                            id='date'
                                            type='date'
                                            defaultValue={today}
                                            // max={today}
                                            {...register("date", {
                                                required: {
                                                    value: true,
                                                    message: "必須項目です",
                                                },
                                            })}
                                        />
                                    </div>
                                </StyledTooltip>
                                <label className='fw-bold' htmlFor='receipt_date'>
                                    受領日：
                                </label>
                                <StyledTooltip
                                    className='me-3'
                                    arrow={true}
                                    title={
                                        displayErrorMessage && formState.errors.receipt_date
                                            ? formState.errors.receipt_date?.message
                                            : ""
                                    }>
                                    <div
                                        className={
                                            displayErrorMessage && formState.errors.receipt_date
                                                ? "border border-danger border-2 rounded rounded-3"
                                                : ""
                                        }>
                                        <input
                                            id='receipt_date'
                                            type='date'
                                            {...register("receipt_date", {
                                                required: {
                                                    value: true,
                                                    message: "必須項目です",
                                                },
                                            })}
                                        />
                                    </div>
                                </StyledTooltip>
                                <label className='fw-bold' htmlFor='amount'>
                                    金額：
                                </label>
                                <StyledTooltip
                                    className='me-3'
                                    arrow={true}
                                    title={
                                        displayErrorMessage && formState.errors.amount
                                            ? formState.errors.amount?.message
                                            : ""
                                    }>
                                    <div
                                        className={
                                            displayErrorMessage && formState.errors.amount
                                                ? "border border-danger border-2 rounded rounded-3"
                                                : ""
                                        }>
                                        <input
                                            id='amount'
                                            type='number'
                                            min='1'
                                            max='99999999'
                                            inputMode='numeric'
                                            pattern='[0-9]*'
                                            {...register("amount", {
                                                required: {
                                                    value: true,
                                                    message: "必須項目です",
                                                },
                                                min: {
                                                    value: 1,
                                                    message: "1以上",
                                                },
                                                max: {
                                                    value: 99999999,
                                                    message: "8桁以内",
                                                },
                                            })}
                                        />
                                    </div>
                                </StyledTooltip>
                                <button
                                    className='btn btn-secondary'
                                    type='button'
                                    onClick={(e) => {
                                        suspensePaymentsSubmit(e);
                                    }}>
                                    登録
                                </button>
                            </div>
                        }
                        {/* {start && (
                            <div className='d-flex justify-content-start align-items-center text-nowrap my-4'>
                                <label className='fw-bold me-3'>
                                    年月：
                                    <input
                                        type='text'
                                        readOnly
                                        value={`${selectedMonthAndYear.split("-")[0]}年${
                                            selectedMonthAndYear.split("-")[1]
                                        }月`}
                                    />
                                </label>
                                <label className='fw-bold me-3'>
                                    担当：
                                    <input type='text' readOnly value={userName} />
                                </label>
                                <button
                                    className='btn btn-secondary me-3'
                                    type='button'
                                    onClick={(e) => {
                                        handleOnClickResetSelected(e);
                                    }}>
                                    再選択
                                </button>
                                {isConfirmed ? <p className='h-auto m-0 fw-bold'> 締め処理済みです</p> : ""}
                            </div>
                        )} */}
                        <div className='d-flex justify-content-center mt-5'>
                            <div>
                                <SuspensePaymentContext.Provider
                                    value={{
                                        equalMaxNumber,
                                        setEqualMaxNumber,
                                        loaded,
                                        setIsDeleted,
                                        isDeleted: false,
                                        displayErrorMessage,
                                        setDisplayErrorMessage,
                                        monthlyPeriod,
                                        unregister,
                                        register,
                                        formState,
                                        setValue,
                                        getValues,
                                        control,
                                        watch,
                                        trigger,
                                        start,
                                        // selectedMonthAndYear,
                                        loggedInUserId,
                                        maxSuspensePaymentAdditionalNumber,
                                        // suspensePayments,
                                        // setSuspensePayments,
                                        // suspensePaymentRequestData,
                                        dispatchSuspensePaymentRequestData,
                                        suspensePaymentAdditionalNumbers,
                                        dispatchSuspensePaymentAdditionalNumbers,
                                        // suspensePaymentValidationErrors,
                                        dispatchSuspensePaymentValidationErrors,
                                        // suspensePaymentValidationTrigger,
                                        // suspensePaymentTotalAmount
                                        // isConfirmed
                                    }}>
                                    <SuspensePaymentRequestTable />
                                </SuspensePaymentContext.Provider>
                            </div>
                        </div>
                    </div>
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackButton path={returnPath} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
