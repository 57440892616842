import React, { createContext, useContext, useEffect, useReducer, useRef, useState } from "react";
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import swal from "sweetalert";
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { NativeSelect, Paper, Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import CalculateForSelfTable from "../../components/transaction/calculate/CalculateForSelfTable";
import BackButton from "../../components/BackButton";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { UserContext } from "../../providers/UserProvider";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { HOME_PATH } from "../../common/constants";
import processResponse from "../../common/processResponse";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//スタイルの定義
// スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        userName: {
            fontSize: "18px",
            fontWeight: "bold"
        },
        userTables: {
            marginBottom: "100px"
        },
        userTopTable: {
            marginBottom: "30px"
        },
        caption: {
            fontSize: "16px"
        },
        tableHead1: {
            backgroundColor: "#99ccff"
        },
        tableHead2: {
            backgroundColor: "#66ff99"
        },
        dialog: {
            maxHeight: "60vh",
            padding: "10px",
            boxSizing: "border-box",
            backgroundColor: "#fefefe",
            border: "1px solid #666",
            textAlign: "center"
        },
        imgWrapper: {
            width: "100%",
            height: "calc(100% - 80px)"
        },
        img: {
            objectFit: "contain",
            maxWidth: "100%",
            maxHeight: "100%"
        },
        me500: { marginRight: "500px" },
        imageDialog: { width: "500px" }
    })
);
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// 戻るときの遷移先のパス
const returnPath = HOME_PATH;
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// ステートの初期状態
const initUsers = { result: [], success: false };

// ステートの初期状態
const initUnpaidData = { result: [], success: false };
const initSuspensePaymentDataArr = { result: [], success: false };
const initTravelExpenseDataArr = { result: [], success: false };
const initTotalAmountDataArr = { result: [], success: false };
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
// コンテクストを作成
export const CalculateContext = createContext();

export default function CalculateForSelf() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //定義したスタイルを利用するための設定
    const classes = useStyles();
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // ログイン中のユーザー情報を取得
    const { userInfo } = useContext(UserContext);
    const userName = userInfo.user.user_name; // ユーザー名
    const loggedInUserId = userInfo.user.user_id; // id
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // ユーザー一覧の値を管理する
    const [users, setUsers] = useState(initUsers);

    // 選択したユーザーのidを管理する
    const [selectedUserId, setSelectedUserId] = useState(loggedInUserId);

    // 選択したユーザーの名前を管理する
    const [selectedUserName, setSelectedUserName] = useState(userName);

    // 選択した対象月に対しての作業を開始する
    const [start, setStart] = useState(false);

    // 読み込み完了の状態を管理する
    const [loaded, setLoaded] = useState(false);

    // ===========================画像表示コンポーネントについて============================= //

    // データ取得用state
    const [unpaidData, setUnpaidData] = useState(initUnpaidData);
    const [travelExpenseDataArr, setTravelExpenseDataArr] = useState(initTravelExpenseDataArr);
    const [suspensePaymentDataArr, setSuspensePaymentDataArr] = useState(initSuspensePaymentDataArr);
    const [totalAmountDataArr, setTotalAmountDataArr] = useState(initTotalAmountDataArr);

    // データ取得完了フラグ
    const [getDataSucceed, setGetDataSucceed] = useState(false);
    // ===========================画像表示コンポーネントについて============================= //

    // 画像管理用ステート
    const [imgData, setImgData] = useState(null);
    const [imgSrc, setImgSrc] = useState("");
    const [showImg, setShowImg] = useState(false);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const getUsers = async () => {
        const url = `users_unpaid`;
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        processResponse(res, setUsers, navigate, returnPath);
    };

    const getUnpaidData = async (id) => {
        const url = `suspense_payments_for_calculate/${id}`;
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        processResponse(res, setUnpaidData, navigate, returnPath);
    };

    const getData = async (url, setState) => {
        const res = await api.get(url);
        // 成功時、ステートを更新
        if (res.data.success) {
            setState(res.data.result);
        }
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    // 開始ボタン押下時
    const selectSubmit = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        setStart(true);
    };

    // 画像の表示切替
    const handleOnClickSnowImg = (targetMonthYear, id, fileName, isReceipt) => {
        if (targetMonthYear !== undefined) {
            const folderName = isReceipt ? "receipt" : "suspense_payment";
            const splittedTargetMonthYear = targetMonthYear.split("-");
            const targetMonth = `${splittedTargetMonthYear[0]}-${splittedTargetMonthYear[1]}`;
            const path = `file/${folderName}/${targetMonth}/${id}/${fileName}`;
            getData(path, setImgData);
        }
    };

    useEffect(() => {
        if (imgData !== null) {
            const adjustedData = JSON.parse(imgData); // 受け取ったデータをjsonに
            const mimeType = adjustedData.mimeType;
            const fileText = adjustedData.fileText;
            const receiptUri = "data:" + mimeType + ";base64," + fileText;
            setShowImg(true);
            setImgSrc(receiptUri);
        }
    }, [imgData]);

    // 画像用
    const handleOnClickCloseImage = () => {
        setImgSrc("");
        setImgData(null);
        setShowImg(false);
    };

    // 選択画面に戻るボタン押下時
    const resetSelected = async (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        await swal({
            title: "担当者の選択に戻ります",
            text: "",
            icon: "info",
            buttons: true
        }).then((value) => {
            if (value) {
                setStart(false);
                setGetDataSucceed(false);
            }
        });
    };

    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => {
        // 各種データの取得
        getUsers();
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const backBeforeStart = () => {
        setLoaded(false);
        // 以下、表示画像を初期化
        setShowImg(false);
    };

    useEffect(() => {
        if (!start) {
            // 担当選択前の状態に戻る
            backBeforeStart(null);
        }
    }, [start]);

    useEffect(() => {
        if (start) {
            getUnpaidData(selectedUserId);
        }
    }, [start]);

    useEffect(() => {
        if (unpaidData.success && unpaidData.success !== undefined) {
            if (unpaidData.result.suspense_payment.length > 0) {
                const obj = { result: unpaidData.result.suspense_payment, success: true };
                setSuspensePaymentDataArr(obj);
            }

            if (unpaidData.result.travel_expense.length > 0) {
                const obj = { result: unpaidData.result.travel_expense, success: true };
                setTravelExpenseDataArr(obj);
            }

            if (unpaidData.result.total_amount) {
                const obj = { result: unpaidData.result.total_amount, success: true };
                setTotalAmountDataArr(obj);
            }

            setGetDataSucceed(true);
        }
    }, [unpaidData]);
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h2>未精算一覧</h2>
                    </div>
                    <div id='scrollable-wrapper' className='card-body'>
                        {!start ? (
                            <div className='d-flex justify-content-start align-items-baseline text-nowrap my-4'>
                                <div className='position-relative me-3'>
                                    <label className='fw-bold'>
                                        担当：
                                        {<input type='text' readOnly value={selectedUserName} />}
                                    </label>
                                </div>
                                <button
                                    className='btn btn-secondary'
                                    type='button'
                                    disabled={!selectedUserId}
                                    onClick={(e) => {
                                        selectSubmit(e);
                                    }}>
                                    開始
                                </button>
                            </div>
                        ) : (
                            <>{!start && <p className=' m-4'>...読み込み中</p>}</>
                        )}
                        {start && (
                            <div className='d-flex justify-content-start align-items-center text-nowrap my-4'>
                                <label className='fw-bold me-3'>
                                    担当：
                                    <input type='text' readOnly value={selectedUserName} />
                                </label>
                            </div>
                        )}
                        {getDataSucceed && (
                            <div className='row mt-5'>
                                <div className='col-8'>
                                    <CalculateForSelfTable
                                        start={start}
                                        setStart={setStart}
                                        loaded={loaded}
                                        setLoaded={setLoaded}
                                        selectedUserId={selectedUserId}
                                        suspensePaymentDataArr={suspensePaymentDataArr}
                                        travelExpenseDataArr={travelExpenseDataArr}
                                        totalAmountDataArr={totalAmountDataArr}
                                        unpaidData={unpaidData}
                                        handleOnClickSnowImg={handleOnClickSnowImg}
                                    />
                                </div>
                                {showImg && (
                                    <div className={`${classes.dialog} col-4 sticky-top`}>
                                        <div className={classes.imgWrapper}>
                                            <img className={classes.img} src={imgSrc} alt='領収書・受領書' />
                                        </div>
                                        <div className='mt-5'>
                                            <button onClick={(e) => handleOnClickCloseImage(e)}>閉じる</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackButton path={returnPath} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
