import React from "react";
import { useNavigate } from "react-router-dom";

export default function CloseButton(props) {
    //画面遷移用
    const navigate = useNavigate();

    // props から遷移先のpathを分割代入
    // ※初期値は前の画面
    const { path = -1 } = props;

    // 戻るボタンをクリックしたら、情報一覧画面に戻る
    const handleOnClickClose = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate(path);
    };

    return (
        <button className='btn btn-outline-secondary fs-3 lh-1' onClick={(e) => handleOnClickClose(e)}>
            ×
        </button>
    );
}
