//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useState, useEffect, useContext } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import swal from "sweetalert";
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

import { Button, Card } from "@material-ui/core";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import DataTable from "../../components/DataTable";
import BackButton from "../../components/BackButton";
import CloseButton from "../../components/CloseButton";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import processResponse from "../../common/processResponse";
import processErrorResponse from "../../common/processErrorResponse";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// 戻るときの遷移先のパス
const returnPath = "/bank_account";

//見出しの配列定義
const headers = ["資金口座名", "備考", "復活"];
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// bankAccounts の初期状態
const initBankAccounts = { result: [], success: false };
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function BankAccountDeleted() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面遷移用
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // bankAccounts の状態を管理する
    const [bankAccounts, setBankAccounts] = useState(initBankAccounts);

    // bankAccounts を表示行に変換したものを保持する
    const [rows, setRows] = useState([]);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 情報を取得しステート bankAccounts にセットする
    const getBankAccountsData = async () => {
        const url = `bank_accounts_deleted`;
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        processResponse(res, setBankAccounts, navigate, returnPath);
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 一覧の復活ボタンクリック時、論理削除済み資金口座情報を復活
    const handleOnClickRestore = async (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 復活対象の資金口座の情報を取得
        const bankAccount = bankAccounts.result.find((bankAccount) => bankAccount.bank_account_id === id);
        // 表示用の名前を宣言
        const bankAccountName = bankAccount.bank_account_name;

        // 確認のための変数を定義
        let confirm = false;

        // 確認画面を表示
        await swal({
            icon: "info",
            title: "資金口座を復活しますか？",
            text: bankAccountName,
            buttons: ["キャンセル", "実行"]
        }).then((value) => {
            if (value) {
                confirm = true;
            }
        });

        // キャンセルの場合は処理を中断する
        if (!confirm) {
            return;
        }

        //復活
        const url = `bank_accounts/${id}/restore`;
        const res = await api.put(url);
        //---------------------------issue{No.81} start-----------------------------
        // 成否の確認
        if (res.data.success) {
            // 復活成功時
            // モーダルウィンドウで成功メッセージを表示
            swal(res.data.message, "", "success").then(() => {
                // 復活した資金口座を除外した全資金口座を配列化
                const newbankAccounts = bankAccounts.result.filter((bankAccount) => bankAccount.bank_account_id !== id);
                // ステートを更新
                setBankAccounts({ result: newbankAccounts, success: true });
            });
        } else {
            // 復活失敗時にエラーに応じた処理を行う
            processErrorResponse(res, () => {}, navigate);
        }
        //---------------------------issue{No.81} end-------------------------------
    };
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面初期表示時に資金口座情報を取得する
    useEffect(() => {
        getBankAccountsData();
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // BankAccountsに応じて、画面表示用の配列を生成
    useEffect(() => {
        if (!bankAccounts.success) {
            return;
        }

        const newRows = bankAccounts.result.map((bankAccount) => {
            return {
                id: bankAccount.bank_account_id,
                isActive: false,
                data: {
                    name: bankAccount.bank_account_name,
                    remarks: bankAccount.bank_account_remarks === null ? "-" : bankAccount.bank_account_remarks,
                    //---------------------------issue{No.102} start-----------------------------
                    //---------------------------issue{No.102} end-------------------------------
                    restoreBtn: (
                        <Button
                            variant='contained'
                            onClick={(e) => handleOnClickRestore(e, bankAccount.bank_account_id)}>
                            復活
                        </Button>
                    )
                }
            };
        });
        setRows(newRows);
    }, [bankAccounts]);
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1>【削除済み】資金口座情報一覧</h1>
                        <div className='ms-auto'>
                            <CloseButton path={returnPath} />
                        </div>
                    </div>
                    <Card>
                        {/* テーブル部分の定義 */}
                        <DataTable headers={headers} rows={rows} />
                    </Card>
                    <div className='card-footer'>
                        <BackButton path={returnPath} />
                    </div>
                </div>
            </div>
        </div>
    );
}
