import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

import { Button } from "@material-ui/core";

import { api } from "../api/api";
import { UserContext } from "../providers/UserProvider";
import { makeStyles, createStyles } from "@material-ui/core/styles";

// スタイリング
//---------------------------issue{No.103} start-----------------------------
const useStyles = makeStyles((theme) =>
    createStyles({
        reissuePassword: {
            padding: "1.5rem 0",
            textAlign: "center"
        },
        reissuePasswordText: {
            display: "inline-block",
            color: "#666",
            textDecoration: "underline 1px dotted #666",
            cursor: "pointer",
            userSelect: "none",
            textUnderlineOffset: "0.3rem",
            transition: "all .5s",
            "&:hover": {
                color: "#6669",
                textDecoration: "underline 1px dotted #6669",
                textUnderlineOffset: "0.6rem"
            }
        }
    })
);
//---------------------------issue{No.103} end-------------------------------

export default function Login() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const classes = useStyles();
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //画面遷移用
    const navigate = useNavigate();

    //ログイン情報を保持する
    const { userInfo, setUserInfo } = useContext(UserContext);

    //入力値を保持する
    const [loginInput, setLogin] = useState({
        email: "",
        password: "",
        error_list: []
    });

    const handleInput = (e) => {
        e.persist();
        setLogin({ ...loginInput, [e.target.name]: e.target.value });
    };

    const loginSubmit = async (e) => {
        e.preventDefault();

        const data = {
            email: loginInput.email,
            password: loginInput.password
        };

        await api.get("/sanctum/csrf-cookie");

        const res = await api.post("/login", data);
        if (res.data.success) {
            setUserInfo({ isLogin: true, user: res.data.result });
            navigate("/home");
        } else {
            swal(res.statusText, loginInput.email, "error").then(() => {});
        }
    };

    const handleOnClick = () => {
        navigate("/reissue_password");
    };

    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header'>
                        <h4>Login</h4>
                    </div>
                    <div className='card-body'>
                        <form onSubmit={loginSubmit}>
                            <div className='form-group mb-3'>
                                <label>メールアドレス</label>
                                <input
                                    type='text'
                                    name='email'
                                    onChange={handleInput}
                                    value={loginInput.mail}
                                    className='form-control'
                                />
                                <span>{loginInput.success ? "" : loginInput.error_list.email}</span>
                            </div>
                            <div className='form-group mb-3'>
                                <label>Password</label>
                                <input
                                    type='password'
                                    name='password'
                                    onChange={handleInput}
                                    value={loginInput.password}
                                    className='form-control'
                                />
                                <span>{loginInput.success ? "" : loginInput.error_list.password}</span>
                            </div>
                            <div className='form-group mb-3'>
                                <Button type='submit' variant='contained'>
                                    ログイン
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={classes.reissuePassword}>
                    <p className={classes.reissuePasswordText} onClick={handleOnClick}>
                        パスワードをお忘れですか？
                    </p>
                </div>
            </div>
        </div>
    );
}
