//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import TargetWithdrawals from "./TargetWithdrawals";
import InputRow from "./InputRow";
import ShowRow from "./ShowRow";
import MonthlyDataAmount from "./MonthlyDataAmount";
import TargetNotice from "./TargetNotice";
import BackButton from "../../BackButton";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { MonthlyDataContext } from "../../../contents/monthly_data/MonthlyDataRegister";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import processErrorResponse from "../../../common/processErrorResponse";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
const useStyles = makeStyles((theme) =>
    createStyles({
        rightCell: {
            width: "50%"
        },
        leftCell: {
            width: "50%"
        },
        wrap: {
            wordBreak: "break-all"
        }
    })
);
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
const radioButton = ["前月", "前年同月"];
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// ステートの初期状態
// const initDefaultMonthlyDataState = { result: [{ monthly_data_amount: 0 }], success: false };
// const initDefaultNoticeState = { result: [{ notice_detail: "" }], success: false };
// const initDefaultTravelExpenseState = { result: [], success: false };
// const caption = ["売上", "経費", "資金", "その他"];
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function MonthlyDataEdit(props) {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //定義したスタイルを利用するための設定
    const classes = useStyles();
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面遷移用
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { errors, setErrors, validationTrigger, setValidationTrigger } = useContext(MonthlyDataContext);
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const {
        selectedTargetDate,
        displayTargetDate,
        displayReferenceDate,
        referenceSwitch,
        setReferenceSwitch,
        proceedsData,
        setProceedsData,
        proceeds,
        setProceeds,
        proceedsRow,
        setProceedsRow,
        proceedsSum,
        setProceedsSum,
        expensesData,
        setExpensesData,
        expenses,
        setExpenses,
        expensesRow,
        setExpensesRow,
        expensesSum,
        setExpensesSum,
        withdrawalsData,
        setWithdrawalsData,
        withdrawalsRow,
        setWithdrawalsRow,
        withdrawalsSum,
        setWithdrawalsSum,
        withdrawalsSub,
        setWithdrawalsSub,
        referenceWithdrawalsData,
        setReferenceWithdrawalsData,
        bankAccountsData,
        setBankAccountsData,
        bankAccounts,
        setBankAccounts,
        bankAccountsRow,
        setBankAccountsRow,
        bankAccountsSum,
        setBankAccountSum,
        bankAccountSub,
        setBankAccountSub,
        advancePayment,
        setAdvancePayment,
        pettyCash,
        setPettyCash,
        nextMonthCash,
        setNextMonthCash,
        travelExpenseTotalAmount,
        referenceTravelExpenseTotalAmount,
        setReferenceTravelExpenseTotalAmount,
        othersData,
        setOthersData,
        others,
        setOthers,
        othersRow,
        setOthersRow,
        othersSum,
        setOthersSum,
        targetNotice,
        setTargetNotice,
        referenceNotice,
        grossMargin,
        referenceData,
        setVisibility,
        setTargetData,
        initDefaultState
    } = props;
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // リクエストデータをapiでコントローラーに渡すなどの処理
    const sendMonthlyDataRequestData = async () => {
        // リクエストデータをまとめる
        const requestData = {
            date: selectedTargetDate,
            notice: targetNotice,
            monthly_data: [
                ...EmptyDelete(proceedsData, "monthly_data_amount"),
                ...EmptyDelete(expensesData, "monthly_data_amount"),
                ...EmptyDelete(bankAccountsData, "monthly_data_amount"),
                EmptyDelete(advancePayment, "monthly_data_amount") ?? [],
                EmptyDelete(pettyCash, "monthly_data_amount") ?? [],
                EmptyDelete(nextMonthCash, "monthly_data_amount") ?? [],
                ...EmptyDelete(othersData, "monthly_data_amount")
            ],
            withdrawals: EmptyDelete(withdrawalsData, "withdrawal_amount")
        };

        // 登録
        const url = "monthly_data";
        const res = await api.post(url, requestData);
        // 成否の確認
        if (res.data.success) {
            // 登録成功時
            swal(res.data.message, "", "success");
            setValidationTrigger(false);
            setVisibility(false);
            setTargetData(initDefaultState);
        } else {
            // 登録失敗時にエラーに応じた処理を行う
            processErrorResponse(res, () => {}, navigate);
        }
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    // 経費入力完了ボタン押下時
    const MonthlyDataSubmit = async (e) => {
        // イベントの伝搬を中止
        e.preventDefault();
        // 確認のための変数を定義
        let confirm = false;

        // 確認画面を表示
        await swal({
            title: "確認",
            text: "入力された内容で登録します。",
            buttons: ["戻る", "登録"]
        }).then((value) => {
            if (value) {
                confirm = true;
            }
        });

        // 戻る場合は処理を中断する
        if (!confirm) {
            return;
        }
        // フォーム全体のバリデーションを実行
        setValidationTrigger(true);

        // エラーの有無により処理を中断する
        if (errors.length > 0) {
            await swal({ title: "", text: "入力の内容を見直してください", icon: "warning" }).then(() => {});
            return;
        } else {
            // await swal({ title: "確認", text: "登録を実行します", icon: "info", buttons: ["中止", "実行"] }).then(
            //     (value) => {
            //         if (value) {
            // リクエストデータをapiで送信する処理へ進む
            sendMonthlyDataRequestData();
            //             }
            //         }
            //     );
        }
    };

    // 参照月変更時の処理
    const handleOnChangeReferenceMonth = (e) => {
        const value = e.target.value;
        setReferenceSwitch(value);
    };
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    function EmptyDelete(data, name) {
        if (Array.isArray(data)) {
            return data.filter((element) => element[name] !== "");
        } else if (data && data[name] !== "") {
            return data;
        } else {
            return null;
        }
    }
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <>
            <div className='me-5'>
                参照月
                <fieldset>
                    {radioButton.map((value, key) => (
                        <label key={key} className='pe-3'>
                            <input
                                type='radio'
                                name='reference_month'
                                value={key}
                                onChange={(e) => handleOnChangeReferenceMonth(e)}
                                checked={key == referenceSwitch}
                            />
                            {` ${value}`}
                        </label>
                    ))}
                </fieldset>
            </div>
            <div className='container-fluid'>
                <div className='row justify-content-around'>
                    {/* 参照月 START */}
                    <div className='col-5'>
                        <table className='w-100 table table-bordered'>
                            <caption className='caption-top'>参照月&nbsp;&nbsp;{displayReferenceDate}</caption>
                            <tbody>
                                {/* 売上 START */}
                                <tr className='table-danger'>
                                    <th colSpan={2}>売上</th>
                                </tr>
                                <ShowRow
                                    dataName='bank_account'
                                    state={proceeds}
                                    data={referenceData[referenceSwitch] ? referenceData[referenceSwitch][0] : null}
                                />
                                <tr>
                                    <th className={classes.leftCell}>売上合計</th>
                                    <td className={classes.rightCell}>
                                        {referenceData[referenceSwitch]
                                            ? referenceData[referenceSwitch][0]
                                                  .reduce(function (sum, element) {
                                                      return parseInt(sum) + parseInt(element.monthly_data_amount);
                                                  }, 0)
                                                  .toLocaleString()
                                            : 0}
                                    </td>
                                </tr>
                                {/* 売上 END */}
                                {/* 経費 START */}
                                <tr className='table-info'>
                                    <th colSpan={2}>経費</th>
                                </tr>
                                <ShowRow
                                    dataName='bank_account'
                                    state={expenses}
                                    data={referenceData[referenceSwitch] ? referenceData[referenceSwitch][1] : null}
                                />
                                <tr>
                                    <th className={classes.leftCell}>領収書合計</th>
                                    <td className={classes.rightCell}>
                                        {referenceTravelExpenseTotalAmount[referenceSwitch]
                                            ? parseInt(
                                                  referenceTravelExpenseTotalAmount[referenceSwitch]
                                                      ?.travel_expense_total_amount ?? 0
                                              ).toLocaleString()
                                            : 0}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.leftCell}>経費合計</th>
                                    <td className={classes.rightCell}>
                                        {(
                                            (referenceData[referenceSwitch]
                                                ? parseInt(
                                                      referenceData[referenceSwitch][1].reduce(function (sum, element) {
                                                          return parseInt(sum) + parseInt(element.monthly_data_amount);
                                                      }, 0) ?? 0
                                                  )
                                                : 0) +
                                            parseInt(
                                                referenceTravelExpenseTotalAmount[referenceSwitch]
                                                    ? parseInt(
                                                          referenceTravelExpenseTotalAmount[referenceSwitch]
                                                              ?.travel_expense_total_amount ?? 0
                                                      )
                                                    : 0
                                            )
                                        ).toLocaleString()}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.leftCell}>予納その他経費</th>
                                    <td className={classes.rightCell}>
                                        {(referenceData[referenceSwitch]
                                            ? referenceData[referenceSwitch][3]
                                                ? parseInt(referenceData[referenceSwitch][3].monthly_data_amount ?? 0)
                                                : 0
                                            : 0
                                        ).toLocaleString()}
                                    </td>
                                </tr>
                                <tr>
                                    <th>粗利益</th>
                                    <td>
                                        {(referenceData[referenceSwitch]
                                            ? parseInt(
                                                  referenceData[referenceSwitch][0].reduce(function (sum, element) {
                                                      return parseInt(sum) + parseInt(element.monthly_data_amount);
                                                  }, 0) ?? 0
                                              ) -
                                              parseInt(
                                                  referenceData[referenceSwitch][1].reduce(function (sum, element) {
                                                      return parseInt(sum) + parseInt(element.monthly_data_amount);
                                                  }, 0) ?? 0
                                              ) -
                                              parseInt(
                                                  referenceTravelExpenseTotalAmount[referenceSwitch]
                                                      ? parseInt(
                                                            referenceTravelExpenseTotalAmount[referenceSwitch]
                                                                ?.travel_expense_total_amount ?? 0
                                                        )
                                                      : 0
                                              ) -
                                              parseInt(
                                                  referenceData[referenceSwitch][3]
                                                      ? referenceData[referenceSwitch][3].monthly_data_amount
                                                      : 0
                                              )
                                            : 0
                                        ).toLocaleString()}
                                    </td>
                                </tr>
                                {/* 経費 END */}
                                {/* 現金出納帳 START */}
                                <tr className='table-success'>
                                    <th colSpan={2}>現金出納帳</th>
                                </tr>
                                <tr>
                                    <th className={classes.leftCell}>小口繰越現金</th>
                                    <td className={classes.rightCell}>
                                        {referenceData[referenceSwitch]
                                            ? referenceData[referenceSwitch][4]
                                                ? referenceData[referenceSwitch][4].monthly_data_amount.toLocaleString()
                                                : 0
                                            : 0}
                                    </td>
                                </tr>
                                {referenceWithdrawalsData[referenceSwitch] &&
                                    referenceWithdrawalsData[referenceSwitch].map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <th className={classes.leftCell}>現金引き出し{index + 1}</th>
                                                <td className={classes.rightCell}>
                                                    {item?.withdrawal_amount.toLocaleString() ?? 0}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                <tr>
                                    <th className={classes.leftCell}>領収書合計</th>
                                    <td className={classes.rightCell}>
                                        {referenceTravelExpenseTotalAmount[referenceSwitch]
                                            ? parseInt(
                                                  referenceTravelExpenseTotalAmount[referenceSwitch]
                                                      ?.travel_expense_total_amount ?? 0
                                              ).toLocaleString()
                                            : 0}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.leftCell}>差引現金残</th>
                                    <td className={classes.rightCell}>
                                        {(
                                            (referenceData[referenceSwitch]
                                                ? referenceData[referenceSwitch][4]
                                                    ? referenceData[referenceSwitch][4].monthly_data_amount
                                                    : 0
                                                : 0) +
                                            (referenceWithdrawalsData[referenceSwitch]
                                                ? referenceWithdrawalsData[referenceSwitch].reduce(function (
                                                      sum,
                                                      element
                                                  ) {
                                                      return parseInt(sum) + parseInt(element?.withdrawal_amount ?? 0);
                                                  },
                                                  0)
                                                : 0) -
                                            (referenceTravelExpenseTotalAmount[referenceSwitch]
                                                ? parseInt(
                                                      referenceTravelExpenseTotalAmount[referenceSwitch]
                                                          ?.travel_expense_total_amount ?? 0
                                                  )
                                                : 0)
                                        ).toLocaleString()}
                                    </td>
                                </tr>
                                {/* 現金出納帳 END */}
                                {/* 資金 START */}
                                <tr className='table-success'>
                                    <th colSpan={2}>資金</th>
                                </tr>
                                <tr>
                                    <th className={classes.leftCell}>翌月繰越現預金</th>
                                    <td className={classes.rightCell}>
                                        {referenceData[referenceSwitch]
                                            ? referenceData[referenceSwitch][5]
                                                ? referenceData[referenceSwitch][5].monthly_data_amount.toLocaleString()
                                                : 0
                                            : 0}
                                    </td>
                                </tr>

                                <ShowRow
                                    dataName='bank_account'
                                    state={bankAccounts}
                                    data={referenceData[referenceSwitch] ? referenceData[referenceSwitch][2] : null}
                                />
                                <tr>
                                    <th className={classes.leftCell}>月残高合計</th>
                                    <td className={classes.rightCell}>
                                        {referenceData[referenceSwitch]
                                            ? referenceData[referenceSwitch][2]
                                                  .reduce(function (sum, element) {
                                                      return parseInt(sum) + parseInt(element.monthly_data_amount);
                                                  }, 0)
                                                  .toLocaleString()
                                            : 0}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.leftCell}>差額</th>
                                    <td className={classes.rightCell}>
                                        {referenceData[referenceSwitch]
                                            ? (
                                                  parseInt(
                                                      referenceData[referenceSwitch][2].reduce(function (sum, element) {
                                                          return parseInt(sum) + parseInt(element.monthly_data_amount);
                                                      }, 0) ?? 0
                                                  ) -
                                                  parseInt(
                                                      referenceData[referenceSwitch][5]
                                                          ? referenceData[referenceSwitch][5].monthly_data_amount
                                                          : 0
                                                  )
                                              ).toLocaleString()
                                            : 0}
                                    </td>
                                </tr>
                                {/* 資金 END */}
                                {/* その他 END */}
                                <tr className='table-secondary'>
                                    <th colSpan={2}>その他</th>
                                </tr>
                                {referenceData[referenceSwitch]
                                    ? referenceData[referenceSwitch][6].map((item, index) => {
                                          return (
                                              <tr key={index}>
                                                  <th className={classes.leftCell}>{item.target_name}</th>
                                                  <td className={classes.rightCell}>
                                                      {item.monthly_data_amount.toLocaleString()}
                                                  </td>
                                              </tr>
                                          );
                                      })
                                    : null}
                                <tr>
                                    <td colSpan={2}></td>
                                </tr>
                                <tr className='table-primary'>
                                    <th colSpan={2}>特記事項</th>
                                </tr>
                                <tr>
                                    <td className={classes.wrap} colSpan={2}>
                                        {referenceNotice[referenceSwitch]?.notice_detail
                                            ? referenceNotice[referenceSwitch]?.notice_detail
                                            : "データなし"}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/* 参照月 END */}
                    {/* 対象月 START */}
                    <div className='col-5'>
                        <table className='table table-bordered'>
                            <caption className='caption-top'>対象月&nbsp;&nbsp;{displayTargetDate}</caption>
                            <tbody>
                                {/* 売上 START */}
                                <tr className='table-danger'>
                                    <th colSpan={2}>売上</th>
                                </tr>
                                <InputRow
                                    id={1}
                                    date={selectedTargetDate}
                                    inputName='proceed'
                                    dataName='bank_account'
                                    state={proceeds}
                                    setState={setProceeds}
                                    setSum={setProceedsSum}
                                    row={proceedsRow}
                                    setRow={setProceedsRow}
                                    data={proceedsData}
                                    setData={setProceedsData}
                                />
                                <tr>
                                    <th className={classes.leftCell}>売上合計</th>
                                    <td className={classes.leftCell}>{proceedsSum.toLocaleString()}</td>
                                </tr>
                                {/* 売上 END */}
                                {/* 経費 START */}
                                <tr className='table-info'>
                                    <th colSpan={2}>経費</th>
                                </tr>
                                <InputRow
                                    id={2}
                                    date={selectedTargetDate}
                                    inputName='expense'
                                    dataName='bank_account'
                                    state={expenses}
                                    setState={setExpenses}
                                    setSum={setExpensesSum}
                                    row={expensesRow}
                                    setRow={setExpensesRow}
                                    data={expensesData}
                                    setData={setExpensesData}
                                />
                                <tr>
                                    <th className={classes.leftCell}>領収書合計</th>
                                    <td className={classes.leftCell}>
                                        {travelExpenseTotalAmount[0]
                                            ? parseInt(
                                                  travelExpenseTotalAmount[0].travel_expense_total_amount ?? 0
                                              ).toLocaleString()
                                            : 0}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.leftCell}>経費合計</th>
                                    <td className={classes.leftCell}>
                                        {(
                                            (travelExpenseTotalAmount[0]
                                                ? parseInt(travelExpenseTotalAmount[0].travel_expense_total_amount ?? 0)
                                                : 0) + expensesSum
                                        ).toLocaleString()}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.leftCell}>予納その他経費</th>
                                    <td className={classes.leftCell}>
                                        <MonthlyDataAmount
                                            id={4}
                                            date={selectedTargetDate}
                                            state={advancePayment}
                                            setState={setAdvancePayment}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.leftCell}>粗利益</th>
                                    <td className={classes.leftCell}>{grossMargin.toLocaleString()}</td>
                                </tr>
                                {/* 経費 END */}
                                {/* 現金出納帳 START */}
                                <tr className='table-success'>
                                    <th colSpan={2}>現金出納帳</th>
                                </tr>
                                <tr>
                                    <th className={classes.leftCell}>小口繰越現金</th>
                                    <td className={classes.leftCell}>
                                        <MonthlyDataAmount
                                            id={5}
                                            date={selectedTargetDate}
                                            state={pettyCash}
                                            setState={setPettyCash}
                                            sum={withdrawalsSum}
                                            setSub={setWithdrawalsSub}
                                            totalAmount={
                                                travelExpenseTotalAmount[0]
                                                    ? travelExpenseTotalAmount[0].travel_expense_total_amount ?? 0
                                                    : 0
                                            }
                                        />
                                    </td>
                                </tr>
                                <TargetWithdrawals
                                    id={3}
                                    date={selectedTargetDate}
                                    inputName='withdrawal'
                                    listName={"現金引き出し"}
                                    sum={withdrawalsSum}
                                    setSum={setWithdrawalsSum}
                                    pettyCash={pettyCash}
                                    totalAmount={
                                        travelExpenseTotalAmount[0]
                                            ? travelExpenseTotalAmount[0].travel_expense_total_amount ?? 0
                                            : 0
                                    }
                                    setSub={setWithdrawalsSub}
                                    row={withdrawalsRow}
                                    setRow={setWithdrawalsRow}
                                    data={withdrawalsData}
                                    setData={setWithdrawalsData}
                                />
                                <tr>
                                    <th className={classes.leftCell}>領収書合計</th>
                                    <td className={classes.leftCell}>
                                        {travelExpenseTotalAmount[0]
                                            ? parseInt(
                                                  travelExpenseTotalAmount[0].travel_expense_total_amount ?? 0
                                              ).toLocaleString()
                                            : 0}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.leftCell}>差引現金残</th>
                                    <td className={classes.leftCell}>{withdrawalsSub.toLocaleString() ?? 0}</td>
                                </tr>
                                {/* 現金出納帳 END */}
                                {/* 資金 START */}
                                <tr className='table-success'>
                                    <th colSpan={2}>資金</th>
                                </tr>
                                <tr>
                                    <th className={classes.leftCell}>翌月繰越現預金</th>
                                    <td className={classes.leftCell}>
                                        <MonthlyDataAmount
                                            id={6}
                                            date={selectedTargetDate}
                                            state={nextMonthCash}
                                            setState={setNextMonthCash}
                                            sum={bankAccountsSum}
                                            setSub={setBankAccountSub}
                                        />
                                    </td>
                                </tr>
                                <InputRow
                                    id={3}
                                    date={selectedTargetDate}
                                    inputName='bank_account'
                                    dataName='bank_account'
                                    state={bankAccounts}
                                    setState={setBankAccounts}
                                    setSum={setBankAccountSum}
                                    amount={nextMonthCash}
                                    setSub={setBankAccountSub}
                                    row={bankAccountsRow}
                                    setRow={setBankAccountsRow}
                                    data={bankAccountsData}
                                    setData={setBankAccountsData}
                                />
                                <tr>
                                    <th className={classes.leftCell}>月残高合計</th>
                                    <td className={classes.leftCell}>{bankAccountsSum.toLocaleString()}</td>
                                </tr>
                                <tr>
                                    <th className={classes.leftCell}>差額</th>
                                    <td className={classes.leftCell}>
                                        {(
                                            (Number(bankAccountsSum) ?? 0) -
                                            Number(nextMonthCash?.monthly_data_amount ?? 0)
                                        ).toLocaleString()}
                                        {/* {bankAccountSub == 0 ? 0 : (-bankAccountSub).toLocaleString()} */}
                                    </td>
                                </tr>
                                {/* 資金 END */}
                                {/* その他 START */}
                                <tr className='table-secondary'>
                                    <th colSpan={2}>その他</th>
                                </tr>
                                <InputRow
                                    id={7}
                                    date={selectedTargetDate}
                                    inputName='other'
                                    dataName='other'
                                    state={others}
                                    setState={setOthers}
                                    setSum={setOthersSum}
                                    row={othersRow}
                                    setRow={setOthersRow}
                                    data={othersData}
                                    setData={setOthersData}
                                />
                                {/* その他 END */}
                                <tr className='table-primary'>
                                    <th colSpan={2}>特記事項</th>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <TargetNotice
                                            date={selectedTargetDate}
                                            state={targetNotice}
                                            setState={setTargetNotice}></TargetNotice>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/* 対象月 END */}
                </div>
                <div className='form-group mb-3 d-flex justify-content-center'>
                    <Button type='button' variant='contained' color='primary' onClick={(e) => MonthlyDataSubmit(e)}>
                        確認
                    </Button>
                </div>
            </div>
        </>
    );
}
