//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { Button, Box } from "@material-ui/core";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import DataTable from "../../components/DataTable";
import BackButton from "../../components/BackButton";
import CloseButton from "../../components/CloseButton";
import FreeWordSearch from "../../components/FreeWordSearch";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { UserContext } from "../../providers/UserProvider";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { HOME_PATH } from "../../common/constants";
import processResponse from "../../common/processResponse";
import { checkAuthorities } from "../../common/checkAuthorities";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// ホーム画面に戻る時の遷移先のパス
const returnPath = HOME_PATH;
// 見出しの配列のパターンを定義
const initHeaders = ["経費先名", "備考"];
const initHeadersOfButtons = ["編集"];

// 検索対象のカラムの配列定義
const columns = ["expense_name", "expense_remarks"];
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// expenses の初期状態
const initExpenses = { result: [], success: false };
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function Expense() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面遷移用
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // ログイン中のユーザー情報を取得
    const { userInfo } = useContext(UserContext);
    const userAuthorityNumber = userInfo.user.authority_number; // 権限
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 権限確認用関数を分割代入
    const { checkAccountant } = checkAuthorities;
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // expenses の状態を管理する
    const [expenses, setExpenses] = useState(initExpenses);

    // expenses を表示行に変換したものを保持する
    const [rows, setRows] = useState([]);

    // 検索された経費先のデータを格納するステート
    const [searchedExpenses, setSearchedExpenses] = useState([]);

    // 見出しの配列を保持するステート
    const [headers, setHeaders] = useState(initHeaders);

    // ボタン表示フラグ
    const [displayButtons, setDisplayButtons] = useState(false);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 情報を取得しステート expenses にセットする
    const getExpensesData = async () => {
        const url = "expenses";
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        processResponse(res, setExpenses, navigate, returnPath);
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 新規登録ボタンクリック時
    const handleOnClickRegister = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // 新規登録画面へ進む
        navigate(`/expense/register`);
    };

    // 一覧の編集ボタンをクリックしたら、経費先情報編集画面へ
    const handleOnClickEdit = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 編集画面へ進む
        navigate(`/expense/edit/${id}`);
    };

    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => {
        // 画面初期表示時に経費先情報を取得する
        getExpensesData();

        // 権限ごとにステート（ボタン表示フラグ及びログインユーザー情報）を調整(初回のみ実行)
        // 管理者以上
        if (checkAccountant(userAuthorityNumber)) {
            setDisplayButtons(true);
            setHeaders([...initHeaders, ...initHeadersOfButtons]);
        }
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // searchedExpensesに応じて、画面表示用の配列を生成
    useEffect(() => {
        if (searchedExpenses && expenses.success) {
            const newRows = searchedExpenses.map((expense) => {
                const row = {
                    id: expense.expense_id,
                    isActive: true,
                    data: {
                        name: expense.expense_name,
                        remarks: expense.expense_remarks === null ? "-" : expense.expense_remarks,
                        editBtn: (
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={(e) => handleOnClickEdit(e, expense.expense_id)}>
                                編集
                            </Button>
                        )
                    }
                };

                // ボタンを表示しない場合はボタン要素のプロパティを削除
                if (!displayButtons) {
                    delete row.data.editBtn;
                }

                return row;
            });

            setRows(newRows);
        }
    }, [searchedExpenses]);
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1>経費先情報一覧</h1>
                        <div className='ms-auto'>
                            <CloseButton path={returnPath} />
                        </div>
                    </div>
                    <div className='card-body'>
                        {displayButtons && (
                            <Box className='card-body' sx={{ display: "flex", width: "max-content", ml: "auto" }}>
                                <Button variant='contained' color='primary' onClick={(e) => handleOnClickRegister(e)}>
                                    新規登録
                                </Button>
                            </Box>
                        )}
                        <FreeWordSearch
                            searchColumns={columns}
                            data={expenses.result}
                            setSearchedData={setSearchedExpenses}
                        />
                        <DataTable headers={headers} rows={rows} />
                    </div>
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackButton path={returnPath} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
