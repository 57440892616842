import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { api } from "../../api/api";
const noImage = "/img/no_image.jpg";
export default function ShowReceipt(props) {
    const { filePath, file, setFile, setIsReceiptOpen } = props;
    const [photo, setPhoto] = useState(null);
    const getReceipt = async (path) => {
        // コントローラーに渡す値を作成
        const data = { path: path };
        const res = await api.post("file", data);
        // 値が存在する場合state(=imgのURI)を更新
        const gotData = res.data.result;
        if (gotData) {
            const adjustedData = JSON.parse(gotData); // 受け取ったデータをjsonに
            const mimeType = adjustedData.mimeType;
            const fileText = adjustedData.fileText;
            const receiptUri = "data:" + mimeType + ";base64," + fileText;
            setPhoto(receiptUri);
            setFile(receiptUri);
        } else {
            setPhoto(noImage);
        }
    };
    const CropDemo = (src) => (
        <span className='text-center my-4 me-5 d-inline-flex align-items-start'>
            <img src={src} className='receiptPhoto' alt='撮影した写真' />
        </span>
    );
    useEffect(() => {
        if (filePath && file === null) {
            getReceipt(filePath); // 保存済みの写真を取得
        } else if (file !== null) {
            setPhoto(file);
        } else {
            setPhoto(noImage);
        }
    }, []);
    return (
        <div className='d-flex justify-content-start align-content-center me-5'>
            {photo !== null ? CropDemo(photo) : <p className='p-5 me-5'>...読み込み中</p>}
        </div>
    );
}
