//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import TravelExpenseDraftInputRow from "./TravelExpenseDraftInputRow";
import TravelExpenseUnregisteredDraftInputRow from "./TravelExpenseUnregisteredDraftInputRow";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { TravelExpenseContext } from "../../../contents/travel_expense/TravelExpenseRegister";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import processResponse from "../../../common/processResponse";
import amountToLocaleString from "../../../common/amountToLocaleString";
import { MIN_MAX_DATE } from "../../../common/constants";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//スタイルの定義
const useStyles = makeStyles((theme) => createStyles({ heightMaxContent: { height: "max-content" } }));
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// ステートの初期状態
const initNewRow = { result: [], success: false };

// ステートの初期状態
const initTravelExpenses = { result: [], success: false };

// ステートの初期状態
const initPayees = { result: [], success: false };

// ステートの初期状態
const initAccountLists = { result: [], success: false };

// テーブルの見出しを定義
const headers = ["No.", "月日", "金額", "支払先", "仕訳", "領収書", ""]; // 経費用

// リクエストデータのカラム名一覧を保持
const inputList = ["date", "amount", "name", "account_list_id"];

//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function TravelExpensesDraftRequestTable(props) {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //定義したスタイルを利用するための設定
    const classes = useStyles();
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面遷移用
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { returnPath, start, selectedMonthAndYear, loggedInUserId, isClosed } = useContext(TravelExpenseContext);
    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const {
        travelExpensesDraft,
        setTravelExpensesDraft = (f) => f,
        dispatchTravelExpenseDraftRequestData = (f) => f,
        maxAdditionalNumber,
        travelExpenseAdditionalNumbers,
        dispatchTravelExpenseAdditionalNumbers = (f) => f,
        dispatchTravelExpenseDraftValidationErrors,
        travelExpenseDraftValidationTrigger,
        travelExpenseDraftTotalAmount,
        travelExpensesSubmit,
        travelExpensesDraftSubmit,
        dispatchAdditionalDraft
    } = props;
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const { MIN_DATE } = MIN_MAX_DATE;
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    const [travelExpensesResultLength, setTravelExpensesResultLength] = useState(0);

    // 展開時に使用するステートを用意
    const [dataForDeploy, setDataForDeploy] = useState([]);

    // payees の状態を管理する
    const [payees, setPayees] = useState(initPayees);

    // プルダウンメニュー用の支払先の配列を格納する
    const [payeesMenu, setPayeesMenu] = useState([]);

    // accountLists の状態を管理する
    const [accountLists, setAccountLists] = useState(initAccountLists);

    // プルダウンメニュー用の勘定科目の配列を格納する
    const [accountListsMenu, setAccountListsMenu] = useState([]);

    // 対象月から期間を割り出す
    const [monthlyPeriod, setMonthlyPeriod] = useState({ min: "", max: "" });

    const [isChanged, setIsChanged] = useState(false);

    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 経費を取得
    const getTravelExpenses = async (user, month) => {
        const url = `travel_expenses/${user}/${month}/${1}`;
        const res = await api.get(url);
        // 成功時、ステートを更新
        if (res.data.success) {
            setTravelExpensesDraft(res.data);
        }
    };

    // 支払先一覧を取得
    const getPayees = async () => {
        const url = "payees";
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        processResponse(res, setPayees, navigate, returnPath);
    };

    // 勘定科目一覧を取得
    const getAccountLists = async () => {
        const url = "account_lists";
        const res = await api.get(url);
        // resの内容に応じて処理を行う
        processResponse(res, setAccountLists, navigate, returnPath);
    };

    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 画面初期表示時に勘定科目情報を取得する
    useEffect(() => {
        // DBから各種データを取得
        getPayees();
        getAccountLists();
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // dateのmin・maxの値を作成
    // 対象月が今月の場合は、入力可能な上限を今日までとし、先月以前の場合はその月末とする
    useEffect(() => {
        if (selectedMonthAndYear) {
            const today = new Date();
            const selectedMonthEnd = new Date(`${selectedMonthAndYear}-01`);
            selectedMonthEnd.setMonth(selectedMonthEnd.getMonth() + 1);
            selectedMonthEnd.setDate(0);
            let maxDate = makeFormattedDate(today);
            if (today > selectedMonthEnd) {
                maxDate = makeFormattedDate(selectedMonthEnd);
            }
            setMonthlyPeriod({ ...monthlyPeriod, min: MIN_DATE, max: maxDate });
        }
    }, [selectedMonthAndYear]);

    const makeFormattedDate = (date) => {
        const y = date.getFullYear();
        const m = ("0" + (date.getMonth() + 1)).slice(-2);
        const d = ("0" + date.getDate()).slice(-2);
        return `${y}-${m}-${d}`;
    };

    useEffect(() => {
        if (start) {
            getTravelExpenses(loggedInUserId, selectedMonthAndYear);
        } else {
            setTravelExpensesDraft(initTravelExpenses);
            setTravelExpensesResultLength(0);
            dispatchTravelExpenseAdditionalNumbers({ type: "reset" });
            setDataForDeploy([]);
            dispatchTravelExpenseDraftRequestData({ type: "reset" });
            dispatchAdditionalDraft({ type: "reset" });
        }
    }, [start]);

    // 初期値をRefオブジェクトに保管・フォーム展開用データの作成
    useEffect(() => {
        if (travelExpensesDraft.success) {
            // 既に登録済みのデータが存在する場合の処理
            if (Object.keys(travelExpensesDraft.result).length > 0) {
                // 追加の行のナンバーの一つ目を設定
                const maxNumber = travelExpensesDraft.result.length;
                setTravelExpensesResultLength(maxNumber);
                const nextNumber = maxNumber + 1;
                dispatchTravelExpenseAdditionalNumbers({ type: "set", value: [nextNumber] });

                // 送信するデータを作成
                const requestData = [];
                let num = 1;
                for (const travelExpense of travelExpensesDraft.result) {
                    const data = {
                        id: travelExpense.travel_expense_id,
                        number: num++,
                        date: travelExpense.travel_expense_date,
                        user_id: loggedInUserId,
                        amount: travelExpense.travel_expense_amount,
                        name: travelExpense.travel_expense_name,
                        is_confirmed: travelExpense.travel_expense_is_confirmed,
                        is_paid: travelExpense.travel_expense_is_paid,
                        is_changed: travelExpense.travel_expense_is_changed,
                        account_list_id: travelExpense.travel_expense_account_list_id,
                        file_name: travelExpense.travel_expense_file_name,
                        file: null // リクエストデータ用
                    };
                    requestData.push(data);
                }
                // ステートに格納
                setDataForDeploy(requestData);
                dispatchTravelExpenseDraftRequestData({ type: "set", data: requestData });
            } else {
                dispatchTravelExpenseAdditionalNumbers({ type: "set", value: [1] });
            }

            // 締め処理が済んでいるかを判断しステートを更新
            // setIsClosed(Boolean(travelExpenses.result.find((item) => item.travel_expense_is_confirmed === 1)));
        }
    }, [travelExpensesDraft]);

    // payees からプルダウンメニュー用の配列を作成する
    useEffect(() => {
        if (payees.success) {
            // 取得した勘定科目情報から必要な値を配列にする
            const payeesArray = payees.result.map((payee) => {
                return {
                    label: payee.payee_name,
                    value: { payeeName: payee.payee_name, accountListId: payee.payee_account_list_id }
                };
            });
            // ステートに格納する
            setPayeesMenu(payeesArray);
        }
    }, [payees]);

    // accountLists からプルダウンメニュー用の配列を作成する
    useEffect(() => {
        if (accountLists.success) {
            // 取得した勘定科目情報から必要な値を配列にする
            const accountListsArray = accountLists.result.map((accountList) => {
                return {
                    label: accountList.account_list_name,
                    value: accountList.account_list_id
                };
            });
            // ステートに格納する
            setAccountListsMenu(accountListsArray);
        }
    }, [accountLists]);

    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <>
            {start && travelExpensesDraft.success ? (
                <>
                    <p className='fw-bold'>下書き</p>
                    <Paper className={classes.heightMaxContent}>
                        <TableContainer>
                            <Table
                                className='table table-sm table-bordered text-nowrap'
                                aria-labelledby='travel_expenses'
                                size='medium'
                                aria-label='travel expenses table'>
                                <TableHead>
                                    <TableRow className='table-active'>
                                        <TableCell align='center' padding='none' colSpan={headers.length}>
                                            経費明細
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className='table-light'>
                                        {headers.map((header, index) => (
                                            <TableCell key={index} align='center' padding='none'>
                                                {header}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataForDeploy.length > 0 &&
                                        dataForDeploy.map((travelExpenseData, index) => {
                                            return (
                                                <TravelExpenseDraftInputRow
                                                    key={travelExpenseData.id}
                                                    index={index + 1}
                                                    targetId={travelExpenseData.id}
                                                    number={travelExpenseData.number}
                                                    loggedInUserId={loggedInUserId}
                                                    travelExpenseData={travelExpenseData}
                                                    dispatchTravelExpenseDraftRequestData={
                                                        dispatchTravelExpenseDraftRequestData
                                                    }
                                                    payeesMenu={payeesMenu}
                                                    accountListsMenu={accountListsMenu}
                                                    monthlyPeriod={monthlyPeriod}
                                                    maxAdditionalNumber={maxAdditionalNumber}
                                                    dispatchTravelExpenseAdditionalNumbers={
                                                        dispatchTravelExpenseAdditionalNumbers
                                                    }
                                                    dispatchTravelExpenseDraftValidationErrors={
                                                        dispatchTravelExpenseDraftValidationErrors
                                                    }
                                                    travelExpenseDraftValidationTrigger={
                                                        travelExpenseDraftValidationTrigger
                                                    }
                                                    setIsChanged={setIsChanged}
                                                />
                                            );
                                        })}
                                    {!isClosed &&
                                        travelExpenseAdditionalNumbers.length > 0 &&
                                        travelExpenseAdditionalNumbers.map((additionalNumber, index) => (
                                            <TravelExpenseUnregisteredDraftInputRow
                                                key={index}
                                                index={travelExpensesResultLength + index + 1}
                                                number={additionalNumber}
                                                loggedInUserId={loggedInUserId}
                                                payeesMenu={payeesMenu}
                                                accountListsMenu={accountListsMenu}
                                                monthlyPeriod={monthlyPeriod}
                                                maxAdditionalNumber={maxAdditionalNumber}
                                                dispatchTravelExpenseAdditionalNumbers={
                                                    dispatchTravelExpenseAdditionalNumbers
                                                }
                                                dispatchTravelExpenseDraftValidationErrors={
                                                    dispatchTravelExpenseDraftValidationErrors
                                                }
                                                travelExpenseDraftValidationTrigger={
                                                    travelExpenseDraftValidationTrigger
                                                }
                                                setIsChanged={setIsChanged}
                                                dispatchAdditionalDraft={dispatchAdditionalDraft}
                                            />
                                        ))}
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell colSpan={2} align='right'>
                                            小計
                                        </TableCell>
                                        <TableCell align='right'>
                                            {amountToLocaleString(travelExpenseDraftTotalAmount)}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>{" "}
                    {start && !isClosed && travelExpensesDraft.success && (
                        <div className='d-flex justify-content-center mt-3 text-nowrap'>
                            <button
                                className='btn btn-lg btn-outline-secondary py-2 px-5 me-3'
                                type='button'
                                onClick={(e) => travelExpensesDraftSubmit(e)}>
                                下書き保存
                            </button>
                            <button
                                className='btn btn-lg btn-outline-success py-2 px-5'
                                type='button'
                                onClick={(e) => travelExpensesSubmit(e)}>
                                登録
                            </button>
                        </div>
                    )}
                </>
            ) : null}
        </>
    );
}
