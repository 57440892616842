//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^React読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import React, { useContext, useEffect, useRef, useState } from "react";
//_________________________________________________React読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-dom関連読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { useNavigate } from "react-router-dom";
//_________________________________________________react-router-dom関連読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ライブラリの読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import swal from "sweetalert";
import { startOfMonth, subMonths } from "date-fns";
//_________________________________________________ライブラリの読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^MUI読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________MUI読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Api読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { api } from "../../api/api";
//_________________________________________________Api読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import ItemNameColumn from "../../components/transaction/monthly_data/ItemNameColumn";
import OutputSingleMonthSpreadsheetButton from "../../components/transaction/monthly_data/OutputSingleMonthSpreadsheetButton";
import MonthlyDataColumnForTaxAccountant from "../../components/transaction/monthly_data/MonthlyDataColumnForTaxAccountant";
import SelectMonthAndYearMenu from "../../components/transaction/monthly_data/SelectMonthAndYearMenu";
import BackButton from "../../components/BackButton";
//_________________________________________________コンポーネント読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンテクスト読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { setMonthlyDataReferenceContext } from "../../providers/MonthlyDataReferenceProvider";
//_________________________________________________コンテクスト読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^共通関数・定数の読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
import { HOME_PATH } from "../../common/constants";
import dateFormat from "../../common/dateFormat";
import monthAndYearFormat from "../../common/monthAndYearFormat";
import processResponse from "../../common/processResponse";
//_________________________________________________共通関数・定数の読み込み_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
//_________________________________________________スタイルの定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント内で扱う定数の定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// 戻るときの遷移先のパス
const returnPath = HOME_PATH;

// 先月の日付を取得
const thisMonth = new Date();
// 先月の日付を取得
const lastMonth = startOfMonth(subMonths(new Date(), 1));
//_________________________________________________コンポーネント内で扱う定数の定義_________________________________________________//

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ステートの初期値を定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
// ステートの初期状態
const initMonthlyData = { result: [], success: false };

// ステートの初期状態
const initBankAccounts = { result: [], success: false };

// ステートの初期状態
const initOthers = { result: [], success: false };

// ステートの初期値
const initTargetTravelExpensesTotalAmounts = { result: [], success: false };
const initTargetNotices = { result: [], success: false };
const initTargetWithdrawal = { result: [], success: false };
//_________________________________________________ステートの初期値を定義_________________________________________________//

//=====================================================関数コンポーネントここから=====================================================//
export default function MonthlyDataReferenceForTaxAccountant() {
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^スタイルの定義を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________スタイルの定義を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^react-router-domに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const navigate = useNavigate();
    //_________________________________________________react-router-domに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^useContextに関する機能を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    const {
        setProceedsList,
        setExpensesList,
        setBankAccountsList,
        setOthersList
    } = useContext(setMonthlyDataReferenceContext);

    //_________________________________________________useContextに関する機能を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^URLに含まれるパラメーターを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________URLに含まれるパラメーターを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^propsを読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________propsを読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込んだ共通関数・定数を読み込み^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    //_________________________________________________読み込んだ共通関数・定数を読み込み_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^各種ステートやRefオブジェクトを定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//

    // datePickerの選択済みの値を管理する
    const [startDate, setStartDate] = useState(thisMonth);

    // 選択した対象月の1日の日付を文字列として管理する
    const [selectedMonthAndYear, setSelectedMonthAndYear] = useState("");

    // 選択した対象月に対しての作業を開始する
    const [start, setStart] = useState(false);

    // 画面読み込みの制御
    const [loaded, setLoaded] = useState(false);

    // 月次損益の選択した年度の値を管理する
    const [monthlyData, setMonthlyData] = useState(initMonthlyData);


    // 資金口座の全データを管理する
    const [bankAccounts, setBankAccounts] = useState(initBankAccounts);

    const bankAccountsListRef = useRef([]); // resultのデータを変換して保持

    // その他の全データを管理する
    const [others, setOthers] = useState(initOthers);

    const othersListRef = useRef([]); // resultのデータを変換して保持

    // 展開用のデータを月ごとに管理
    const [monthlyDataForDeploy, setMonthlyDataForDeploy] = useState({});

    // DBから取得した旅費精算合計の値を管理
    const [
        targetTravelExpensesTotalAmounts,
        setTargetTravelExpensesTotalAmounts
    ] = useState(initTargetTravelExpensesTotalAmounts);

    // 展開用の旅費精算の合計値のデータを管理する
    const [
        travelExpensesTotalAmountsForDeploy,
        setTravelExpensesTotalAmountsForDeploy
    ] = useState({});

    // DBから取得した特記事項の値を管理
    const [targetNotices, setTargetNotices] = useState(initTargetNotices);

    // 特記事項の合計値のデータを管理する
    const [noticesForDeploy, setNoticesForDeploy] = useState({});

    // DBから取得した現金引き出しの値を管理
    const [targetWithdrawal, setTargetWithdrawal] =
        useState(initTargetWithdrawal);

    // 現金引き出しの合計値のデータを管理する
    const [withdrawalForDeploy, setWithdrawalForDeploy] = useState({});

    //データがない時True
    const [isNoData, setIsNoData] = useState(false);
    //_________________________________________________各種ステートやRefオブジェクトを定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^DB接続に関する関数の記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 各種データを取得
    const getData = async (url, setState) => {
        const res = await api.get(url);
        processResponse(res, setState, navigate, returnPath);
    };
    //_________________________________________________DB接続に関する関数の記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^イベントハンドラーの定義^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 年月の選択に変更があったときの処理
    const handleOnChangeDate = (date) => {
        // 年月（datePicker）の選択している月をステートに格納
        setStartDate(date);
    };

    // 開始ボタン押下時
    const handleOnClickSelectSubmit = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // 年月のデータを読み込み開始
        setStart(true);
    };

    // 選択画面に戻るボタン押下時
    const handleOnClickResetSelected = async (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        await swal({
            title: "年月の選択に戻ります",
            text: "",
            icon: "info",
            buttons: true
        }).then((value) => {
            if (value) {
                backBeforeStart();
            }
        });
    };
    //_________________________________________________イベントハンドラーの定義_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^読み込み時の一度きりの副作用フックを記述^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    useEffect(() => {
        // 各種カテゴリのそれぞれの項目名一覧を取得
        getData(`bank_accounts`, setBankAccounts); // 口座を取得
        getData(`others`, setOthers); // その他を取得
    }, []);
    //_________________________________________________読み込み時の一度きりの副作用フックを記述_________________________________________________//

    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^コンポーネント独自の関数など^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    // 読み込み前に戻る
    const backBeforeStart = () => {
        setStart(false);
        setLoaded(false);
        setMonthlyData(initMonthlyData);
        setMonthlyDataForDeploy({});
        setTargetTravelExpensesTotalAmounts(
            initTargetTravelExpensesTotalAmounts
        );
        setTravelExpensesTotalAmountsForDeploy({});
        setTargetNotices(initTargetNotices);
        setNoticesForDeploy({});
        setWithdrawalForDeploy({});
    };

    // 年度が選択、またはステートの初期値が入った時に、選択済みの年度をステートに格納
    useEffect(() => {
        if (startDate) {
            setSelectedMonthAndYear(monthAndYearFormat(startDate));
        }
    }, [startDate]);

    // 年度のデータ読み込み開始時の処理
    useEffect(() => {
        if (start && selectedMonthAndYear !== "") {
            getData(`monthly_data/${selectedMonthAndYear}`, setMonthlyData); // 選択した年度の月次損益データを取得

            getData(
                `travel_expenses_target_month_total_amount/${selectedMonthAndYear.slice(
                    0,
                    7
                )}`,
                setTargetTravelExpensesTotalAmounts
            ); // 表示対象の年度内の旅費精算の合計値全件を取得

            getData(`notices/${selectedMonthAndYear}`, setTargetNotices); // 表示対象の年度内の特記事項全件を取得
            getData(
                `withdrawal_month_total/${selectedMonthAndYear}`,
                setTargetWithdrawal
            ); // 表示対象の年度内の現金引き出し全件を取得
        }
    }, [start]);

    // 各カテゴリーごとにデータを振り分ける処理
    const distributeData = (data) => {
        // 各月ごとに全カテゴリーのidをキーにしたオブジェクトを包括する空の配列を用意
        const distributedData = {
            1: data[0],
            2: data[1],
            3: data[2],
            4: data[3] ? [data[3]] : [],
            5: data[4] ? [data[4]] : [],
            6: data[5] ? [data[5]] : [],
            7: data[6]
        };
        setIsNoData(
            distributedData[1].length === 0 &&
                distributedData[2].length === 0 &&
                distributedData[3].length === 0 &&
                distributedData[4].length === 0 &&
                distributedData[5].length === 0 &&
                distributedData[6].length === 0 &&
                distributedData[7].length === 0
        );
        return distributedData;
    };

    // 表示する項目名を表示対象の項目のみに絞り込む
    const makeFilteredList = (tableDataList, monthlyPrices) => {
        const data = monthlyPrices.filter(
            (monthlyPrice) => monthlyPrice !== null
        );
        // 表示対象、または前年度のいずれかに該当する項目の値が存在する項目のみを抽出
        return tableDataList.filter(
            (tableData) =>
                data.findIndex(
                    (price) => price.monthly_data_target_id === tableData.id
                ) != -1
        );
    };

    // データベースからデータの取得が済んだとき
    useEffect(() => {
        if (monthlyData.success) {
            if (monthlyData.result.length > 0) {
                // 対象の年月のリストが作成されてから、表示対象のデータを展開するための形に振り分ける
                setMonthlyDataForDeploy(
                    distributeData(monthlyData.result ?? [])
                );
            }
        }
    }, [monthlyData]);

    // データベースからデータの取得が済んだとき
    useEffect(async () => {
        if (monthlyData.success && Object.keys(monthlyDataForDeploy).length > 0) {
            if (isNoData) {
                // データが存在しない場合、アラートを表示し
                await swal("データが未作成です", "", "info");

                // 開始以前に戻る
                backBeforeStart();

                return;
            } else {
                // 表示する項目名を表示対象の項目のみに絞り込み、展開するためのステートにセットしていく
                setProceedsList(
                    makeFilteredList(
                        bankAccountsListRef.current,
                        monthlyDataForDeploy[1]
                    )
                );
                setExpensesList(
                    makeFilteredList(
                        bankAccountsListRef.current,
                        monthlyDataForDeploy[2]
                    )
                );
                setBankAccountsList(
                    makeFilteredList(
                        bankAccountsListRef.current,
                        monthlyDataForDeploy[3]
                    )
                );
                setOthersList(
                    makeFilteredList(
                        othersListRef.current,
                        monthlyDataForDeploy[7]
                    )
                );
            }
        }
    }, [monthlyDataForDeploy]);

    // 表示・行管理のために値を配列に作り直す
    useEffect(() => {
        if (bankAccounts.result.length > 0) {
            const result = bankAccounts.result;
            // 配列を作成
            const list = result.map((item) => {
                return {
                    id: item.bank_account_id,
                    name: item.bank_account_name
                };
            });
            // Refオブジェクトを更新
            bankAccountsListRef.current = list;
        }
    }, [bankAccounts]);

    // 表示・行管理のために値を配列に作り直す
    useEffect(() => {
        if (others.result.length > 0) {
            const result = others.result;
            // 配列を作成
            const list = result.map((item) => {
                return { id: item.other_id, name: item.other_name };
            });
            // Refオブジェクトを更新
            othersListRef.current = list;
        }
    }, [others]);

    // 各カテゴリーごとにデータを振り分ける処理
    const distributeForCellData = (targetData, parameterKeyName) => {
        // 各月ごとに全カテゴリーのidをキーにしたオブジェクトを包括する空の配列を用意
        const distributedData = {
            target: null
        };
        if (targetData.length > 0) {
            // 表示対象のデータを振り分ける処理
            for (const item of targetData) {
                distributedData.target = item[parameterKeyName];
            }
        }
        return distributedData;
    };

    // 表示する旅費精算の合計値のステートを更新
    useEffect(() => {
        if (targetTravelExpensesTotalAmounts.success) {
            setTravelExpensesTotalAmountsForDeploy(
                distributeForCellData(
                    targetTravelExpensesTotalAmounts.result,
                    "travel_expense_total_amount"
                )
            );
        }
    }, [targetTravelExpensesTotalAmounts]);

    // 表示する特記事項のステートを更新
    useEffect(() => {
        if (targetNotices.success) {
            setNoticesForDeploy(
                distributeForCellData(
                    targetNotices.result ? [targetNotices.result] : [],
                    "notice_detail"
                )
            );
        }
    }, [targetNotices]);

    // 表示する現金引き出しのステートを更新
    useEffect(() => {
        if (targetWithdrawal.success) {
            setWithdrawalForDeploy(
                distributeForCellData(
                    targetWithdrawal.result,
                    "withdrawal_amount"
                )
            );
        }
    }, [targetWithdrawal]);

    // 読み込みがおおよそ完了したことをステートに反映させて、表を表示する
    useEffect(() => {
        if (
            Object.keys(monthlyDataForDeploy).length > 0 &&
            Object.keys(travelExpensesTotalAmountsForDeploy).length > 0 &&
            Object.keys(noticesForDeploy).length > 0 &&
            Object.keys(withdrawalForDeploy).length > 0 &&
            selectedMonthAndYear.length >= 7 &&
            !isNoData
        ) {
            setLoaded(true);
        }
    }, [
        monthlyDataForDeploy,
        travelExpensesTotalAmountsForDeploy,
        noticesForDeploy,
        withdrawalForDeploy,
        selectedMonthAndYear
    ]);
    //_________________________________________________コンポーネント独自の関数など_________________________________________________//

    //=====================================================JSXここから=====================================================//
    return (
        <div className="row justify-content-center">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header d-flex">
                        <h2>月次損益確認</h2>
                    </div>
                    <div className="card-body">
                        {!start ? (
                            <div className="d-flex justify-content-start align-items-baseline text-nowrap my-4">
                                <SelectMonthAndYearMenu
                                    startDate={startDate}
                                    setStartDate={setStartDate}
                                    minMonthAndYear={lastMonth}
                                />
                                <button
                                    className="btn btn-secondary"
                                    type="button"
                                    disabled={!startDate}
                                    onClick={(e) =>
                                        handleOnClickSelectSubmit(e)
                                    }>
                                    開始
                                </button>
                            </div>
                        ) : (
                            <>
                                {!start && <p className="m-4">...読み込み中</p>}
                            </>
                        )}
                        {start && (
                            <div className="d-flex justify-content-start align-items-center text-nowrap my-4">
                                <label className="fw-bold me-3">
                                    対象月：
                                    <input
                                        type="text"
                                        readOnly
                                        value={
                                            dateFormat(
                                                selectedMonthAndYear,
                                                false,
                                                "Y-m"
                                            ).format_date
                                        }
                                    />
                                </label>
                                {loaded && (
                                    <>
                                        <button
                                            className="btn btn-secondary me-5"
                                            type="button"
                                            onClick={(e) => {
                                                handleOnClickResetSelected(e);
                                            }}>
                                            再選択
                                        </button>
                                        <OutputSingleMonthSpreadsheetButton
                                            month={selectedMonthAndYear}
                                            monthDeployData={
                                                monthlyDataForDeploy
                                            }
                                            withDraw={
                                                withdrawalForDeploy.target
                                            }
                                            totalTravelExpenses={
                                                travelExpensesTotalAmountsForDeploy.target
                                            }
                                            notice={noticesForDeploy.target}
                                        />
                                    </>
                                )}
                            </div>
                        )}
                        <div className="d-flex justify-content-center">
                            {loaded ? (
                                <div className="d-flex shadow">
                                    <div>
                                        <ItemNameColumn />
                                    </div>
                                    <div>
                                        <MonthlyDataColumnForTaxAccountant
                                            targetMonth={
                                                dateFormat(
                                                    selectedMonthAndYear,
                                                    false,
                                                    "Y-m"
                                                ).format_date
                                            }
                                            targetData={monthlyDataForDeploy}
                                            travelExpensesData={
                                                travelExpensesTotalAmountsForDeploy
                                            }
                                            noticesData={noticesForDeploy}
                                            withdrawalData={withdrawalForDeploy}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {start && (
                                        <p className="m-4">...読み込み中</p>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="form-group mb-3 d-flex">
                            <BackButton path={returnPath} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
