import swal from "sweetalert";

export default function processResponse(
    response = { data: {} },
    setResponseData = () => {},
    navigate = () => {},
    path = -1
) {
    if (response.data.success) {
        setResponseData(response.data);
    } else {
        // データ取得に失敗した、もしくはデータがない場合に画面を閉じる
        swal("読み込み失敗", "エラーが発生したため、読み込みを中止します。", "warning").then(() => {
            navigate(path);
        });
    }
}
